import "./StepOneAddCar.css";
import { FC } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormUnregister,
  UseFormWatch,
  FieldErrors,
} from "react-hook-form";
import { BasicInfo } from "../../edit car/sections/basic info/BasicInfo";
import { AddCarDataType } from "../AddCar";

export type OutletContextAddCarType = {
  register: UseFormRegister<AddCarDataType>;
  unregister: UseFormUnregister<AddCarDataType>;
  setValue: UseFormSetValue<AddCarDataType>;
  watch: UseFormWatch<AddCarDataType>;
  trigger: UseFormTrigger<AddCarDataType>;
  errors: FieldErrors<AddCarDataType>;
  error: any;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  isAddSaleCarLoading: boolean;
};

export type StepProps = {
  className?: string;
};
export const StepOneAddCar: FC<StepProps> = ({ className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, unregister, setValue, watch, trigger, errors, setStep } =
    useOutletContext<OutletContextAddCarType>();

  // Current step required fields
  const requiredFields: (keyof AddCarDataType)[] = [
    "make",
    "model",
    "modelYear",
    "trim",
    "exteriorColor",
    "drivetrain",
    "paint",
    "bodyStyle",
  ];

  const handleNext = async () => {
    const isValid = await trigger(requiredFields, { shouldFocus: true });
    if (isValid) {
      navigate("step-two");
      setStep((prev) => prev + 1);
    }
  };

  return (
    <div id="step-one-add-car">
      <BasicInfo
        isAddCar={true}
        register={register}
        unregister={unregister}
        errors={errors}
        setValue={setValue}
        watch={watch}
      />
      {className !== "hidden" && (
        <div className="btns">
          <button className="next" type="button" onClick={handleNext}>
            {t("btns&links.next")}
          </button>
        </div>
      )}
    </div>
  );
};

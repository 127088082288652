import "./EditCar.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSaleCar, useShowroom } from "../../../../../../../hooks/useAuth";
import { useUpdateSaleCarByIdMutation } from "../../../../../../../app/api/saleCarsApiSlice";
import { SaleCarOptions } from "../../../../../../../data/FilterData";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddCarDataType } from "../../add car/AddCar";
import { BasicInfo } from "../sections/basic info/BasicInfo";
import { MileageDetails } from "../sections/mileage details/MileageDetails";
import { TechnicalSpecs } from "../sections/technical specs/TechnicalSpecs";
import { Pricing } from "../sections/pricing/Pricing";
import { AdditionalInfo } from "../sections/additional info/AdditionalInfo";
import { UpdateImages } from "../../../../../../../components/update car images/UpdateImages";
import { Loading } from "../../../../../../../components/customs/loading/Loading";

export type UpdateCarDataType = AddCarDataType & {
  deletedImages?: string[];
};
export const EditCar = () => {
  const { t } = useTranslation();
  const errMessage = (field: string) => t(`errorMessage.${field}`);
  const navigate = useNavigate();
  const { showroom } = useShowroom();
  const { carId } = useParams();
  const [images, setImages] = useState<FileList>([] as any);
  const [savedImages, setSavedImages] = useState<string[]>([]);

  // Update car schema
  const UpdateCarSchema = yup.object().shape({
    make: yup.string().required(errMessage("make")),
    model: yup.string().required(errMessage("model")),
    images: yup.mixed<FileList>().required(errMessage("carImages")),
    // .test("images", errMessage("carImages"), (value) => {
    //   if (value) {
    //     return value.length >= 2;
    //   }
    //   return false;
    // }),
    exteriorColor: yup.string().required(errMessage("exteriorColor")),
    interiorColor: yup.string().required(errMessage("interiorColor")),
    seatMaterial: yup.string().required(errMessage("seatMaterial")),
    seating: yup.string().required(errMessage("seating")),
    transmission: yup.string().required(errMessage("transmission")),
    engine: yup.string().required(errMessage("engine")),
    cylinders: yup.string().required(errMessage("cylinders")),
    fuelType: yup.string().required(errMessage("fuelType")),
    trim: yup.string().required(errMessage("trim")),
    modelYear: yup.number().required(errMessage("modelYear")),
    paint: yup.string().required(errMessage("paint")),
    drivetrain: yup.string().required(errMessage("drivetrain")),
    plateCategory: yup.string().required(errMessage("plateCategory")),
    plateCity: yup.string().required(errMessage("plateCity")),
    mileage: yup.string().required(errMessage("mileage")),
    mileageUnit: yup.string().required(errMessage("mileageUnit")),
    horsepower: yup.string().required(errMessage("horsepower")),
    bodyStyle: yup.string().required(errMessage("bodyStyle")),
    price: yup.number().required().typeError(errMessage("price")),
    priceUnit: yup.string().required(errMessage("priceUnit")),
    isPriceShown: yup.boolean().required(errMessage("isPriceShown")),
  });

  // Update car mutation
  const [Update, { isLoading: isUpdateLoading, error: updateError }] =
    useUpdateSaleCarByIdMutation();

  // Get sale car
  const {
    saleCar,
    isLoading: isSaleCarLoading,
    isError: isSaleCarError,
  } = useSaleCar(carId as string);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    unregister,
    reset,
  } = useForm<UpdateCarDataType>({
    resolver: yupResolver(UpdateCarSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<UpdateCarDataType> = async (data) => {
    const carData = new FormData();

    showroom.phoneNumbers && carData.append("phone", showroom.phoneNumbers[0]);
    data.make && carData.append("make", data.make);
    data.model && carData.append("model", data.model);
    data.mileage &&
      carData.append("condition", Number(data.mileage) > 100 ? "used" : "new");
    data.exteriorColor && carData.append("exteriorColor", data.exteriorColor);
    data.interiorColor && carData.append("interiorColor", data.interiorColor);

    data.drivetrain && carData.append("drivetrain", data.drivetrain);

    data.paint && carData.append("paint", data.paint);
    data.seating && carData.append("seating", data.seating);
    data.seatMaterial && carData.append("seatMaterial", data.seatMaterial);
    data.transmission && carData.append("transmission", data.transmission);
    data.modelYear && carData.append("modelYear", data.modelYear.toString());
    data.trim && carData.append("trim", data.trim);

    data.cylinders && carData.append("cylinders", data.cylinders);
    data.engine && carData.append("engine", data.engine);
    data.horsepower && carData.append("horsepower", data.horsepower);
    data.fuelType && carData.append("fuelType", data.fuelType);
    carData.append("price", data.price.toString());
    data.priceUnit && carData.append("priceUnit", data.priceUnit);

    carData.append("isPriceShown", data.isPriceShown.toString());

    data.mileage && carData.append("mileage", data.mileage);
    data.mileageUnit && carData.append("mileageUnit", data.mileageUnit);
    data.plateCategory && carData.append("plateCategory", data.plateCategory);
    data.plateCity && carData.append("plateCity", data.plateCity);
    showroom.city && carData.append("location", showroom.city?.name);
    showroom.iqdValue &&
      carData.append("iqdValue", showroom.iqdValue.toString());
    data.status && carData.append("status", data.status);

    if (data.features) {
      for (let i = 0; i < data.features.length; i++) {
        carData.append("features", data.features[i]);
      }
    }
    data.description && carData.append("description", data.description);
    if (images) {
      for (let i = 0; i < images.length; i++) {
        carData.append("images", images[i]);
      }
    }
    data.deletedImages &&
      carData.append("deletedImages", JSON.stringify(data.deletedImages));
    if (data.primaryImageIndex || typeof data.primaryImageIndex === "number")
      carData.append("primaryImageIndex", data.primaryImageIndex.toString());

    data.bodyStyle && carData.append("bodyStyle", data.bodyStyle);

    data.importer && carData.append("importer", data.importer);

    try {
      await Update({
        id: saleCar._id,
        credentials: carData,
      }).unwrap();
      navigate("../inventory-management/car-listings", {
        state: { carUpdated: t("alert.carUpdated") },
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Set car data to form
  useEffect(() => {
    if (saleCar) {
      reset({
        make: saleCar.make,
        model: saleCar.model,
        modelYear: saleCar.modelYear,
        trim: saleCar.trim,
        mileage: saleCar.mileage,
        exteriorColor: saleCar.exteriorColor,
        interiorColor: saleCar.interiorColor,
        drivetrain: saleCar.drivetrain,
        paint: saleCar.paint,
        seating: saleCar.seating,
        seatMaterial: saleCar.seatMaterial,
        transmission: saleCar.transmission,
        cylinders: saleCar.cylinders,
        engine: saleCar.engine,
        horsepower: saleCar.horsepower,
        fuelType: saleCar.fuelType,
        price: saleCar.price,
        priceUnit: saleCar.priceUnit,
        isPriceShown: saleCar.isPriceShown?.toString() as any,
        mileageUnit: saleCar.mileageUnit,
        plateCategory: saleCar.plateCategory,
        plateCity: saleCar.plateCity,
        features: saleCar.features,
        description: saleCar.description,
        primaryImageIndex: saleCar.primaryImageIndex,
        bodyStyle: saleCar.bodyStyle,
        importer: saleCar.importer,
        images: saleCar.images as any,
        status: saleCar.status,
      });
    }
  }, [saleCar]);

  // Set saved images
  useEffect(() => {
    setSavedImages(saleCar?.images);
  }, [saleCar?.images]);

  // Check if showroom is the owner of the car or car exist or sale car exist
  useEffect(() => {
    if (showroom && saleCar) {
      if (showroom._id !== saleCar.showroom?._id) {
        navigate("..");
      }
    }
    if (isSaleCarError) navigate("..");
  }, [isSaleCarError, showroom, saleCar]);

  if (isSaleCarLoading) return <Loading />;
  return (
    <div id="edit-car">
      <header className="edit-heading">
        <p>{t("showroomManagement.inventory.editCar.heading")}</p>
      </header>
      {saleCar && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <BasicInfo
            register={register}
            unregister={unregister}
            errors={errors}
            setValue={setValue}
            watch={watch}
          />
          <MileageDetails
            register={register}
            errors={errors}
            setValue={setValue}
            watch={watch}
          />
          <TechnicalSpecs errors={errors} setValue={setValue} watch={watch} />
          <UpdateImages
            errors={errors}
            setValue={setValue}
            watch={watch}
            unregister={unregister}
            images={images}
            setImages={setImages}
            savedImages={savedImages}
            setSavedImages={setSavedImages}
          />
          <Pricing
            register={register}
            errors={errors}
            setValue={setValue}
            watch={watch}
          />
          <AdditionalInfo
            register={register}
            setValue={setValue}
            watch={watch}
          />
          {updateError && <p className="error">{updateError?.data?.message}</p>}
          <div className="btns">
            <button
              onClick={() => navigate("../inventory-management/car-listings")}
              className="cancel"
              type="button"
            >
              {t("btns&links.cancel")}
            </button>
            <button disabled={isUpdateLoading} className="update" type="submit">
              {isUpdateLoading ? t("loading") : t("btns&links.saveChanges")}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const ExpandIcon = (props: Props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1855_2)">
      <path
        d="M19.7917 25H17.7084C17.4321 25 17.1671 24.8902 16.9718 24.6949C16.7764 24.4995 16.6667 24.2346 16.6667 23.9583C16.6667 23.6821 16.7764 23.4171 16.9718 23.2218C17.1671 23.0264 17.4321 22.9167 17.7084 22.9167H19.7917C20.6205 22.9167 21.4153 22.5874 22.0014 22.0014C22.5874 21.4153 22.9167 20.6205 22.9167 19.7917V17.7083C22.9167 17.4321 23.0264 17.1671 23.2218 16.9718C23.4171 16.7764 23.6821 16.6667 23.9584 16.6667C24.2346 16.6667 24.4996 16.7764 24.6949 16.9718C24.8903 17.1671 25 17.4321 25 17.7083V19.7917C24.9984 21.1725 24.4491 22.4963 23.4727 23.4727C22.4963 24.4491 21.1725 24.9983 19.7917 25Z"
        fill="white"
      />
      <path
        d="M1.04167 8.33333C0.7654 8.33333 0.500448 8.22359 0.305097 8.02824C0.109747 7.83289 0 7.56793 0 7.29167V5.20833C0.00165402 3.82751 0.550919 2.50371 1.52731 1.52731C2.50371 0.550919 3.82751 0.00165402 5.20833 0L7.29167 0C7.56793 0 7.83289 0.109747 8.02824 0.305097C8.22359 0.500448 8.33333 0.7654 8.33333 1.04167C8.33333 1.31793 8.22359 1.58289 8.02824 1.77824C7.83289 1.97359 7.56793 2.08333 7.29167 2.08333H5.20833C4.37953 2.08333 3.58468 2.41257 2.99862 2.99862C2.41257 3.58468 2.08333 4.37953 2.08333 5.20833V7.29167C2.08333 7.56793 1.97359 7.83289 1.77824 8.02824C1.58289 8.22359 1.31793 8.33333 1.04167 8.33333Z"
        fill="white"
      />
      <path
        d="M7.29167 25H5.20833C3.82751 24.9983 2.50371 24.4491 1.52731 23.4727C0.550919 22.4963 0.00165402 21.1725 0 19.7917L0 17.7083C0 17.4321 0.109747 17.1671 0.305097 16.9718C0.500448 16.7764 0.7654 16.6667 1.04167 16.6667C1.31793 16.6667 1.58289 16.7764 1.77824 16.9718C1.97359 17.1671 2.08333 17.4321 2.08333 17.7083V19.7917C2.08333 20.6205 2.41257 21.4153 2.99862 22.0014C3.58468 22.5874 4.37953 22.9167 5.20833 22.9167H7.29167C7.56793 22.9167 7.83289 23.0264 8.02824 23.2218C8.22359 23.4171 8.33333 23.6821 8.33333 23.9583C8.33333 24.2346 8.22359 24.4995 8.02824 24.6949C7.83289 24.8902 7.56793 25 7.29167 25Z"
        fill="white"
      />
      <path
        d="M23.9584 8.33333C23.6821 8.33333 23.4171 8.22359 23.2218 8.02824C23.0264 7.83289 22.9167 7.56793 22.9167 7.29167V5.20833C22.9167 4.37953 22.5874 3.58468 22.0014 2.99862C21.4153 2.41257 20.6205 2.08333 19.7917 2.08333H17.7084C17.4321 2.08333 17.1671 1.97359 16.9718 1.77824C16.7764 1.58289 16.6667 1.31793 16.6667 1.04167C16.6667 0.7654 16.7764 0.500448 16.9718 0.305097C17.1671 0.109747 17.4321 0 17.7084 0L19.7917 0C21.1725 0.00165402 22.4963 0.550919 23.4727 1.52731C24.4491 2.50371 24.9984 3.82751 25 5.20833V7.29167C25 7.56793 24.8903 7.83289 24.6949 8.02824C24.4996 8.22359 24.2346 8.33333 23.9584 8.33333Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={25} height={25} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { ExpandIcon };

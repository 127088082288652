import "./EditRentCar.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { SaleCarOptions } from "../../../../../../../data/FilterData";
import { useUpdateRentCarByIdMutation } from "../../../../../../../app/api/rentCarsApiSlice";
import { useRentCar, useShowroom } from "../../../../../../../hooks/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AddRentCarDataType } from "../../add car/AddCar";
import { BasicInfoRent } from "../../../../../../../components/rent car form sections/basic info/BasicInfoRent";
import { InteriorDetails } from "../../../../../../../components/rent car form sections/interior details/InteriorDetails";
import { TechnicalSpecsRent } from "../../../../../../../components/rent car form sections/technical specs/TechnicalSpecsRent";
import { PricingRent } from "../../../../../../../components/rent car form sections/pricing/PricingRent";
import { AdditionalInfoRent } from "../../../../../../../components/rent car form sections/additional info/AdditionalInfoRent";
import { UpdateImages } from "../../../../../../../components/update car images/UpdateImages";
import { Loading } from "../../../../../../../components/customs/loading/Loading";

export type UpdateRentCarDataType = AddRentCarDataType & {
  deletedImages?: string[];
};
export const EditRentCar = () => {
  const { t } = useTranslation();
  const errMessage = (field: string) => t(`errorMessage.${field}`);
  const navigate = useNavigate();
  const { showroom } = useShowroom();
  const { rentCarId } = useParams();
  const [images, setImages] = useState<FileList>([] as any);
  const [savedImages, setSavedImages] = useState<string[]>([]);

  // Get rent car
  const {
    rentCar,
    isLoading: isRentCarLoading,
    isError: isRentCarError,
  } = useRentCar(rentCarId as string);

  // Update rent car mutation
  const [Update, { isLoading: isUpdateLoading, error: updateError }] =
    useUpdateRentCarByIdMutation();

  // Update rent car schema
  const UpdateRentCarSchema = yup.object().shape({
    make: yup.string().required(errMessage("make")),
    model: yup.string().required(errMessage("model")),
    images: yup.mixed<FileList>().required(errMessage("carImages")),
    // .test("images", errMessage("carImages"), (value) => {
    //   if (value) {
    //     return value.length >= 1;
    //   }
    //   return false;
    // }),
    exteriorColor: yup.string().required(errMessage("exteriorColor")),
    interiorColor: yup.string().required(errMessage("interiorColor")),
    seatMaterial: yup.string().required(errMessage("seatMaterial")),
    seating: yup.string().required(errMessage("seating")),
    transmission: yup.string().required(errMessage("transmission")),
    engine: yup.string().required(errMessage("engine")),
    cylinders: yup.string().required(errMessage("cylinders")),
    fuelType: yup.string().required(errMessage("fuelType")),
    trim: yup.string().required(errMessage("trim")),
    modelYear: yup.number().required(errMessage("modelYear")),

    price: yup.object().shape({
      daily: yup.number().required().typeError(errMessage("price")),
      weekly: yup.number().required().typeError(errMessage("price")),
      monthly: yup.number().required().typeError(errMessage("price")),
    }),
    priceUnit: yup.string().required(errMessage("priceUnit")),
    isPriceShown: yup.boolean().required(errMessage("isPriceShown")),
    bodyStyle: yup.string().required(errMessage("bodyStyle")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    unregister,
    reset,
  } = useForm<UpdateRentCarDataType>({
    resolver: yupResolver(UpdateRentCarSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<UpdateRentCarDataType> = async (data) => {
    const carData = new FormData();

    // const bodyStyle = SaleCarOptions.bodyStyle[data.make][0];
    showroom.phoneNumbers && carData.append("phone", showroom.phoneNumbers[0]);
    data.make && carData.append("make", data.make);
    data.model && carData.append("model", data.model);
    data.exteriorColor && carData.append("exteriorColor", data.exteriorColor);
    data.interiorColor && carData.append("interiorColor", data.interiorColor);

    data.seating && carData.append("seating", data.seating);
    data.seatMaterial && carData.append("seatMaterial", data.seatMaterial);
    data.transmission && carData.append("transmission", data.transmission);
    data.modelYear && carData.append("modelYear", data.modelYear.toString());
    data.trim && carData.append("trim", data.trim);

    data.cylinders && carData.append("cylinders", data.cylinders);
    data.engine && carData.append("engine", data.engine);
    data.fuelType && carData.append("fuelType", data.fuelType);
    carData.append("price", JSON.stringify(data.price));
    data.priceUnit && carData.append("priceUnit", data.priceUnit);
    carData.append("isPriceShown", data.isPriceShown.toString());
    showroom.city && carData.append("location", showroom.city.name);
    showroom.iqdValue &&
      carData.append("iqdValue", showroom.iqdValue.toString());
    data.status && carData.append("status", data.status);
    data.rentType && carData.append("rentType", data.rentType);
    data.toDateRent && carData.append("toDateRent", data.toDateRent.toString());
    if (images) {
      for (let i = 0; i < images.length; i++) {
        carData.append("images", images[i]);
      }
    }
    data.deletedImages &&
      carData.append("deletedImages", JSON.stringify(data.deletedImages));
    if (data.features) {
      for (let i = 0; i < data.features.length; i++) {
        carData.append("features", data.features[i]);
      }
    }
    data.description && carData.append("description", data.description);
    if (data.images) {
      for (let i = 0; i < data.images.length; i++) {
        carData.append("images", data.images[i]);
      }
    }

    if (data.primaryImageIndex || typeof data.primaryImageIndex === "number")
      carData.append("primaryImageIndex", data.primaryImageIndex.toString());

    data.bodyStyle && carData.append("bodyStyle", data.bodyStyle);

    data.importer && carData.append("importer", data.importer);

    try {
      await Update({
        id: rentCar._id,
        credentials: carData,
      }).unwrap();
      navigate("../inventory-management/car-listings", {
        state: { carUpdated: t("alert.carUpdated") },
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Set rent car data to form
  useEffect(() => {
    if (rentCar) {
      reset({
        make: rentCar.make,
        model: rentCar.model,
        modelYear: rentCar.modelYear,
        trim: rentCar.trim,
        exteriorColor: rentCar.exteriorColor,
        interiorColor: rentCar.interiorColor,
        seating: rentCar.seating,
        seatMaterial: rentCar.seatMaterial,
        transmission: rentCar.transmission,
        cylinders: rentCar.cylinders,
        engine: rentCar.engine,
        fuelType: rentCar.fuelType,
        price: rentCar.price,
        priceUnit: rentCar.priceUnit,
        isPriceShown: rentCar.isPriceShown?.toString() as any,
        features: rentCar.features,
        description: rentCar.description,
        primaryImageIndex: rentCar.primaryImageIndex,
        bodyStyle: rentCar.bodyStyle,
        importer: rentCar.importer,
        images: rentCar.images as any,
        status: rentCar.status,
      });
    }
  }, [rentCar]);

  // Set saved images
  useEffect(() => {
    setSavedImages(rentCar?.images);
  }, [rentCar?.images]);

  // Check if showroom is the owner of the rent car or rent car exist
  useEffect(() => {
    if (showroom && rentCar) {
      if (showroom._id !== rentCar.showroom?._id) {
        navigate("..");
      }
    }
    if (isRentCarError) navigate("..");
  }, [isRentCarError, rentCar, showroom]);

  if (isRentCarLoading) return <Loading />;
  return (
    <div id="edit-rent-car">
      <header className="edit-heading">
        <p>{t("showroomManagement.inventory.editCar.heading")}</p>
      </header>
      {rentCar && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <BasicInfoRent
            register={register}
            unregister={unregister}
            errors={errors}
            setValue={setValue}
            watch={watch}
          />
          <InteriorDetails
            register={register}
            errors={errors}
            setValue={setValue}
            watch={watch}
          />
          <TechnicalSpecsRent
            errors={errors}
            setValue={setValue}
            watch={watch}
          />
          <UpdateImages
            errors={errors}
            setValue={setValue}
            watch={watch}
            unregister={unregister}
            images={images}
            setImages={setImages}
            savedImages={savedImages}
            setSavedImages={setSavedImages}
          />
          <PricingRent
            register={register}
            errors={errors}
            setValue={setValue}
            watch={watch}
          />
          <AdditionalInfoRent
            register={register}
            setValue={setValue}
            watch={watch}
          />
          {updateError && <p className="error">{updateError?.data?.message}</p>}
          <div className="btns">
            <button
              onClick={() => navigate("../inventory-management/car-listings")}
              className="cancel"
              type="button"
            >
              {t("btns&links.cancel")}
            </button>
            <button disabled={isUpdateLoading} className="update" type="submit">
              {isUpdateLoading ? t("loading") : t("btns&links.saveChanges")}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

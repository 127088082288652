import "./CarAnalyticsTable.css";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CarType } from "../cards/SaleCard";
import { camelCase } from "../../utilities/camelCase";
import { EyeIcon } from "../../assets/svg/eyeIcon";
import { VisitorIcon } from "../../assets/svg/visitorIcon";

type Props = {
  thead: string[];
  cars: CarType[];
  carPath: string;
  carAnalytics: { _id: string; views: number; visitors: number }[];
};

export const CarAnalyticsTable: FC<Props> = ({
  thead,
  cars,
  carPath,
  carAnalytics,
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <div id="car-analytics-table">
      <table>
        <thead>
          <tr>
            {thead.map((title) => {
              return <th key={title}>{title}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {cars?.length > 0 &&
            cars.map((car: CarType, index: number) => (
              <tr key={car._id.toString()}>
                <td
                  onClick={() => navigate(carPath + car._id)}
                  className="td-1"
                >
                  <img src={car.images[car.primaryImageIndex || 0]} alt="" />
                  <div className="col-1">
                    <p className="make">{t(`make.${camelCase(car.make)}`)}</p>
                    <p className="model">
                      {t(`model.${camelCase(car.model)}`)}&nbsp;
                      {t(`number`, { count: car.modelYear })}
                    </p>
                  </div>
                </td>

                <td>
                  {i18n.language !== "en"
                    ? new Date(car.createdAt).toLocaleDateString("ar-IQ", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })
                    : new Date(car.createdAt).toLocaleDateString("en-GB")}
                </td>
                <td>
                  <div className="view">
                    <EyeIcon />
                    {t(`number`, {
                      count: carAnalytics.find(
                        (saleCar) => saleCar._id === car._id
                      )?.views,
                    })}
                  </div>
                </td>
                <td>
                  <div className="view">
                    <VisitorIcon />
                    {t(`number`, {
                      count: carAnalytics.find(
                        (saleCar) => saleCar._id === car._id
                      )?.visitors,
                    })}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

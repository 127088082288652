import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  isDpOpen?: boolean;
}

const DpdownIcon = ({ isDpOpen, ...props }: Props) => (
  <svg
    style={isDpOpen ? { transform: "rotate(-180deg)" } : { transform: "none" }}
    width={props.width}
    height={props.height}
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 1L7 7L13 1"
      stroke="#28282B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { DpdownIcon };

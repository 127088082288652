import { Link } from "react-router-dom";
import "./TermsConditions.css";
import { SEO } from "../../utilities/SEO";
import { useTranslation } from "react-i18next";
export const TermsConditions = () => {
  const { t } = useTranslation();
  return (
    <div id="terms-conditions">
      {/* SEO */}
      <SEO
        title={t("seo.termsTitle")}
        description={t("seo.homeDescription").toString()}
      />

      <div className="container">
        <h1>Terms and Conditions</h1>
        <p>
          Welcome to Noyya! These terms and conditions outline the rules and
          regulations for the use of Noyya's Website, located at&nbsp;
          <a href="https://www.noyya.net">www.noyya.net</a>.
        </p>
        <p>
          By accessing this website, we assume you accept these terms and
          conditions. Do not continue to use Noyya if you do not agree to take
          all of the terms and conditions stated on this page.
        </p>

        <section>
          <h2>1. User Registration</h2>
          <h3>1.1 Eligibility:</h3>
          <p>
            Only car showrooms are eligible to register on Noyya. By
            registering, you confirm that you represent a legitimate car
            showroom and provide accurate information during the registration
            process.
          </p>
          <h3>1.2 Registration Process:</h3>
          <p>
            Car showrooms agree to provide accurate and complete information
            during the registration process. Noyya reserves the right to verify
            the information provided and may reject or suspend registrations
            that do not meet our criteria.
          </p>
        </section>

        <section>
          <h2>2. Listings and Content</h2>
          <h3>2.1 Accuracy of Information:</h3>
          <p>
            Car showrooms are responsible for the accuracy and completeness of
            the vehicle listings and related information provided on Noyya.
            Noyya does not guarantee the accuracy of the information presented.
          </p>
          <h3>2.2 Listing Restrictions:</h3>
          <p>
            Car showrooms must not publish misleading or false information in
            their listings. Noyya reserves the right to remove or suspend
            listings that violate our policies.
          </p>
        </section>

        <section>
          <h2>3. Platform Usage</h2>
          <p>
            <strong>3.1 Limited License:</strong> Unless otherwise stated, Noyya
            and/or its licensors own the intellectual property rights for all
            material on Noyya. Car showrooms are granted a limited license to
            access and use the platform for the purpose of showcasing their
            vehicles.
          </p>
          <h3>3.2 Prohibited Actions:</h3>
          <ul>
            <p>
              <strong>-</strong> Republish material from Noyya.
            </p>
            <p>
              <strong>-</strong> Sell, rent, or sub-license material from Noyya.
            </p>
            <p>
              <strong>-</strong> Reproduce, duplicate, or copy material from
              Noyya.
            </p>
            <p>
              <strong>-</strong> Redistribute content from Noyya.
            </p>
          </ul>
        </section>

        <section>
          <h2>4. Privacy Policy</h2>
          <p>
            By using Noyya, you agree to the terms outlined in our&nbsp;
            <Link to="../privacy-policy">Privacy Policy</Link>. Please refer to
            the Privacy Policy for details on how we collect, use, and protect
            your information.
          </p>
        </section>

        <section>
          <h2>5. Liability and Disclaimer</h2>
          <h3>5.1 Information Accuracy:</h3>
          <p>
            Noyya disclaims any liability for the accuracy or completeness of
            the information provided by car showrooms. Users are encouraged to
            independently verify details before making any decisions based on
            the information presented on the platform.
          </p>
          <h3>5.2 Limitation of Liability:</h3>
          <p>
            To the maximum extent permitted by applicable law, Noyya shall not
            be held responsible for any loss or damage arising from the use of
            the website.
          </p>
        </section>

        <section>
          <h2>6. Modification and Termination</h2>
          <h3>6.1 Modification:</h3>
          <p>
            Noyya reserves the right to modify these terms and conditions at any
            time. Car showrooms will be informed of any changes, and continued
            use of the platform constitutes acceptance of the modified terms.
          </p>
          <h3>6.2 Termination:</h3>
          <p>
            Noyya may terminate or suspend accounts that violate these terms and
            conditions or for any other reason deemed necessary by Noyya.
          </p>
        </section>

        <section>
          <h2>7. Contact Information</h2>
          <p>
            For any questions or concerns regarding these terms and conditions,
            please contact us at&nbsp;
            <a href="mailto:info@noyya.net">info@noyya.net</a>.
          </p>
        </section>
      </div>
    </div>
  );
};

import jwtDecode from "jwt-decode";
import { ShowroomType } from "../components/cards/ShowroomCard";
import { CarType } from "../components/cards/SaleCard";
import { RentCarType } from "../components/cards/RentalCard";
import {
  useGetAllShowroomsQuery,
  useGetShowroomByIdQuery,
} from "../app/api/showroomApiSlice";
import {
  useGetAllSaleCarsQuery,
  useGetSaleCarByIdQuery,
  useGetSaleCarsByShowroomQuery,
} from "../app/api/saleCarsApiSlice";
import {
  useGetAllRentCarsQuery,
  useGetRentCarByIdQuery,
  useGetRentCarsByShowroomQuery,
} from "../app/api/rentCarsApiSlice";
import {
  useLogoutMutation,
  useRefreshTokenQuery,
} from "../app/api/authApiSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Auth data type
type authDataType = {
  showroomInfo: {
    id: string;
    image: string;
  };
  exp: number;
};

// Get showroom data type
type getShowroomDataType = {
  data: ShowroomType;
  isSuccess: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  error: any;
};
// Get showrooms data type
type getShowroomsDataType = {
  data: {
    showrooms: ShowroomType[];
    pageCount: number;
    totalCars: { _id: string; count: number }[];
  };
  isSuccess: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  error: any;
};

// Get sale cars data type
type getSaleCarsDataType = {
  data: {
    saleCars: CarType[];
    pageCount: number;
    totalCars: number;
    currentRangeStart: number;
    currentRangeEnd: number;
  };
  isLoading: boolean;
  isSuccess: boolean;
  isFetching: boolean;
  isError: boolean;
  error: any;
};
// Get rent cars data type
type getRentCarsDataType = {
  data: {
    rentCars: RentCarType[];
    pageCount: number;
    totalCars: number;
    currentRangeStart: number;
    currentRangeEnd: number;
  };
  isSuccess: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  error: any;
};

// Get sale car data type
type getSaleCarDataType = {
  data: CarType;
  isSuccess: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  error: any;
};
// Get rent car data type
type getRentCarDataType = {
  data: RentCarType;
  isSuccess: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  error: any;
};

// Get showroom sale cars data type
type getShowroomSaleCars = getSaleCarsDataType & {
  data: {
    totalAvailableCars: number;
  };
};
// Get showroom rent cars data type
type getShowroomRentCars = getRentCarsDataType & {
  data: {
    totalAvailableCars: number;
  };
};

// Get showroom info from token
export const useAuth = () => {
  const token = localStorage.getItem("token");

  const expireTime = token && (jwtDecode(token) as authDataType).exp * 1000;
  const [logout] = useLogoutMutation();

  const isAuth = expireTime && Date.now() < expireTime;

  const { data } = useRefreshTokenQuery("", {
    skip: !token || isAuth === false,
  }) as {
    data: { accessToken: string };
  };
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  if (token && token !== undefined) {
    const decoded: authDataType = jwtDecode(token);
    const { id } = decoded.showroomInfo;

    // if it is expired call refresh token
    if (isAuth === false) {
      if (data) {
        localStorage.setItem("token", data.accessToken);
      } else {
        logout();
        localStorage.removeItem("token");
        navigate(`${i18n.language}/sign-in`);
      }
    }

    return { id, isAuth };
  }
  return { id: "", isAuth: false };
};

// Get showroom
export const useShowroom = (showroomId?: string, isAnalytics?: boolean) => {
  let { id } = useAuth();
  id = showroomId || id;

  const {
    data: showroom,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetShowroomByIdQuery(
    { id, isAnalytics: isAnalytics || false },
    {
      skip: !id,
    }
  ) as getShowroomDataType;

  const isSaleShowroom = showroom?.showroomType === "sale";

  return {
    showroom,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
    isSaleShowroom,
  };
};
// Get showrooms
export const useShowrooms = (
  page: number,
  limit: number,
  showroomType?: string
) => {
  const {
    data: showrooms,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetAllShowroomsQuery({
    page,
    limit,
    showroomType,
  }) as getShowroomsDataType;

  return { showrooms, isSuccess, isLoading, isFetching, isError, error };
};

// Get sale cars
export const useSaleCars = (
  page: string | number,
  limit: number,
  make?: string,
  model?: string,
  condition?: string,
  fromYear?: string,
  toYear?: string,
  minPrice?: string,
  maxPrice?: string,
  mileage?: string,
  location?: string,
  exteriorColor?: string,
  interiorColor?: string,
  transmission?: string,
  fuelType?: string,
  selectedSort?: string
) => {
  // refetch
  const {
    data: cars,
    isLoading,
    isSuccess,
    isFetching,
    isError,
    error,
  } = useGetAllSaleCarsQuery(
    {
      page,
      limit,
      make: make || "all makes",
      model: model || "all models",
      condition: condition || "both",
      fromYear: fromYear || "oldest",
      toYear: toYear || "newest",
      minPrice: minPrice || "lowest",
      maxPrice: maxPrice || "highest",
      mileage: mileage || "any",
      location: location || "all",
      exteriorColor: exteriorColor || "all colors",
      interiorColor: interiorColor || "all colors",
      transmission: transmission || "all transmissions",
      fuelType: fuelType || "all fuel types",
      sort: selectedSort,
    },
    {
      refetchOnReconnect: true,
    }
  ) as getSaleCarsDataType;

  return { cars, isSuccess, isLoading, isFetching, isError, error };
};
// Get rent cars
export const useRentCars = (
  page: string | number,
  limit: number,
  make?: string,
  model?: string,
  fromYear?: string,
  toYear?: string,
  location?: string,
  transmission?: string,
  fuelType?: string,
  selectedSort?: string
) => {
  const {
    data: rentCars,
    isLoading,
    isSuccess,
    isFetching,
    isError,
    error,
  } = useGetAllRentCarsQuery({
    page,
    limit,
    make: make || "all makes",
    model: model || "all models",
    fromYear: fromYear || "oldest",
    toYear: toYear || "newest",
    location: location || "all",
    transmission: transmission || "all transmissions",
    fuelType: fuelType || "all fuel types",
    sort: selectedSort,
  }) as getRentCarsDataType;

  return { rentCars, isSuccess, isLoading, isFetching, isError, error };
};

// Get sale car
export const useSaleCar = (id: string) => {
  const {
    data: saleCar,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetSaleCarByIdQuery(id, {
    skip: !id,
  }) as getSaleCarDataType;

  return { saleCar, isSuccess, isLoading, isFetching, isError, error };
};
// Get rent car
export const useRentCar = (id: string) => {
  const {
    data: rentCar,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetRentCarByIdQuery(id, {
    skip: !id,
  }) as getRentCarDataType;
  return { rentCar, isSuccess, isLoading, isFetching, isError, error };
};

// Get sale cars by showroom
export const useShowroomCars = (
  id: string,
  page: number,
  limit: number,
  sort: string,
  isAnalytics: boolean,
  showroomType?: string,
  status?: string
) => {
  const {
    data: cars,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetSaleCarsByShowroomQuery(
    {
      page,
      limit,
      showroomId: id,
      sort,
      status,
      isAnalytics,
    },
    {
      skip: showroomType !== "sale" || !id,
    }
  ) as getShowroomSaleCars;

  return { cars, isSuccess, isLoading, isFetching, isError, error };
};
// get rent cars by showroom
export const useShowroomRentCars = (
  id: string,
  page: number,
  limit: number,
  sort: string,
  isAnalytics: boolean,
  showroomType?: string,
  status?: string
) => {
  const {
    data: cars,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetRentCarsByShowroomQuery(
    {
      page,
      limit,
      showroomId: id,
      sort,
      status,
      isAnalytics,
    },
    {
      skip: showroomType !== "rental" || !id,
    }
  ) as getShowroomRentCars;

  return { cars, isSuccess, isLoading, isFetching, isError, error };
};

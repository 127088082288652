import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const LogoutIcon = (props: Props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2029_29)">
      <path
        d="M7.65067 10C7.47386 10 7.30429 10.0702 7.17926 10.1953C7.05424 10.3203 6.984 10.4899 6.984 10.6667V12.6667C6.984 13.1971 6.77329 13.7058 6.39821 14.0809C6.02314 14.456 5.51443 14.6667 4.984 14.6667H3.33333C2.8029 14.6667 2.29419 14.456 1.91912 14.0809C1.54405 13.7058 1.33333 13.1971 1.33333 12.6667V3.33333C1.33333 2.8029 1.54405 2.29419 1.91912 1.91912C2.29419 1.54405 2.8029 1.33333 3.33333 1.33333H4.984C5.51443 1.33333 6.02314 1.54405 6.39821 1.91912C6.77329 2.29419 6.984 2.8029 6.984 3.33333V5.33333C6.984 5.51014 7.05424 5.67971 7.17926 5.80474C7.30429 5.92976 7.47386 6 7.65067 6C7.82748 6 7.99705 5.92976 8.12207 5.80474C8.2471 5.67971 8.31733 5.51014 8.31733 5.33333V3.33333C8.31627 2.4496 7.96475 1.60237 7.33985 0.97748C6.71496 0.352588 5.86773 0.00105857 4.984 0H3.33333C2.4496 0.00105857 1.60237 0.352588 0.97748 0.97748C0.352588 1.60237 0.00105857 2.4496 0 3.33333L0 12.6667C0.00105857 13.5504 0.352588 14.3976 0.97748 15.0225C1.60237 15.6474 2.4496 15.9989 3.33333 16H4.984C5.86773 15.9989 6.71496 15.6474 7.33985 15.0225C7.96475 14.3976 8.31627 13.5504 8.31733 12.6667V10.6667C8.31733 10.4899 8.2471 10.3203 8.12207 10.1953C7.99705 10.0702 7.82748 10 7.65067 10Z"
        fill="#61616B"
      />
      <path
        d="M15.2447 6.58599L12.1873 3.52866C12.1258 3.46499 12.0523 3.4142 11.9709 3.37926C11.8896 3.34432 11.8021 3.32593 11.7136 3.32516C11.6251 3.32439 11.5373 3.34126 11.4554 3.37478C11.3734 3.4083 11.299 3.4578 11.2364 3.5204C11.1738 3.58299 11.1243 3.65743 11.0908 3.73936C11.0573 3.82129 11.0404 3.90907 11.0412 3.99759C11.0419 4.08611 11.0603 4.17359 11.0953 4.25493C11.1302 4.33627 11.181 4.40983 11.2447 4.47133L14.086 7.31333L4.00001 7.33333C3.8232 7.33333 3.65363 7.40356 3.52861 7.52859C3.40358 7.65361 3.33334 7.82318 3.33334 7.99999C3.33334 8.1768 3.40358 8.34637 3.52861 8.4714C3.65363 8.59642 3.8232 8.66666 4.00001 8.66666L14.1253 8.64599L11.2433 11.5287C11.1797 11.5902 11.1289 11.6637 11.0939 11.7451C11.059 11.8264 11.0406 11.9139 11.0398 12.0024C11.0391 12.0909 11.0559 12.1787 11.0895 12.2606C11.123 12.3426 11.1725 12.417 11.2351 12.4796C11.2977 12.5422 11.3721 12.5917 11.454 12.6252C11.536 12.6587 11.6238 12.6756 11.7123 12.6748C11.8008 12.6741 11.8883 12.6557 11.9696 12.6207C12.0509 12.5858 12.1245 12.535 12.186 12.4713L15.2433 9.41399C15.6185 9.03912 15.8293 8.5306 15.8296 8.00027C15.8298 7.46994 15.6194 6.96123 15.2447 6.58599Z"
        fill="#61616B"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { LogoutIcon };

import "./Login.css";
import { ChangeEvent, useState, KeyboardEvent, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { useLoginMutation } from "../../../app/api/authApiSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import logoSrc from "../../../assets/images/NoyyaBlack36.png";
import { EyeIcon } from "../../../assets/svg/eyeIcon";
import flagSrc from "../../../assets/svg/iraqFlag.svg";
import { SEO } from "../../../utilities/SEO";

type LoginData = {
  phoneNumber: string;
  password: string;
};

export const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const phonePattern = /^(07\d{9}|7\d{9})$/;

  // Login mutation
  const [login, { isLoading, error }] = useLoginMutation();

  // Login schema
  const loginSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .required(t("errorMessage.phoneNumber") as string)
      .matches(phonePattern, t("errorMessage.phoneNumberMatch") as string),
    password: yup.string().required(t("errorMessage.password") as string),
  });
  // Form hook
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>({
    resolver: yupResolver(loginSchema),
  });
  // Submit handler
  const onSubmit: SubmitHandler<LoginData> = async (data) => {
    try {
      const { accessToken } = await login({
        phoneNumber: data.phoneNumber,
        password: data.password,
      }).unwrap();
      localStorage.setItem("token", accessToken);
      navigate(`/${i18n.language}/showroom-profile`);
    } catch (error) {
      console.log(error);
    }
  };
  // Handle change phone number input
  const onChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    const firstChar = e.target.value[0];
    if (firstChar !== "0" && e.target.value.length > 10)
      e.target.value = e.target.value.slice(0, 10);
    if (firstChar === "0" && e.target.value.length > 11)
      e.target.value = e.target.value.slice(0, 11);
    let formattedNumber = "";
    if (firstChar === "0") {
      formattedNumber = e.target.value.slice(1);
    } else {
      formattedNumber = e.target.value;
    }
    // if number is arabic convert it to english
    formattedNumber = formattedNumber.replace(/[\u0660-\u0669]/g, (c) => {
      return String.fromCharCode(c.charCodeAt(0) - 0x0660 + 48).toString();
    });
    setValue(`phoneNumber`, formattedNumber, { shouldValidate: true });
  };

  // Only allow numbers
  const onKeyDownPhone = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 46
      )
    )
      e.preventDefault();
  };

  // Show toast if password changed successfully
  useEffect(() => {
    if (location.state && location.state.passwordChanged) {
      toast.success(location.state.passwordChanged);
      location.state = {};
    }
  }, [location.state]);

  // Show toast if account deleted  successfully
  useEffect(() => {
    if (location.state && location.state.accountDeleted) {
      toast.success(location.state.accountDeleted);
      location.state = {};
    }
  }, [location.state]);

  return (
    <>
      {/* SEO */}
      <SEO title={t("seo.signInTitle")} />

      {/* Login Section */}
      <section id="login">
        <ToastContainer />
        <div className="container">
          <div className="logo">
            <img src={logoSrc} alt="noyya logo" />
          </div>
          <p>{t("login.heading")}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col">
                <div className="phone-input-container">
                  <div className="country">
                    <img src={flagSrc} alt="iraq flag" />
                    <p>{t("countryCode")}</p>
                  </div>
                  <div className="phone-input-group">
                    <input
                      placeholder=" "
                      type="tel"
                      onKeyDown={onKeyDownPhone}
                      onChange={onChangePhone}
                    />
                    <label>{t("inputPlaceholder.phoneNumber")}</label>
                  </div>
                </div>
                {errors.phoneNumber && (
                  <p className="error">{errors.phoneNumber.message}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="password">
                  <div className="input-group">
                    <input
                      placeholder=" "
                      type={showPassword ? "text" : "password"}
                      {...register("password", { required: true })}
                    />
                    <label>{t("inputPlaceholder.password")}</label>
                  </div>
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <EyeIcon />
                  </button>
                </div>
                {errors.password && (
                  <p className="error">{errors.password.message}</p>
                )}
              </div>
            </div>
            {error && <p className="error">{error.data.message}</p>}
            <div className="row">
              <Link className="link" to={`/${i18n.language}/forgot-password`}>
                {t("btns&links.forgotPassword")}
              </Link>
            </div>

            <button
              disabled={isLoading}
              type="submit"
              className="login-btn"
              aria-label={t("btns&links.signIn").toString()}
            >
              {isLoading ? t("loading") : t("btns&links.signIn")}
            </button>
          </form>
          <p className="register">
            {t("login.dontHaveAccount")}&nbsp;
            <Link className="link" to={`/${i18n.language}/register`}>
              {t("btns&links.register")}
            </Link>
          </p>
        </div>
      </section>
    </>
  );
};

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useResetPasswordMutation } from "../../../../app/api/showroomApiSlice";
import { ForgotPassOutletContextType } from "./ForgotStepOne";
import { EyeIcon } from "../../../../assets/svg/eyeIcon";

type resetPassDataType = {
  newPassword: string;
  confirmNewPassword: string;
  isVerified?: boolean;
};

export const ForgotStepThree = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const { phoneNumber, isVerified } =
    useOutletContext<ForgotPassOutletContextType>();

  // Reset password mutation
  const [resetPass, { isLoading, error }] = useResetPasswordMutation();

  // Reset password schema
  const resetPassSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required(t("errorMessage.newPassword") as string)
      .min(8),
    confirmNewPassword: yup
      .string()
      .required(t("errorMessage.confirmNewPassword") as string)
      .oneOf(
        [yup.ref("newPassword")],
        t("errorMessage.passwordsMatch") as string
      ),
  });

  // Form hook
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<resetPassDataType>({
    resolver: yupResolver(resetPassSchema),
    mode: "onChange",
  });

  // Submit handler
  const onSubmit: SubmitHandler<resetPassDataType> = async (data) => {
    const { newPassword } = data;
    try {
      await resetPass({
        credentials: {
          newPassword: newPassword,
          phoneNumber: phoneNumber,
          isVerified: isVerified,
        },
      }).unwrap();
      navigate(`/${i18n.language}/sign-in`, {
        state: { passwordChanged: t("alert.passwordChanged") },
        replace: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // navigate to prev step if is not verified
  useEffect(() => {
    if (!isVerified) {
      navigate(`/${i18n.language}/sign-in`, { replace: true });
    }
  }, [navigate]);

  return (
    <section>
      <header>
        <p className="title">{t("forgotPassword.stepThree.heading")}</p>
        {/* <p>{t("forgotPassword.stepThree.desc")}</p> */}
      </header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="password">
            <div className="input-group">
              <input
                placeholder=" "
                type={showPassword ? "text" : "password"}
                {...register("newPassword", { required: true })}
              />
              <label>{t("inputPlaceholder.newPassword")}</label>
            </div>
            <button
              className="eye-btn"
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              <EyeIcon />
            </button>
          </div>
          {errors.newPassword && (
            <p className="error">{errors.newPassword.message}</p>
          )}
        </div>
        <div className="row">
          <div className="password">
            <div className="input-group">
              <input
                placeholder=" "
                type={showConfirmPassword ? "text" : "password"}
                {...register("confirmNewPassword", { required: true })}
              />
              <label>{t("inputPlaceholder.confirmNewPassword")}</label>
            </div>
            <button
              className="eye-btn"
              type="button"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              <EyeIcon />
            </button>
          </div>
          {errors.confirmNewPassword && (
            <p className="error">{errors.confirmNewPassword.message}</p>
          )}
        </div>
        {error && <p className="error">{error?.data?.message}</p>}
        <button
          className="primary-btn"
          type="submit"
          disabled={isSubmitting || isLoading}
        >
          {isLoading ? t("loading") : t("btns&links.resetPassword")}
        </button>
      </form>
    </section>
  );
};

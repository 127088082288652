import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useVerifyPhoneNumberMutation } from "../../../../app/api/showroomApiSlice";
import { ForgotPassOutletContextType } from "./ForgotStepOne";

export const ForgotStepTwo = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setOtp, otp, phoneNumber, setIsVerified } =
    useOutletContext<ForgotPassOutletContextType>();

  const [errorMessage, setErrorMessage] = useState<string>("");

  // Verify phone number
  const [verifyPhone, { isLoading, error }] = useVerifyPhoneNumberMutation();

  const otpPattern = /^[0-9]{6}$/;
  // Validate OTP
  const otpValidation = (otp: string) => {
    if (otp === "") {
      setErrorMessage(t("errorMessage.verificationCode") as string);
      return false;
    }
    if (otp.length < 6 || otp.length > 6) {
      setErrorMessage(t("errorMessage.codeLength") as string);
      return false;
    }
    if (otp.length === 6) {
      setErrorMessage("");
      return otpPattern.test(otp);
    }
    return false;
  };

  // Verify OTP
  const verifyOtp = async (otp: string) => {
    try {
      await verifyPhone({ phoneNumber: phoneNumber, code: otp }).unwrap();
      setIsVerified(true);
      navigate("../new-password");
    } catch (error) {
      console.log(error);
      setErrorMessage(t("errorMessage.codeMatch") as string);
    }
  };

  // Handle OTP input
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 6) {
      e.target.value = e.target.value.slice(0, 6);
    }
    setOtp(e.target.value);
    otpValidation(e.target.value);
  };

  // navigate back if phone number is not entered
  useEffect(() => {
    if (phoneNumber.length === 0) {
      navigate("..");
    }
  }, [navigate]);

  return (
    <section>
      <header>
        <p className="title">{t("forgotPassword.stepTwo.heading")}</p>
        <p>{t("forgotPassword.stepTwo.desc")}</p>
      </header>
      <form>
        <div className="row">
          <div className="input-group">
            <input placeholder=" " type="number" onChange={handleOnChange} />
            <label>{t("inputPlaceholder.code")}</label>
          </div>
          {errorMessage && <p className="error">{errorMessage}</p>}
          {error && <p className="error">{error?.data?.message}</p>}
        </div>

        <button
          className="primary-btn"
          type="button"
          disabled={isLoading}
          onClick={() => verifyOtp(otp)}
        >
          {isLoading ? t("loading") : t("btns&links.verify")}
        </button>
      </form>
    </section>
  );
};

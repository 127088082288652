import "./LangDropDown.css";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DpdownIcon } from "../../../assets/svg/dpdownIcon";

type LanguageType = {
  value: string;
  label: string;
};
const languages: LanguageType[] = [
  { value: "en", label: "English" },
  { value: "ku", label: "کوردی" },
  { value: "ar", label: "العربية" },
];
export const LangDropDown = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  const currentLanguageValue: string = i18n.language;
  const currentLanguage: LanguageType = languages.find(
    (l) => l.value === currentLanguageValue
  ) || { value: "en", label: "English" };

  const headerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggling = () => setIsOpen(!isOpen);
  // Handle option click
  const onOptionClicked = async (option: LanguageType) => {
    await i18n.changeLanguage(option.value);
    navigate(
      `${location.pathname.replace(currentLanguageValue, `${option.value}`)}`
    );
    document.documentElement.lang = option.value;
    setIsOpen(false);
  };

  // Close dropdown when clicked outside
  useEffect(() => {
    const handler = (e: Event) => {
      if (
        headerRef.current &&
        !headerRef.current.contains(e.target as HTMLInputElement)
      ) {
        setIsOpen(false);
      }
    };
    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  }, []);

  return (
    <div id="lang">
      <div className="dd-container">
        <div ref={headerRef} className="dd-header" onClick={toggling}>
          <p>{currentLanguage.label}</p>
          <DpdownIcon isDpOpen={isOpen} width={"12px"} />
        </div>
        {isOpen && (
          <div className="ddl-container">
            <ul className="dd-list">
              {languages.map((option: LanguageType) => (
                <li
                  onClick={() => {
                    onOptionClicked(option);
                  }}
                  key={option.value}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

import { CarType } from "../../components/cards/SaleCard";
import { apiSlice } from "./apiSlice";

export const saleCarsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSaleCars: builder.query({
      query: ({
        page,
        limit,
        make,
        model,
        condition,
        fromYear,
        toYear,
        minPrice,
        maxPrice,
        mileage,
        location,
        exteriorColor,
        interiorColor,
        transmission,
        fuelType,
        sort,
      }) =>
        `saleCars?page=${page}&limit=${limit}&make=${make || ""}&model=${
          model || ""
        }&condition=${condition || ""}&fromYear=${fromYear || ""}&toYear=${
          toYear || ""
        }&minPrice=${minPrice || ""}&maxPrice=${maxPrice || ""}&mileage=${
          mileage || ""
        }&location=${location || ""}&exteriorColor=${
          exteriorColor || ""
        }&interiorColor=${interiorColor || ""}&transmission=${
          transmission || ""
        }&fuelType=${fuelType || ""}&sort=${sort || ""} `,
      providesTags: ["SaleCars"],
    }),

    getSaleCarById: builder.query({
      query: (id) => `saleCars/${id}`,
      providesTags: ["SaleCars"],
    }),
    getSaleCarByModel: builder.query<
      CarType[],
      { model: string; make: string; id: string }
    >({
      query: ({ model, make, id }) => `saleCars/model/${model}/${make}/${id}`,
      providesTags: ["SaleCars"],
    }),
    getSaleCarsByShowroom: builder.query({
      query: ({ page, limit, showroomId, sort, status, isAnalytics }) =>
        `saleCars/s/${showroomId}?page=${page}&limit=${limit}&sort=${
          sort || ""
        }&status=${status || ""}&isAnalytics=${isAnalytics || ""}`,
      providesTags: ["SaleCars"],
    }),
    getSaleCarsBodyStyleByShowroom: builder.query({
      query: (showroomId) => `saleCars/body/${showroomId}`,
      providesTags: ["SaleCars"],
    }),
    addSaleCar: builder.mutation({
      query: (credentials) => ({
        url: "saleCars",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["SaleCars"],
    }),
    updateSaleCarById: builder.mutation({
      query: ({ id, credentials }) => ({
        url: `saleCars/${id}`,
        method: "PATCH",
        body: credentials,
      }),
      invalidatesTags: ["SaleCars"],
    }),
    deleteSaleCarById: builder.mutation({
      query: (id) => ({
        url: `saleCars/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SaleCars"],
    }),
  }),
});

export const {
  useGetAllSaleCarsQuery,
  useGetSaleCarByIdQuery,
  useGetSaleCarByModelQuery,
  useGetSaleCarsByShowroomQuery,
  useGetSaleCarsBodyStyleByShowroomQuery,
  useAddSaleCarMutation,
  useDeleteSaleCarByIdMutation,
  useUpdateSaleCarByIdMutation,
} = saleCarsApiSlice;

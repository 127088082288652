import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const EmailIcon = (props: Props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.997452 3.26012C1.15589 3.10168 1.37078 3.01267 1.59485 3.01267V3H16.4051C16.6292 3 16.8441 3.08901 17.0025 3.24745C17.161 3.40589 17.25 3.62078 17.25 3.84485V13.4086C17.25 13.6327 17.161 13.8476 17.0025 14.006C16.8441 14.1644 16.6292 14.2535 16.4051 14.2535H1.59485C1.37078 14.2535 1.15589 14.1644 0.997452 14.006C0.839011 13.8476 0.75 13.6327 0.75 13.4086V3.85753C0.75 3.63346 0.839011 3.41857 0.997452 3.26012ZM0.997452 3.26012L9 9.50115L17.0025 3.26012"
      stroke={props.color || "white"}
      strokeWidth={0.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { EmailIcon };

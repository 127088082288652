import "./AdditionalInfo.css";
import { FC } from "react";
import { SaleCarOptions } from "../../../../../../../../data/FilterData";
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DropDown } from "../../../../../../../../components/customs/drop down/DropDown";
import { AddCarDataType } from "../../../add car/AddCar";

type AdditionalInfoProps = {
  register: UseFormRegister<AddCarDataType>;
  setValue: UseFormSetValue<AddCarDataType>;
  watch: UseFormWatch<AddCarDataType>;
};

export const AdditionalInfo: FC<AdditionalInfoProps> = ({
  register,
  setValue,
  watch,
}) => {
  const { t } = useTranslation();
  return (
    <section id="additional-info">
      <header>
        <p>{t("showroomManagement.inventory.addCar.stepSixHeading")}</p>
      </header>
      <div className="row">
        <DropDown
          options={SaleCarOptions.importer}
          placeholder={"Importer"}
          setValue={setValue}
          watch={watch}
        />
      </div>
      <div className="row">
        <p className="feature-heading">{t("inputPlaceholder.features")}</p>
        <div className="features">
          {SaleCarOptions.features.map((feature, index) => (
            <div className="feature" key={index}>
              <input
                value={feature}
                type="checkbox"
                id={feature}
                {...register(`features`)}
              />
              <label htmlFor={feature}>
                <p>{t(`feature.${feature}`)}</p>
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="row">
        <div className="input-group">
          <textarea placeholder=" " {...register("description")} />
          <label>{t("inputPlaceholder.description")}</label>
        </div>
      </div>
    </section>
  );
};

import "./Profile.css";
import { useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useShowroom } from "../../../hooks/useAuth";
import { SideTabs } from "./tabs/sidebar tabs/SideTabs";
import { LangDropDown } from "../../../components/customs/lang drop down/LangDropDown";
import { MenuIcon } from "../../../assets/svg/menuIcon";
import logoSrc from "../../../assets/images/NoyyaBlack72.png";
import { SEO } from "../../../utilities/SEO";

export const Profile = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState<boolean>(false);

  // Get showroom data
  const { showroom, isSuccess } = useShowroom();
  // Showroom name in the current language
  const showroomName = useMemo(() => {
    const showroomNames: { [key: string]: string } = {
      ar: showroom?.nameInAr,
      ku: showroom?.nameInKu,
      default: showroom?.name,
    };

    return showroomNames[i18n.language] || showroomNames.default;
  }, [i18n.language, showroom?.name, showroom?.nameInAr, showroom?.nameInKu]);

  const toggleMenu = () => setShow(!show);
  const handleOutsideClick = () => {
    if (show) {
      setShow(false);
    }
  };

  return (
    <>
      <SEO title={t("seo.showroomManagementTitle")} />
      <div id="showroom-container">
        {/* Side tabs */}
        <section id="left-side">
          <div onClick={() => navigate(`/${i18n.language}`)} className="logo">
            <img src={logoSrc} alt={"Noyya logo"} />
          </div>
          <SideTabs />
        </section>
        {/* Mobile UI side tabs */}
        <aside
          style={
            show
              ? {
                  transform: "translateX(0)",
                }
              : {}
          }
        >
          {isSuccess && showroom && (
            <div className="profile-greet">
              <img
                src={showroom.images[showroom.logoImageIndex]}
                alt={showroomName + " logo"}
              />
              <p>{showroomName}</p>
            </div>
          )}
          <div className="solid"></div>
          <SideTabs toggleMenu={toggleMenu} />
        </aside>
        {/* Overlay */}
        <div
          onClick={handleOutsideClick}
          style={
            show
              ? {
                  visibility: "initial",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }
              : {}
          }
          className="overlay"
        ></div>
        {/* Outlet */}
        <div className="outlet">
          {/* Navbar */}
          <nav>
            <div className="left">
              {/* Menu button */}
              <button onClick={toggleMenu}>
                <MenuIcon width={20} height={20} />
              </button>
              {/* Noyya logo */}
              <div
                onClick={() => navigate(`/${i18n.language}`)}
                className="noyya-logo"
              >
                <img src={logoSrc} alt="Noyya logo" />
              </div>
              {/* Showroom name and logo */}
              {isSuccess && showroom && (
                <div className="profile-greet">
                  <img
                    src={showroom.images[showroom.logoImageIndex]}
                    alt={showroomName + " logo"}
                  />
                  <p>{showroomName}</p>
                </div>
              )}
            </div>
            {/* Language */}
            <div className="right">
              <LangDropDown />
            </div>
          </nav>
          <Outlet context={{ show }} />
        </div>
      </div>
    </>
  );
};

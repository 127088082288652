import "./TechnicalSpecs.css";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { UseFormWatch, UseFormSetValue, FieldErrors } from "react-hook-form";
import { SaleCarOptions } from "../../../../../../../../data/FilterData";
import { DropDown } from "../../../../../../../../components/customs/drop down/DropDown";
import { AddCarDataType } from "../../../add car/AddCar";

type TechnicalSpecsProps = {
  watch: UseFormWatch<AddCarDataType>;
  setValue: UseFormSetValue<AddCarDataType>;
  errors: FieldErrors<AddCarDataType>;
};
export const TechnicalSpecs: FC<TechnicalSpecsProps> = ({
  watch,
  setValue,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <section id="technical-specs">
      <header>
        <p>{t(`showroomManagement.inventory.addCar.stepThreeHeading`)}</p>
      </header>
      <div className="row">
        <div className="col">
          <DropDown
            options={SaleCarOptions.engine}
            placeholder={"Engine"}
            setValue={setValue}
            watch={watch}
          />
          {errors.engine && <p className="error">{errors.engine.message}</p>}
        </div>
        <div className="col">
          <DropDown
            options={SaleCarOptions.horsepower}
            placeholder={"Horsepower"}
            setValue={setValue}
            watch={watch}
          />
          {errors.horsepower && (
            <p className="error">{errors.horsepower.message}</p>
          )}
        </div>
        <div className="col">
          <DropDown
            options={SaleCarOptions.transmission}
            placeholder={"Transmission"}
            setValue={setValue}
            watch={watch}
          />
          {errors.transmission && (
            <p className="error">{errors.transmission.message}</p>
          )}
        </div>
        <div className="col">
          <DropDown
            options={SaleCarOptions.cylinders}
            placeholder={"Cylinders"}
            setValue={setValue}
            watch={watch}
          />
          {errors.cylinders && (
            <p className="error">{errors.cylinders.message}</p>
          )}
        </div>
        <div className="col">
          <DropDown
            options={SaleCarOptions.fuelType}
            placeholder={"Fuel Type"}
            setValue={setValue}
            watch={watch}
          />
          {errors.fuelType && (
            <p className="error">{errors.fuelType.message}</p>
          )}
        </div>
      </div>
    </section>
  );
};

import "./PlateListings.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import {
  useDeletePlateByIdMutation,
  useGetPlatesByShowroomIdQuery,
} from "../../../../../../app/api/platesApiSlice";
import { useShowroom } from "../../../../../../hooks/useAuth";
import { PlateTable } from "../../../../../../components/plate listings table/PlateTable";
import { NextIcon } from "../../../../../../assets/svg/nextIcon";
import { PreviousIcon } from "../../../../../../assets/svg/previousIcon";
import { Loading } from "../../../../../../components/customs/loading/Loading";

export const PlateListings = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { showroom, isSuccess } = useShowroom();
  const [pageNumber, setPageNumber] = useState(1);
  const limit = 20;

  // Plates table heading
  const platesTableHeading = [
    t("showroomManagement.plateTable.plate"),
    t("showroomManagement.plateTable.price"),
    t("showroomManagement.plateTable.status.heading"),
    t("showroomManagement.plateTable.listedAt"),
    t("showroomManagement.plateTable.actions"),
  ];

  // Delete plate mutation
  const [Delete, { isLoading: isDeleteLoading, isSuccess: isDeleteSuccess }] =
    useDeletePlateByIdMutation();

  // Get plates
  const {
    data,
    isSuccess: isPlatesSuccess,
    isLoading: isPlatesLoading,
    isFetching: isPlatesFetching,
  } = useGetPlatesByShowroomIdQuery(
    {
      showroomId: showroom?._id,
      page: pageNumber,
      limit,
    },
    {
      skip: !showroom?._id,
    }
  );

  // Change page
  const changePage = ({ selected }: { selected: number }) => {
    setPageNumber(selected + 1);
    window.scrollTo(0, 0);
  };

  // Edit plate success toast
  useEffect(() => {
    if (location.state && location.state.plateAdded) {
      toast.success(location.state.plateAdded);
      location.state = {};
    }
    if (location.state && location.state.plateUpdated) {
      toast.success(location.state.plateUpdated);
      location.state = {};
    }
  }, [location.state]);

  // Delete plate success toast
  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success(t("alert.plateDeleted"));
    }
  }, [Delete, isDeleteSuccess]);

  return (
    <div id="plate-listings">
      <ToastContainer />
      {isPlatesLoading || isPlatesFetching ? (
        <Loading />
      ) : isPlatesSuccess && data.plates?.length > 0 ? (
        <PlateTable
          thead={platesTableHeading}
          plates={data.plates}
          editPath="../../edit-plate/"
          Delete={Delete}
          isDeleteLoading={isDeleteLoading}
        />
      ) : (
        <p className="no-cars">
          {t("showroomManagement.inventory.plateListings.noPlates")}
        </p>
      )}
      <footer>
        {isPlatesSuccess && data.plates?.length > 0 && (
          <p>
            {t("number", { count: data?.currentRangeStart })}-
            {t("number", { count: data?.currentRangeEnd })}
            &nbsp;{t("showroomManagement.inventory.plateListings.of")}&nbsp;
            {t("number", { count: data?.totalPlates })}
            &nbsp;{t("showroomManagement.inventory.plateListings.listings")}
          </p>
        )}
        {/* Pagination */}
        {isPlatesSuccess && data.pageCount > 0 && (
          <ReactPaginate
            previousLabel={<PreviousIcon height={10} />}
            nextLabel={<NextIcon height={10} />}
            pageCount={data.pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBtns"}
            previousLinkClassName={"previousBtn"}
            nextLinkClassName={"nextBtn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            forcePage={Number(pageNumber) - 1}
            pageRangeDisplayed={3}
            breakLabel={"..."}
          />
        )}
      </footer>
    </div>
  );
};

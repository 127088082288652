import "./ShowroomDetails.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import { sortData } from "../../data/SortData";
import { RegisterData } from "../../data/RegisterData";
import {
  useShowroom,
  useShowroomCars,
  useShowroomRentCars,
} from "../../hooks/useAuth";
import { SaleCard } from "../../components/cards/SaleCard";
import { RentalCard } from "../../components/cards/RentalCard";
import { SortDropDown } from "../../components/customs/sort drop down/SortDropDown";
import { ShowroomImageSlider } from "../../components/showroom image slider/ShowroomImageSlider";
import { MobileSort } from "../../components/customs/mobile sort/MobileSort";
import { Loading } from "../../components/customs/loading/Loading";
import { camelCase } from "../../utilities/camelCase";
import { DpdownIcon } from "../../assets/svg/dpdownIcon";
import { FacebookIcon } from "../../assets/svg/facebookIcon";
import { InstagramIcon } from "../../assets/svg/instagramIcon";
import { SnapchatIcon } from "../../assets/svg/snapchatIcon";
import { TiktokIcon } from "../../assets/svg/tiktokIcon";
import { LinkedinIcon } from "../../assets/svg/linkedinIcon";
import { YoutubeIcon } from "../../assets/svg/youtubeIcon";
import { LocationIcon } from "../../assets/svg/locationIcon";
import { CheckSolidIcon } from "../../assets/svg/checkSolidIcon";
import { TwitterXIcon } from "../../assets/svg/twitterXIcon";
import { SEO } from "../../utilities/SEO";
import { CardSkeleton } from "../../components/skeleton/CardSkeleton";
import { SortIcon } from "../../assets/svg/sortIcon";
import { motion } from "framer-motion";
import { ShareIcon } from "../../assets/svg/shareIcon";
import { ShareModal } from "../../components/share modal/ShareModal";

export const ShowroomDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { showroomId } = useParams();
  const carStatus = "available";

  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSortOpen, setIsSortOpen] = useState<boolean>(false);
  const [isAddressOpen, setIsAddressOpen] = useState<boolean>(false);
  const [isMobileVisible, setIsMobileVisible] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState<number>(
    Number(searchParams.get("page")) || 1
  );
  const limit = 24;

  const [selectedSort, setSelectedSort] = useState<string>(
    searchParams.get("sort") || ""
  );

  // Get showroom
  const { showroom, isLoading, isSuccess, isFetching, isError, error } =
    useShowroom(showroomId as string);

  const showroomType = showroom?.showroomType;

  // Showroom name
  const showroomName = useMemo(() => {
    const showroomNames: { [key: string]: string } = {
      ar: showroom?.nameInAr,
      ku: showroom?.nameInKu,
      default: showroom?.name,
    };

    return showroomNames[i18n.language] || showroomNames.default;
  }, [i18n.language, showroom?.name, showroom?.nameInAr, showroom?.nameInKu]);

  // Get sale showroom cars
  const {
    cars,
    isSuccess: isCarsDataSuccess,
    isLoading: isCarsDataLoading,
    isFetching: isCarsDataFetching,
    isError: isCarsDataError,
    error: carsError,
  } = useShowroomCars(
    showroomId as string,
    Number(pageNumber),
    limit,
    selectedSort,
    false,
    showroomType,
    carStatus
  );

  // Get rental showroom cars
  const {
    cars: rentCars,
    isSuccess: isRentCarsDataSuccess,
    isLoading: isRentCarsDataLoading,
    isFetching: isRentCarsDataFetching,
    isError: isRentCarsDataError,
    error: rentCarsError,
  } = useShowroomRentCars(
    showroomId as string,
    Number(pageNumber),
    limit,
    selectedSort,
    false,
    showroomType,
    carStatus
  );

  // Change page number
  const changePage = ({ selected }: { selected: number }) => {
    setPageNumber(selected + 1);
    // Scroll to section #sd-feature
    const featurePosition = document.getElementById("sd-feature")?.offsetTop;
    window.scrollTo(0, featurePosition! - 80);
  };

  // Format time for opening days
  const formatTime = (time: string | undefined) => {
    if (time) {
      const [hour, minute] = time.split(":");
      const newHour = Number(hour) > 12 ? Number(hour) - 12 : hour;

      if (i18n.language === "ar") {
        const timeInAr = Intl.DateTimeFormat("ar-IQ", {
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(0, 0, 0, Number(hour), Number(minute)));
        return timeInAr;
      } else if (i18n.language === "ku") {
        const timeInKu = Intl.DateTimeFormat("ar-IQ", {
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(0, 0, 0, Number(hour), Number(minute)));
        return timeInKu.replace("م", "د.ن").replace("ص", "پ.ن");
      }

      return `${newHour}:${minute} ${Number(hour) > 12 ? "PM" : "AM"}`;
    }
  };

  // Path for sale or rental cars
  const path = location.pathname.split("/");
  const newPath =
    path
      .slice(0, -1)
      .join("/")
      .replace("showrooms", showroomType === "sale" ? "cars" : "rental-cars") +
    "/";

  // Check if showroom social media is empty
  const isSocialMediaEmpty = (socialMedia: typeof showroom.socialMedia) => {
    if (
      !socialMedia?.facebook &&
      !socialMedia?.instagram &&
      !socialMedia?.linkedin &&
      !socialMedia?.snapchat &&
      !socialMedia?.tiktok &&
      !socialMedia?.twitter &&
      !socialMedia?.youtube
    ) {
      return true;
    }
  };

  // Set page number and sort to url
  useEffect(() => {
    setSearchParams((prev) => {
      pageNumber && prev.set("page", pageNumber.toString());
      selectedSort && prev.set("sort", selectedSort);
      return prev;
    });
  }, [pageNumber, selectedSort]);

  // Reset page number when sort changes
  useEffect(() => {
    setPageNumber(1);
  }, [selectedSort]);

  // Set page number to the last page when i leave the page
  useEffect(() => {
    const savedPageNumber = Number(searchParams.get("page")) || 1;
    setPageNumber(savedPageNumber > 1 ? savedPageNumber : 1);
  }, []);

  // Show sort btn when scrolling to feature section for mobile UI
  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY;
    const featurePosition = document.getElementById("sd-feature")?.offsetTop;

    if (scrollPosition >= featurePosition!) {
      document.querySelector(".mobile")?.classList.add("mobile-fade-in");
      setIsMobileVisible(true);
    } else {
      document.querySelector(".mobile")?.classList.remove("mobile-fade-in");
      setIsMobileVisible(false);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  // Share button handler
  const handleShareButton = () => {
    // Check if navigator.share is supported by the browser
    if (navigator?.share) {
      navigator.share({
        url: location.pathname,
        title: t("seo.showroomTitle", {
          name: showroomName,
          deal: t(`showroomType.${showroom.showroomType}`),
        }).toString(),
      });
    } else {
      setShowModal(true);
    }
  };
  // Redirect to showroom list if showroom is not found or error
  useEffect(() => {
    if (isError) {
      navigate("..");
    }
  }, [isError, navigate]);

  return (
    <>
      {/* SEO */}
      {showroom && (
        <SEO
          title={t("seo.showroomTitle", {
            name: showroomName,
            deal: t(`showroomType.${showroom.showroomType}`),
          })}
          titlePost={
            t("seo.showroomTitle", {
              name: showroomName,
              deal: "",
            }).toString() +
            t(`dealershipType.${camelCase(showroom.dealershipType)}`)
          }
          description={showroom?.description}
          image={showroom.images[showroom.primaryImageIndex || 0]}
        />
      )}

      {/* Hero Section */}
      <section id="sd-hero">
        {isLoading || isFetching ? (
          <Loading />
        ) : isSuccess && showroom ? (
          <div className="showroom">
            {/* Header */}
            <header>
              <div className="leftside">
                <div className="showroom-logo">
                  <img
                    src={showroom.images[showroom.logoImageIndex]}
                    alt={showroomName + " logo"}
                  />
                </div>
                <div className="showroom-info">
                  <h1>{showroomName}</h1>
                  <div className="showroom-type">
                    <CheckSolidIcon />
                    <h2>
                      {t(`showroomType.${showroom.showroomType}`)}&nbsp;
                      {showroom?.dealershipType && "-"}
                      &nbsp;
                      {showroom?.dealershipType &&
                        t(
                          `dealershipType.${camelCase(showroom.dealershipType)}`
                        )}
                    </h2>
                  </div>
                  <div className="city">
                    <LocationIcon fill="var(--primary-900)" />
                    <h3>
                      {showroom.city?.name &&
                        t(`city.${camelCase(showroom.city.name)}`)}
                    </h3>
                  </div>
                </div>
              </div>

              {/* Share button */}
              <button className="share-btn" onClick={handleShareButton}>
                <ShareIcon fill="#28282b" width={20} height={20} />
              </button>
              {showModal && <ShareModal setShowModal={setShowModal} />}
            </header>
            {/* Showroom details */}
            <div className="showroom-details">
              <div className="col">
                <div className="row">
                  {/* Images slider */}
                  <ShowroomImageSlider
                    images={showroom.images.filter(
                      (image, index) => index !== showroom.logoImageIndex
                    )}
                    showroomName={showroomName}
                  />
                </div>
              </div>
              <div className="col">
                {/* Description */}
                {showroom.description && (
                  <div className="row">
                    <p className="heading"> {t("showrooms.details.about")}</p>
                    <p>{showroom.description}</p>
                  </div>
                )}
                {/* Website */}
                {showroom.website && (
                  <div className="row">
                    <p className="heading">{t("showrooms.details.website")}</p>
                    <a href={showroom.website} target="_blank" rel="noreferrer">
                      {
                        showroom.website
                          .replace(/^https?:\/\//, "")
                          .split("/")[0]
                      }
                    </a>
                  </div>
                )}
                {/* Phone number */}
                <div className="row">
                  <p className="heading">
                    {t("showrooms.details.phoneNumber")}
                  </p>
                  <div className="phone-numbers">
                    {showroom.phoneNumbers.map((number) => (
                      <p key={number}>
                        {t("phone", { count: Number(number) })}
                      </p>
                    ))}
                  </div>
                </div>
                {/* Email */}
                {showroom.email && (
                  <div className="row">
                    <p className="heading">{t("showrooms.details.email")}</p>
                    <p>{showroom.email}</p>
                  </div>
                )}
                {/* Social media */}
                {!isSocialMediaEmpty(showroom.socialMedia) && (
                  <div className="row">
                    <p className="heading">
                      {t("showrooms.details.socialMedia")}
                    </p>
                    <div className="social-accounts">
                      {showroom.socialMedia?.instagram && (
                        <a
                          href={showroom.socialMedia?.instagram}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <InstagramIcon fill="var(--primary-900)" />
                        </a>
                      )}
                      {showroom.socialMedia?.facebook && (
                        <a
                          href={showroom.socialMedia?.facebook}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FacebookIcon fill="var(--primary-900)" />
                        </a>
                      )}
                      {showroom.socialMedia?.snapchat && (
                        <a
                          href={showroom.socialMedia?.snapchat}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <SnapchatIcon fill="var(--primary-900)" />
                        </a>
                      )}
                      {showroom.socialMedia?.tiktok && (
                        <a
                          href={showroom.socialMedia?.tiktok}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <TiktokIcon fill="var(--primary-900)" />
                        </a>
                      )}
                      {showroom.socialMedia?.linkedin && (
                        <a
                          href={showroom.socialMedia?.linkedin}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <LinkedinIcon fill="var(--primary-900)" />
                        </a>
                      )}
                      {showroom.socialMedia?.twitter && (
                        <a
                          href={showroom.socialMedia?.twitter}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <TwitterXIcon
                            fill="#fff"
                            color="var(--primary-900)"
                          />
                        </a>
                      )}
                      {showroom.socialMedia?.youtube && (
                        <a
                          href={showroom.socialMedia?.youtube}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <YoutubeIcon fill="var(--primary-900)" />
                        </a>
                      )}
                    </div>
                  </div>
                )}
                {/* Opening times */}
                {showroom.openingDays && (
                  <div className="row">
                    <p
                      className="heading heading-dp"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      {t("inputPlaceholder.openingTimes.heading")}
                      <DpdownIcon isDpOpen={isOpen} width={14} />
                    </p>
                    {isOpen && (
                      <table>
                        <thead>
                          <tr>
                            <th>{t("showrooms.details.day")}</th>
                            <th>{t("showrooms.details.time")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {RegisterData.daysOfTheWeekOptions.map((day) => (
                            <tr key={day}>
                              <td>
                                {t(`inputPlaceholder.openingTimes.days.${day}`)}
                              </td>
                              <td>
                                {showroom.openingDays[day].opening === "open"
                                  ? formatTime(
                                      showroom.openingDays[day].openTime
                                    ) +
                                    " - " +
                                    formatTime(
                                      showroom.openingDays[day].closeTime
                                    )
                                  : t(
                                      `inputPlaceholder.openingTimes.${showroom.openingDays[day].opening}`
                                    )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
                {/* Address on map */}
                <div className="row">
                  <p
                    onClick={() => setIsAddressOpen(!isAddressOpen)}
                    className="heading heading-dp"
                  >
                    {t(`inputPlaceholder.address`)}
                    <DpdownIcon isDpOpen={isAddressOpen} width={14} />
                  </p>
                  {isAddressOpen && (
                    <iframe
                      title={showroom.name}
                      loading="lazy"
                      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${showroom.latLng.lat},${showroom.latLng.lng}&zoom=15`}
                    ></iframe>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          isError && (
            <p className="fetch-error">
              {error.status} <br />
              {JSON.stringify(error.data)}
            </p>
          )
        )}
      </section>

      {/* Showroom cars if showroom type is sale */}
      {showroomType === "sale" ? (
        isCarsDataLoading || isCarsDataFetching ? (
          <div id="sd-feature">
            <div className="header"></div>
            <div className="sale">
              <div className="cards">
                {Array.from({ length: 6 }).map((_, index) => (
                  <CardSkeleton key={index} />
                ))}
              </div>
            </div>
          </div>
        ) : isCarsDataSuccess && cars.saleCars?.length > 0 ? (
          <section id="sd-feature">
            {/* Mobile UI tab btn for sort */}
            {isMobileVisible && (
              <div className="mobile">
                <div className="btn">
                  <button
                    onClick={() => setIsSortOpen(!isSortOpen)}
                    aria-label={t("btns&links.sort").toString()}
                  >
                    <SortIcon />
                    {t("btns&links.sort")}
                  </button>
                </div>
              </div>
            )}
            {/* Header */}
            <div className="header">
              <h3>
                {cars.totalCars}&nbsp;
                {t("showrooms.listings")}
              </h3>
              {/* Sort drop-down */}
              <div>
                <SortDropDown
                  options={sortData.sortCars}
                  placeholder="Sort By"
                  setSelectedSort={setSelectedSort}
                  selectedSort={selectedSort}
                />
              </div>
            </div>
            {/* Sale cars cards */}
            <div className="sale">
              <div className="cards">
                {cars.saleCars?.map((car, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, transform: "translateY(40px)" }}
                    whileInView={{
                      opacity: 1,
                      transform: "none",
                      transition: { delay: index * 0.015 },
                    }}
                    viewport={{ once: true }}
                  >
                    <SaleCard key={car._id} data={car} path={newPath} />
                  </motion.div>
                ))}
              </div>
              {/* Sale cars pagination */}
              {cars.pageCount > 0 && (
                <ReactPaginate
                  previousLabel={t("btns&links.previous")}
                  nextLabel={t("btns&links.next")}
                  pageCount={cars.pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBtns"}
                  previousLinkClassName={"previousBtn"}
                  nextLinkClassName={"nextBtn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                  forcePage={pageNumber - 1}
                  pageRangeDisplayed={3}
                  breakLabel={"..."}
                />
              )}
            </div>
          </section>
        ) : (
          isCarsDataError && (
            <div id="sd-feature">
              <p className="fetch-error">
                {carsError.status}
                {JSON.stringify(carsError.data)}
              </p>
            </div>
          )
        )
      ) : // Showroom cars if showroom type is rental
      isRentCarsDataLoading || isRentCarsDataFetching ? (
        <div id="sd-feature">
          <div className="header"></div>
          <div className="rental">
            <div className="cards">
              {Array.from({ length: 6 }).map((_, index) => (
                <CardSkeleton key={index} />
              ))}
            </div>
          </div>
        </div>
      ) : isRentCarsDataSuccess && rentCars.rentCars?.length > 0 ? (
        <section id="sd-feature">
          {/* Mobile UI tab btn for sort */}
          {isMobileVisible && (
            <div className="mobile">
              <div className="btn">
                <button
                  onClick={() => setIsSortOpen(!isSortOpen)}
                  aria-label={t("btns&links.sort").toString()}
                >
                  <SortIcon />
                  {t("btns&links.sort")}
                </button>
              </div>
            </div>
          )}
          {/* Header */}
          <div className="header">
            <h3>
              {rentCars.totalCars} {t("showrooms.listings")}
            </h3>
            {/* Sort drop-down */}
            <div>
              <SortDropDown
                options={sortData.sortRentalCars}
                placeholder="Sort By"
                setSelectedSort={setSelectedSort}
                selectedSort={selectedSort}
              />
            </div>
          </div>
          {/* Rent cars cards */}
          <div className="rental">
            <div className="cards">
              {rentCars.rentCars?.map((car, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, transform: "translateY(40px)" }}
                  whileInView={{
                    opacity: 1,
                    transform: "none",
                    transition: { delay: index * 0.015 },
                  }}
                  viewport={{ once: true }}
                >
                  <RentalCard key={car._id} data={car} path={newPath} />
                </motion.div>
              ))}
            </div>
            {/* Rent cars pagination */}
            {rentCars.pageCount > 0 && (
              <ReactPaginate
                previousLabel={t("btns&links.previous")}
                nextLabel={t("btns&links.next")}
                pageCount={rentCars.pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBtns"}
                previousLinkClassName={"previousBtn"}
                nextLinkClassName={"nextBtn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                forcePage={pageNumber - 1}
                pageRangeDisplayed={3}
                breakLabel={"..."}
              />
            )}
          </div>
        </section>
      ) : (
        isRentCarsDataError && (
          <div id="sd-feature">
            <p className="fetch-error">
              {rentCarsError.status}
              {JSON.stringify(rentCarsError.data)}
            </p>
          </div>
        )
      )}
      {/* Mobile UI for sort */}
      {isSortOpen && (
        <MobileSort
          sort={
            showroomType === "sale"
              ? sortData.sortCars
              : sortData.sortRentalCars
          }
          setSortOpen={setIsSortOpen}
          setSelectedSort={setSelectedSort}
          selectedSort={selectedSort}
          isShowroomCars={true}
        />
      )}
    </>
  );
};

import { useState } from "react";
import {
  useShowroom,
  useShowroomRentCars,
} from "../../../../../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { NextIcon } from "../../../../../../../assets/svg/nextIcon";
import { PreviousIcon } from "../../../../../../../assets/svg/previousIcon";
import { Loading } from "../../../../../../../components/customs/loading/Loading";
import { RentCarAnalyticsTable } from "../../../../../../../components/rent car analytics table/RentCarAnalyticsTable";
import { RentCarType } from "../../../../../../../components/cards/RentalCard";
import { useGetRentCarsByShowroomQuery } from "../../../../../../../app/api/rentCarsApiSlice";
export const RentAnalytics = () => {
  const { t, i18n } = useTranslation();

  const { showroom } = useShowroom();
  const [pageNumber, setPageNumber] = useState(1);
  const limit = 8;

  // Rent cars table heading
  const rentCarsTableHeading = [
    t("showroomManagement.carTable.car"),
    t("showroomManagement.carTable.listedAt"),
    t("showroomManagement.carTable.views"),
    t("showroomManagement.carTable.visitors"),
  ];

  // Get showroom cars
  const {
    data,
    isSuccess: isCarsDataSuccess,
    isFetching: isCarsDataFetching,
    isLoading: isCarsDataLoading,
  } = useGetRentCarsByShowroomQuery(
    {
      page: pageNumber,
      limit,
      showroomId: showroom?._id,
      sort: "",
      status: "",
      isAnalytics: true,
    },
    {
      skip: showroom?.showroomType !== "rental" || !showroom?._id,
    }
  ) as {
    data: {
      rentCars: RentCarType[];
      pageCount: number;
      totalCars: number;
      currentRangeStart: number;
      currentRangeEnd: number;
      carAnalytics: { views: number; visitors: number; _id: string }[];
    };
    isSuccess: boolean;
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    error: unknown;
  };

  // Change page
  const changePage = ({ selected }: { selected: number }) => {
    setPageNumber(selected + 1);
    window.scrollTo(0, 0);
  };

  return (
    <div id="rent-analytics">
      {isCarsDataLoading || isCarsDataFetching ? (
        <Loading />
      ) : isCarsDataSuccess && data?.rentCars?.length > 0 ? (
        <RentCarAnalyticsTable
          thead={rentCarsTableHeading}
          cars={data?.rentCars}
          carPath={`/${i18n.language}/cars/details/`}
          carAnalytics={data?.carAnalytics}
        />
      ) : (
        <p className="no-cars">
          {t("showroomManagement.inventory.carListings.noCars")}
        </p>
      )}
      <footer>
        {isCarsDataSuccess && data.rentCars?.length > 0 && (
          <p>
            {t("number", { count: data?.currentRangeStart })}-
            {t("number", { count: data?.currentRangeEnd })}
            &nbsp;{t("showroomManagement.inventory.carListings.of")}&nbsp;
            {t("number", { count: data?.totalCars })}
            &nbsp;{t("showroomManagement.inventory.carListings.listings")}
          </p>
        )}
        {/* Pagination */}
        {isCarsDataSuccess && data.pageCount > 0 && (
          <ReactPaginate
            previousLabel={<PreviousIcon height={10} />}
            nextLabel={<NextIcon height={10} />}
            pageCount={data.pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBtns"}
            previousLinkClassName={"previousBtn"}
            nextLinkClassName={"nextBtn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            forcePage={Number(pageNumber) - 1}
            pageRangeDisplayed={3}
            breakLabel={"..."}
          />
        )}
      </footer>
    </div>
  );
};

import "./StepTwo.css";
import { useEffect, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  UseFormRegister,
  FieldErrors,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
  UseFormUnregister,
} from "react-hook-form";
import { RegisterData } from "../../../../data/RegisterData";
import { DropDown } from "../../../../components/customs/drop down/DropDown";
import { OpeningDay } from "../../../../components/opening times/OpeningDay";
import { Map } from "../../../../components/google map/Map";
import { RegisterDataType } from "../Register";

type OutletContextType = {
  register: UseFormRegister<RegisterDataType>;
  error: any;
  errors: FieldErrors<RegisterDataType>;
  watch: UseFormWatch<RegisterDataType>;
  setValue: UseFormSetValue<RegisterDataType>;
  trigger: UseFormTrigger<RegisterDataType>;
  unregister: UseFormUnregister<RegisterDataType>;
};
export const StepTwo = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, errors, watch, setValue, trigger, unregister } =
    useOutletContext<OutletContextType>();

  // Only allow numbers in input
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // only allow numbers
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 46
      )
    )
      e.preventDefault();
  };

  // Redirect to first step if showroom is not verified
  useEffect(() => {
    const redirectToFirstStep = async () => {
      const isValid = await trigger(
        ["name", "phoneNumber", "showroomType", "password", "confirmPassword"],
        { shouldFocus: true }
      );
      if (!isValid || watch("isVerified") !== true) {
        navigate("..");
      }
    };
    redirectToFirstStep();
  }, [navigate]);

  return (
    <section id="step-two">
      <p>{t("register.stepTwo.heading")}</p>
      <div className="row">
        <div className="col">
          <div className="input-group">
            <input
              placeholder=" "
              type="text"
              {...register("nameInKu", { required: true })}
            />
            <label>{t("inputPlaceholder.showroomNameInKu")}</label>
          </div>
          {errors.nameInKu && (
            <p className="error">{errors.nameInKu.message}</p>
          )}
        </div>
        <div className="col">
          <div className="input-group">
            <input
              placeholder=" "
              type="text"
              {...register("nameInAr", { required: true })}
            />
            <label>{t("inputPlaceholder.showroomNameInAr")}</label>
          </div>
          {errors.nameInAr && (
            <p className="error">{errors.nameInAr.message}</p>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="input-group">
            <input placeholder=" " type="text" {...register("website")} />
            <label>{t("inputPlaceholder.website")}</label>
          </div>
          {/* {errors.website && <p className="error">{errors.website.message}</p>} */}
        </div>
        {watch("showroomType") === "sale" && (
          <div className="col">
            <DropDown
              options={RegisterData.dealershipTypeOptions}
              placeholder={"Dealership Type"}
              setValue={setValue}
              watch={watch}
            />
            {errors.dealershipType && (
              <p className="error">{errors.dealershipType.message}</p>
            )}
          </div>
        )}
      </div>
      <div className="row">
        <div className="col">
          <div className="input-group">
            <input
              placeholder=" "
              type="text"
              maxLength={4}
              {...register("iqdValue", { required: true })}
              onKeyDown={onKeyDown}
            />
            <label>{t("inputPlaceholder.iqdValue")}</label>
          </div>
          {errors.iqdValue && (
            <p className="error">{errors.iqdValue.message}</p>
          )}
        </div>
        <div className="col">
          <DropDown
            options={RegisterData.cityOptions}
            placeholder={"City"}
            setValue={setValue}
            watch={watch}
            unregister={unregister}
          />
          {errors.city?.name && (
            <p className="error">{errors.city.name.message}</p>
          )}
        </div>
      </div>
      {watch("city") && (
        <div className="row">
          <div className="col">
            <div className="address">
              <p>{t("inputPlaceholder.address")}</p>
              <div className="map">
                <Map setValue={setValue} watch={watch} />
                {errors.latLng?.lat && (
                  <p className="error">{errors.latLng.lat.message}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col">
          <div className="opening-times">
            <p>{t("inputPlaceholder.openingTimes.heading")}</p>
            <div className="days">
              {RegisterData.daysOfTheWeekOptions.map(
                (day) =>
                  day && (
                    <OpeningDay
                      key={day}
                      day={day}
                      register={register}
                      watch={watch}
                      setValue={setValue}
                      trigger={trigger}
                    />
                  )
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="input-group">
          <textarea placeholder=" " {...register("description")} />
          <label>{t("inputPlaceholder.aboutYourShowroom")}</label>
        </div>
      </div>

      <button
        className="btn"
        type="button"
        onClick={async () => {
          const isValid = await trigger(
            ["nameInKu", "nameInAr", "city", "latLng", "iqdValue"],
            {
              shouldFocus: true,
            }
          );
          isValid && navigate("../step-three");
        }}
      >
        {t("btns&links.next")}
      </button>
    </section>
  );
};

import "./CardSkeleton.css";
type Props = {
  isShowroomCard?: boolean;
};
export const CardSkeleton = ({ isShowroomCard }: Props) => {
  return (
    <div className="card-skeleton">
      <div className="card-header-skeleton">
        {/* Primary Image Skeleton */}
        <div className="card-img-container-skeleton"></div>
        <div
          className={`card-logo-skeleton ${isShowroomCard ? "" : "hidden"}`}
        ></div>
      </div>
      <div className="card-footer-skeleton">
        {/* Make and Model Skeleton */}
        <div>
          <div className="make-skeleton"></div>
          <div
            className={`model-skeleton ${isShowroomCard ? "hidden" : ""}`}
          ></div>
        </div>
        {/* Solid Line */}
        <div className="solid"></div>
        {/* Card Info Skeleton */}
        <div className="card-info-skeleton">
          {/* Mileage Skeleton */}
          <div></div>
          {/* Location Skeleton */}
          <div></div>
          {/* Condition Skeleton */}
          <div></div>
        </div>
      </div>
    </div>
  );
};

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const BackIcon = (props: Props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <g>
        <path
          d="M16.875 7.75141H3.5325L7.61625 2.84641C7.80721 2.61666 7.89908 2.32047 7.87165 2.02299C7.84422 1.72552 7.69974 1.45111 7.47 1.26016C7.24026 1.0692 6.94407 0.977333 6.64659 1.00476C6.34911 1.03219 6.07471 1.17667 5.88375 1.40641L0.25875 8.15641C0.220906 8.2101 0.187064 8.2665 0.1575 8.32516C0.1575 8.38141 0.1575 8.41516 0.0787502 8.47141C0.0277579 8.6004 0.00105876 8.73771 0 8.87641C0.00105876 9.01511 0.0277579 9.15242 0.0787502 9.28141C0.0787502 9.33766 0.0787498 9.37141 0.1575 9.42766C0.187064 9.48632 0.220906 9.54272 0.25875 9.59641L5.88375 16.3464C5.98953 16.4734 6.12198 16.5755 6.2717 16.6455C6.42142 16.7155 6.58473 16.7517 6.75 16.7514C7.01286 16.7519 7.2676 16.6604 7.47 16.4927C7.58392 16.3982 7.67808 16.2822 7.7471 16.1513C7.81612 16.0204 7.85864 15.8772 7.87223 15.7299C7.88581 15.5825 7.8702 15.4339 7.82627 15.2926C7.78235 15.1513 7.71098 15.0201 7.61625 14.9064L3.5325 10.0014H16.875C17.1734 10.0014 17.4595 9.88288 17.6705 9.6719C17.8815 9.46093 18 9.17478 18 8.87641C18 8.57804 17.8815 8.29189 17.6705 8.08091C17.4595 7.86994 17.1734 7.75141 16.875 7.75141Z"
          fill={props.fill || "#28282B"}
        />
      </g>
    </g>
  </svg>
);

export { BackIcon };

import "./PlateCardSkeleton.css";
import React from "react";

export const PlateCardSkeleton: React.FC = () => {
  return (
    <div className="plate-card-skeleton">
      {/* Canvas Skeleton */}
      <div className="canvas"></div>
      <div className="card-footer">
        {/* Price Skeleton */}
        <div className="price"></div>
        {/* Location Skeleton */}
        <div className="location"></div>
        {/* Phone Number Skeleton */}
        <div className="phone"></div>
      </div>
    </div>
  );
};

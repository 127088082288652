import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const ProfileIcon = (props: Props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1701_218)">
      <g>
        <path
          d="M9.00003 0.899994C4.52639 0.899994 0.900024 4.52636 0.900024 8.99999C0.900024 13.4736 4.52639 17.1 9.00003 17.1C13.4737 17.1 17.1 13.4736 17.1 8.99999C17.1 4.52636 13.4737 0.899994 9.00003 0.899994Z"
          stroke="#28282B"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.73962 14.1404C2.73962 14.1404 4.54511 11.8351 9.00011 11.8351C13.4551 11.8351 15.2614 14.1404 15.2614 14.1404M9.00011 9.00014C9.64459 9.00014 10.2627 8.74412 10.7184 8.28841C11.1741 7.83269 11.4301 7.21461 11.4301 6.57014C11.4301 5.92566 11.1741 5.30758 10.7184 4.85187C10.2627 4.39615 9.64459 4.14014 9.00011 4.14014C8.35564 4.14014 7.73756 4.39615 7.28184 4.85187C6.82613 5.30758 6.57011 5.92566 6.57011 6.57014C6.57011 7.21461 6.82613 7.83269 7.28184 8.28841C7.73756 8.74412 8.35564 9.00014 9.00011 9.00014Z"
          stroke="#28282B"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath>
        <rect width={18} height={18} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { ProfileIcon };

import { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  UseFormWatch,
  UseFormSetValue,
  FieldErrors,
  UseFormUnregister,
} from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { UpdateRentCarDataType } from "../../pages/auth showroom/profile/Inventory management/outlet/edit car/rent car/EditRentCar";
import { UpdateCarDataType } from "../../pages/auth showroom/profile/Inventory management/outlet/edit car/sale car/EditCar";
import { CheckIcon } from "../../assets/svg/checkIcon";
import { CheckSolidIcon } from "../../assets/svg/checkSolidIcon";
import { CloseIcon } from "../../assets/svg/closeIcon";
import { ImgIcon } from "../../assets/svg/imgIcon";
import Compressor from "compressorjs";

type Props = {
  setValue: UseFormSetValue<UpdateRentCarDataType | UpdateCarDataType | any>;
  unregister: UseFormUnregister<
    UpdateRentCarDataType | UpdateCarDataType | any
  >;
  watch: UseFormWatch<UpdateRentCarDataType | UpdateCarDataType | any>;
  errors: FieldErrors<UpdateRentCarDataType | UpdateCarDataType | any>;
  images: FileList;
  setImages: Dispatch<SetStateAction<FileList>>;
  savedImages: string[];
  setSavedImages: Dispatch<SetStateAction<string[]>>;
};
export const UpdateImages: FC<Props> = ({
  setValue,
  unregister,
  watch,
  errors,
  images,
  setImages,
  savedImages,
  setSavedImages,
}) => {
  const { t } = useTranslation();
  const [deletedImages, setDeletedImages] = useState<string[]>([]);
  const primaryImageIndex = watch("primaryImageIndex");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Add images
  const handleImagesChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    // max 20 images
    if (savedImages.length + images.length + e.target.files!.length > 20) {
      toast.error(t("alert.carImagesMax"));
      return;
    }
    const files = e.target.files;
    if (files) {
      const newImages: File[] = Array.from(files);

      const updatedImages = Array.from(images).filter(
        (existingImage) =>
          !newImages.some((newImage) => newImage.name === existingImage.name)
      );

      const isDuplicate = newImages.some((newImage) =>
        Array.from(images).some(
          (existingImage) => existingImage.name === newImage.name
        )
      );
      if (!isDuplicate) {
        // Compress each new image before adding it
        const compressedImages = await Promise.all(
          newImages.map(
            (newImage) =>
              new Promise<File>((resolve) => {
                const currentImg = new Image();
                currentImg.src = URL.createObjectURL(newImage);

                currentImg.onload = () => {
                  const originalWidth = currentImg.width;
                  const originalHeight = currentImg.height;

                  let targetWidth = originalWidth;
                  let targetHeight = originalHeight;

                  if (originalWidth > 1920 && originalHeight < originalWidth) {
                    targetWidth = 1920;
                    targetHeight = 1080;
                  } else if (
                    originalHeight > 1920 &&
                    originalWidth < originalHeight
                  ) {
                    targetWidth = 1080;
                    targetHeight = 1920;
                  }

                  new Compressor(newImage, {
                    quality: 0.8, // Adjust the quality as needed
                    width: targetWidth,
                    height: targetHeight,

                    success(result) {
                      // Convert the compressed result to a File
                      const compressedFile = new File([result], newImage.name, {
                        type: result.type,
                        lastModified: Date.now(),
                      });
                      resolve(compressedFile);
                    },
                    error(err) {
                      console.error("Image compression failed:", err.message);
                      resolve(newImage); // Fallback to the original image if compression fails
                    },
                  });
                };
              })
          )
        );

        const allImages: File[] = [...updatedImages, ...compressedImages];

        const fileList = new DataTransfer();
        allImages.forEach((file) => fileList.items.add(file));
        setImages(fileList.files);
        setIsLoading(false);
      } else {
        toast.error(t("alert.imageAlreadyExists"));
        setIsLoading(false);
      }
    }
  };

  // Delete image
  const handleDeleteImage = (index: number) => {
    // cannot delete if less than 4 images
    if (savedImages.length + images.length < 5) {
      toast.error(t("alert.carImagesDelete"));
      return;
    }
    const newImages = Array.from(images).filter(
      (image, i) => i + savedImages.length !== index
    );
    const isPrimary = primaryImageIndex === index;

    const fileList = new DataTransfer();
    newImages.forEach((file) => fileList.items.add(file));

    setImages(fileList.files);

    if (isPrimary) {
      unregister("primaryImageIndex", { keepError: true });
    } else if (index < Number(primaryImageIndex)) {
      setValue("primaryImageIndex", Number(primaryImageIndex) - 1, {
        shouldValidate: true,
      });
    }

    const inputElement = document.querySelector("#showroom-images");
    if (inputElement instanceof HTMLInputElement) {
      inputElement.files = fileList.files;
    }
  };

  // Delete saved image
  const handleDeleteSavedImage = (index: number) => {
    // cannot delete if less than 4 images
    if (savedImages.length + images.length < 5) {
      toast.error(t("alert.carImagesDelete"));
      return;
    }
    const newImages = savedImages.filter((image, i) => i !== index);
    const isPrimary = primaryImageIndex === index;

    setSavedImages(newImages);
    if (isPrimary) {
      unregister("primaryImageIndex", { keepError: true });
    } else if (index < Number(primaryImageIndex)) {
      setValue("primaryImageIndex", Number(primaryImageIndex) - 1, {
        shouldValidate: true,
      });
    }

    setDeletedImages([...deletedImages, savedImages[index]]);
    setValue("deletedImages", [...deletedImages, savedImages[index]], {
      shouldValidate: true,
    });
  };

  return (
    <div id="upload-images">
      <header>
        <p>{t("showroomManagement.inventory.addCar.stepFourHeading")}</p>
        <p>{t("showroomManagement.inventory.addCar.stepFourDesc")}</p>
      </header>
      <div className="row">
        <div className="images-input">
          <input
            id="car-images"
            type="file"
            onChange={handleImagesChange}
            multiple
            accept="image/*"
          />
          <label htmlFor="car-images">
            <ImgIcon />
            {isLoading ? t("loading") : t("inputPlaceholder.uploadImages")}
          </label>
        </div>
        {errors.images && (
          <p className="error">{errors.images.message as any}</p>
        )}

        <div className="images-preview">
          {savedImages &&
            savedImages.map((image, index) => (
              <div key={index}>
                <div className="image">
                  <img src={image} alt="preview" />
                  <button
                    type="button"
                    onClick={() => handleDeleteSavedImage(index)}
                  >
                    <CloseIcon width={12} height={12} />
                  </button>
                </div>
                <button
                  className="primary-img-btn"
                  type="button"
                  onClick={() => {
                    setValue("primaryImageIndex", index, {
                      shouldValidate: true,
                    });
                  }}
                >
                  {primaryImageIndex === index ? (
                    <CheckSolidIcon width={16} height={16} />
                  ) : (
                    <CheckIcon width={16} height={16} />
                  )}
                  {primaryImageIndex === index
                    ? t("inputPlaceholder.primary")
                    : t("inputPlaceholder.setAsPrimary")}
                </button>
              </div>
            ))}
          {images &&
            Array.from(images).map((image, index) => (
              <div key={index + savedImages.length}>
                <div className="image">
                  <img src={URL.createObjectURL(image)} alt="preview" />
                  <button
                    type="button"
                    onClick={() =>
                      handleDeleteImage(index + savedImages.length)
                    }
                  >
                    <CloseIcon width={12} height={12} />
                  </button>
                </div>
                <button
                  className="primary-img-btn"
                  type="button"
                  onClick={() => {
                    setValue("primaryImageIndex", index + savedImages.length, {
                      shouldValidate: true,
                    });
                  }}
                >
                  {primaryImageIndex === index + savedImages.length ? (
                    <CheckSolidIcon width={16} height={16} />
                  ) : (
                    <CheckIcon width={16} height={16} />
                  )}
                  {primaryImageIndex === index + savedImages.length
                    ? t("inputPlaceholder.primary")
                    : t("inputPlaceholder.setAsPrimary")}
                </button>
              </div>
            ))}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

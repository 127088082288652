import "./SaleCard.css";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ShowroomType } from "./ShowroomCard";
import {
  formatCurrency,
  iqdFormatCurrency,
} from "../../utilities/formatCurrency";
import { camelCase } from "../../utilities/camelCase";
import { LocationIcon } from "../../assets/svg/locationIcon";
import { SpeedoIcon } from "../../assets/svg/speedoIcon";
import { CarConditionIcon } from "../../assets/svg/carConditionIcon";

export type CarType = {
  _id: string;
  images: string[];
  make: string;
  model: string;
  condition: string;
  color: string;
  exteriorColor: string;
  interiorColor: string;
  bodyStyle: string;
  drivetrain: string;
  paint: string;
  seating: string;
  seatMaterial: string;
  transmission: string;
  plate: string;
  plateCategory: string;
  plateCity: string;
  mileage: string;
  mileageUnit: string;
  modelYear: number;
  trim: string;
  engine: string;
  cylinders: string;
  horsepower: string;
  fuelType: string;
  showroom: ShowroomType;
  importer?: string;
  location: string;
  price: number;
  priceUnit: string;
  priceInUsd?: number;
  isPriceShown: boolean;
  createdAt: Date;
  phone: string;
  status: string;
  features?: string[];
  description?: string;
  primaryImageIndex?: number;
  views?: number;
  visitors?: number;
};
type Props = {
  data: CarType;
  path: string;
};
export const SaleCard: FC<Props> = ({ data: car, path }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onCardClick = () => {
    navigate(path + "details/" + car._id);
  };

  return (
    <div onClick={onCardClick} className="card" key={car._id}>
      <div className="card-header">
        {car.status === "available" && car.isPriceShown === true && (
          <p className="price">
            {car.priceUnit === "usd"
              ? formatCurrency(car.price)
              : iqdFormatCurrency(car.price)}
          </p>
        )}
        {car.status === "sold" && (
          <p className="sold">{t(`saleCars.badge.${car.status}`)}</p>
        )}
        {/* Primary image */}
        <div className="card-img-container">
          <img
            loading="lazy"
            className="card-img"
            src={car.images[car.primaryImageIndex || 0]}
            alt={
              t(`make.${camelCase(car.make)}`) +
              " " +
              t(`model.${camelCase(car.model)}`) +
              " " +
              t("number", { count: car.modelYear })
            }
          />
        </div>
      </div>
      <div className="card-footer">
        {/* Make and model, model year */}
        <div>
          <h3 className="make">{t(`make.${camelCase(car.make)}`)}</h3>
          <h4 className="model">
            {t(`model.${camelCase(car.model)}`)}&nbsp;
            {/* {t(`trim.${camelCase(car.trim)}`)}&nbsp; */}
            {t("number", { count: car.modelYear })}
          </h4>
        </div>
        <div className="solid"></div>
        <div className="card-info">
          {/* Mileage */}
          <div>
            <SpeedoIcon />
            <p>
              {t("mileageNumber", { count: Number(car.mileage) })}&nbsp;
              {car.mileageUnit && t(`saleCars.details.${car.mileageUnit}`)}
            </p>
          </div>
          {/* Location */}
          <div>
            <LocationIcon />
            <p>
              {(car.showroom?.city?.name &&
                t(`city.${camelCase(car.showroom.city.name)}`)) ??
                (car.location && t(`city.${camelCase(car.location)}`))}
            </p>
          </div>
          {/* Condition */}
          <div>
            <CarConditionIcon />
            <p>{car.condition && t(`condition.${camelCase(car.condition)}`)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

import "./EmailModal.css";
import { Dispatch, FC, SetStateAction, KeyboardEvent } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useVerifyEmailOtpMutation } from "../../../../../../../app/api/showroomApiSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CloseIcon } from "../../../../../../../assets/svg/closeIcon";

type verifyEmailDataType = {
  email?: string;
  code: string;
  id?: string;
};

type Props = {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  email: string;
  id?: string;
};

export const EmailModal: FC<Props> = ({ setOpenModal, email, id }) => {
  const { t } = useTranslation();

  const verifyEmailSchema = yup.object().shape({
    code: yup
      .string()
      .min(6, t("errorMessage.codeLength") as string)
      .max(6, t("errorMessage.codeLength") as string)
      .required(t("errorMessage.verificationCode") as string),
  });
  const [verifyEmailOpt, { isLoading, error }] = useVerifyEmailOtpMutation();

  const {
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<verifyEmailDataType>({
    resolver: yupResolver(verifyEmailSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<verifyEmailDataType> = async (data) => {
    if (!id || !email) return;
    try {
      await verifyEmailOpt({
        email: email,
        code: data.code,
        id: id,
      }).unwrap();

      setOpenModal(false);
      toast.success(t("alert.emailVerified"));
    } catch (error) {
      console.log(error);
    }
  };

  // Only allow 6 numbers and convert arabic numbers to english in input
  const onChangeCodeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.slice(0, 6);

    // if it is arabic number convert it to english number
    let inputValue = e.target.value.replace(/[\u0660-\u0669]/g, (c) =>
      String.fromCharCode(c.charCodeAt(0) - 0x0660 + 48)
    );

    setValue(`code`, inputValue, {
      shouldValidate: true,
    });
  };

  // Only allow numbers in input
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // only allow numbers
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 46
      )
    )
      e.preventDefault();
  };

  return (
    <div id="email-modal">
      <div className="email-modal-container">
        <div className="close-btn" onClick={() => setOpenModal(false)}>
          <CloseIcon />
        </div>
        <header>
          <p className="title">{t("register.verifyEmailOtp.heading")}</p>
          <p>{t("register.verifyEmailOtp.desc")}</p>
        </header>
        <div className="col">
          <div className="input-group">
            <input
              placeholder=" "
              type="number"
              onChange={onChangeCodeInput}
              onKeyDown={onKeyDown}
            />
            <label>{t("inputPlaceholder.code")}</label>
          </div>
          {errors.code && <p className="error">{errors.code.message}</p>}
          {error && <p className="error">{error?.data?.message}</p>}
        </div>
        <button
          disabled={isLoading}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          {isLoading ? t("loading") : t("btns&links.verify")}
        </button>
      </div>
    </div>
  );
};

import "./ProgressBar.css";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  step: number;
  totalSteps: number;
};
export const ProgressBar: FC<Props> = ({ step, totalSteps }) => {
  const { t } = useTranslation();
  const progress = ((step / totalSteps) * 100).toFixed(1);
  const steps = Array.from({ length: totalSteps }).map((_, i) => {
    return (
      <div key={i} className="barmarker">
        Step {i + 1}
      </div>
    );
  });

  return (
    <section id="progress-bar">
      {/* <span>{t("number", { count: Number(progress) })}%</span> */}
      {/* <div className="barmarker-container">{steps}</div> */}
      <progress value={step} max={totalSteps}></progress>
    </section>
  );
};

import "./RentalCard.css";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ShowroomType } from "./ShowroomCard";
import {
  formatCurrency,
  iqdFormatCurrency,
} from "../../utilities/formatCurrency";
import { camelCase } from "../../utilities/camelCase";
import { LocationIcon } from "../../assets/svg/locationIcon";
import { TransmissionIcon } from "../../assets/svg/transmissionIcon";

export type RentCarType = {
  _id: string;
  make: string;
  model: string;
  modelYear: number;
  exteriorColor: string;
  interiorColor: string;
  seatMaterial: string;
  seating: string;
  trim: string;
  engine: string;
  transmission: string;
  cylinders: string;
  fuelType: string;
  bodyStyle: string;
  importer?: string;
  images: string[];
  showroom: ShowroomType;
  location: string;
  price: {
    daily: number;
    weekly: number;
    monthly: number;
  };
  priceUnit: string;
  isPriceShown: boolean;
  createdAt: Date;
  phone?: string;
  status: string;
  features?: string[];
  description?: string;
  primaryImageIndex?: number;
  views: number;
  visitors?: number;
};

type Props = {
  data: RentCarType;
  path: string;
};

export const RentalCard: FC<Props> = ({ data: rentCar, path }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const onCardClick = () => {
    navigate(path + "details/" + rentCar._id);
  };
  return (
    <div onClick={onCardClick} className="card" key={rentCar._id}>
      <div className="card-header">
        {rentCar.status === "available" && rentCar.isPriceShown && (
          <p className="price">
            {rentCar.price?.daily && rentCar.priceUnit === "usd"
              ? formatCurrency(rentCar.price.daily)
              : iqdFormatCurrency(rentCar.price.daily)}
            <span>
              {i18n.language === "en" ? "/" : "\\"}
              {t("rentCars.details.daily")}
            </span>
          </p>
        )}
        {rentCar.status === "rented" && (
          <p className="not-available">
            {t(`rentCars.badge.${rentCar.status}`)}
          </p>
        )}
        {/* Primary image */}
        <div className="card-img-container">
          <img
            loading="lazy"
            className="card-img"
            src={rentCar.images[rentCar.primaryImageIndex || 0]}
            alt={
              t(`make.${camelCase(rentCar.make)}`) +
              " " +
              t(`model.${camelCase(rentCar.model)}`) +
              " " +
              t("number", { count: rentCar.modelYear })
            }
          />
        </div>
      </div>
      <div className="card-footer">
        {/* Make and model, model year */}
        <div>
          <h3 className="make">{t(`make.${camelCase(rentCar.make)}`)}</h3>
          <h4 className="model">
            {t(`model.${camelCase(rentCar.model)}`)}
            &nbsp;
            {/* {t(`trim.${camelCase(rentCar.trim)}`)}&nbsp; */}
            {t("number", { count: rentCar.modelYear })}
          </h4>
        </div>
        <div className="solid"></div>
        <div className="card-info">
          {/* Transmission */}
          <div>
            <TransmissionIcon />
            <p>{t(`transmission.${rentCar.transmission}`)}</p>
          </div>
          {/* Location */}
          <div>
            <LocationIcon />
            <p>
              {(rentCar.showroom?.city?.name &&
                t(`city.${camelCase(rentCar.showroom.city.name)}`)) ??
                (rentCar.location && t(`city.${camelCase(rentCar.location)}`))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

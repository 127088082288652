import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { UploadImagesRent } from "../../../../../../../components/rent car form sections/upload images/UploadImagesRent";
import { OutletContextAddRentCarType } from "./StepOneAddRentCar";
import { StepProps } from "../sale steps/StepOneAddCar";
import { AddRentCarDataType } from "../AddCar";

export const StepFourAddRentCar: FC<StepProps> = ({ className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { unregister, setValue, watch, trigger, errors, setStep } =
    useOutletContext<OutletContextAddRentCarType>();

  // Prev step required fields
  const prevStepRequiredFields: (keyof AddRentCarDataType)[] = [
    "engine",
    "transmission",
    "cylinders",
    "fuelType",
  ];

  // Current step required fields
  const requiredFields: (keyof AddRentCarDataType)[] = ["images"];

  const handleBack = () => {
    navigate("../step-three");
    setStep((prev) => prev - 1);
  };

  const handleNext = async () => {
    const isValid = await trigger(requiredFields, {
      shouldFocus: true,
    });
    if (isValid) {
      navigate("../step-five");
      setStep((prev) => prev + 1);
    }
  };

  // Redirect to prev step
  useEffect(() => {
    const redirectToPrevStep = async () => {
      const isPrevStepValid = await trigger(prevStepRequiredFields, {
        shouldFocus: true,
      });
      if (!isPrevStepValid) {
        navigate("../step-three");
      }
    };
    redirectToPrevStep();
  }, [navigate]);
  return (
    <div id="step-four-add-car">
      <UploadImagesRent
        unregister={unregister}
        setValue={setValue}
        watch={watch}
        errors={errors}
      />
      {className !== "hidden" && (
        <div className="btns">
          <button className="back" type="button" onClick={handleBack}>
            {t("btns&links.back")}
          </button>
          <button className="next" type="button" onClick={handleNext}>
            {t("btns&links.next")}
          </button>
        </div>
      )}
    </div>
  );
};

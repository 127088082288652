import "./FilterDropDown.css";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { camelCase } from "../../../utilities/camelCase";
import { DpdownIcon } from "../../../assets/svg/dpdownIcon";

type Props = {
  options: string[];
  placeholder: string;
  setSelection?: Dispatch<SetStateAction<string>>;
  selectedOption: string;
  onChangeMake?: () => void;
};

export const FilterDropDown: FC<Props> = ({
  options,
  placeholder,
  setSelection,
  selectedOption,
  onChangeMake,
}) => {
  const { t } = useTranslation();
  const isDisabled = !options?.some((option) => option);

  const listRef = useRef<HTMLUListElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const label = t(`dropDownPlaceholder.${camelCase(placeholder)}`);

  const toggleDropdown = () => setIsOpen(!isOpen);

  // Handle option click
  const handleOptionClick = (option: string) => {
    onChangeMake && onChangeMake();
    setSelection && setSelection(option);
    setIsOpen(false);
  };

  // Check if an option is selected
  const isOptionSelected = useMemo(
    () => (option: string) => !!selectedOption && selectedOption === option,
    [selectedOption]
  );

  // Close dropdown when clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        headerRef.current &&
        !headerRef.current.contains(event.target as HTMLElement)
      ) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Scroll to selected option
  useEffect(() => {
    if (isOpen && listRef.current) {
      // Find the selected option element
      const selectedElement = listRef.current.querySelector(".selected");

      // Scroll to the selected element if found
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [isOpen, selectedOption]);

  return (
    <div id="filter-drop-down" className={isDisabled ? "f-disabled" : ""}>
      <div className="dd-container">
        <div
          className="dd-header"
          ref={headerRef}
          onClick={toggleDropdown}
          style={isOpen ? { borderColor: "#28282b" } : {}}
        >
          <p>
            {selectedOption &&
              (placeholder === "Location"
                ? t(`city.${camelCase(selectedOption)}`)
                : placeholder.includes("Price")
                ? t(`price.${camelCase(selectedOption)}`)
                : t(`${camelCase(placeholder)}.${camelCase(selectedOption)}`))}
          </p>
          <DpdownIcon isDpOpen={isOpen} width={14} />
        </div>
        <label
          style={
            isOpen || selectedOption
              ? {
                  top: "0.875rem",
                  fontSize: "0.75rem",
                }
              : {}
          }
        >
          {label}
        </label>

        {isOpen && (
          <div className="ddl-container">
            <ul className="dd-list" ref={listRef}>
              {options.map((option) => (
                <li
                  key={option}
                  className={isOptionSelected(option) ? "selected" : ""}
                  onClick={() => handleOptionClick(option)}
                >
                  {t(
                    placeholder === "Location"
                      ? `city.${camelCase(option)}`
                      : placeholder.includes("Price")
                      ? `price.${camelCase(option)}`
                      : `${camelCase(placeholder)}.${camelCase(option)}`
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

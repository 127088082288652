import { FC } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormUnregister,
  UseFormWatch,
  FieldErrors,
} from "react-hook-form";
import { BasicInfoRent } from "../../../../../../../components/rent car form sections/basic info/BasicInfoRent";
import { StepProps } from "../sale steps/StepOneAddCar";
import { AddRentCarDataType } from "../AddCar";

export type OutletContextAddRentCarType = {
  register: UseFormRegister<AddRentCarDataType>;
  unregister: UseFormUnregister<AddRentCarDataType>;
  setValue: UseFormSetValue<AddRentCarDataType>;
  watch: UseFormWatch<AddRentCarDataType>;
  trigger: UseFormTrigger<AddRentCarDataType>;
  errors: FieldErrors<AddRentCarDataType>;
  error: any;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  isAddRentCarLoading: boolean;
};

export const StepOneAddRentCar: FC<StepProps> = ({ className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, unregister, setValue, watch, trigger, errors, setStep } =
    useOutletContext<OutletContextAddRentCarType>();

  // Current step required fields
  const requiredFields: (keyof AddRentCarDataType)[] = [
    "make",
    "model",
    "modelYear",
    "trim",
    "exteriorColor",
    "bodyStyle",
  ];

  const handleNext = async () => {
    const isValid = await trigger(requiredFields, { shouldFocus: true });
    if (isValid) {
      navigate("step-two");
      setStep((prev) => prev + 1);
    }
  };

  return (
    <div id="step-one-add-car">
      <BasicInfoRent
        isAddCar={true}
        register={register}
        unregister={unregister}
        errors={errors}
        setValue={setValue}
        watch={watch}
      />
      {className !== "hidden" && (
        <div className="btns">
          <button className="next" type="button" onClick={handleNext}>
            {t("btns&links.next")}
          </button>
        </div>
      )}
    </div>
  );
};

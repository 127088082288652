import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const TiktokIcon = (props: Props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1993_233)">
      <path
        d="M22.465 9.866C20.326 9.866 18.343 9.182 16.725 8.02V16.405C16.725 20.593 13.318 23.999 9.131 23.999C7.513 23.999 6.012 23.489 4.779 22.623C2.821 21.248 1.537 18.974 1.537 16.405C1.537 12.217 4.944 8.81 9.132 8.81C9.48 8.81 9.82 8.839 10.155 8.884V9.861V13.096C9.831 12.995 9.489 12.936 9.132 12.936C7.22 12.936 5.664 14.492 5.664 16.405C5.664 17.737 6.42 18.894 7.524 19.475C8.005 19.728 8.552 19.873 9.133 19.873C11.001 19.873 12.525 18.387 12.595 16.535L12.598 0H16.724C16.724 0.358 16.759 0.707 16.821 1.047C17.112 2.619 18.045 3.968 19.338 4.811C20.238 5.398 21.312 5.741 22.464 5.741L22.465 9.866Z"
        fill={props.fill || "#fff"}
      />
    </g>
    <defs>
      <clipPath>
        <rect width={24} height={24} fill={props.fill || "#fff"} />
      </clipPath>
    </defs>
  </svg>
);

export { TiktokIcon };

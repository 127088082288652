import { useTranslation } from "react-i18next";
import "./NotFound.css";
import { Link } from "react-router-dom";

export const NotFound = () => {
  const { t, i18n } = useTranslation();
  return (
    <section id="not-found">
      <div className="container">
        <h1>{t("number", { count: 404 })}</h1>
        <h1>{t("notFound.heading")}</h1>

        <Link className="link" to={`${i18n.language}`}>
          {t("notFound.btn")}
        </Link>
      </div>
    </section>
  );
};

import "./PhoneNumbers.css";
import { useEffect, useState, KeyboardEvent, ChangeEvent } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  useDeletePhoneNumberMutation,
  useSendSmsMutation,
  useUpdateShowroomByIdMutation,
} from "../../../../../../app/api/showroomApiSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useShowroom } from "../../../../../../hooks/useAuth";
import { PhoneModal } from "./modal/PhoneModal";
import { PlusIcon } from "../../../../../../assets/svg/plusIcon";
import { TrashIcon } from "../../../../../../assets/svg/trashIcon";
import { CheckSolidIcon } from "../../../../../../assets/svg/checkSolidIcon";
import flagSrc from "../../../../../../assets/svg/iraqFlag.svg";

export type phoneNumbersDataType = {
  phoneNumbers: string[];
  code: string;
};
// phone number must start with 07 and be 11 digits or start with 7 and be 10 digits
const phonePattern = /^(07\d{9}|7\d{9})$/;

export const PhoneNumbers = () => {
  const { t } = useTranslation();
  const { showroom, isSuccess } = useShowroom();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [currentPhoneNumberIndex, setCurrentPhoneNumberIndex] = useState<
    number | null
  >(null);

  // Update phone numbers mutation
  const [
    UpdatePhoneNumbers,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      error: updateError,
    },
  ] = useUpdateShowroomByIdMutation();

  // Delete phone number mutation
  const [
    DeletePhoneNumber,
    {
      isSuccess: isDeleteSuccess,
      isLoading: isDeleteLoading,
      error: deleteError,
    },
  ] = useDeletePhoneNumberMutation();

  // Send sms mutation
  const [
    sendSms,
    {
      isLoading: isSendSmsLoading,
      isSuccess: isSendSmsSuccess,
      error: sendSmsError,
    },
  ] = useSendSmsMutation();

  // Phone numbers schema
  const phoneNumbersSchema = yup.object().shape({
    phoneNumbers: yup
      .array(
        yup
          .string()
          .required(t("errorMessage.phoneNumber") as string)
          .matches(phonePattern, t("errorMessage.phoneNumberMatch") as string)
      )
      .min(1, t("errorMessage.phoneNumberMin") as string)
      .required(t("errorMessage.phoneNumberMin") as string)
      .test(
        "phoneNumbers",
        t("errorMessage.phoneNumberMin") as string,
        (value) => {
          if (value) {
            return value.length >= 1;
          }
          return false;
        }
      ),

    code: yup
      .string()
      .min(6, t("errorMessage.codeLength") as string)
      .max(6, t("errorMessage.codeLength") as string)
      .required(t("errorMessage.verificationCode") as string),
  });

  // Form hook
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isLoading },
    watch,
    setValue,
    reset,
    trigger,
    unregister,
  } = useForm<phoneNumbersDataType>({
    resolver: yupResolver(phoneNumbersSchema),
    mode: "onChange",
  });
  const phoneNumbers = watch("phoneNumbers") || [];

  // Submit handler
  const onSubmit: SubmitHandler<phoneNumbersDataType> = async (data) => {
    if (data.phoneNumbers) {
      data.phoneNumbers = data.phoneNumbers.map((phoneNumber: string) => {
        if (phoneNumber[0] === "0") phoneNumber = phoneNumber.slice(1);
        return phoneNumber;
      });
    }

    try {
      await UpdatePhoneNumbers({
        id: showroom._id,
        credentials: {
          phoneNumbers: data.phoneNumbers,
          code: data.code,
        },
      }).unwrap();
      setOpenModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Send OTP
  const sendOtp = async (phoneNumber: string) => {
    setCurrentPhoneNumberIndex(phoneNumbers.indexOf(phoneNumber));
    // remove 0 from the beginning of the number
    if (phoneNumber[0] === "0") phoneNumber = phoneNumber.slice(1);
    try {
      await sendSms({ phoneNumbers: phoneNumber }).unwrap();
      setOpenModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  // Delete phone number handler
  const onDeletePhoneNumber = async (phoneNumber: string) => {
    try {
      await DeletePhoneNumber({
        id: showroom._id,
        phoneNumber: phoneNumber,
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  // Set default values
  useEffect(() => {
    if (showroom) {
      reset({
        phoneNumbers: showroom.phoneNumbers,
      });
    }
  }, [showroom]);

  if (!isSuccess) {
    return <div>{t("loading")}</div>;
  }

  // Handle change phone number input
  const onChangePhone = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const firstChar = e.target.value[0];
    if (firstChar !== "0" && e.target.value.length > 10)
      e.target.value = e.target.value.slice(0, 10);
    if (firstChar === "0" && e.target.value.length > 11)
      e.target.value = e.target.value.slice(0, 11);
    let formattedNumber = "";
    if (firstChar === "0") {
      formattedNumber = e.target.value.slice(1);
    } else {
      formattedNumber = e.target.value;
    }
    // if number is arabic convert it to english
    formattedNumber = formattedNumber.replace(/[\u0660-\u0669]/g, (c) =>
      String.fromCharCode(c.charCodeAt(0) - 0x0660 + 48)
    );
    setValue(`phoneNumbers.${index}`, formattedNumber, {
      shouldValidate: true,
    });
  };
  // Only allow numbers in input
  const onKeyDownPhone = (e: KeyboardEvent<HTMLInputElement>) => {
    // only allow numbers
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 46
      )
    )
      e.preventDefault();
  };

  return (
    <div id="phone-numbers">
      {isUpdateSuccess && (
        <div className="row">
          <div className="success">
            {<CheckSolidIcon fill={"var(--green-600)"} />}
            <p>{t("alert.changesSaved")}</p>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          {phoneNumbers.map((phoneNumber: string, index: number) => {
            return (
              <div key={index} className="phone-container">
                <div className={`phone-input-container`}>
                  <div className="country">
                    <img src={flagSrc} alt="iraq flag" />
                    <p>{t("countryCode")}</p>
                  </div>
                  <div className="input-group">
                    <input
                      placeholder=" "
                      type="tel"
                      value={phoneNumber}
                      onChange={(e) => onChangePhone(e, index)}
                      onKeyDown={onKeyDownPhone}
                    />
                    <label>{t("inputPlaceholder.phoneNumber")}</label>
                  </div>
                  {/* Delete button for each field except first one*/}
                  {showroom.phoneNumbers.length > 1 && (
                    <button
                      disabled={isDeleteLoading}
                      className="delete-phone-btn"
                      type="button"
                      onClick={() => onDeletePhoneNumber(phoneNumbers[index])}
                    >
                      {!isDeleteLoading && <TrashIcon width={16} height={16} />}
                    </button>
                  )}
                  {isDeleteLoading && <p className="loading">{t("loading")}</p>}
                  {deleteError && (
                    <p className="error">{deleteError?.data?.message}</p>
                  )}
                </div>
                {errors.phoneNumbers &&
                  Array.isArray(errors.phoneNumbers) &&
                  errors.phoneNumbers[index] && (
                    <p className="error">
                      {errors.phoneNumbers[index].message}
                    </p>
                  )}
                {currentPhoneNumberIndex === index && sendSmsError && (
                  <p className="error">{sendSmsError?.data?.message}</p>
                )}
                {phoneNumbers.length <= 2 &&
                  !showroom?.phoneNumbers.includes(phoneNumber) && (
                    <button
                      disabled={isSendSmsLoading}
                      id="verify-btn"
                      type="button"
                      onClick={async () => {
                        const isValid = await trigger(`phoneNumbers.${index}`);
                        if (isValid) {
                          watch(`phoneNumbers.${index}`) &&
                            sendOtp(watch(`phoneNumbers.${index}`));
                        }
                      }}
                    >
                      {isSendSmsLoading ? t("loading") : t("btns&links.verify")}
                    </button>
                  )}
              </div>
            );
          })}
          {/* Add more btn */}
          {phoneNumbers.length < 2 && (
            <button
              className="add-phone-btn"
              type="button"
              onClick={() => {
                setValue("phoneNumbers", [...phoneNumbers, ""]);
              }}
            >
              <PlusIcon width={14} height={14} />
              {t("btns&links.addMore")}
            </button>
          )}
        </div>
        {/* Phone modal */}
        {openModal && (
          <PhoneModal
            setOpenModal={setOpenModal}
            setValue={setValue}
            isLoading={isUpdateLoading}
            errors={errors}
            error={updateError}
          />
        )}
      </form>
    </div>
  );
};

import "./RentalCars.css";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import { sortData } from "../../data/SortData";
import { FilterData } from "../../data/FilterData";
import { useMakes, useModelYears, useModels } from "../../hooks/useCarSpecs";
import { useRentCars } from "../../hooks/useAuth";
import { RentCarType, RentalCard } from "../../components/cards/RentalCard";
import { SortDropDown } from "../../components/customs/sort drop down/SortDropDown";
import { MobileSort } from "../../components/customs/mobile sort/MobileSort";
import { FilterDropDown } from "../../components/customs/filter drop down/FilterDropDown";
import { camelCase } from "../../utilities/camelCase";
import { CloseIcon } from "../../assets/svg/closeIcon";
import { SEO } from "../../utilities/SEO";
import { CardSkeleton } from "../../components/skeleton/CardSkeleton";
import { SearchIcon } from "../../assets/svg/searchIcon";
import { SortIcon } from "../../assets/svg/sortIcon";
import { motion } from "framer-motion";

export const RentalCars = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState<number>(
    Number(searchParams.get("page")) || 1
  );
  const limit = 24;

  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [sortOpen, setSortOpen] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<string>(
    searchParams.get("sort") || ""
  );

  // Search filters
  const [make, setMake] = useState<string>(searchParams.get("make") || "");
  const [model, setModel] = useState<string>(searchParams.get("model") || "");
  const [fromYear, setFromYear] = useState<string>(
    searchParams.get("fromYear") || ""
  );
  const [toYear, setToYear] = useState<string>(
    searchParams.get("toYear") || ""
  );
  const [fuelType, setFuelType] = useState<string>(
    searchParams.get("fuelType") || ""
  );
  const [carLocation, setCarLocation] = useState<string>(
    searchParams.get("location") || ""
  );
  const [transmission, setTransmission] = useState<string>(
    searchParams.get("transmission") || ""
  );

  const { makes } = useMakes();
  const { models } = useModels(make);
  const { modelYears } = useModelYears(model, make);

  // Change page number
  const changePage = ({ selected }: { selected: number }) => {
    setPageNumber(selected + 1);
    window.scrollTo(0, 0);
  };

  // Get rent cars
  const { rentCars, isSuccess, isLoading, isFetching, isError, error } =
    useRentCars(
      pageNumber,
      limit,
      make,
      model,
      fromYear,
      toYear,
      carLocation,
      transmission,
      fuelType,
      selectedSort
    );

  // Set model to empty string when make changes
  const handleMakeChange = () => {
    setModel("");
    searchParams.delete("model");
  };

  // Clear search filters
  const handleResetSearch = () => {
    setMake("");
    setModel("");
    setFromYear("");
    setToYear("");
    setCarLocation("");
    setTransmission("");
    setFuelType("");

    setSearchParams((prev) => {
      prev.delete("page");
      prev.delete("make");
      prev.delete("model");
      prev.delete("fromYear");
      prev.delete("toYear");
      prev.delete("fuelType");
      prev.delete("location");
      prev.delete("transmission");
      prev.delete("sort");
      return prev;
    });

    window.scrollTo(0, 0);
  };

  // From year field options
  const fromYearOptions = () => {
    if (make && model) {
      return toYear ? modelYears.filter((year) => year <= toYear) : modelYears;
    } else if (toYear && toYear !== "newest") {
      return FilterData.year.from.filter((year) => year <= toYear);
    } else {
      return FilterData.year.from;
    }
  };

  // To year field options
  const toYearOptions = () => {
    if (make && model) {
      return fromYear
        ? modelYears.filter((year) => year >= fromYear)
        : modelYears;
    } else if (fromYear && fromYear !== "oldest") {
      return FilterData.year.to.filter((year) => year >= fromYear);
    } else {
      return FilterData.year.to;
    }
  };

  // Set search filters to local storage
  useEffect(() => {
    setSearchParams((prev) => {
      pageNumber && prev.set("page", pageNumber.toString());
      make && prev.set("make", make);
      model && prev.set("model", model);
      fromYear && prev.set("fromYear", fromYear);
      toYear && prev.set("toYear", toYear);
      fuelType && prev.set("fuelType", fuelType);
      carLocation && prev.set("location", carLocation);
      transmission && prev.set("transmission", transmission);
      selectedSort && prev.set("sort", selectedSort);
      return prev;
    });
  }, [
    rentCars,
    make,
    model,
    fromYear,
    toYear,
    fuelType,
    carLocation,
    transmission,
    selectedSort,
  ]);

  // Reset page number when search filters change
  useEffect(() => {
    setPageNumber(1);
  }, [
    make,
    model,
    fromYear,
    toYear,
    fuelType,
    carLocation,
    transmission,
    selectedSort,
  ]);

  // Set page number to the last page when i leave the page
  useEffect(() => {
    const savedPageNumber = Number(searchParams.get("page")) || 1;
    setPageNumber(savedPageNumber > 1 ? savedPageNumber : 1);
  }, []);
  return (
    <>
      {/* SEO */}
      <SEO
        title={t("seo.rentCarsTitle", {
          car:
            make && model
              ? t(`make.${camelCase(make)}`) +
                " " +
                t(`model.${camelCase(model)}`)
              : t("cars"),
          location: carLocation
            ? t(carLocation !== "all" ? `city.${carLocation}` : `city.iraq`)
            : t("city.iraq"),
        })}
        description={t("seo.homeDescription").toString()}
      />

      <section id="rc-hero">
        {/* Mobile UI tabs for search and sort */}
        <div className="mobile">
          <div className="btns">
            <button
              onClick={() => setSearchOpen(!searchOpen)}
              aria-label={t("btns&links.search").toString()}
            >
              <SearchIcon />
              {t("btns&links.search")}
            </button>
            <button
              onClick={() => setSortOpen(!sortOpen)}
              aria-label={t("btns&links.sort").toString()}
            >
              <SortIcon />
              {t("btns&links.sort")}
            </button>
          </div>
        </div>
        {/* Header */}
        <header className="header">
          <p>{t("rentCars.heading")}</p>
          {/* Sort drop-down */}
          <div>
            <SortDropDown
              options={sortData.sortRentalCars}
              placeholder="Sort By"
              setSelectedSort={setSelectedSort}
              selectedSort={selectedSort}
            />
          </div>
        </header>
        <div className="container">
          {/* Search Section */}
          <div className="left">
            <form>
              <p className="heading">{t("rentCars.searchHeading")}</p>
              <div className="filters">
                <FilterDropDown
                  placeholder="Make"
                  options={makes}
                  setSelection={setMake}
                  onChangeMake={handleMakeChange}
                  selectedOption={make}
                />
                <FilterDropDown
                  placeholder="Model"
                  options={models}
                  setSelection={setModel}
                  selectedOption={model}
                />
                <div className="solid"></div>
                <div className="year">
                  <FilterDropDown
                    placeholder="From Year"
                    options={fromYearOptions()}
                    setSelection={setFromYear}
                    selectedOption={fromYear}
                  />
                  <FilterDropDown
                    placeholder="To Year"
                    options={toYearOptions()}
                    setSelection={setToYear}
                    selectedOption={toYear}
                  />
                </div>
                <FilterDropDown
                  placeholder="Location"
                  options={FilterData.location}
                  setSelection={setCarLocation}
                  selectedOption={carLocation}
                />
                <div className="solid"></div>
                <FilterDropDown
                  placeholder="Transmission"
                  options={FilterData.transmission}
                  setSelection={setTransmission}
                  selectedOption={transmission}
                />
                <FilterDropDown
                  placeholder="Fuel Type"
                  options={FilterData.fuelType}
                  setSelection={setFuelType}
                  selectedOption={fuelType}
                />
              </div>
              <button
                type="button"
                className="clear-search"
                onClick={handleResetSearch}
                aria-label={t("btns&links.clearSearch").toString()}
              >
                {t("btns&links.clearSearch")}
              </button>
            </form>
          </div>
          {/* Rent cars section */}
          <div className="right">
            <div className="rental">
              {/* Listings range */}
              {isSuccess && rentCars.rentCars?.length > 0 ? (
                <p className="listings-range">
                  {t("number", { count: rentCars?.currentRangeStart })}
                  &nbsp;-&nbsp;
                  {t("number", { count: rentCars?.currentRangeEnd })}&nbsp;
                  {t("rentCars.of")}&nbsp;
                  {t("number", { count: rentCars?.totalCars })}&nbsp;
                  {t("rentCars.listings")}
                </p>
              ) : rentCars?.rentCars && !isFetching ? (
                <div className="no-results">
                  <p>
                    {t("rentCars.noResults")}
                    <br />
                    <span>{t("rentCars.noResultsDesc")}</span>
                  </p>
                  <button
                    onClick={handleResetSearch}
                    aria-label={t("btns&links.clearSearch").toString()}
                  >
                    {t("btns&links.clearSearch")}
                  </button>
                </div>
              ) : (
                isSuccess &&
                !rentCars?.rentCars && (
                  <div className="no-results">
                    <p>{t("rentCars.noResults")}</p>
                  </div>
                )
              )}
              {/* Rent cars cards */}
              {isLoading || isFetching ? (
                <div className="cards">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <CardSkeleton key={index} />
                  ))}
                </div>
              ) : isSuccess && rentCars.rentCars ? (
                <div className="cards">
                  {rentCars.rentCars?.map((car: RentCarType, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, transform: "translateY(40px)" }}
                      whileInView={{
                        opacity: 1,
                        transform: "none",
                        transition: { delay: index * 0.015 },
                      }}
                      viewport={{ once: true }}
                    >
                      <RentalCard
                        key={car._id.toString()}
                        data={car}
                        path={location.pathname + "/"}
                      />
                    </motion.div>
                  ))}
                </div>
              ) : (
                isError && (
                  <p className="fetch-error">
                    {error.status} <br />
                    {JSON.stringify(error.data)}
                  </p>
                )
              )}
              {/* Pagination */}
              {isSuccess && rentCars.pageCount > 0 && (
                <ReactPaginate
                  previousLabel={t("btns&links.previous")}
                  nextLabel={t("btns&links.next")}
                  pageCount={rentCars.pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBtns"}
                  previousLinkClassName={"previousBtn"}
                  nextLinkClassName={"nextBtn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                  forcePage={pageNumber - 1}
                  pageRangeDisplayed={3}
                  breakLabel={"..."}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* Mobile UI for search section */}
      {searchOpen && (
        <div className="rc-mobile-search">
          <div>
            <p className="heading">{t("rentCars.searchHeading")}</p>
            <button
              onClick={() => setSearchOpen(!searchOpen)}
              aria-label={"close button"}
            >
              <CloseIcon width={12} height={12} />
            </button>
          </div>
          <form>
            <div className="filters">
              <FilterDropDown
                placeholder="Make"
                options={makes}
                setSelection={setMake}
                selectedOption={make}
              />
              <FilterDropDown
                placeholder="Model"
                options={models}
                setSelection={setModel}
                selectedOption={model}
              />
              <div className="solid"></div>
              <div className="year">
                <FilterDropDown
                  placeholder="From Year"
                  options={FilterData.year.from}
                  setSelection={setFromYear}
                  selectedOption={fromYear}
                />
                <FilterDropDown
                  placeholder="To Year"
                  options={FilterData.year.to}
                  setSelection={setToYear}
                  selectedOption={toYear}
                />
              </div>
              <FilterDropDown
                placeholder="Fuel Type"
                options={FilterData.fuelType}
                setSelection={setFuelType}
                selectedOption={fuelType}
              />
              <div className="solid"></div>
              <FilterDropDown
                placeholder="Location"
                options={FilterData.location}
                setSelection={setCarLocation}
                selectedOption={carLocation}
              />
              <FilterDropDown
                placeholder="Transmission"
                options={FilterData.transmission}
                setSelection={setTransmission}
                selectedOption={transmission}
              />
            </div>
          </form>
          <div>
            <button
              onClick={() => {
                setSearchOpen(!searchOpen);
                window.scrollTo(0, 0);
              }}
              type="button"
              aria-label={t("btns&links.search").toString()}
            >
              {t("btns&links.search")}
            </button>
          </div>
        </div>
      )}
      {/* Mobile UI for sort */}
      {sortOpen && (
        <MobileSort
          sort={sortData.sortRentalCars}
          setSortOpen={setSortOpen}
          setSelectedSort={setSelectedSort}
          selectedSort={selectedSort}
        />
      )}
    </>
  );
};

import "./ShowroomCard.css";
import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { camelCase } from "../../utilities/camelCase";
import { LocationIcon } from "../../assets/svg/locationIcon";
import { PhoneIcon } from "../../assets/svg/phoneIcon";
import { ConditionIcon } from "../../assets/svg/conditionIcon";

export type cityType = {
  name: string;
  value: { lat: number; lng: number };
};
export type ShowroomType = {
  _id: string;
  name: string;
  nameInAr: string;
  nameInKu: string;
  email: string;
  password: string;
  logo: string;
  images: string[];
  location: string;
  city: cityType;
  latLng: cityType["value"];
  phoneNumbers: string[];
  socialMedia: {
    facebook: string;
    instagram: string;
    twitter: string;
    linkedin: string;
    tiktok: string;
    snapchat: string;
    youtube: string;
  };
  website: string;
  showroomType: string;
  description: string;
  status: string;
  createdAt?: Date;
  primaryImageIndex?: number;
  logoImageIndex: number;
  openingDays: {
    saturday: {
      opening: "open" | "closed" | string;
      openTime?: string;
      closeTime?: string;
    };
    sunday: {
      opening: "open" | "closed" | string;
      openTime?: string;
      closeTime?: string;
    };
    monday: {
      opening: "open" | "closed" | string;
      openTime?: string;
      closeTime?: string;
    };
    tuesday: {
      opening: "open" | "closed" | string;
      openTime?: string;
      closeTime?: string;
    };
    wednesday: {
      opening: "open" | "closed" | string;
      openTime?: string;
      closeTime?: string;
    };
    thursday: {
      opening: "open" | "closed" | string;
      openTime?: string;
      closeTime?: string;
    };

    friday: {
      opening: "open" | "closed" | string;
      openTime?: string;
      closeTime?: string;
    };
  };
  dealershipType: string;
  iqdValue?: number;
  views?: number;
  visitors?: number;
  carsViews?: number;
  carsVisitors?: number;
};

type Props = {
  data: ShowroomType;
  path: string;
  totalCars?: number;
};
export const ShowroomCard: FC<Props> = ({
  data: showroom,
  path,
  totalCars,
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Showroom name in the current language
  const showroomName = useMemo(() => {
    const showroomNames: { [key: string]: string } = {
      ar: showroom?.nameInAr,
      ku: showroom?.nameInKu,
      default: showroom?.name,
    };

    return showroomNames[i18n.language] || showroomNames.default;
  }, [i18n.language, showroom?.name, showroom?.nameInAr, showroom?.nameInKu]);

  const onCardClick = () => {
    navigate(path + showroom._id);
  };

  return (
    <div onClick={onCardClick} className="card">
      <div className="header">
        {/* Primary image */}
        <div className="card-img-container">
          <img
            loading="lazy"
            className="card-img"
            src={showroom.images[showroom.primaryImageIndex || 0]}
            alt={showroomName}
          />
        </div>
        {/* Logo */}
        <div className="showroom-logo">
          <img
            loading="lazy"
            src={showroom.images[showroom.logoImageIndex || 0]}
            alt={showroomName + " logo"}
          />
        </div>
      </div>
      <div>
        {/* Showroom name */}
        <p className="showroom-name">{showroomName}</p>
        <div className="solid"></div>
        <div className="card-footer">
          {/* Condition */}
          <div>
            <ConditionIcon />
            <p>
              {t(`showroomType.${showroom.showroomType}`)}&nbsp;
              {totalCars && "|"}&nbsp;
              {totalCars && t("number", { count: totalCars })}&nbsp;
              {totalCars && t("showrooms.listings")}
            </p>
          </div>
          {/* Phone number */}
          <div>
            <PhoneIcon />
            <p>
              {t("phone", {
                count: Number(showroom.phoneNumbers[0]),
              })}
            </p>
          </div>
          {/* Location */}
          <div>
            <LocationIcon />
            <p>
              {showroom.city?.name &&
                t(`city.${camelCase(showroom.city.name)}`)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

import "./Dashboard.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteSaleCarByIdMutation } from "../../../../app/api/saleCarsApiSlice";
import { useGetSoldCarsByShowroomQuery } from "../../../../app/api/soldCarApiSlice";
import { useDeleteRentCarByIdMutation } from "../../../../app/api/rentCarsApiSlice";
import { FilterData } from "../../../../data/FilterData";
import {
  useShowroom,
  useShowroomCars,
  useShowroomRentCars,
} from "../../../../hooks/useAuth";
import { CarTable } from "../../../../components/car listings table/CarTable";
import { RentCarTable } from "../../../../components/rent car listings table/RentCarTable";
import { SortDropDown } from "../../../../components/customs/sort drop down/SortDropDown";
import { Loading } from "../../../../components/customs/loading/Loading";
import { useGetRentedCarsByShowroomQuery } from "../../../../app/api/rentedCarApiSlice";
import { ToastContainer, toast } from "react-toastify";
import { StatCard } from "../../../../components/dashboard components/stat card/StatCard";
import { useLocation } from "react-router-dom";
type soldCarsDataType = {
  data: {
    totalSoldCars: number;
    revenue: number;
  };
  isSuccess: boolean;
  isFetching: boolean;
  isLoading: boolean;
};
type rentedCarsDataType = {
  data: {
    totalRentedCars: number;
    revenue: number;
  };
  isSuccess: boolean;
  isFetching: boolean;
  isLoading: boolean;
};

export const Dashboard = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState<string>("today");
  const pageNumber = 1;
  const limit = 10;

  // Sale cars table heading
  const saleCarsTableHeading = [
    t("showroomManagement.carTable.car"),
    t("showroomManagement.carTable.price"),
    t("showroomManagement.carTable.status.heading"),
    t("showroomManagement.carTable.listedAt"),
    t("showroomManagement.carTable.actions"),
  ];
  // Rent cars table heading
  const rentCarsTableHeading = [
    t("showroomManagement.carTable.car"),
    t("showroomManagement.carTable.price"),
    t("showroomManagement.carTable.status.heading"),
    t("showroomManagement.carTable.listedAt"),
    t("showroomManagement.carTable.actions"),
  ];

  // Get showroom
  const { showroom, isLoading } = useShowroom();
  const isSaleShowroom = showroom?.showroomType === "sale";

  // Delete sale car mutation
  const [
    DeleteSaleCar,
    { isLoading: isDeleteSaleCarLoading, isSuccess: isDeleteSaleCarSuccess },
  ] = useDeleteSaleCarByIdMutation();

  // Delete rent car mutation
  const [
    DeleteRentCar,
    { isLoading: isDeleteRentCarLoading, isSuccess: isDeleteRentCarSuccess },
  ] = useDeleteRentCarByIdMutation();

  // Get showroom cars
  const {
    cars,
    isSuccess: isCarsDataSuccess,
    isFetching: isCarsDataFetching,
    isLoading: isCarsDataLoading,
  } = useShowroomCars(
    showroom?._id,
    pageNumber,
    limit,
    "",
    false,
    showroom?.showroomType
  );

  // Get sold cars
  const {
    data: soldCars,
    isSuccess: isSoldCarsSuccess,
    isFetching: isSoldCarsFetching,
    isLoading: isSoldCarsLoading,
  } = useGetSoldCarsByShowroomQuery(
    { showroomId: showroom?._id, filter },
    {
      skip: !showroom?._id || !isSaleShowroom,
    }
  ) as soldCarsDataType;
  const {
    data: rentedCars,
    isSuccess: isRentedCarsSuccess,
    isFetching: isRentedCarsFetching,
    isLoading: isRentedCarsLoading,
  } = useGetRentedCarsByShowroomQuery(
    { showroomId: showroom?._id, filter },
    {
      skip: !showroom?._id || showroom?.showroomType === "sale",
    }
  ) as rentedCarsDataType;

  // Get rent cars
  const {
    cars: rentCars,
    isSuccess: isRentCarsDataSuccess,
    isFetching: isRentCarsDataFetching,
    isLoading: isRentCarsDataLoading,
  } = useShowroomRentCars(
    showroom?._id,
    pageNumber,
    limit,
    "",
    false,
    showroom?.showroomType
  );

  useEffect(() => {
    if (isDeleteSaleCarSuccess) {
      toast.success(t("alert.carDeleted"));
    }
    if (isDeleteRentCarSuccess) {
      toast.success(t("alert.carDeleted"));
    }
    if (location.state && location.state.showroomRegistered) {
      toast.success(location.state.showroomRegistered);
      location.state = {};
    }
  }, [isDeleteSaleCarSuccess, isDeleteRentCarSuccess, location.state]);

  if (isLoading) return <Loading />;

  return (
    <section id="dashboard">
      <ToastContainer />
      <header>
        <p className="dashboard-heading">
          {t("showroomManagement.dashboard.heading")}
        </p>
        <div>
          <SortDropDown
            options={FilterData.times}
            placeholder="Filter By"
            selectedSort={filter}
            setSelectedSort={setFilter}
          />
        </div>
      </header>
      {isSaleShowroom ? (
        <>
          {isCarsDataSuccess && (
            <div className="row-cards">
              <StatCard
                title={t("showroomManagement.dashboard.statCard.availableCars")}
                value={cars?.totalAvailableCars}
                link={t("btns&links.viewCars")}
                path={`../inventory-management`}
              />
              <StatCard
                title={t("showroomManagement.dashboard.statCard.totalRevenue")}
                amount={soldCars?.revenue}
                link={t("btns&links.viewReport")}
              />
              <StatCard
                title={t("showroomManagement.dashboard.statCard.carsSold")}
                value={soldCars?.totalSoldCars}
                link={t("btns&links.viewReport")}
              />
            </div>
          )}

          {isCarsDataLoading || isCarsDataFetching ? (
            <Loading />
          ) : isCarsDataSuccess && cars.saleCars?.length > 0 ? (
            <CarTable
              thead={saleCarsTableHeading}
              cars={cars?.saleCars}
              carPath={`/${i18n.language}/cars/details/`}
              editPath="../edit-car/"
              Delete={DeleteSaleCar}
              isDeleteLoading={isDeleteSaleCarLoading}
            />
          ) : (
            <p className="no-cars">
              {t("showroomManagement.inventory.carListings.noCars")}
            </p>
          )}
        </>
      ) : (
        <>
          {isRentCarsDataSuccess && (
            <div className="row-cards">
              <StatCard
                title={t("showroomManagement.dashboard.statCard.availableCars")}
                value={rentCars?.totalAvailableCars}
                link={t("btns&links.viewCars")}
                path={`../inventory-management`}
              />
              <StatCard
                title={t("showroomManagement.dashboard.statCard.totalRevenue")}
                amount={rentedCars?.revenue}
                link={t("btns&links.viewReport")}
              />
              <StatCard
                title={t("showroomManagement.dashboard.statCard.carsRented")}
                value={rentedCars?.totalRentedCars}
                link={t("btns&links.viewReport")}
              />
            </div>
          )}
          {isRentCarsDataLoading || isRentCarsDataFetching ? (
            <Loading />
          ) : isRentCarsDataSuccess && rentCars?.rentCars.length > 0 ? (
            <RentCarTable
              thead={rentCarsTableHeading}
              cars={rentCars?.rentCars}
              carPath={`/${i18n.language}/rental-cars/details/`}
              editPath="../edit-rent-car/"
              Delete={DeleteRentCar}
              isDeleteLoading={isDeleteRentCarLoading}
            />
          ) : (
            <p className="no-cars">
              {t("showroomManagement.inventory.carListings.noCars")}
            </p>
          )}
        </>
      )}
    </section>
  );
};

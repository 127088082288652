import "./SettingsTabs.css";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const SettingsTabs = () => {
  const location = useLocation();
  const { t } = useTranslation();

  // Check if the path is active
  const isActive = (path: string) => location.pathname.includes(path);
  return (
    <div id="settings-tabs">
      <Link
        style={
          isActive("account")
            ? {
                borderColor: "var(--primary-900)",
                color: "var(--primary-900)",
              }
            : {}
        }
        to={"account"}
        className="tab"
      >
        {t("showroomManagement.settings.myAccount.heading")}
      </Link>
      <Link
        style={
          isActive("phone-number")
            ? {
                borderColor: "var(--primary-900)",
                color: "var(--primary-900)",
              }
            : {}
        }
        to={"phone-number"}
        className="tab"
      >
        {t("showroomManagement.settings.phoneNumbers.heading")}
      </Link>
      <Link
        style={
          isActive("social-media")
            ? {
                borderColor: "var(--primary-900)",
                color: "var(--primary-900)",
              }
            : {}
        }
        to={"social-media"}
        className="tab"
      >
        {t("showroomManagement.settings.socialMedia.heading")}
      </Link>
      <Link
        style={
          isActive("change-password")
            ? {
                borderColor: "var(--primary-900)",
                color: "var(--primary-900)",
              }
            : {}
        }
        to={"change-password"}
        className="tab"
      >
        {t("showroomManagement.settings.changePassword.heading")}
      </Link>
    </div>
  );
};

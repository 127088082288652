import { apiSlice } from "./apiSlice";

export const adApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAds: builder.query({
      query: () => ({
        url: "ads",
        method: "GET",
      }),
    }),
    getAd: builder.query({
      query: (title) => ({
        url: `ads/t/${title}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAdsQuery, useGetAdQuery } = adApiSlice;

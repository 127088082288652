import { apiSlice } from "./apiSlice";

export const soldCarApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllRentedCars: builder.query({
      query: ({ page, limit }) => `rentedCars?page=${page}&limit=${limit}`,
      providesTags: ["RentedCars", "RentCars"],
    }),
    getRentedCarsByShowroom: builder.query({
      query: ({
        showroomId,
        filter,
      }) => `rentedCars/r/${showroomId}?filter=${filter}
      `,
      providesTags: ["RentedCars", "RentCars"],
    }),
  }),
});

export const { useGetAllRentedCarsQuery, useGetRentedCarsByShowroomQuery } =
  soldCarApiSlice;

import "./MobileSort.css";
import { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { camelCase } from "../../../utilities/camelCase";
import { CloseIcon } from "../../../assets/svg/closeIcon";

type MobileSortType = {
  sort: string[];
  setSortOpen: (value: boolean) => void;
  setSelectedSort?: Dispatch<SetStateAction<string>>;
  selectedSort?: string;
  isFilter?: boolean;
  isShowroomCars?: boolean;
};

export const MobileSort: FC<MobileSortType> = ({
  sort,
  setSortOpen,
  setSelectedSort,
  selectedSort,
  isFilter,
  isShowroomCars,
}) => {
  const { t } = useTranslation();

  // Handle radio button click
  const handleRadioClick = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedSort && setSelectedSort(e.currentTarget.value);
    setSortOpen(false);
    const carsPosition = document.getElementById("sd-feature")?.offsetTop;
    !isShowroomCars
      ? window.scrollTo(0, 0)
      : window.scrollTo(0, carsPosition! - 80);
  };

  return (
    <div className="mobile-sort">
      <header>
        <p>
          {t(
            isFilter
              ? "dropDownPlaceholder.filterBy"
              : "dropDownPlaceholder.sortBy"
          )}
        </p>
        <button onClick={() => setSortOpen(false)}>
          <CloseIcon width={12} height={12} />
        </button>
      </header>
      <div className="sort-container">
        <div className="radio-btns">
          {sort.map((option) => (
            <div>
              <input
                type="radio"
                value={option}
                id={option}
                name={option}
                checked={selectedSort === option}
                onChange={handleRadioClick}
              />
              <label htmlFor={option}> {t(`sort.${camelCase(option)}`)}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

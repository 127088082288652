import "./StepFiveAddCar.css";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { OutletContextAddCarType, StepProps } from "./StepOneAddCar";
import { Pricing } from "../../edit car/sections/pricing/Pricing";
import { AddCarDataType } from "../AddCar";

export const StepFiveAddCar: FC<StepProps> = ({ className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, setValue, watch, trigger, errors, setStep } =
    useOutletContext<OutletContextAddCarType>();

  // Prev step required fields
  const prevStepRequiredFields: (keyof AddCarDataType)[] = ["images"];

  // Current step required fields
  const requiredFields: (keyof AddCarDataType)[] = [
    "priceUnit",
    "price",
    "isPriceShown",
  ];

  const handleBack = () => {
    navigate("../step-four");
    setStep(4);
  };

  const handleNext = async () => {
    const isValid = await trigger(requiredFields, {
      shouldFocus: true,
    });
    if (isValid) {
      navigate("../step-six");
      setStep(6);
    }
  };

  // Redirect to prev step
  useEffect(() => {
    const redirectToPrevStep = async () => {
      const isPrevStepValid = await trigger(prevStepRequiredFields, {
        shouldFocus: true,
      });
      if (!isPrevStepValid) {
        navigate("../step-four");
      }
    };
    redirectToPrevStep();
  }, [navigate]);

  return (
    <div id="step-five-add-car">
      <Pricing
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
      />
      {className !== "hidden" && (
        <div className="btns">
          <button className="back" type="button" onClick={handleBack}>
            {t("btns&links.back")}
          </button>
          <button className="next" type="button" onClick={handleNext}>
            {t("btns&links.next")}
          </button>
        </div>
      )}
    </div>
  );
};

import "./Support.css";
import { useTranslation } from "react-i18next";
import { PhoneIcon } from "../../../../assets/svg/phoneIcon";
import { EmailIcon } from "../../../../assets/svg/emailIcon";
export const Support = () => {
  const { t } = useTranslation();
  const { phone, email } = {
    phone: "7733532323",
    email: "info@noyya.net",
  };
  return (
    <div id="support">
      <header>
        <p className="support-heading">
          {t("showroomManagement.support.heading")}
        </p>
        <p>{t("showroomManagement.support.secSubHeading")}</p>
      </header>
      <div className="container">
        <div className="contact-info">
          <div>
            <PhoneIcon />
            <a href={`tel:+964${phone}`}>
              {t("phone", { count: Number(phone) })}
            </a>
          </div>
          <div>
            <EmailIcon color="var(--primary-900)" />
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        </div>
        <p className="desc">{t("showroomManagement.support.desc")}</p>
      </div>
    </div>
  );
};

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { formatPhoneNumber } from "./utilities/formatPhoneNumber";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "ku", "ar"],
    fallbackLng: "en",
    detection: {
      order: ["path", "localStorage", "cookie", "htmlTag", "subdomain"],
      caches: ["localStorage", "cookie"],
    },

    interpolation: {
      format: function (value, format, lng) {
        if (format === "phoneNumber") {
          if (lng === "ar" || lng === "ku") {
            return (
              "٠" +
              Intl.NumberFormat("ar-IQ", {
                useGrouping: false,
                minimumFractionDigits: 0,
              }).format(value)
            );
          }
          return formatPhoneNumber(
            Intl.NumberFormat(lng, {
              useGrouping: false,
              minimumFractionDigits: 0,
            }).format(value)
          );
        } else if (format === "number") {
          if (lng === "ar" || lng === "ku") {
            return Intl.NumberFormat("ar-IQ", {
              useGrouping: false,
              minimumFractionDigits: 0,
            }).format(value);
          }
          return Intl.NumberFormat(lng, {
            useGrouping: false,
            minimumFractionDigits: 0,
          }).format(value);
        } else if (format === "mileage") {
          if (lng === "ar" || lng === "ku") {
            return Intl.NumberFormat("ar-IQ", {
              useGrouping: true,
              minimumFractionDigits: 0,
            }).format(value);
          }
          return Intl.NumberFormat(lng, {
            useGrouping: true,
            minimumFractionDigits: 0,
          }).format(value);
        }
        return value;
      },
    },
    backend: { loadPath: "/locales/{{lng}}/translation.json" },
    react: { useSuspense: true },
  });

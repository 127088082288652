import { apiSlice } from "./apiSlice";

export const showroomApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllShowrooms: builder.query({
      query: ({ page, limit = 2, showroomType }) =>
        `showrooms?page=${page}&limit=${limit}&showroomType=${
          showroomType || ""
        }`,
      providesTags: ["Showrooms"],
    }),
    getShowroomById: builder.query<any, { id: string; isAnalytics: boolean }>({
      query: ({ id, isAnalytics }) =>
        `showrooms/${id}?isAnalytics=${isAnalytics}`,
      providesTags: ["Showrooms"],
    }),
    registerShowroom: builder.mutation({
      query: (credentials) => ({
        url: "showrooms",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["Showrooms"],
    }),
    verifyPhoneNumber: builder.mutation({
      query: (credentials) => ({
        url: "showrooms/verify-phone",
        method: "POST",
        body: credentials,
      }),
    }),
    updateShowroomById: builder.mutation({
      query: ({ id, credentials }) => ({
        url: `showrooms/${id}`,
        method: "PATCH",
        body: credentials,
      }),
      invalidatesTags: ["Showrooms"],
    }),
    changePassword: builder.mutation({
      query: ({ id, credentials }) => ({
        url: `showrooms/change-password/${id}`,
        method: "PATCH",
        body: credentials,
      }),
    }),
    sendSms: builder.mutation({
      query: (credentials) => ({
        url: "showrooms/check-showroom-exists",
        method: "POST",
        body: credentials,
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ credentials }) => ({
        url: "showrooms/reset-password",
        method: "POST",
        body: credentials,
      }),
    }),
    sendOtpResetPass: builder.mutation({
      query: (credentials) => ({
        url: "showrooms/otp-reset-pass",
        method: "POST",
        body: credentials,
      }),
    }),
    deleteShowroomById: builder.mutation({
      query: (id) => ({
        url: `showrooms/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Showrooms"],
    }),
    sendEmailOtp: builder.mutation({
      query: (credentials) => ({
        url: "showrooms/send-email",
        method: "POST",
        body: credentials,
      }),
    }),
    verifyEmailOtp: builder.mutation({
      query: (credentials) => ({
        url: `showrooms/verify-email/${credentials.id}`,
        method: "POST",
        body: credentials,
      }),
    }),
    deletePhoneNumber: builder.mutation({
      query: (credentials) => ({
        url: `showrooms/delete-phone/${credentials.id}`,
        method: "DELETE",
        body: credentials,
      }),
      invalidatesTags: ["Showrooms"],
    }),
  }),
});

export const {
  useGetAllShowroomsQuery,
  useGetShowroomByIdQuery,
  useRegisterShowroomMutation,
  useVerifyPhoneNumberMutation,
  useUpdateShowroomByIdMutation,
  useChangePasswordMutation,
  useSendSmsMutation,
  useResetPasswordMutation,
  useSendOtpResetPassMutation,
  useDeleteShowroomByIdMutation,
  useSendEmailOtpMutation,
  useVerifyEmailOtpMutation,
  useDeletePhoneNumberMutation,
} = showroomApiSlice;

import "./Home.css";
import { FormEvent, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { FilterData } from "../../data/FilterData";
import { useAppDispatch } from "../../app/hooks";
import { useMakes, useModels } from "../../hooks/useCarSpecs";
import { useRentCars, useSaleCars, useShowrooms } from "../../hooks/useAuth";
import {
  setCarMake,
  setCarModel,
  setCarCondition,
  setCarLocation as setCLocation,
} from "../../features/filter/filterSlice";
import { SaleCard } from "../../components/cards/SaleCard";
import { RentalCard } from "../../components/cards/RentalCard";
import { ShowroomCard } from "../../components/cards/ShowroomCard";
import { FilterDropDown } from "../../components/customs/filter drop down/FilterDropDown";
import { RightArrowIcon } from "../../assets/svg/rightArrowIcon";
import { LeftArrowIcon } from "../../assets/svg/leftArrowIcon";
import { SEO } from "../../utilities/SEO";
import { CardSkeleton } from "../../components/skeleton/CardSkeleton";
import { motion } from "framer-motion";
import heroImg from "../../assets/images/porsche-hero.webp";

export const Home = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const targetRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const [make, setMake] = useState<string>("");
  const [model, setModel] = useState<string>("");
  const [condition, setCondition] = useState<string>("");
  const [carLocation, setCarLocation] = useState<string>("");
  const page = 1;
  const limit = 12;

  // Handle CTA action
  const scrollToSearch = () => {
    const navigationBarHeight = 120; // the height of your navigation bar
    const targetPosition = targetRef.current!.offsetTop - navigationBarHeight;
    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
  };

  const { makes } = useMakes();
  const { models } = useModels(make);

  // Get sale cars
  const {
    cars,
    isLoading: isCarsLoading,
    isSuccess: isCarsSuccess,
    isFetching: isCarsFetching,
    isError: isCarsError,
    error: carsError,
  } = useSaleCars(page, limit);

  // Get rent cars
  const {
    rentCars,
    isLoading: isRentCarsLoading,
    isSuccess: isRentCarsSuccess,
    isFetching: isRentCarsFetching,
    isError: isRentCarsError,
    error: rentCarsError,
  } = useRentCars(page, limit);

  // Get showrooms
  const {
    showrooms,
    isLoading: isShowroomsLoading,
    isSuccess: isShowroomsSuccess,
    isFetching: isShowroomsFetching,
    isError: isShowroomsError,
    error: showroomsError,
  } = useShowrooms(page, limit);

  // Set model to empty string when make changes
  const handleMakeChange = () => {
    setModel("");
  };

  // Handle sale cars search
  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(setCarMake(make));
    dispatch(setCarModel(model));
    dispatch(setCarCondition(condition));
    dispatch(setCLocation(carLocation));

    navigate(`${location.pathname}/cars`);
  };

  return (
    <>
      {/* SEO */}
      <SEO
        title={t("seo.homeTitle")}
        titlePost={t("seo.homeTitle").toString()}
        description={t("seo.homeDescription").toString()}
      />

      {/* Hero Section */}
      <section id="hero">
        <img src={heroImg} alt="Hero Background" className="hero-background" />
        <div className="header">
          <header className="headline">
            <h1>{t("home.firstHeadline")}</h1>
            <h2>{t("home.secHeadline")}</h2>
          </header>
          <button
            aria-label={t("home.heroCTA").toString()}
            onClick={scrollToSearch}
          >
            {t("home.heroCTA")}
          </button>
        </div>
      </section>
      {/* Search Section */}
      <section ref={targetRef} id="search">
        <div>
          <form onSubmit={handleSearch}>
            <h2 className="heading">{t("saleCars.searchHeading")}</h2>
            <div className="filters">
              <FilterDropDown
                options={makes}
                placeholder="Make"
                onChangeMake={handleMakeChange}
                setSelection={setMake}
                selectedOption={make}
              />
              <FilterDropDown
                options={models}
                placeholder="Model"
                setSelection={setModel}
                selectedOption={model}
              />
              <FilterDropDown
                options={FilterData.condition}
                placeholder="Condition"
                setSelection={setCondition}
                selectedOption={condition}
              />
              <FilterDropDown
                options={FilterData.location}
                placeholder="Location"
                setSelection={setCarLocation}
                selectedOption={carLocation}
              />
            </div>
            <div>
              <button
                type="submit"
                className="search-btn"
                aria-label={t("btns&links.search").toString()}
              >
                {t("btns&links.search")}
              </button>
              <Link
                className="advanced-search"
                to={`${location.pathname}/cars`}
              >
                {t("btns&links.advancedSearch")}
              </Link>
            </div>
            <button
              type="submit"
              className="mob-search-btn"
              aria-label={t("btns&links.search").toString()}
            >
              {t("btns&links.search")}
            </button>
          </form>
        </div>
      </section>
      {/* Feature Section */}
      <main id="feature">
        {/* Sale Section */}
        <section className="sale">
          <div>
            <h2 className="heading">{t("home.saleHeading")}</h2>
            {cars?.saleCars?.length >= limit && (
              <Link className="link" to={`${location.pathname}/cars`}>
                {t("btns&links.viewMore")}
                <span>
                  {i18n.language === "en" ? (
                    <RightArrowIcon />
                  ) : (
                    <LeftArrowIcon />
                  )}
                </span>
              </Link>
            )}
          </div>
          {isCarsLoading || isCarsFetching ? (
            <div className="cards">
              {Array.from({ length: 4 }).map((_, index) => (
                <CardSkeleton key={index} />
              ))}
            </div>
          ) : isCarsSuccess && cars.saleCars ? (
            <div className="cards">
              {cars.saleCars?.length > 0 ? (
                cars.saleCars.map((car, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, transform: "translateY(40px)" }}
                    whileInView={{
                      opacity: 1,
                      transform: "none",
                      transition: { delay: index * 0.015 },
                    }}
                    viewport={{ once: true }}
                  >
                    <SaleCard
                      key={car._id.toString()}
                      data={car}
                      path={location.pathname + "/cars/"}
                    />
                  </motion.div>
                ))
              ) : (
                <p className="no-results">{t("home.noCars")}</p>
              )}
            </div>
          ) : (
            isCarsError && (
              <div className="sale">
                <p className="fetch-error">
                  {carsError.status} <br />
                  {JSON.stringify(carsError.data)}
                </p>
              </div>
            )
          )}
          {cars?.saleCars?.length >= limit && (
            <Link className="mob-link" to={`${location.pathname}/cars`}>
              {t("btns&links.viewMore")}
              <span>
                {i18n.language === "en" ? (
                  <RightArrowIcon />
                ) : (
                  <LeftArrowIcon />
                )}
              </span>
            </Link>
          )}
        </section>

        {/* Rental Section */}
        {rentCars?.rentCars?.length > 0 && (
          <section className="rental">
            <div>
              <h2 className="heading">{t("home.rentHeading")}</h2>
              {rentCars?.rentCars?.length >= limit && (
                <Link className="link" to={`${location.pathname}/rental-cars`}>
                  {t("btns&links.viewMore")}
                  <span>
                    {i18n.language === "en" ? (
                      <RightArrowIcon />
                    ) : (
                      <LeftArrowIcon />
                    )}
                  </span>
                </Link>
              )}
            </div>
            {isRentCarsLoading || isRentCarsFetching ? (
              <div className="cards">
                {Array.from({ length: 4 }).map((_, index) => (
                  <CardSkeleton key={index} />
                ))}
              </div>
            ) : isRentCarsSuccess && rentCars.rentCars ? (
              <div className="cards">
                {rentCars.rentCars?.length > 0 ? (
                  rentCars.rentCars.map((rentCar, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, transform: "translateY(40px)" }}
                      whileInView={{
                        opacity: 1,
                        transform: "none",
                        transition: { delay: index * 0.015 },
                      }}
                      viewport={{ once: true }}
                    >
                      <RentalCard
                        key={rentCar._id.toString()}
                        data={rentCar}
                        path={location.pathname + "/rental-cars/"}
                      />
                    </motion.div>
                  ))
                ) : (
                  <p className="no-results">{t("home.noRentCars")}</p>
                )}
              </div>
            ) : (
              isRentCarsError && (
                <div className="rental">
                  <p className="fetch-error">
                    {rentCarsError.status} <br />
                    {JSON.stringify(rentCarsError.data)}
                  </p>
                </div>
              )
            )}
            {rentCars?.rentCars?.length >= limit && (
              <Link
                className="mob-link"
                to={`${location.pathname}/rental-cars`}
              >
                {t("btns&links.viewMore")}
                <span>
                  {i18n.language === "en" ? (
                    <RightArrowIcon />
                  ) : (
                    <LeftArrowIcon />
                  )}
                </span>
              </Link>
            )}
          </section>
        )}

        {/* Showroom Section */}
        <section className="showroom">
          <div>
            <h2 className="heading">{t("home.showroomHeading")}</h2>
            {showrooms?.showrooms?.length >= limit && (
              <Link className="link" to={`${location.pathname}/showrooms`}>
                {t("btns&links.viewMore")}
                <span>
                  {i18n.language === "en" ? (
                    <RightArrowIcon />
                  ) : (
                    <LeftArrowIcon />
                  )}
                </span>
              </Link>
            )}
          </div>
          {isShowroomsLoading || isShowroomsFetching ? (
            <div className="cards">
              {Array.from({ length: 4 }).map((_, index) => (
                <CardSkeleton key={index} isShowroomCard={true} />
              ))}
            </div>
          ) : isShowroomsSuccess && showrooms.showrooms ? (
            <div className="cards">
              {showrooms.showrooms?.length > 0 ? (
                showrooms.showrooms.map((showroom, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, transform: "translateY(40px)" }}
                    whileInView={{
                      opacity: 1,
                      transform: "none",
                      transition: { delay: index * 0.015 },
                    }}
                    viewport={{ once: true }}
                  >
                    <ShowroomCard
                      key={showroom._id}
                      data={showroom}
                      path={location.pathname + "/showrooms/"}
                      totalCars={
                        showrooms.totalCars.find(
                          (totalCar) => totalCar._id === showroom._id
                        )?.count
                      }
                    />
                  </motion.div>
                ))
              ) : (
                <p className="no-results"> {t("home.noShowrooms")}</p>
              )}
            </div>
          ) : (
            isShowroomsError && (
              <div className="showroom">
                <p className="fetch-error">
                  {showroomsError.status} <br />
                  {JSON.stringify(showroomsError.data)}
                </p>
              </div>
            )
          )}
          {showrooms?.showrooms?.length >= limit && (
            <Link className="mob-link" to={`${location.pathname}/showrooms`}>
              {t("btns&links.viewMore")}
              <span>
                {i18n.language === "en" ? (
                  <RightArrowIcon />
                ) : (
                  <LeftArrowIcon />
                )}
              </span>
            </Link>
          )}
        </section>
      </main>
      {/* About Section */}
      <section id="about">
        <div className="container">
          <div>
            <h3 className="heading">{t("home.whatWeDo")}</h3>
            <p>
              <Trans>{t("home.whatWeDoDesc")}</Trans>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

import "./ProfileDp.css";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../../app/api/authApiSlice";
import { useAuth, useShowroom } from "../../../hooks/useAuth";
import { ProfileIcon } from "../../../assets/svg/profileIcon";
import { HomeIcon } from "../../../assets/svg/homeIcon";
import { LogoutIcon } from "../../../assets/svg/logoutIcon";

export const ProfileDp = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { isAuth } = useAuth();

  const headerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggling = () => setIsOpen(!isOpen);

  // Log out mutation
  const [logout] = useLogoutMutation();
  // Get showroom
  const { showroom } = useShowroom();

  // Handle log out
  const handleLogout = async () => {
    await logout();
    localStorage.removeItem("token");
    navigate(`${i18n.language}/sign-in`);
  };

  // Handle click outside
  useEffect(() => {
    const handler = (e: Event) => {
      if (
        headerRef.current &&
        !headerRef.current.contains(e.target as HTMLInputElement)
      ) {
        setIsOpen(false);
      }
    };
    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  }, []);

  return (
    <div id="profile">
      <div className="dd-container">
        <div ref={headerRef} className="dd-header" onClick={toggling}>
          {showroom?.images[showroom.logoImageIndex] !== null ? (
            <img src={showroom?.images[showroom.logoImageIndex || 0]} alt="" />
          ) : (
            <ProfileIcon />
          )}
        </div>
        {isOpen && (
          <div className="ddl-container">
            <ul className="dd-list">
              <li onClick={() => navigate(`${i18n.language}/showroom-profile`)}>
                <HomeIcon /> {t("showroomManagement.heading")}
              </li>
              {isAuth && (
                <li onClick={handleLogout}>
                  <LogoutIcon /> {t("showroomManagement.logout")}
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

import "./PricingRent.css";
import { FC, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { AddRentCarDataType } from "../../../pages/auth showroom/profile/Inventory management/outlet/add car/AddCar";

type PricingProps = {
  register: UseFormRegister<AddRentCarDataType>;
  errors: FieldErrors<AddRentCarDataType>;
  setValue: UseFormSetValue<AddRentCarDataType>;
  watch: UseFormWatch<AddRentCarDataType>;
};
export const PricingRent: FC<PricingProps> = ({ register, errors }) => {
  const { t } = useTranslation();

  // Only allow numbers in input
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // only allow numbers
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 46
      )
    )
      e.preventDefault();
  };
  return (
    <section id="pricing-rent">
      <header>
        <p>{t("showroomManagement.inventory.addCar.stepFiveHeading")}</p>
      </header>
      <div className="row-1">
        <p>{t("dropDownPlaceholder.priceUnit")}</p>
        <div className="col">
          <div className="price-unit">
            <div className="radio-input-group">
              <input
                id="usd"
                type="radio"
                {...register("priceUnit", { required: true })}
                value={"usd"}
              />
              <label htmlFor="usd">USD ($)</label>
            </div>
            <div className="radio-input-group">
              <input
                id="iqd"
                type="radio"
                {...register("priceUnit", { required: true })}
                value={"iqd"}
              />
              <label htmlFor="iqd">IQD (دینار)</label>
            </div>
          </div>
          {errors.priceUnit && (
            <p className="error">{errors.priceUnit.message}</p>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="input-group">
            <input
              placeholder=" "
              type="number"
              {...register("price.daily", { required: true })}
              onKeyDown={onKeyDown}
            />
            <label>{t("inputPlaceholder.dailyPrice")}</label>
          </div>
          {errors.price?.daily && (
            <p className="error">{errors.price.daily.message}</p>
          )}
        </div>
        <div className="col">
          <div className="input-group">
            <input
              placeholder=" "
              type="number"
              {...register("price.weekly", { required: true })}
              onKeyDown={onKeyDown}
            />
            <label>{t("inputPlaceholder.weeklyPrice")}</label>
          </div>
          {errors.price?.weekly && (
            <p className="error">{errors.price.weekly.message}</p>
          )}
        </div>
        <div className="col">
          <div className="input-group">
            <input
              placeholder=" "
              type="number"
              {...register("price.monthly", { required: true })}
              onKeyDown={onKeyDown}
            />
            <label>{t("inputPlaceholder.monthlyPrice")}</label>
          </div>
          {errors.price?.monthly && (
            <p className="error">{errors.price.monthly.message}</p>
          )}
        </div>
      </div>
      <div className="row-3">
        <div className="price-shown">
          <p>{t("inputPlaceholder.priceShown")}</p>
          <div className="btns">
            <div className="radio-group">
              <input
                type="radio"
                id="true"
                value={"true"}
                {...register("isPriceShown", { required: true })}
              />
              <label htmlFor="true">{t("priceShown.show")}</label>
            </div>
            <div className="radio-group">
              <input
                type="radio"
                id="false"
                value={"false"}
                {...register("isPriceShown", { required: true })}
              />
              <label htmlFor="false">{t("priceShown.hide")}</label>
            </div>
          </div>
        </div>
        {errors.isPriceShown && (
          <p className="error">{errors.isPriceShown.message}</p>
        )}
      </div>
    </section>
  );
};

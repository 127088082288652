import "./ShareModal.css";
import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import { CloseIcon } from "../../assets/svg/closeIcon";
import { CopyIcon } from "../../assets/svg/copyIcon";
import { WhatsAppIcon } from "../../assets/svg/whatsAppIcon";
import { FacebookIcon } from "../../assets/svg/facebookIcon";
import { EmailIcon } from "../../assets/svg/emailIcon";

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  // title: string;
};
export const ShareModal: FC<Props> = ({ setShowModal }) => {
  const { t } = useTranslation();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
  };
  return (
    <div id="share-modal">
      <div className="share-modal-container">
        <button
          type="button"
          className="close-btn"
          onClick={() => setShowModal(false)}
        >
          <CloseIcon width={16} height={16} />
        </button>
        <p className="title">{t("share.title")}</p>
        <div className="share-modal-options">
          <div className="option">
            <button className="copy-link" onClick={handleCopyLink}>
              <CopyIcon />
            </button>
            <p>{t("share.copyLink")}</p>
          </div>
          <div className="option">
            <WhatsappShareButton
              style={{ backgroundColor: "#25D366" }}
              children={<WhatsAppIcon width={22} height={22} />}
              url={window.location.href}
            />
            <p>{t("share.whatsApp")}</p>
          </div>
          <div className="option">
            <FacebookShareButton
              style={{ backgroundColor: "#1877f2" }}
              children={<FacebookIcon width={22} height={22} />}
              url={window.location.href}
            />
            <p>{t("share.facebook")}</p>
          </div>
          <div className="option">
            <EmailShareButton
              style={{ backgroundColor: "#808080" }}
              children={<EmailIcon width={22} height={22} />}
              url={window.location.href}
            />
            <p>{t("share.email")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FilterCarState = {
  carMake: string;
  carModel: string;
  carCondition: string;
  cLocation: string;
};
// initial state
const initialState: FilterCarState = {
  carMake: "",
  carModel: "",
  carCondition: "",
  cLocation: "",
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setCarMake: (state, action: PayloadAction<string>) => {
      state.carMake = action.payload;
    },
    setCarModel: (state, action: PayloadAction<string>) => {
      state.carModel = action.payload;
    },
    setCarCondition: (state, action: PayloadAction<string>) => {
      state.carCondition = action.payload;
    },
    setCarLocation: (state, action: PayloadAction<string>) => {
      state.cLocation = action.payload;
    },
    resetFilter: (state) => {
      state.carMake = initialState.carMake;
      state.carModel = initialState.carModel;
      state.carCondition = initialState.carCondition;
      state.cLocation = initialState.cLocation;
    },
  },
});

export const selectCarMake = (state: { filter: { carMake: string } }) =>
  state.filter.carMake;
export const selectCarModel = (state: { filter: { carModel: string } }) =>
  state.filter.carModel;
export const selectCarCondition = (state: {
  filter: { carCondition: string };
}) => state.filter.carCondition;
export const selectCarLocation = (state: { filter: { cLocation: string } }) =>
  state.filter.cLocation;

export const {
  setCarMake,
  setCarModel,
  setCarCondition,
  setCarLocation,
  resetFilter,
} = filterSlice.actions;

export default filterSlice.reducer;

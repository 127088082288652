import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const EyeIcon = (props: Props) => (
  <svg
    width={22}
    height={17}
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.9738 8.02663C19.9474 7.96506 19.3128 6.49607 17.8921 5.01251C16.5762 3.63832 14.313 2 11 2C7.687 2 5.42376 3.63832 4.10787 5.01251C2.68724 6.49607 2.05257 7.96506 2.02619 8.02663C2.00891 8.06819 2 8.113 2 8.1583C2 8.2036 2.00891 8.24841 2.02619 8.28997C2.05257 8.35235 2.68724 9.82053 4.10787 11.3041C5.42687 12.6815 7.68778 14.3158 11 14.3158C14.3122 14.3158 16.5762 12.6783 17.8921 11.3041C19.3128 9.82053 19.9474 8.35235 19.9738 8.28997C19.9911 8.24841 20 8.2036 20 8.1583C20 8.113 19.9911 8.06819 19.9738 8.02663ZM17.4367 10.8625C15.6467 12.7261 13.4797 13.6676 10.9969 13.6676C8.51409 13.6676 6.35016 12.7236 4.55711 10.8625C3.79418 10.0679 3.15256 9.15577 2.65465 8.15789C3.15348 7.15978 3.79616 6.24761 4.56021 5.45328C6.35326 3.59214 8.51719 2.6482 11 2.6482C13.4828 2.6482 15.6467 3.59214 17.4398 5.45328C18.2037 6.24769 18.8464 7.15985 19.3453 8.15789C18.8465 9.15599 18.2038 10.0682 17.4398 10.8625H17.4367ZM11 4.5928C10.3248 4.5928 9.66477 4.80189 9.10336 5.19362C8.54195 5.58536 8.10439 6.14215 7.846 6.79359C7.58761 7.44503 7.52001 8.16185 7.65173 8.85341C7.78346 9.54497 8.1086 10.1802 8.58603 10.6788C9.06347 11.1774 9.67176 11.5169 10.334 11.6545C10.9962 11.792 11.6826 11.7214 12.3064 11.4516C12.9302 11.1818 13.4634 10.7248 13.8385 10.1386C14.2136 9.55228 14.4139 8.863 14.4139 8.15789C14.4128 7.2127 14.0528 6.30653 13.4128 5.63817C12.7728 4.96982 11.9051 4.59387 11 4.5928ZM11 11.0748C10.4476 11.0748 9.90754 10.9037 9.4482 10.5832C8.98887 10.2627 8.63086 9.80714 8.41945 9.27414C8.20805 8.74115 8.15273 8.15466 8.26051 7.58884C8.36828 7.02301 8.63431 6.50327 9.02494 6.09534C9.41557 5.6874 9.91326 5.40959 10.4551 5.29704C10.9969 5.1845 11.5585 5.24226 12.0689 5.46303C12.5793 5.68381 13.0155 6.05767 13.3224 6.53735C13.6293 7.01703 13.7932 7.58099 13.7932 8.15789C13.7932 8.9315 13.4989 9.67343 12.9751 10.2205C12.4512 10.7675 11.7408 11.0748 11 11.0748Z"
      fill="#7C7C85"
    />
  </svg>
);

export { EyeIcon };

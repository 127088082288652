import { useTranslation } from "react-i18next";
import {
  useAuth,
  useShowroomCars,
  useShowroomRentCars,
} from "../../../../../../hooks/useAuth";
import { Loading } from "../../../../../../components/customs/loading/Loading";
import { ShowroomAnalyticsTable } from "../../../../../../components/showroom analytics table/ShowroomAnalyticsTable";
import { useGetShowroomByIdQuery } from "../../../../../../app/api/showroomApiSlice";
import { ShowroomType } from "../../../../../../components/cards/ShowroomCard";

export const ShowroomAnalytics = () => {
  const { t, i18n } = useTranslation();
  const { id } = useAuth();

  const { data, isSuccess, isLoading, isFetching } = useGetShowroomByIdQuery(
    { id, isAnalytics: true },
    {
      skip: !id,
    }
  ) as {
    data: {
      showroom: ShowroomType;
      views: number;
      visitors: number;
    };
    isSuccess: boolean;
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    error: unknown;
  };

  const {
    cars,
    isSuccess: isCarsDataSuccess,
    isLoading: isCarsDataLoading,
    isFetching: isCarsDataFetching,
  } = useShowroomCars(
    data?.showroom?._id,
    1,
    1,
    "",
    false,
    data?.showroom?.showroomType
  );

  // Get showroom cars
  const {
    cars: rentCars,
    isSuccess: isRentCarsDataSuccess,
    isFetching: isRentCarsDataFetching,
    isLoading: isRentCarsDataLoading,
  } = useShowroomRentCars(
    data?.showroom?._id,
    1,
    1,
    "",
    false,
    data?.showroom?.showroomType
  );

  // Sale cars table heading
  const showroomTableHeading = [
    t("showroomManagement.showroomTable.showroom"),
    t("showroomManagement.showroomTable.registeredAt"),
    t("showroomManagement.showroomTable.views"),
    t("showroomManagement.showroomTable.visitors"),
    t("showroomManagement.showroomTable.uploadedCars"),
  ];

  return (
    <div id="showroom-analytics">
      {isLoading ||
      isFetching ||
      isCarsDataLoading ||
      isRentCarsDataLoading ||
      isCarsDataFetching ||
      isRentCarsDataFetching ? (
        <Loading />
      ) : isSuccess && data.showroom ? (
        <ShowroomAnalyticsTable
          thead={showroomTableHeading}
          showroom={data.showroom}
          showroomPath={`/${i18n.language}/showrooms/`}
          totalCars={cars ? cars?.totalCars : rentCars?.totalCars || 0}
          views={data.views}
          visitors={data.visitors}
        />
      ) : (
        <p className="no-cars">{t("showroomManagement.analytics.noData")}</p>
      )}
    </div>
  );
};

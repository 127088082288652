import "./PhoneModal.css";
import { Dispatch, FC, SetStateAction, KeyboardEvent } from "react";
import { FieldErrors, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { phoneNumbersDataType } from "../PhoneNumbers";
import { CloseIcon } from "../../../../../../../assets/svg/closeIcon";

type Props = {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setValue: UseFormSetValue<phoneNumbersDataType>;
  isLoading: boolean;
  errors: FieldErrors<phoneNumbersDataType>;
  error: any;
};
export const PhoneModal: FC<Props> = ({
  setOpenModal,
  setValue,
  isLoading,
  errors,
  error,
}) => {
  const { t } = useTranslation();

  // Only allow 6 numbers and convert arabic numbers to english in input
  const onChangeCodeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.slice(0, 6);

    // if it is arabic number convert it to english number
    let inputValue = e.target.value.replace(/[\u0660-\u0669]/g, (c) =>
      String.fromCharCode(c.charCodeAt(0) - 0x0660 + 48)
    );

    setValue(`code`, inputValue, {
      shouldValidate: true,
    });
  };

  // Only allow numbers in input
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // only allow numbers
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 46
      )
    )
      e.preventDefault();
  };
  return (
    <div id="phone-modal">
      <div className="phone-modal-container">
        <div className="close-btn" onClick={() => setOpenModal(false)}>
          <CloseIcon />
        </div>
        <header>
          <p className="title">{t("register.verifyOtp.heading")}</p>
          <p>{t("register.verifyOtp.desc")}</p>
        </header>
        <div className="col">
          <div className="input-group">
            <input
              placeholder=" "
              type="number"
              onChange={onChangeCodeInput}
              onKeyDown={onKeyDown}
            />
            <label>{t("inputPlaceholder.code")}</label>
          </div>
          {errors.code && <p className="error">{errors.code.message}</p>}
          {error && <p className="error">{error?.data?.message}</p>}
        </div>
        <button disabled={isLoading} type="submit">
          {isLoading ? t("loading") : t("btns&links.verify")}
        </button>
      </div>
    </div>
  );
};

import "./UploadImages.css";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import {
  FieldErrors,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from "react-hook-form";
import { AddCarDataType } from "../../../add car/AddCar";
import { CheckIcon } from "../../../../../../../../assets/svg/checkIcon";
import { CheckSolidIcon } from "../../../../../../../../assets/svg/checkSolidIcon";
import { CloseIcon } from "../../../../../../../../assets/svg/closeIcon";
import { ImgIcon } from "../../../../../../../../assets/svg/imgIcon";
import Compressor from "compressorjs";

type Props = {
  setValue: UseFormSetValue<AddCarDataType>;
  unregister: UseFormUnregister<AddCarDataType>;
  watch: UseFormWatch<AddCarDataType>;
  errors: FieldErrors<AddCarDataType>;
};

export const UploadImages: FC<Props> = ({
  setValue,
  unregister,
  watch,
  errors,
}) => {
  const { t } = useTranslation();
  const images = watch("images") || [];
  const primaryImageIndex = watch("primaryImageIndex");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Add images
  const handleImagesChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    // max 20 images
    if (images.length + e.target.files!.length > 20) {
      toast.error(t("alert.carImagesMax"));
      return;
    }

    const files = e.target.files;

    if (files) {
      const newImages: File[] = Array.from(files);

      const updatedImages = Array.from(images).filter(
        (existingImage) =>
          !newImages.some((newImage) => newImage.name === existingImage.name)
      );

      const isDuplicate = newImages.some((newImage) =>
        Array.from(images).some(
          (existingImage) => existingImage.name === newImage.name
        )
      );
      if (!isDuplicate) {
        // Compress each new image before adding it
        const compressedImages = await Promise.all(
          newImages.map(
            (newImage) =>
              new Promise<File>((resolve) => {
                const currentImg = new Image();
                currentImg.src = URL.createObjectURL(newImage);

                currentImg.onload = () => {
                  const originalWidth = currentImg.width;
                  const originalHeight = currentImg.height;

                  let targetWidth = originalWidth;
                  let targetHeight = originalHeight;

                  if (originalWidth > 1920 && originalHeight < originalWidth) {
                    targetWidth = 1920;
                    targetHeight = 1080;
                  } else if (
                    originalHeight > 1920 &&
                    originalWidth < originalHeight
                  ) {
                    targetWidth = 1080;
                    targetHeight = 1920;
                  }

                  new Compressor(newImage, {
                    quality: 0.8, // Adjust the quality as needed
                    width: targetWidth,
                    height: targetHeight,

                    success(result) {
                      // Convert the compressed result to a File
                      const compressedFile = new File([result], newImage.name, {
                        type: result.type,
                        lastModified: Date.now(),
                      });
                      resolve(compressedFile);
                    },
                    error(err) {
                      console.error("Image compression failed:", err.message);
                      resolve(newImage); // Fallback to the original image if compression fails
                    },
                  });
                };
              })
          )
        );

        const allImages: File[] = [...updatedImages, ...compressedImages];

        const fileList = new DataTransfer();
        allImages.forEach((file) => fileList.items.add(file));

        setValue("images", fileList.files, { shouldValidate: true });
        setIsLoading(false);
      } else {
        toast.error(t("alert.imageAlreadyExists"));
        setIsLoading(false);
      }
    }
  };

  // Handle changes for individual images
  const handleIndividualImageChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    // Handle changes for individual images
    const files = e.target.files;
    if (files) {
      const updatedImages: File[] = Array.from(images);

      const isDuplicate = Array.from(files).some((newImage) =>
        updatedImages.some(
          (existingImage) => existingImage.name === newImage.name
        )
      );

      if (!isDuplicate) {
        // Compress the new image before updating the state
        const compressedImage = await new Promise<File>((resolve) => {
          const currentImg = new Image();
          currentImg.src = URL.createObjectURL(files[0]);

          currentImg.onload = () => {
            const originalWidth = currentImg.width;
            const originalHeight = currentImg.height;

            let targetWidth = originalWidth;
            let targetHeight = originalHeight;

            if (originalWidth > 1920 && originalHeight < originalWidth) {
              targetWidth = 1920;
              targetHeight = 1080;
            } else if (
              originalHeight > 1920 &&
              originalWidth < originalHeight
            ) {
              targetWidth = 1080;
              targetHeight = 1920;
            }

            new Compressor(files[0], {
              quality: 0.8, // Adjust the quality as needed
              width: targetWidth,
              height: targetHeight,
              success(result) {
                // Convert the compressed result to a File
                const compressedFile = new File([result], files[0].name, {
                  type: result.type,
                  lastModified: Date.now(),
                });
                resolve(compressedFile);
              },
              error(err) {
                console.error("Image compression failed:", err.message);
                resolve(files[0]); // Fallback to the original image if compression fails
              },
            });
          };
        });

        updatedImages[index] = compressedImage || updatedImages[index];

        const fileList = new DataTransfer();
        updatedImages.forEach((file) => fileList.items.add(file));
        setValue("images", fileList.files, { shouldValidate: true });

        const inputElement = document.querySelector("#showroom-images");
        if (inputElement instanceof HTMLInputElement) {
          inputElement.files = fileList.files;
        }
        setIsLoading(false);
      } else {
        toast.error(t("alert.imageAlreadyExists"));
        setIsLoading(false);
      }
    }
  };

  // Handle delete image
  const handleDeleteImage = (index: number) => {
    const newImages = Array.from(images).filter((image, i) => i !== index);
    const isPrimary = primaryImageIndex === index;

    const fileList = new DataTransfer();
    newImages.forEach((file) => fileList.items.add(file));

    setValue("images", fileList.files, { shouldValidate: true });
    if (isPrimary) {
      unregister("primaryImageIndex", { keepError: true });
    } else if (index < Number(primaryImageIndex)) {
      setValue("primaryImageIndex", Number(primaryImageIndex) - 1, {
        shouldValidate: true,
      });
    }

    const inputElement = document.querySelector("#showroom-images");
    if (inputElement instanceof HTMLInputElement) {
      inputElement.files = fileList.files;
    }
  };

  return (
    <div id="upload-images">
      <header>
        <p>{t("showroomManagement.inventory.addCar.stepFourHeading")}</p>
        <p>{t("showroomManagement.inventory.addCar.stepFourDesc")}</p>
      </header>
      <div className="row">
        <div className="images-input">
          <input
            id="car-images"
            type="file"
            onChange={handleImagesChange}
            multiple
            accept="image/*"
          />
          <label htmlFor="car-images">
            <ImgIcon />
            {isLoading ? t("loading") : t("inputPlaceholder.uploadImages")}
          </label>
        </div>
        {errors.images && <p className="error">{errors.images.message}</p>}
        {images && images.length > 0 && (
          <div className="images-preview">
            {Array.from(images).map((image, index) => (
              <div key={index}>
                <div className="image">
                  <img src={URL.createObjectURL(image)} alt="preview" />
                  <button
                    type="button"
                    onClick={() => handleDeleteImage(index)}
                  >
                    <CloseIcon width={12} height={12} />
                  </button>
                  <div className="change-input">
                    <input
                      id={`img-${index}`}
                      type="file"
                      onChange={(e) => handleIndividualImageChange(e, index)}
                      accept="image/*"
                    />
                    <label htmlFor={`img-${index}`}></label>
                  </div>
                </div>
                <button
                  className="primary-img-btn"
                  type="button"
                  onClick={() => {
                    setValue("primaryImageIndex", index, {
                      shouldValidate: true,
                    });
                  }}
                >
                  {primaryImageIndex === index ? (
                    <CheckSolidIcon width={16} height={16} />
                  ) : (
                    <CheckIcon width={16} height={16} />
                  )}
                  {primaryImageIndex === index
                    ? t("inputPlaceholder.primary")
                    : t("inputPlaceholder.setAsPrimary")}
                </button>
              </div>
            ))}
          </div>
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

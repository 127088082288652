export const RegisterData = {
  cityOptions: [
    {
      name: "sulaymaniyah",
      value: { lat: 35.557045, lng: 45.435943 },
    },
    {
      name: "erbil",
      value: { lat: 36.191113, lng: 44.009167 },
    },
    {
      name: "duhok",
      value: { lat: 36.857777, lng: 42.988888 },
    },
    {
      name: "kirkuk",
      value: { lat: 35.468056, lng: 44.392222 },
    },
    {
      name: "halabja",
      value: { lat: 35.177222, lng: 45.986944 },
    },
    {
      name: "zakho",
      value: { lat: 37.144169, lng: 42.6875 },
    },
    {
      name: "baghdad",
      value: { lat: 33.312806, lng: 44.361488 },
    },
    {
      name: "basrah",
      value: { lat: 30.508056, lng: 47.783056 },
    },
    {
      name: "mosul",
      value: { lat: 36.345, lng: 43.098889 },
    },
    {
      name: "anbar",
      value: { lat: 33.375, lng: 43.5 },
    },
  ],
  showroomTypeOptions: ["sale", "rental"],
  dealershipTypeOptions: ["authorized dealership", "independent dealership"],
  daysOfTheWeekOptions: [
    "saturday",
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
  ] as [
    "saturday",
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday"
  ],
};

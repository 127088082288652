type TypeSortData = {
  sortCars: string[];
  sortRentalCars: string[];
  sortPlates: string[];
};
export const sortData: TypeSortData = {
  sortCars: [
    "newest listed",
    "oldest listed",
    "lowest price",
    "highest price",
    "lowest mileage",
    "highest mileage",
    "newest year",
    "oldest year",
  ],
  sortRentalCars: [
    "newest listed",
    "oldest listed",
    "lowest price",
    "highest price",
    "newest year",
    "oldest year",
  ],
  sortPlates: [
    "newest listed",
    "oldest listed",
    "lowest price",
    "highest price",
  ],
};

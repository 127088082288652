import "./WhiteLoading.css";
export const WhiteLoading = () => {
  return (
    <div className="white-loading-container">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

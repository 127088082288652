import "./EditPlate.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  PlateOptions,
  SaleCarOptions,
} from "../../../../../../data/FilterData";
import {
  useGetPlateByIdQuery,
  useUpdatePlateByIdMutation,
} from "../../../../../../app/api/platesApiSlice";
import { useShowroom } from "../../../../../../hooks/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DropDown } from "../../../../../../components/customs/drop down/DropDown";
import { AddPlateType } from "../add plate/AddPlate";
import { Loading } from "../../../../../../components/customs/loading/Loading";
import { PlateType } from "../../../../../../components/cards/PlateCard";

// updatePlateType is equal to AddPlateType but with status field
type UpdatePlateType = AddPlateType & {
  status?: string;
};
export const EditPlate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { plateId } = useParams();
  const { showroom } = useShowroom();

  // Get plate
  const {
    data: plate,
    isLoading: isPlateLoading,
    isError: isPlateError,
  } = useGetPlateByIdQuery(plateId, {
    skip: !plateId,
  }) as {
    data: PlateType;
    isLoading: boolean;
    isError: boolean;
  };

  // Update plate mutation
  const [Update, { isLoading: isUpdateLoading, error: updateError }] =
    useUpdatePlateByIdMutation();

  // Update plate schema
  const UpdatePlateSchema = yup.object().shape({
    plateNumber: yup
      .number()
      .required()
      .typeError(t("errorMessage.plateNumber") as string),
    plateCategory: yup
      .string()
      .required(t("errorMessage.plateCategory") as string),
    plateCity: yup.string().required(t("errorMessage.plateCity") as string),
    plateLetter: yup.string().required(t("errorMessage.plateLetter") as string),
    phoneNumber: yup.string().required(t("errorMessage.phoneNumber") as string),
    priceUnit: yup.string().required(t("errorMessage.priceUnit") as string),
    price: yup
      .number()
      .required()
      .typeError(t("errorMessage.price") as string),

    isPriceShown: yup
      .boolean()
      .required(t("errorMessage.isPriceShown") as string),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = useForm<UpdatePlateType>({
    resolver: yupResolver(UpdatePlateSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<UpdatePlateType> = async (data) => {
    try {
      await Update({
        id: plate._id,
        credentials: {
          ...data,
          iqdValue: showroom?.iqdValue,
        },
      }).unwrap();
      navigate("../inventory-management/plate-listings", {
        state: { plateUpdated: t("alert.plateUpdated") },
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Set plate data to form
  useEffect(() => {
    if (plate) {
      reset({
        plateNumber: plate?.plateNumber,
        plateCategory: plate?.plateCategory,
        plateCity: plate?.plateCity,
        plateLetter: plate?.plateLetter,
        priceUnit: plate?.priceUnit,
        price: plate?.price,
        isPriceShown: plate?.isPriceShown?.toString() as any,
        phoneNumber: plate?.phoneNumber,
        status: plate?.status,
      });
    }
  }, [plate]);

  // Check if showroom is the owner of the plate or plate exist
  useEffect(() => {
    if (showroom && plate) {
      if (showroom._id !== plate.showroom?._id) {
        navigate("..");
      }
    }
    if (isPlateError) navigate("..");
  }, [isPlateError, plate, showroom]);

  if (isPlateLoading) return <Loading />;

  return (
    <div id="edit-plate">
      <header className="edit-heading">
        <p>{t("showroomManagement.inventory.editPlate.heading")}</p>
      </header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <DropDown
              options={SaleCarOptions.status}
              placeholder={"Status"}
              setValue={setValue}
              watch={watch}
            />
          </div>
          <div className="col">
            <div className="input-group">
              <input
                className="plate-number"
                placeholder=" "
                type="number"
                {...register("plateNumber", { required: true })}
              />
              <label>{t("inputPlaceholder.plateNumber")}</label>
            </div>
            {errors.plateNumber && (
              <p className="error">{errors.plateNumber.message}</p>
            )}
          </div>
          <div className="col">
            <DropDown
              placeholder="Plate Category"
              options={PlateOptions.category}
              setValue={setValue}
              watch={watch}
            />
            {errors.plateCategory && (
              <p className="error">{errors.plateCategory.message}</p>
            )}
          </div>
          <div className="col">
            <DropDown
              placeholder="Plate City"
              options={PlateOptions.city}
              setValue={setValue}
              watch={watch}
            />
            {errors.plateCity && (
              <p className="error">{errors.plateCity.message}</p>
            )}
          </div>
          <div className="col">
            <DropDown
              placeholder="Plate Letter"
              options={PlateOptions.letters}
              setValue={setValue}
              watch={watch}
            />
            {errors.plateLetter && (
              <p className="error">{errors.plateLetter.message}</p>
            )}
          </div>
          <div className="col">
            <DropDown
              placeholder="Phone Number"
              options={showroom?.phoneNumbers}
              setValue={setValue}
              watch={watch}
            />
            {errors.phoneNumber && (
              <p className="error">{errors.phoneNumber.message}</p>
            )}
          </div>
          <div className="col">
            <div className="price">
              <div className="price-unit">
                <DropDown
                  options={SaleCarOptions.priceUnit}
                  placeholder={"Price Unit"}
                  setValue={setValue}
                  watch={watch}
                />
              </div>
              <div className="input-group">
                <input
                  placeholder=" "
                  type="number"
                  {...register("price", { required: true })}
                />
                <label>{t("inputPlaceholder.price")}</label>
              </div>
            </div>
            {errors.priceUnit && (
              <p className="error">{errors.priceUnit.message}</p>
            )}
            {errors.price && <p className="error">{errors.price.message}</p>}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="price-shown">
              <p>{t("inputPlaceholder.priceShown")}</p>
              <div className="btns">
                <div className="radio-group">
                  <input
                    type="radio"
                    id="true"
                    value={"true"}
                    {...register("isPriceShown", { required: true })}
                  />
                  <label htmlFor="true">{t("priceShown.show")}</label>
                </div>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="false"
                    value={"false"}
                    {...register("isPriceShown", { required: true })}
                  />
                  <label htmlFor="false">{t("priceShown.hide")}</label>
                </div>
              </div>
            </div>
            {errors.isPriceShown && (
              <p className="error">{errors.isPriceShown.message}</p>
            )}
          </div>
        </div>
        {updateError && <p className="error">{updateError}</p>}
        {/* Buttons */}
        <div className="action-btns">
          <button
            onClick={() => navigate("../inventory-management/plate-listings")}
            className="cancel"
            type="button"
          >
            {t("btns&links.cancel")}
          </button>
          <button disabled={isUpdateLoading} className="update" type="submit">
            {isUpdateLoading ? t("loading") : t("btns&links.saveChanges")}
          </button>
        </div>
      </form>
    </div>
  );
};

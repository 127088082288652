import "./DeleteModal.css";
import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  onDeleteAccount: () => Promise<void>;
  isLoading: boolean;
  error: any;
};
export const DeleteModal: FC<Props> = ({
  setOpenModal,
  onDeleteAccount,
  isLoading,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <div id="delete-modal">
      <div className="delete-modal-container">
        <header>
          <p className="title">{t("alert.deleteAccountHeading")}</p>
          <p>{t("alert.deleteAccountDesc")}</p>
        </header>
        {error && <p className="error">{error?.data?.message}</p>}
        <div className="delete-modal-btns">
          <button
            type="button"
            className="cancel-btn"
            onClick={() => setOpenModal(false)}
          >
            {t("btns&links.cancel")}
          </button>

          <button
            disabled={isLoading}
            type="button"
            className="delete-btn"
            onClick={onDeleteAccount}
          >
            {isLoading ? t("loading") : t("btns&links.delete")}
          </button>
        </div>
      </div>
    </div>
  );
};

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const HomeIcon = (props: Props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1496_350)">
      <path
        d="M14.6667 3.81601V1.33334C14.6667 0.965343 14.3687 0.666676 14 0.666676C13.6313 0.666676 13.3333 0.965343 13.3333 1.33334V2.91068L9.86467 0.570009C8.732 -0.193991 7.268 -0.193991 6.13533 0.570009L1.46867 3.71934C0.549333 4.34001 0 5.37268 0 6.48268V12.6667C0 14.5047 1.49533 16 3.33333 16H5.33333C5.702 16 6 15.7013 6 15.3333V10C6 9.63268 6.29867 9.33334 6.66667 9.33334H9.33333C9.70133 9.33334 10 9.63268 10 10V15.3333C10 15.7013 10.298 16 10.6667 16H12.6667C14.5047 16 16 14.5047 16 12.6667V6.48268C16 5.42868 15.504 4.44401 14.6667 3.81601ZM14.6667 12.6667C14.6667 13.7693 13.7693 14.6667 12.6667 14.6667H11.3333V10C11.3333 8.89734 10.436 8.00001 9.33333 8.00001H6.66667C5.564 8.00001 4.66667 8.89734 4.66667 10V14.6667H3.33333C2.23067 14.6667 1.33333 13.7693 1.33333 12.6667V6.48268C1.33333 5.81668 1.66267 5.19668 2.21467 4.82468L6.88133 1.67534C7.56133 1.21668 8.43867 1.21668 9.118 1.67534L13.7847 4.82468C14.3367 5.19668 14.666 5.81668 14.666 6.48268L14.6667 12.6667Z"
        fill={props.fill || "var(--primary-500)"}
      />
    </g>
    <defs>
      <clipPath>
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { HomeIcon };

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const RightArrowIcon = (props: Props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.34435 1.54966C9.47293 1.41112 9.64722 1.33331 9.82894 1.33331C10.0107 1.33331 10.185 1.41112 10.3135 1.54966L15.7995 7.46767C15.9279 7.60637 16 7.79439 16 7.99043C16 8.18646 15.9279 8.37448 15.7995 8.51319L10.3135 14.4312C10.2508 14.5039 10.175 14.5622 10.0909 14.6026C10.0068 14.643 9.91601 14.6648 9.82393 14.6665C9.73186 14.6683 9.6404 14.65 9.55501 14.6128C9.46963 14.5756 9.39206 14.5202 9.32695 14.45C9.26183 14.3797 9.21049 14.2961 9.176 14.2039C9.14152 14.1118 9.12458 14.0132 9.1262 13.9138C9.12783 13.8145 9.14798 13.7166 9.18546 13.6258C9.22294 13.5351 9.27698 13.4534 9.34435 13.3857L13.6599 8.73018H0.68574C0.503871 8.73018 0.32945 8.65224 0.200849 8.51351C0.0722473 8.37478 0 8.18662 0 7.99043C0 7.79423 0.0722473 7.60608 0.200849 7.46735C0.32945 7.32861 0.503871 7.25068 0.68574 7.25068H13.6599L9.34435 2.59517C9.21594 2.45647 9.14381 2.26845 9.14381 2.07241C9.14381 1.87638 9.21594 1.68836 9.34435 1.54966Z"
      fill="#28282B"
    />
  </svg>
);

export { RightArrowIcon };

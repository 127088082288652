import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { TechnicalSpecsRent } from "../../../../../../../components/rent car form sections/technical specs/TechnicalSpecsRent";
import { OutletContextAddRentCarType } from "./StepOneAddRentCar";
import { StepProps } from "../sale steps/StepOneAddCar";
import { AddRentCarDataType } from "../AddCar";

export const StepThreeAddRentCar: FC<StepProps> = ({ className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setValue, watch, trigger, errors, setStep } =
    useOutletContext<OutletContextAddRentCarType>();

  // Prev step required fields
  const prevStepRequiredFields: (keyof AddRentCarDataType)[] = [
    "interiorColor",
    "seatMaterial",
    "seating",
  ];

  // Current step required fields
  const requiredFields: (keyof AddRentCarDataType)[] = [
    "engine",
    "transmission",
    "cylinders",
    "fuelType",
  ];

  const handleBack = () => {
    navigate("../step-two");
    setStep((prev) => prev - 1);
  };

  const handleNext = async () => {
    const isValid = await trigger(requiredFields, { shouldFocus: true });
    if (isValid) {
      navigate("../step-four");
      setStep((prev) => prev + 1);
    }
  };

  // Redirect to prev step
  useEffect(() => {
    const redirectToPrevStep = async () => {
      const isPrevStepValid = await trigger(prevStepRequiredFields, {
        shouldFocus: true,
      });
      if (!isPrevStepValid) {
        navigate("../step-two");
      }
    };
    redirectToPrevStep();
  }, [navigate]);

  return (
    <div id="step-three-add-car">
      <TechnicalSpecsRent setValue={setValue} watch={watch} errors={errors} />
      {className !== "hidden" && (
        <div className="btns">
          <button className="back" type="button" onClick={handleBack}>
            {t("btns&links.back")}
          </button>
          <button className="next" type="button" onClick={handleNext}>
            {t("btns&links.next")}
          </button>
        </div>
      )}
    </div>
  );
};

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const CloseMenuIcon = (props: Props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M5.51471 22.5L22.4853 5.52944"
        stroke="#28282B"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M5.51471 5.5L22.4853 22.4706"
        stroke="#28282B"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export { CloseMenuIcon };

import "./Plates.css";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { sortData } from "../../data/SortData";
import { useGetAllPlatesQuery } from "../../app/api/platesApiSlice";
import { PlateCard, PlateType } from "../../components/cards/PlateCard";
import { SortDropDown } from "../../components/customs/sort drop down/SortDropDown";
import { MobileSort } from "../../components/customs/mobile sort/MobileSort";
import { Loading } from "../../components/customs/loading/Loading";
import { SEO } from "../../utilities/SEO";
import { PlateCardSkeleton } from "../../components/skeleton/PlateCardSkeleton";
import { SortIcon } from "../../assets/svg/sortIcon";
import { motion } from "framer-motion";

type getPlatesDataType = {
  data: { plates: PlateType[]; pageCount: number };
  isSuccess: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  error: any;
};

export const Plates = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState<number>(
    Number(searchParams.get("page")) || 1
  );
  const limit = 10;

  const [sortOpen, setSortOpen] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<string>(
    searchParams.get("sort") || ""
  );

  // Change page number
  const changePage = ({ selected }: { selected: number }) => {
    setPageNumber(selected + 1);
    window.scrollTo(0, 0);
  };

  // Get plates
  const {
    data: plates,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetAllPlatesQuery({
    page: pageNumber,
    limit,
    sort: selectedSort,
  }) as getPlatesDataType;

  // Set page number and sort to url
  useEffect(() => {
    setSearchParams((prev) => {
      pageNumber && prev.set("page", pageNumber.toString());
      selectedSort && prev.set("sort", selectedSort);
      return prev;
    });
  }, [pageNumber, selectedSort]);

  // Reset page number when sort changes
  useEffect(() => {
    setPageNumber(1);
  }, [selectedSort]);

  // Set page number to the last page when i leave the page
  useEffect(() => {
    const savedPageNumber = Number(searchParams.get("page")) || 1;
    setPageNumber(savedPageNumber > 1 ? savedPageNumber : 1);
  }, []);

  return (
    <>
      {/* SEO */}
      <SEO title={t("seo.platesTitle")} />
      {/* Hero Section */}
      <section id="p-hero">
        {/* Mobile UI tab for sort */}
        <div className="mobile">
          <div className="btn">
            <button
              onClick={() => setSortOpen(!sortOpen)}
              aria-label={t("btns&links.sort").toString()}
            >
              <SortIcon />
              {t("btns&links.sort")}
            </button>
          </div>
        </div>
        {/* Header */}
        <div className="plates-header">
          <h1>{t("numberPlates.heading")}</h1>
          {/* Sort drop-down */}
          <div>
            <SortDropDown
              options={sortData.sortPlates}
              placeholder="Sort By"
              setSelectedSort={setSelectedSort}
              selectedSort={selectedSort}
            />
          </div>
        </div>
        {/* Plates cards */}
        <div className="plates">
          {isSuccess && plates.plates?.length < 1 && (
            <p className="no-results">{t("numberPlates.noResults")}</p>
          )}
          {isLoading || isFetching ? (
            <div className="cards">
              {Array.from({ length: 10 }).map((_, index) => (
                <PlateCardSkeleton key={index} />
              ))}
            </div>
          ) : isSuccess && plates.plates?.length > 0 ? (
            <div className="cards">
              {plates.plates.map((plate: PlateType, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, transform: "translateY(40px)" }}
                  whileInView={{
                    opacity: 1,
                    transform: "none",
                    transition: { delay: index * 0.015 },
                  }}
                  viewport={{ once: true }}
                >
                  <PlateCard key={plate._id} data={plate} />
                </motion.div>
              ))}
            </div>
          ) : (
            isError && (
              <p className="fetch-error">
                {error.status} <br />
                {JSON.stringify(error.data)}
              </p>
            )
          )}
          {/* Pagination */}
          {isSuccess && plates.pageCount > 0 && (
            <ReactPaginate
              previousLabel={t("btns&links.previous")}
              nextLabel={t("btns&links.next")}
              pageCount={plates.pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBtns"}
              previousLinkClassName={"previousBtn"}
              nextLinkClassName={"nextBtn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
              forcePage={pageNumber - 1}
              pageRangeDisplayed={3}
              breakLabel={"..."}
            />
          )}
        </div>
      </section>
      {/* Mobile UI for sort */}
      {sortOpen && (
        <MobileSort
          sort={sortData.sortPlates}
          setSortOpen={setSortOpen}
          setSelectedSort={setSelectedSort}
          selectedSort={selectedSort}
        />
      )}
    </>
  );
};

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const CompressIcon = (props: Props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2057_2)">
      <path
        d="M7.29167 0C7.0154 0 6.75045 0.109747 6.5551 0.305097C6.35975 0.500447 6.25 0.765399 6.25 1.04167V3.125C6.25 3.9538 5.92076 4.74866 5.33471 5.33471C4.74866 5.92076 3.9538 6.25 3.125 6.25H1.04167C0.765399 6.25 0.500447 6.35975 0.305097 6.5551C0.109747 6.75045 0 7.0154 0 7.29167C0 7.56793 0.109747 7.83289 0.305097 8.02824C0.500447 8.22359 0.765399 8.33333 1.04167 8.33333H3.125C4.50583 8.33168 5.82963 7.78241 6.80602 6.80602C7.78241 5.82963 8.33168 4.50583 8.33333 3.125V1.04167C8.33333 0.765399 8.22359 0.500447 8.02824 0.305097C7.83289 0.109747 7.56793 0 7.29167 0Z"
        fill="white"
      />
      <path
        d="M23.9583 16.6667H21.875C20.4942 16.6683 19.1704 17.2176 18.194 18.194C17.2176 19.1704 16.6683 20.4942 16.6667 21.875V23.9584C16.6667 24.2346 16.7764 24.4996 16.9718 24.6949C17.1671 24.8903 17.4321 25 17.7083 25C17.9846 25 18.2495 24.8903 18.4449 24.6949C18.6402 24.4996 18.75 24.2346 18.75 23.9584V21.875C18.75 21.0462 19.0792 20.2514 19.6653 19.6653C20.2513 19.0793 21.0462 18.75 21.875 18.75H23.9583C24.2346 18.75 24.4995 18.6403 24.6949 18.4449C24.8902 18.2496 25 17.9846 25 17.7084C25 17.4321 24.8902 17.1671 24.6949 16.9718C24.4995 16.7764 24.2346 16.6667 23.9583 16.6667Z"
        fill="white"
      />
      <path
        d="M21.875 8.33333H23.9583C24.2346 8.33333 24.4995 8.22359 24.6949 8.02824C24.8902 7.83289 25 7.56793 25 7.29167C25 7.0154 24.8902 6.75045 24.6949 6.5551C24.4995 6.35975 24.2346 6.25 23.9583 6.25H21.875C21.0462 6.25 20.2513 5.92076 19.6653 5.33471C19.0792 4.74866 18.75 3.9538 18.75 3.125V1.04167C18.75 0.765399 18.6402 0.500447 18.4449 0.305097C18.2495 0.109747 17.9846 0 17.7083 0C17.4321 0 17.1671 0.109747 16.9718 0.305097C16.7764 0.500447 16.6667 0.765399 16.6667 1.04167V3.125C16.6683 4.50583 17.2176 5.82963 18.194 6.80602C19.1704 7.78241 20.4942 8.33168 21.875 8.33333Z"
        fill="white"
      />
      <path
        d="M3.125 16.6667H1.04167C0.765399 16.6667 0.500447 16.7764 0.305097 16.9718C0.109747 17.1671 0 17.4321 0 17.7084C0 17.9846 0.109747 18.2496 0.305097 18.4449C0.500447 18.6403 0.765399 18.75 1.04167 18.75H3.125C3.9538 18.75 4.74866 19.0793 5.33471 19.6653C5.92076 20.2514 6.25 21.0462 6.25 21.875V23.9584C6.25 24.2346 6.35975 24.4996 6.5551 24.6949C6.75045 24.8903 7.0154 25 7.29167 25C7.56793 25 7.83289 24.8903 8.02824 24.6949C8.22359 24.4996 8.33333 24.2346 8.33333 23.9584V21.875C8.33168 20.4942 7.78241 19.1704 6.80602 18.194C5.82963 17.2176 4.50583 16.6683 3.125 16.6667Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={25} height={25} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { CompressIcon };

import "./StepSixAddCar.css";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { OutletContextAddCarType, StepProps } from "./StepOneAddCar";
import { AdditionalInfo } from "../../edit car/sections/additional info/AdditionalInfo";
import { AddCarDataType } from "../AddCar";

export const StepSixAddCar: FC<StepProps> = ({ className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, setValue, watch, trigger, setStep } =
    useOutletContext<OutletContextAddCarType>();

  // Prev step required fields
  const prevStepRequiredFields: (keyof AddCarDataType)[] = [
    "priceUnit",
    "price",
    "isPriceShown",
  ];

  const handleBack = () => {
    navigate("../step-five");
    setStep(5);
  };

  const handleNext = async () => {
    navigate("../step-seven");
    setStep(7);
  };

  // Redirect to prev step
  useEffect(() => {
    const redirectToPrevStep = async () => {
      const isPrevStepValid = await trigger(prevStepRequiredFields, {
        shouldFocus: true,
      });
      if (!isPrevStepValid) {
        navigate("../step-five");
      }
    };
    redirectToPrevStep();
  }, [navigate]);

  return (
    <div id="step-six-add-car">
      <AdditionalInfo register={register} setValue={setValue} watch={watch} />
      {className !== "hidden" && (
        <div className="btns">
          <button className="back" type="button" onClick={handleBack}>
            {t("btns&links.back")}
          </button>
          <button className="next" type="button" onClick={handleNext}>
            {t("btns&links.next")}
          </button>
        </div>
      )}
    </div>
  );
};

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const CloseIcon = (props: Props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1408_333)">
      <path
        d="M17.6013 0.412344C17.475 0.285782 17.325 0.185365 17.1598 0.116855C16.9946 0.0483447 16.8175 0.0130796 16.6388 0.0130796C16.46 0.0130796 16.2829 0.0483447 16.1178 0.116855C15.9526 0.185365 15.8026 0.285782 15.6763 0.412344L9.00003 7.07497L2.32375 0.398691C2.19735 0.272293 2.04729 0.172026 1.88213 0.103612C1.71699 0.0352108 1.53998 0 1.36122 0C1.18246 0 1.00545 0.0352108 0.840307 0.103612C0.675148 0.172026 0.525089 0.272293 0.398691 0.398691C0.272293 0.525089 0.172026 0.675148 0.103612 0.840307C0.0352108 1.00545 0 1.18246 0 1.36122C0 1.53998 0.0352108 1.71699 0.103612 1.88213C0.172026 2.04729 0.272293 2.19735 0.398691 2.32375L7.07497 9.00003L0.398691 15.6763C0.272293 15.8027 0.172026 15.9528 0.103612 16.1178C0.0352108 16.283 0 16.46 0 16.6388C0 16.8175 0.0352108 16.9946 0.103612 17.1597C0.172026 17.3249 0.272293 17.4749 0.398691 17.6013C0.525089 17.7278 0.675148 17.828 0.840307 17.8964C1.00545 17.9648 1.18246 18 1.36122 18C1.53998 18 1.71699 17.9648 1.88213 17.8964C2.04729 17.828 2.19735 17.7278 2.32375 17.6013L9.00003 10.9251L15.6763 17.6013C15.8027 17.7278 15.9528 17.828 16.1178 17.8964C16.283 17.9648 16.46 18 16.6388 18C16.8175 18 16.9946 17.9648 17.1597 17.8964C17.3249 17.828 17.4749 17.7278 17.6013 17.6013C17.7278 17.4749 17.828 17.3249 17.8964 17.1597C17.9648 16.9946 18 16.8175 18 16.6388C18 16.46 17.9648 16.283 17.8964 16.1178C17.828 15.9528 17.7278 15.8027 17.6013 15.6763L10.9251 9.00003L17.6013 2.32375C18.1201 1.80494 18.1201 0.931153 17.6013 0.412344Z"
        fill={props.color || "var(--primary-900)"}
      />
    </g>
    <defs>
      <clipPath>
        <rect width={18} height={18} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { CloseIcon };

import "./ForgotPass.css";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { SEO } from "../../../utilities/SEO";
import { useTranslation } from "react-i18next";

export const ForgotPass = () => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isVerified, setIsVerified] = useState<boolean>(false);

  return (
    <>
      <SEO title={t("forgotPassword.stepOne.heading") + " - Noyya"} />
      <div id="forgot-pass">
        <div className="container">
          <Outlet
            context={{
              otp,
              setOtp,
              phoneNumber,
              setPhoneNumber,
              isVerified,
              setIsVerified,
            }}
          />
        </div>
      </div>
    </>
  );
};

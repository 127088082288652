import { useTranslation } from "react-i18next";
import { SEO } from "../../utilities/SEO";
import "./PrivacyPolicy.css";
export const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <div id="privacy-policy">
      {/* SEO */}
      <SEO
        title={t("seo.privacyTitle")}
        description={t("seo.homeDescription").toString()}
      />

      <div className="container">
        <h1>Privacy Policy</h1>
        <p>Last Updated: January 11, 2024</p>
        <p>
          Welcome to Noyya! This Privacy Policy outlines how we collect, use,
          and protect the information you provide on our car marketplace
          website.
        </p>

        <section>
          <h2>1. Information We Collect</h2>
          <h3>1.1 User Registration Information</h3>
          <p>
            During the registration process, car showrooms provide information
            such as business name, phone number, email address, and other
            details necessary for account creation.
          </p>
          <h3>1.2 Vehicle Listings Information</h3>
          <p>
            Car showrooms provide vehicle details, pricing information, images,
            and descriptions as part of their listings on Noyya.
          </p>
        </section>

        <section>
          <h2>2. How We Use Your Information</h2>
          <p>
            <strong>2.1 Account Management:</strong> User registration
            information is used for managing and facilitating the car showroom's
            account on Noyya.
          </p>
          <p>
            <strong>2.2 Listing Display:</strong> Vehicle listings information
            is displayed on Noyya to connect car showrooms with potential
            customers.
          </p>
          <p>
            <strong>2.3 Communication:</strong> We may use the provided contact
            information to communicate with car showrooms regarding
            account-related updates, inquiries, and promotional materials.
          </p>
        </section>
        <section>
          <h2>3. Information Sharing</h2>
          <p>
            <strong>3.1 With Users:</strong> Vehicle listings provided by car
            showrooms are made public on Noyya for users to browse and engage
            with.
          </p>
          <p>
            <strong>3.2 Legal Requirements:</strong> We may disclose information
            in response to lawful requests or legal processes.
          </p>
        </section>

        <section>
          <h2>4. Cookies and Tracking Technologies</h2>
          <p>
            Noyya uses cookies to enhance user experience and track website
            analytics. Users can manage cookie preferences through their browser
            settings.
          </p>
        </section>

        <section>
          <h2>5. Data Security</h2>
          <p>
            We implement security measures to protect user information,
            including encryption, access controls, and regular security audits.
          </p>
        </section>

        <section>
          <h2>6. User Rights</h2>
          <p>
            Users have the right to access, correct, or delete their personal
            information. Car showrooms can manage their account details through
            the Noyya platform.
          </p>
        </section>

        <section>
          <h2>7. Third-Party Links</h2>
          <p>
            Noyya may contain links to third-party websites. We are not
            responsible for the privacy practices or content of these websites.
          </p>
        </section>

        <section>
          <h2>8. Changes to this Privacy Policy</h2>
          <p>
            We reserve the right to update this Privacy Policy. Any changes will
            be effective upon posting the revised policy on our website.
          </p>
        </section>

        <section>
          <h2>9. Contact Information</h2>
          <p>
            For any questions or concerns regarding this Privacy Policy, contact
            us at <a href="mailto:info@noyya.net">info@noyya.net</a>.
          </p>
        </section>
      </div>
    </div>
  );
};

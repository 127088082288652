import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const MenuIcon = (props: Props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1938_214)">
      <path
        d="M17.25 8.25H0.75C0.335786 8.25 0 8.58579 0 9C0 9.41421 0.335786 9.75 0.75 9.75H17.25C17.6642 9.75 18 9.41421 18 9C18 8.58579 17.6642 8.25 17.25 8.25Z"
        fill="#28282B"
      />
      <path
        d="M17.25 3H0.75C0.335786 3 0 3.33579 0 3.75C0 4.16421 0.335786 4.5 0.75 4.5H17.25C17.6642 4.5 18 4.16421 18 3.75C18 3.33579 17.6642 3 17.25 3Z"
        fill="#28282B"
      />
      <path
        d="M17.25 13.5H0.75C0.335786 13.5 0 13.8358 0 14.25C0 14.6642 0.335786 15 0.75 15H17.25C17.6642 15 18 14.6642 18 14.25C18 13.8358 17.6642 13.5 17.25 13.5Z"
        fill="#28282B"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={18} height={18} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { MenuIcon };

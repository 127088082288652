import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useState,
  KeyboardEvent,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSendOtpResetPassMutation } from "../../../../app/api/showroomApiSlice";
import flagSrc from "../../../../assets/svg/iraqFlag.svg";

export type ForgotPassOutletContextType = {
  otp: string;
  setOtp: Dispatch<SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  isVerified: boolean;
  setIsVerified: Dispatch<SetStateAction<boolean>>;
};

export const ForgotStepOne = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setPhoneNumber, phoneNumber } =
    useOutletContext<ForgotPassOutletContextType>();

  const [errorMessage, setErrorMessage] = useState<string>("");

  // Send OTP reset pass mutation
  const [sendOtpResetPass, { isSuccess, isLoading, error }] =
    useSendOtpResetPassMutation();

  const phonePattern = /^(07\d{9}|7\d{9})$/;
  // Validate phone number
  const phoneValidation = (phoneNumber: string) => {
    if (phoneNumber === "") {
      setErrorMessage(t("errorMessage.phoneNumber") as string);
      return false;
    }
    if (!phonePattern.test(phoneNumber)) {
      setErrorMessage(t("errorMessage.phoneNumberMatch") as string);
      return false;
    }
    if (phonePattern.test(phoneNumber)) {
      setErrorMessage("");
      return true;
    }
    return false;
  };

  // Handle send OTP
  const sendOtp = async (phoneNumber: string) => {
    if (phoneNumber[0] === "0") {
      phoneNumber = phoneNumber.slice(1);
    }
    try {
      await sendOtpResetPass({
        phoneNumber: phoneNumber,
      }).unwrap();
      navigate(`otp`);
    } catch (error) {
      console.log(error);
    }
  };

  // Handle phone number input
  const onChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    const firstChar = e.target.value[0];
    if (firstChar !== "0" && e.target.value.length > 10)
      e.target.value = e.target.value.slice(0, 10);
    if (firstChar === "0" && e.target.value.length > 11)
      e.target.value = e.target.value.slice(0, 11);
    let formattedNumber = "";
    if (firstChar === "0") {
      formattedNumber = e.target.value.slice(1);
    } else {
      formattedNumber = e.target.value;
    }
    // if number is arabic convert it to english
    formattedNumber = formattedNumber.replace(/[\u0660-\u0669]/g, (c) => {
      return String.fromCharCode(c.charCodeAt(0) - 0x0660 + 48).toString();
    });

    setPhoneNumber(formattedNumber);
    phoneValidation(formattedNumber);
    // setValue(`phoneNumber`, formattedNumber, { shouldValidate: true });
  };

  // Only allow numbers
  const onKeyDownPhone = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 46
      )
    )
      e.preventDefault();
  };

  return (
    <section>
      <header>
        <p className="title">{t("forgotPassword.stepOne.heading")}</p>
        <p>{t("forgotPassword.stepOne.desc")}</p>
      </header>
      <form>
        <div className="row">
          <div className="phone-input-container">
            <div className="country">
              <img src={flagSrc} alt="iraq flag" />
              <p>{t("countryCode")}</p>
            </div>
            <div className="phone-input-group">
              <input
                placeholder=" "
                type="tel"
                onKeyDown={onKeyDownPhone}
                onChange={onChangePhone}
              />
              <label>{t("inputPlaceholder.phoneNumber")}</label>
            </div>
          </div>
          {errorMessage && <p className="error">{errorMessage}</p>}
        </div>
        <button
          className="primary-btn"
          type="button"
          disabled={isLoading}
          onClick={() => {
            if (phoneValidation(phoneNumber)) {
              sendOtp(phoneNumber);
            }
          }}
          aria-label={t("btns&links.sendCode").toString()}
        >
          {isLoading ? t("loading") : t("btns&links.sendCode")}
        </button>
      </form>
    </section>
  );
};

import "./PlateTable.css";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PlateType } from "../cards/PlateCard";
import {
  formatCurrency,
  iqdFormatCurrency,
} from "../../utilities/formatCurrency";

type Props = {
  thead: string[];
  plates: PlateType[];
  editPath: string;
  Delete: (id: string) => any;
  isDeleteLoading: boolean;
};

export const PlateTable: FC<Props> = ({
  thead,
  plates,
  editPath,
  Delete,
  isDeleteLoading,
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <div id="plate-table">
      <table>
        <thead>
          <tr>
            {thead.map((title) => {
              return <th key={title}>{title}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {plates.map((plate: PlateType) => (
            <tr key={plate._id}>
              <td className="td-1">
                <p className="plate-number">
                  {plate.plateNumber}&nbsp;
                  {plate.plateLetter}
                </p>
                <p className="plate-city">
                  {t(`plateCategory.${plate.plateCategory}`) + ","}&nbsp;
                  {t(`city.${plate.plateCity}`)}
                </p>
              </td>
              <td className="price">
                {plate.priceUnit === "usd"
                  ? formatCurrency(plate.price)
                  : iqdFormatCurrency(plate.price)}
              </td>
              <td>
                {plate.status && (
                  <p
                    className={`status ${
                      plate.status === "sold" ? "sold" : "available"
                    }`}
                  >
                    {t(`showroomManagement.plateTable.status.${plate.status}`)}
                  </p>
                )}
              </td>
              <td>
                {i18n.language !== "en"
                  ? new Date(plate.createdAt).toLocaleDateString("ar-IQ", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })
                  : new Date(plate.createdAt).toLocaleDateString("en-GB")}
              </td>
              <td>
                <div className="action-btns">
                  <button
                    className="edit"
                    onClick={() => {
                      navigate(editPath + plate._id);
                    }}
                  >
                    {t("btns&links.edit")}
                  </button>
                  <button
                    disabled={isDeleteLoading}
                    className="delete"
                    onClick={async () => await Delete(plate._id).unwrap()}
                  >
                    {t("btns&links.delete")}
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

import { apiSlice } from "./apiSlice";

export const soldCarApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSoldCars: builder.query({
      query: ({ page, limit }) => `soldCars?page=${page}&limit=${limit}`,
      providesTags: ["SoldCars", "SaleCars"],
    }),
    getSoldCarsByShowroom: builder.query({
      query: ({
        showroomId,
        filter,
      }) => `soldCars/s/${showroomId}?filter=${filter}
      `,
      providesTags: ["SoldCars", "SaleCars"],
    }),
  }),
});

export const { useGetAllSoldCarsQuery, useGetSoldCarsByShowroomQuery } =
  soldCarApiSlice;

import "./CarListings.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import { useDeleteSaleCarByIdMutation } from "../../../../../../../app/api/saleCarsApiSlice";
import {
  useShowroom,
  useShowroomCars,
} from "../../../../../../../hooks/useAuth";
import { CarTable } from "../../../../../../../components/car listings table/CarTable";
import { PreviousIcon } from "../../../../../../../assets/svg/previousIcon";
import { NextIcon } from "../../../../../../../assets/svg/nextIcon";
import { Loading } from "../../../../../../../components/customs/loading/Loading";

export const CarListings = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { showroom } = useShowroom();
  const [pageNumber, setPageNumber] = useState(1);
  const limit = 20;

  // Sale cars table heading
  const saleCarsTableHeading = [
    t("showroomManagement.carTable.car"),
    t("showroomManagement.carTable.price"),
    t("showroomManagement.carTable.status.heading"),
    t("showroomManagement.carTable.listedAt"),
    t("showroomManagement.carTable.actions"),
  ];

  // Delete sale car mutation
  const [Delete, { isLoading: isDeleteLoading, isSuccess: isDeleteSuccess }] =
    useDeleteSaleCarByIdMutation();

  // Get showroom cars
  const {
    cars,
    isSuccess: isCarsDataSuccess,
    isLoading: isCarsDataLoading,
    isFetching: isCarsDataFetching,
  } = useShowroomCars(
    showroom?._id,
    pageNumber,
    limit,
    "",
    false,
    showroom?.showroomType
  );

  // Change page
  const changePage = ({ selected }: { selected: number }) => {
    setPageNumber(selected + 1);
    window.scrollTo(0, 0);
  };

  // Edit car toast success
  useEffect(() => {
    if (location.state && location.state.carAdded) {
      toast.success(location.state.carAdded);
      location.state = {};
    }
    if (location.state && location.state.carUpdated) {
      toast.success(location.state.carUpdated);
      location.state = {};
    }
  }, [location.state]);

  // Delete car toast success
  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success(t("alert.carDeleted"));
    }
  }, [Delete, isDeleteSuccess]);

  return (
    <div id="car-listings">
      <ToastContainer />
      {isCarsDataLoading || isCarsDataFetching ? (
        <Loading />
      ) : isCarsDataSuccess && cars.saleCars?.length > 0 ? (
        <CarTable
          thead={saleCarsTableHeading}
          cars={cars?.saleCars}
          carPath={`/${i18n.language}/cars/details/`}
          editPath="../../edit-car/"
          Delete={Delete}
          isDeleteLoading={isDeleteLoading}
        />
      ) : (
        <p className="no-cars">
          {t("showroomManagement.inventory.carListings.noCars")}
        </p>
      )}
      <footer>
        {isCarsDataSuccess && cars.saleCars?.length > 0 && (
          <p>
            {t("number", { count: cars?.currentRangeStart })}-
            {t("number", { count: cars?.currentRangeEnd })}
            &nbsp;{t("showroomManagement.inventory.carListings.of")}&nbsp;
            {t("number", { count: cars?.totalCars })}
            &nbsp;{t("showroomManagement.inventory.carListings.listings")}
          </p>
        )}
        {/* Pagination */}
        {isCarsDataSuccess && cars.pageCount > 0 && (
          <ReactPaginate
            previousLabel={<PreviousIcon height={10} />}
            nextLabel={<NextIcon height={10} />}
            pageCount={cars.pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBtns"}
            previousLinkClassName={"previousBtn"}
            nextLinkClassName={"nextBtn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            forcePage={Number(pageNumber) - 1}
            pageRangeDisplayed={3}
            breakLabel={"..."}
          />
        )}
      </footer>
    </div>
  );
};

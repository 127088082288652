import "./SocialMedia.css";
import { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useUpdateShowroomByIdMutation } from "../../../../../../app/api/showroomApiSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useShowroom } from "../../../../../../hooks/useAuth";
import { ShowroomType } from "../../../../../../components/cards/ShowroomCard";
import { FacebookIcon } from "../../../../../../assets/svg/facebookIcon";
import { InstagramIcon } from "../../../../../../assets/svg/instagramIcon";
import { LinkedinIcon } from "../../../../../../assets/svg/linkedinIcon";
import { SnapchatIcon } from "../../../../../../assets/svg/snapchatIcon";
import { TiktokIcon } from "../../../../../../assets/svg/tiktokIcon";
import { YoutubeIcon } from "../../../../../../assets/svg/youtubeIcon";
import { CheckSolidIcon } from "../../../../../../assets/svg/checkSolidIcon";
import { TwitterXIcon } from "../../../../../../assets/svg/twitterXIcon";

type socialMediaType = {
  socialMedia: ShowroomType["socialMedia"];
};

export const SocialMedia = () => {
  const { t } = useTranslation();
  const linkT = (key: string) => t(`inputPlaceholder.${key}`);
  const { showroom } = useShowroom();
  const validateUrl = t("errorMessage.url");

  // Update showroom mutation
  const [Update, { isLoading, isSuccess: isUpdateSuccess }] =
    useUpdateShowroomByIdMutation();

  // Social media schema
  const socialMediaSchema = yup.object().shape({
    socialMedia: yup.object().shape({
      facebook: yup.string().url(validateUrl) as yup.StringSchema<string>,
      instagram: yup.string().url(validateUrl) as yup.StringSchema<string>,
      twitter: yup.string().url(validateUrl) as yup.StringSchema<string>,
      linkedin: yup.string().url(validateUrl) as yup.StringSchema<string>,
      tiktok: yup.string().url(validateUrl) as yup.StringSchema<string>,
      snapchat: yup.string().url(validateUrl) as yup.StringSchema<string>,
      youtube: yup.string().url(validateUrl) as yup.StringSchema<string>,
    }),
  });

  // Form hook
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    unregister,
  } = useForm<socialMediaType>({
    resolver: yupResolver(socialMediaSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<socialMediaType> = async (data) => {
    // do not send undefined values
    Object.keys(data.socialMedia).forEach((key) => {
      if (
        data.socialMedia.hasOwnProperty(key as keyof typeof data.socialMedia) &&
        data.socialMedia[key as keyof typeof data.socialMedia] === undefined
      ) {
        unregister(`socialMedia.${key as keyof typeof data.socialMedia}`);
      }
    });

    try {
      await Update({
        id: showroom._id,
        credentials: data,
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  // Set showroom data to form
  useEffect(() => {
    if (showroom) {
      reset({
        socialMedia: showroom.socialMedia,
      });
    }
  }, [showroom]);

  return (
    <div id="social-media">
      {isUpdateSuccess && (
        <div className="row">
          <div className="success">
            {<CheckSolidIcon fill={"var(--green-600)"} />}
            <p>{t("alert.changesSaved")}</p>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="input-container">
              <div className="icon">
                <FacebookIcon fill="var(--primary-900)" />
              </div>
              <div className="input-group">
                <input placeholder=" " {...register("socialMedia.facebook")} />
                <label>{linkT("facebook")}</label>
              </div>
            </div>
            {errors.socialMedia?.facebook && (
              <p className="error">{errors.socialMedia?.facebook.message}</p>
            )}
          </div>
          <div className="col">
            <div className="input-container">
              <div className="icon">
                <InstagramIcon fill="var(--primary-900)" />
              </div>
              <div className="input-group">
                <input placeholder=" " {...register("socialMedia.instagram")} />
                <label>{linkT("instagram")}</label>
              </div>
            </div>
            {errors.socialMedia?.instagram && (
              <p className="error">{errors.socialMedia?.instagram.message}</p>
            )}
          </div>
          <div className="col">
            <div className="input-container">
              <div className="icon">
                <TwitterXIcon color="var(--primary-900)" fill="#fff" />
              </div>
              <div className="input-group">
                <input placeholder=" " {...register("socialMedia.twitter")} />
                <label>{linkT("twitter")}</label>
              </div>
            </div>
            {errors.socialMedia?.twitter && (
              <p className="error">{errors.socialMedia?.twitter.message}</p>
            )}
          </div>
          <div className="col">
            <div className="input-container">
              <div className="icon">
                <LinkedinIcon fill="var(--primary-900)" />
              </div>
              <div className="input-group">
                <input placeholder=" " {...register("socialMedia.linkedin")} />
                <label>{linkT("linkedin")}</label>
              </div>
            </div>
            {errors.socialMedia?.linkedin && (
              <p className="error">{errors.socialMedia?.linkedin.message}</p>
            )}
          </div>
          <div className="col">
            <div className="input-container">
              <div className="icon">
                <SnapchatIcon fill="var(--primary-900)" />
              </div>
              <div className="input-group">
                <input placeholder=" " {...register("socialMedia.snapchat")} />
                <label>{linkT("snapchat")}</label>
              </div>
            </div>
            {errors.socialMedia?.snapchat && (
              <p className="error">{errors.socialMedia?.snapchat.message}</p>
            )}
          </div>
          <div className="col">
            <div className="input-container">
              <div className="icon">
                <TiktokIcon fill="var(--primary-900)" />
              </div>
              <div className="input-group">
                <input placeholder=" " {...register("socialMedia.tiktok")} />
                <label>{linkT("tiktok")}</label>
              </div>
            </div>
            {errors.socialMedia?.tiktok && (
              <p className="error">{errors.socialMedia?.tiktok.message}</p>
            )}
          </div>
          <div className="col">
            <div className="input-container">
              <div className="icon">
                <YoutubeIcon fill="var(--primary-900)" />
              </div>
              <div className="input-group">
                <input placeholder=" " {...register("socialMedia.youtube")} />
                <label>{linkT("youtube")}</label>
              </div>
            </div>
            {errors.socialMedia?.youtube && (
              <p className="error">{errors.socialMedia?.youtube.message}</p>
            )}
          </div>
        </div>

        <button disabled={isLoading} type="submit">
          {isLoading ? t("loading") : t("btns&links.saveChanges")}
        </button>
      </form>
    </div>
  );
};

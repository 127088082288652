import "./Analytics.css";
import { Outlet } from "react-router-dom";
import { AnalyticsTabs } from "../tabs/analytics tabs/AnalyticsTabs";
import { useTranslation } from "react-i18next";

export const Analytics = () => {
  const { t } = useTranslation();

  return (
    <div id="analytics">
      <header>
        <p className="analytics-heading">
          {t("showroomManagement.analytics.heading")}
        </p>
      </header>
      <AnalyticsTabs />
      <Outlet />
    </div>
  );
};

import "./Register.css";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRegisterShowroomMutation } from "../../../app/api/showroomApiSlice";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { cityType } from "../../../components/cards/ShowroomCard";
import logoSrc from "../../../assets/images/NoyyaBlack36.png";
import { CloseIcon } from "../../../assets/svg/closeIcon";
import { BackIcon } from "../../../assets/svg/backIcon";
import { SEO } from "../../../utilities/SEO";

export type RegisterDataType = {
  name: string;
  nameInAr: string;
  nameInKu: string;
  email?: string;
  password: string;
  confirmPassword: string;
  showroomType: string;
  phoneNumber: string;
  website?: string;
  description?: string;
  city: cityType;
  latLng: {
    lat: number;
    lng: number;
  };
  images: FileList;
  primaryImageIndex?: number;
  logoImageIndex: number;
  openingDays?: {
    saturday: {
      opening: "open" | "closed" | string;
      openTime?: string;
      closeTime?: string;
    };
    sunday: {
      opening: "open" | "closed" | string;
      openTime?: string;
      closeTime?: string;
    };
    monday: {
      opening: "open" | "closed" | string;
      openTime?: string;
      closeTime?: string;
    };
    tuesday: {
      opening: "open" | "closed" | string;
      openTime?: string;
      closeTime?: string;
    };
    wednesday: {
      opening: "open" | "closed" | string;
      openTime?: string;
      closeTime?: string;
    };
    thursday: {
      opening: "open" | "closed" | string;
      openTime?: string;
      closeTime?: string;
    };

    friday: {
      opening: "open" | "closed" | string;
      openTime?: string;
      closeTime?: string;
    };
  };
  dealershipType?: string;
  iqdValue: number;
  isVerified?: boolean;
};

const phonePattern = /^(07\d{9}|7\d{9})$/;

export const Register = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const isInclude =
    location.pathname.includes("step") || location.pathname.includes("otp");
  const navigate = useNavigate();
  const [registerShowroom, { isLoading: isRegisterLoading, error }] =
    useRegisterShowroomMutation();

  // Register schema
  const registerSchema = yup.object().shape({
    name: yup.string().required(t("errorMessage.showroomName") as string),
    nameInAr: yup
      .string()
      .required(t("errorMessage.showroomNameInAr") as string),
    nameInKu: yup
      .string()
      .required(t("errorMessage.showroomNameInKu") as string),
    password: yup
      .string()
      .min(8, t("errorMessage.passwordLength") as string)
      .required(t("errorMessage.password") as string),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], t("errorMessage.passwordsMatch") as string)
      .required(t("errorMessage.confirmPassword") as string),
    showroomType: yup
      .string()
      .required(t("errorMessage.showroomType") as string),
    // website: yup
    //   .string()
    //   .url("errorMessage.websiteUrl" as string) as yup.StringSchema<string>,
    city: yup.object().shape({
      name: yup.string().required(t("errorMessage.city") as string),
      value: yup.object().shape({
        lat: yup.number().required(t("errorMessage.city") as string),
        lng: yup.number().required(t("errorMessage.city") as string),
      }),
    }),
    latLng: yup.object().shape({
      lat: yup.number().required(t("errorMessage.address") as string),
      lng: yup.number().required(t("errorMessage.address") as string),
    }),
    logoImageIndex: yup.number().required(t("errorMessage.logo") as string),
    // validate phone number
    phoneNumber: yup
      .string()
      .required(t("errorMessage.phoneNumber") as string)
      .matches(phonePattern, t("errorMessage.phoneNumberMatch") as string),
    images: yup
      .mixed<FileList>()
      .required(t("errorMessage.showroomImages") as string)
      // .test(
      //   "fileSize",
      //   "Only documents up to 2MB are permitted.",
      //   (files) =>
      //     !files || // Check if `files` is defined
      //     files.length === 0 || // Check if `files` is not an empty list
      //     Array.from(files).every((file) => file.size <= 2_000_000)
      // )
      .test("images", t("errorMessage.showroomImages") as string, (value) => {
        if (value) {
          return value.length >= 2;
        }
        return false;
      }),
    iqdValue: yup
      .number()
      .required()
      .typeError(t("errorMessage.iqdValue") as string),
  });

  // Form hook
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = useForm<RegisterDataType>({
    resolver: yupResolver(registerSchema),
    mode: "onChange",
    defaultValues: {
      openingDays: {
        saturday: {
          opening: "closed",
        },
        sunday: {
          opening: "closed",
        },
        monday: {
          opening: "closed",
        },
        tuesday: {
          opening: "closed",
        },
        wednesday: {
          opening: "closed",
        },
        thursday: {
          opening: "closed",
        },
        friday: {
          opening: "closed",
        },
      },
    },
  });

  // Submit handler
  const onSubmit: SubmitHandler<RegisterDataType> = async (data) => {
    const { confirmPassword, ...showroomData } = data;

    const shData = new FormData();
    shData.append(
      "isVerified",
      data.isVerified ? data.isVerified.toString() : "false"
    );
    shData.append("name", showroomData.name);
    shData.append("nameInAr", showroomData.nameInAr);
    shData.append("nameInKu", showroomData.nameInKu);
    shData.append("password", showroomData.password);
    shData.append("showroomType", showroomData.showroomType);
    shData.append("city", JSON.stringify(showroomData.city));
    shData.append("latLng", JSON.stringify(showroomData.latLng));
    shData.append("phoneNumbers", showroomData.phoneNumber);
    shData.append("iqdValue", showroomData.iqdValue.toString());

    showroomData.website && shData.append("website", showroomData.website);
    showroomData.dealershipType &&
      shData.append("dealershipType", showroomData.dealershipType);

    showroomData.description &&
      shData.append("description", showroomData.description);

    if (showroomData.images) {
      for (let i = 0; i < showroomData.images.length; i++) {
        shData.append("images", showroomData.images[i]);
      }
    }
    showroomData.primaryImageIndex &&
      shData.append(
        "primaryImageIndex",
        showroomData.primaryImageIndex.toString()
      );
    showroomData.logoImageIndex &&
      shData.append("logoImageIndex", showroomData.logoImageIndex.toString());
    showroomData.openingDays &&
      shData.append("openingDays", JSON.stringify(showroomData.openingDays));
    try {
      const { accessToken } = await registerShowroom(shData).unwrap();
      localStorage.setItem("token", accessToken);
      navigate(`/${i18n.language}/showroom-profile`, {
        state: {
          showroomRegistered: t("alert.showroomRegistered"),
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* SEO */}
      <SEO title={t("seo.registerTitle")} />

      {/* Register Section */}
      <section id="register">
        <div className="container">
          <div className="top">
            <button
              style={
                isInclude ? { visibility: "initial" } : { visibility: "hidden" }
              }
              onClick={() => navigate(-1)}
            >
              <BackIcon />
            </button>

            <Link to={".."}>
              <CloseIcon />
            </Link>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="logo">
              <img src={logoSrc} alt="Noyya logo" />
            </div>
            <Outlet
              context={{
                register,
                unregister,
                error,
                errors,
                setValue,
                watch,
                trigger,
                isRegisterLoading,
              }}
            />
          </form>
        </div>
      </section>
    </>
  );
};

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const CarConditionIcon = (props: Props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1828_28)">
      <path
        d="M15.69 7.6725L15.6825 7.6605L12.3953 2.9445C11.7645 2.04 10.7295 1.5 9.627 1.5H6C4.6065 1.5 3.3375 2.37525 2.844 3.67875L1.2465 7.89225C0.49875 8.35575 0 9.183 0 10.125V12C0 12.8273 0.67275 13.5 1.5 13.5V14.25C1.5 15.4905 2.5095 16.5 3.75 16.5C4.9905 16.5 6 15.4905 6 14.25V13.5H12V14.25C12 15.4905 13.0095 16.5 14.25 16.5C15.4905 16.5 16.5 15.4905 16.5 14.25V13.5C17.3273 13.5 18 12.8273 18 12V10.875C18 9.38625 17.031 8.1195 15.69 7.6725ZM11.7802 3.37425L14.6565 7.50075H8.25V2.25H9.627C10.485 2.25 11.2897 2.67 11.7802 3.37425ZM3.546 3.945C3.93 2.931 4.91625 2.25075 6 2.25075H7.5V7.50075H2.625C2.47425 7.50075 2.32725 7.5135 2.18325 7.53825L3.54525 3.94575L3.546 3.945ZM5.25 14.2507C5.25 15.078 4.57725 15.7507 3.75 15.7507C2.92275 15.7507 2.25 15.078 2.25 14.2507V13.5007H5.25V14.2507ZM15.75 14.2507C15.75 15.078 15.0773 15.7507 14.25 15.7507C13.4227 15.7507 12.75 15.078 12.75 14.2507V13.5007H15.75V14.2507ZM17.25 12.0007C17.25 12.414 16.914 12.7507 16.5 12.7507H1.5C1.086 12.7507 0.75 12.414 0.75 12.0007V10.1258C0.75 9.09225 1.59075 8.25075 2.625 8.25075H14.625C16.0725 8.25075 17.25 9.42825 17.25 10.8758V12.0007Z"
        fill="#28282B"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={18} height={18} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { CarConditionIcon };

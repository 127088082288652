import "./SortDropDown.css";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { camelCase } from "../../../utilities/camelCase";
import { DpdownIcon } from "../../../assets/svg/dpdownIcon";

type Props = {
  options: string[];
  placeholder: string;
  setSelectedSort?: Dispatch<SetStateAction<string>>;
  selectedSort?: string;
};

export const SortDropDown: FC<Props> = ({
  options,
  placeholder,
  setSelectedSort,
  selectedSort,
}) => {
  const { t } = useTranslation();

  const listRef = useRef<HTMLUListElement>(null);
  const headerRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const onOptionClicked = (option: string) => {
    setSelectedSort && setSelectedSort(option);
    setIsOpen(false);
  };

  const isSelected = useMemo(
    () => (option: string) => !!selectedSort && selectedSort === option,
    [selectedSort]
  );

  useEffect(() => {
    const handler = (e: Event) => {
      if (
        headerRef.current &&
        !headerRef.current.contains(e.target as HTMLInputElement)
      ) {
        setIsOpen(false);
      }
    };
    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  }, []);

  useEffect(() => {
    if (isOpen && listRef.current) {
      // Find the selected option element
      const selectedElement = listRef.current.querySelector(".selected");

      // Scroll to the selected element if found
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [isOpen, selectedSort]);

  return (
    <div id="sort">
      <div className="dd-container">
        <div
          className="dd-header"
          ref={headerRef}
          onClick={toggleDropdown}
          style={isOpen ? { borderColor: "var(--primary-900)" } : {}}
        >
          <p>{selectedSort && t(`sort.${camelCase(selectedSort)}`)}</p>
          <DpdownIcon isDpOpen={isOpen} width={14} />
        </div>
        <label
          style={
            isOpen || selectedSort
              ? {
                  top: "0.875rem",
                  fontSize: "0.75rem",
                }
              : {}
          }
        >
          {t(`dropDownPlaceholder.${camelCase(placeholder)}`)}
        </label>
        {isOpen && (
          <div className="ddl-container">
            <ul className="dd-list" ref={listRef}>
              {options.map((option: string) => (
                <li
                  className={`${isSelected(option) && "selected"} `}
                  onClick={() => onOptionClicked(option)}
                  key={option}
                >
                  {t(`sort.${camelCase(option)}`)}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

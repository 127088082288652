import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const AnalyticsIcon = (props: Props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1496_356)">
      <path
        d="M15.3333 14.6667H3.33333C2.8029 14.6667 2.29419 14.456 1.91912 14.0809C1.54405 13.7058 1.33333 13.1971 1.33333 12.6667V0.666667C1.33333 0.489856 1.2631 0.320286 1.13807 0.195262C1.01305 0.0702379 0.843478 0 0.666667 0C0.489856 0 0.320286 0.0702379 0.195262 0.195262C0.0702379 0.320286 0 0.489856 0 0.666667L0 12.6667C0.00105857 13.5504 0.352588 14.3976 0.97748 15.0225C1.60237 15.6474 2.4496 15.9989 3.33333 16H15.3333C15.5101 16 15.6797 15.9298 15.8047 15.8047C15.9298 15.6797 16 15.5101 16 15.3333C16 15.1565 15.9298 14.987 15.8047 14.8619C15.6797 14.7369 15.5101 14.6667 15.3333 14.6667Z"
        fill="#61616B"
      />
      <path
        d="M4.00001 13.3333C4.17682 13.3333 4.34639 13.2631 4.47141 13.1381C4.59644 13.0131 4.66668 12.8435 4.66668 12.6667V8.00001C4.66668 7.8232 4.59644 7.65363 4.47141 7.52861C4.34639 7.40358 4.17682 7.33334 4.00001 7.33334C3.8232 7.33334 3.65363 7.40358 3.52861 7.52861C3.40358 7.65363 3.33334 7.8232 3.33334 8.00001V12.6667C3.33334 12.8435 3.40358 13.0131 3.52861 13.1381C3.65363 13.2631 3.8232 13.3333 4.00001 13.3333Z"
        fill="#61616B"
      />
      <path
        d="M6.66666 6.66667V12.6667C6.66666 12.8435 6.73689 13.013 6.86192 13.1381C6.98694 13.2631 7.15651 13.3333 7.33332 13.3333C7.51013 13.3333 7.6797 13.2631 7.80473 13.1381C7.92975 13.013 7.99999 12.8435 7.99999 12.6667V6.66667C7.99999 6.48986 7.92975 6.32029 7.80473 6.19526C7.6797 6.07024 7.51013 6 7.33332 6C7.15651 6 6.98694 6.07024 6.86192 6.19526C6.73689 6.32029 6.66666 6.48986 6.66666 6.66667Z"
        fill="#61616B"
      />
      <path
        d="M10 8.66667V12.6667C10 12.8435 10.0702 13.013 10.1953 13.1381C10.3203 13.2631 10.4899 13.3333 10.6667 13.3333C10.8435 13.3333 11.013 13.2631 11.1381 13.1381C11.2631 13.013 11.3333 12.8435 11.3333 12.6667V8.66667C11.3333 8.48986 11.2631 8.32029 11.1381 8.19526C11.013 8.07024 10.8435 8 10.6667 8C10.4899 8 10.3203 8.07024 10.1953 8.19526C10.0702 8.32029 10 8.48986 10 8.66667Z"
        fill="#61616B"
      />
      <path
        d="M13.3333 6.00001V12.6667C13.3333 12.8435 13.4036 13.0131 13.5286 13.1381C13.6536 13.2631 13.8232 13.3333 14 13.3333C14.1768 13.3333 14.3464 13.2631 14.4714 13.1381C14.5964 13.0131 14.6667 12.8435 14.6667 12.6667V6.00001C14.6667 5.8232 14.5964 5.65363 14.4714 5.52861C14.3464 5.40358 14.1768 5.33334 14 5.33334C13.8232 5.33334 13.6536 5.40358 13.5286 5.52861C13.4036 5.65363 13.3333 5.8232 13.3333 6.00001Z"
        fill="#61616B"
      />
      <path
        d="M3.99999 5.99999C4.17679 5.99995 4.34633 5.92969 4.47132 5.80465L6.86199 3.41399C6.98906 3.29294 7.15783 3.22542 7.33332 3.22542C7.50882 3.22542 7.67759 3.29294 7.80466 3.41399L9.25266 4.86199C9.62771 5.23693 10.1363 5.44756 10.6667 5.44756C11.197 5.44756 11.7056 5.23693 12.0807 4.86199L15.8047 1.13799C15.9261 1.01225 15.9933 0.84385 15.9918 0.669052C15.9903 0.494255 15.9201 0.327046 15.7965 0.203441C15.6729 0.0798355 15.5057 0.00972286 15.3309 0.00820391C15.1561 0.00668497 14.9877 0.0738812 14.862 0.19532L11.138 3.91865C11.013 4.04363 10.8434 4.11384 10.6667 4.11384C10.4899 4.11384 10.3203 4.04363 10.1953 3.91865L8.74732 2.47132C8.37227 2.09638 7.86365 1.88575 7.33332 1.88575C6.803 1.88575 6.29438 2.09638 5.91932 2.47132L3.52866 4.86199C3.43545 4.95522 3.37198 5.074 3.34627 5.2033C3.32056 5.3326 3.33376 5.46662 3.38421 5.58842C3.43465 5.71022 3.52008 5.81433 3.62968 5.88759C3.73929 5.96084 3.86816 5.99996 3.99999 5.99999Z"
        fill="#61616B"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { AnalyticsIcon };

import "./PlateCard.css";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Canvas } from "../canvas/Canvas";
import {
  formatCurrency,
  iqdFormatCurrency,
} from "../../utilities/formatCurrency";
import { PhoneIcon } from "../../assets/svg/phoneIcon";
import { ShowroomType } from "./ShowroomCard";

export type PlateType = {
  _id: string;
  plateNumber: number;
  plateCategory: string;
  plateCity: string;
  plateLetter: string;
  priceUnit: string;
  price: number;
  isPriceShown: boolean;
  phoneNumber: string;
  status: string;
  showroom?: ShowroomType;
  createdAt: Date;
};
type PropsType = {
  data: PlateType;
};
export const PlateCard: FC<PropsType> = ({ data: plate }) => {
  const { t } = useTranslation();
  return (
    <div className="card">
      {/* Canvas */}
      <Canvas plate={plate} width={300} height={165} />
      <div className="card-footer">
        {/* Price */}
        <div className="price">
          {plate.status !== "sold" ? (
            <p>
              {plate.isPriceShown
                ? plate?.price && plate.priceUnit === "usd"
                  ? formatCurrency(plate.price)
                  : iqdFormatCurrency(plate.price)
                : t("callUs")}
            </p>
          ) : (
            <p className="sold">{t(`saleCars.badge.${plate.status}`)}</p>
          )}
        </div>
        {/* Location */}
        <div>
          <p className="location">{t(`city.${plate.plateCity}`)}</p>
        </div>
        {/* Phone number */}
        <div>
          <PhoneIcon color="#fff" />
          <p className="phone">
            {t("phone", { count: Number(plate.phoneNumber) })}
          </p>
        </div>
      </div>
    </div>
  );
};

import {
  BaseQueryApi,
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://api.noyya.net",
  credentials: "include",
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (
  args: FetchArgs | string | any,
  api: BaseQueryApi,
  extraOptions: any
) => {
  let result = await baseQuery(args, api, extraOptions);

  // If you want, handle other status codes, too
  if (result?.error?.status === 403) {
    // send refresh token to get new access token
    const refreshResult: any = await baseQuery(
      "auth/showroom/refresh",
      api,
      extraOptions
    );

    if (refreshResult?.data) {
      // store the new token
      localStorage.setItem("token", refreshResult.data.accessToken);
      // retry original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      if (refreshResult?.error?.status === 403) {
        localStorage.removeItem("token");
      }
      return refreshResult;
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Showrooms",
    "SaleCars",
    "RentCars",
    "RentedCars",
    "SoldCars",
    "Plates",
  ],
  endpoints: (builder) => ({}),
});

export const {} = apiSlice;

import "./AddPlate.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  PlateOptions,
  SaleCarOptions,
} from "../../../../../../data/FilterData";
import { useAddPlateMutation } from "../../../../../../app/api/platesApiSlice";
import { useShowroom } from "../../../../../../hooks/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { PlateType } from "../../../../../../components/cards/PlateCard";
import { DropDown } from "../../../../../../components/customs/drop down/DropDown";

export type AddPlateType = Omit<PlateType, "_id" | "createdAt" | "status">;

export const AddPlate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showroom } = useShowroom();

  // Add plate schema
  const AddPlateSchema = yup.object().shape({
    plateNumber: yup
      .number()
      .required()
      .typeError(t("errorMessage.plateNumber") as string),
    plateCategory: yup
      .string()
      .required(t("errorMessage.plateCategory") as string),
    plateCity: yup.string().required(t("errorMessage.plateCity") as string),
    plateLetter: yup.string().required(t("errorMessage.plateLetter") as string),
    phoneNumber: yup.string().required(t("errorMessage.phoneNumber") as string),
    priceUnit: yup.string().required(t("errorMessage.priceUnit") as string),
    price: yup
      .number()
      .required()
      .typeError(t("errorMessage.price") as string),

    isPriceShown: yup
      .boolean()
      .required(t("errorMessage.isPriceShown") as string),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<AddPlateType>({
    resolver: yupResolver(AddPlateSchema),
    mode: "onChange",
  });

  // Add plate mutation
  const [addPlate, { isLoading, error }] = useAddPlateMutation();

  const onSubmit: SubmitHandler<AddPlateType> = async (data) => {
    try {
      await addPlate({
        ...data,
        iqdValue: showroom?.iqdValue,
        showroom: showroom?._id,
      }).unwrap();
      navigate("../plate-listings", {
        state: { plateAdded: t("alert.plateAdded") },
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div id="add-plate">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="input-group">
              <input
                className="plate-number"
                placeholder=" "
                type="number"
                {...register("plateNumber", { required: true })}
              />
              <label>{t("inputPlaceholder.plateNumber")}</label>
            </div>
            {errors.plateNumber && (
              <p className="error">{errors.plateNumber.message}</p>
            )}
          </div>
          <div className="col">
            <DropDown
              placeholder="Plate Category"
              options={PlateOptions.category}
              setValue={setValue}
              watch={watch}
            />
            {errors.plateCategory && (
              <p className="error">{errors.plateCategory.message}</p>
            )}
          </div>
          <div className="col">
            <DropDown
              placeholder="Plate City"
              options={PlateOptions.city}
              setValue={setValue}
              watch={watch}
            />
            {errors.plateCity && (
              <p className="error">{errors.plateCity.message}</p>
            )}
          </div>
          <div className="col">
            <DropDown
              placeholder="Plate Letter"
              options={PlateOptions.letters}
              setValue={setValue}
              watch={watch}
            />
            {errors.plateLetter && (
              <p className="error">{errors.plateLetter.message}</p>
            )}
          </div>
          <div className="col">
            <DropDown
              placeholder="Phone Number"
              options={showroom?.phoneNumbers}
              setValue={setValue}
              watch={watch}
            />
            {errors.phoneNumber && (
              <p className="error">{errors.phoneNumber.message}</p>
            )}
          </div>
          <div className="col">
            <div className="price">
              <div className="price-unit">
                <DropDown
                  options={SaleCarOptions.priceUnit}
                  placeholder={"Price Unit"}
                  setValue={setValue}
                  watch={watch}
                />
              </div>
              <div className="input-group">
                <input
                  placeholder=" "
                  type="number"
                  {...register("price", { required: true })}
                />
                <label>{t("inputPlaceholder.price")}</label>
              </div>
            </div>
            {errors.priceUnit && (
              <p className="error">{errors.priceUnit.message}</p>
            )}
            {errors.price && <p className="error">{errors.price.message}</p>}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="price-shown">
              <p>{t("inputPlaceholder.priceShown")}</p>
              <div className="btns">
                <div className="radio-group">
                  <input
                    type="radio"
                    id="true"
                    value={"true"}
                    {...register("isPriceShown", { required: true })}
                  />
                  <label htmlFor="true">{t("priceShown.show")}</label>
                </div>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="false"
                    value={"false"}
                    {...register("isPriceShown", { required: true })}
                  />
                  <label htmlFor="false">{t("priceShown.hide")}</label>
                </div>
              </div>
            </div>
            {errors.isPriceShown && (
              <p className="error">{errors.isPriceShown.message}</p>
            )}
          </div>
        </div>
        {error && <p className="error">{error?.data?.message}</p>}
        <button disabled={isLoading} type="submit">
          {isLoading ? t("loading") : t("btns&links.add")}
        </button>
      </form>
    </div>
  );
};

import "./Showrooms.css";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import { FilterData } from "../../data/FilterData";
import { useShowrooms } from "../../hooks/useAuth";
import {
  ShowroomCard,
  ShowroomType,
} from "../../components/cards/ShowroomCard";
import { SortDropDown } from "../../components/customs/sort drop down/SortDropDown";
import { MobileSort } from "../../components/customs/mobile sort/MobileSort";
import { SEO } from "../../utilities/SEO";
import { CardSkeleton } from "../../components/skeleton/CardSkeleton";
import { FilterIcon } from "../../assets/svg/filterIcon";
import { motion } from "framer-motion";

export const Showrooms = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState<number>(
    Number(searchParams.get("page")) || 1
  );
  const limit = 12;

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>(
    searchParams.get("filter") || ""
  );

  // Change page number
  const changePage = ({ selected }: { selected: number }) => {
    setPageNumber(selected + 1);
    window.scrollTo(0, 0);
  };

  // Get showrooms
  const { showrooms, isSuccess, isLoading, isFetching, isError, error } =
    useShowrooms(Number(pageNumber), limit, selectedFilter);

  // Set page number and sort to local storage
  useEffect(() => {
    setSearchParams((prev) => {
      pageNumber && prev.set("page", pageNumber.toString());
      selectedFilter && prev.set("filter", selectedFilter);
      return prev;
    });
  }, [pageNumber, selectedFilter]);

  // Set page number to 1 when filter changes
  useEffect(() => {
    setPageNumber(1);
  }, [selectedFilter]);

  // Set page number to the last page when i leave the page
  useEffect(() => {
    const savedPageNumber = Number(searchParams.get("page")) || 1;
    setPageNumber(savedPageNumber > 1 ? savedPageNumber : 1);
  }, []);

  return (
    <>
      {/* SEO */}
      <SEO
        title={t("seo.showroomsTitle", { location: t(`city.iraq`) })}
        titlePost={t("home.secHeadline").toString()}
        description={t("home.secHeadline").toString()}
      />

      {/* Hero Section */}
      <section id="s-hero">
        {/* Mobile UI tab for filter */}
        <div className="mobile">
          <div className="btn">
            <button
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              aria-label={t("btns&links.filter").toString()}
            >
              <FilterIcon />
              {t("btns&links.filter")}
            </button>
          </div>
        </div>
        {/* Header */}
        <div className="showroom-header">
          <h1>{t("showrooms.heading")}</h1>
          {/* Sort drop-down */}
          <div>
            <SortDropDown
              options={FilterData.filterShowrooms}
              placeholder={"Filter By"}
              setSelectedSort={setSelectedFilter}
              selectedSort={selectedFilter}
            />
          </div>
        </div>
        {/* Showroom cards */}
        <div className="showroom">
          {isSuccess && showrooms.showrooms?.length < 1 && (
            <p className="no-results">{t("showrooms.noResults")}</p>
          )}
          {isLoading || isFetching ? (
            <div className="cards">
              {Array.from({ length: 8 }).map((_, index) => (
                <CardSkeleton key={index} isShowroomCard={true} />
              ))}
            </div>
          ) : isSuccess && showrooms.showrooms ? (
            <div className="cards">
              {showrooms.showrooms.map((showroom: ShowroomType, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, transform: "translateY(40px)" }}
                  whileInView={{
                    opacity: 1,
                    transform: "none",
                    transition: { delay: index * 0.015 },
                  }}
                  viewport={{ once: true }}
                >
                  <ShowroomCard
                    key={showroom._id}
                    data={showroom}
                    path={location.pathname + "/"}
                    totalCars={
                      showrooms.totalCars.find(
                        (totalCar) => totalCar._id === showroom._id
                      )?.count
                    }
                  />
                </motion.div>
              ))}
            </div>
          ) : (
            isError && (
              <p className="fetch-error">
                {error.status} <br />
                {JSON.stringify(error.data)}
              </p>
            )
          )}
          {/* Pagination */}
          {isSuccess && showrooms.pageCount > 0 && (
            <ReactPaginate
              previousLabel={t("btns&links.previous")}
              nextLabel={t("btns&links.next")}
              pageCount={showrooms.pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBtns"}
              previousLinkClassName={"previousBtn"}
              nextLinkClassName={"nextBtn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
              forcePage={pageNumber - 1}
              pageRangeDisplayed={3}
              breakLabel={"..."}
            />
          )}
        </div>
      </section>
      {/* Mobile UI for filter */}
      {isFilterOpen && (
        <MobileSort
          sort={FilterData.filterShowrooms}
          setSortOpen={setIsFilterOpen}
          setSelectedSort={setSelectedFilter}
          selectedSort={selectedFilter}
          isFilter={true}
        />
      )}
    </>
  );
};

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const NextIcon = (props: Props) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      style={{ transition: "fill 0.3s ease-in-out" }}
      d="M4.27003 14L3.5 13.2196L9.25183 7.39073C9.35421 7.28587 9.41084 7.1479 9.41084 7C9.41084 6.8521 9.35421 6.71413 9.25183 6.60927L3.5 0.780353L4.27003 0L10.0219 5.82892C10.3301 6.14128 10.5 6.5574 10.5 7C10.5 7.44261 10.3301 7.85762 10.0219 8.17108L4.27003 14Z"
      fill="#28282B"
    />
  </svg>
);

export { NextIcon };

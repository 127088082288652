import "./SideTabs.css";
import { FC } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLogoutMutation } from "../../../../../app/api/authApiSlice";
import { HelpIcon } from "../../../../../assets/svg/helpIcon";
import { HomeIcon } from "../../../../../assets/svg/homeIcon";
import { InventoryIcon } from "../../../../../assets/svg/inventoryIcon";
import { LogoutIcon } from "../../../../../assets/svg/logoutIcon";
import { SettingsIcon } from "../../../../../assets/svg/settingsIcon";
import { AnalyticsIcon } from "../../../../../assets/svg/analyticsIcon";

type PropsType = {
  toggleMenu?: () => void;
};
export const SideTabs: FC<PropsType> = ({ toggleMenu }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Sign out
  const [logout] = useLogoutMutation();

  // Check if the path is active
  const isActive = (path: string) => location.pathname.includes(path);
  return (
    <div id="side-tabs" onClick={toggleMenu}>
      <div className="top-tabs">
        <Link
          className="tab"
          to="dashboard"
          style={
            isActive("dashboard")
              ? {
                  backgroundColor: "var(--primary-50)",
                  color: "var(--primary-900)",
                }
              : {}
          }
        >
          <HomeIcon fill={isActive("dashboard") ? "var(--primary-900)" : ""} />
          {t("showroomManagement.dashboard.heading")}
        </Link>

        <Link
          className="tab"
          to="inventory-management"
          style={
            isActive("inventory")
              ? {
                  backgroundColor: "var(--primary-50)",
                  color: "var(--primary-900)",
                }
              : {}
          }
        >
          <InventoryIcon
            fill={isActive("inventory") ? "var(--primary-900)" : ""}
          />
          {t("showroomManagement.inventory.heading")}
        </Link>
        <Link
          className="tab"
          to="analytics"
          style={
            isActive("analytics")
              ? {
                  backgroundColor: "var(--primary-50)",
                  color: "var(--primary-900)",
                }
              : {}
          }
        >
          <AnalyticsIcon
            fill={isActive("analytics") ? "var(--primary-900)" : ""}
          />
          {t("showroomManagement.analytics.heading")}
        </Link>
        <Link
          className="tab"
          to="settings"
          style={
            isActive("settings")
              ? {
                  backgroundColor: "var(--primary-50)",
                  color: "var(--primary-900)",
                }
              : {}
          }
        >
          <SettingsIcon
            fill={isActive("settings") ? "var(--primary-900)" : ""}
          />
          {t("showroomManagement.settings.heading")}
        </Link>
      </div>
      <div className="bottom-tabs">
        <Link
          className="tab"
          to="support"
          style={
            isActive("support")
              ? {
                  backgroundColor: "var(--primary-50)",
                  color: "var(--primary-900)",
                }
              : {}
          }
        >
          <HelpIcon fill={isActive("support") ? "var(--primary-900)" : ""} />
          {t("showroomManagement.support.heading")}
        </Link>
        <button
          className="tab"
          onClick={async () => {
            await logout();
            localStorage.removeItem("token");
            navigate(`/${i18n.language}/sign-in`);
          }}
        >
          <LogoutIcon />
          {t("showroomManagement.logout")}
        </button>
      </div>
    </div>
  );
};

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const HelpIcon = (props: Props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1496_365)">
      <path
        d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845886 10.121 0.00229405 8 0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.99261 1.46144 6.6994C1.71867 5.40619 2.35361 4.21831 3.28596 3.28596C4.21831 2.35361 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76752 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z"
        fill={props.fill || "var(--primary-500)"}
      />
      <path
        d="M8.47801 3.37534C8.09356 3.3053 7.69842 3.3206 7.32054 3.42017C6.94266 3.51974 6.59128 3.70115 6.29127 3.95155C5.99126 4.20195 5.74995 4.51522 5.58442 4.86921C5.41888 5.22319 5.33317 5.60923 5.33334 6.00001C5.33334 6.17682 5.40358 6.34639 5.52861 6.47142C5.65363 6.59644 5.8232 6.66668 6.00001 6.66668C6.17682 6.66668 6.34639 6.59644 6.47141 6.47142C6.59644 6.34639 6.66668 6.17682 6.66668 6.00001C6.66651 5.80386 6.70962 5.61009 6.79294 5.43252C6.87626 5.25494 6.99773 5.09794 7.14869 4.9727C7.29965 4.84746 7.47639 4.75707 7.66629 4.70797C7.8562 4.65888 8.0546 4.65229 8.24734 4.68868C8.5107 4.7398 8.75285 4.86827 8.94284 5.05767C9.13284 5.24707 9.26206 5.48881 9.31401 5.75201C9.36648 6.02828 9.33025 6.31406 9.21051 6.5685C9.09078 6.82294 8.89366 7.03301 8.64734 7.16868C8.23946 7.40499 7.9024 7.74635 7.67129 8.15721C7.44019 8.56807 7.32348 9.03338 7.33334 9.50468V10C7.33334 10.1768 7.40358 10.3464 7.52861 10.4714C7.65363 10.5964 7.8232 10.6667 8.00001 10.6667C8.17682 10.6667 8.34639 10.5964 8.47142 10.4714C8.59644 10.3464 8.66668 10.1768 8.66668 10V9.50468C8.65831 9.27269 8.71121 9.04258 8.82006 8.83754C8.9289 8.63249 9.08984 8.45974 9.28668 8.33668C9.76969 8.07139 10.1586 7.66298 10.4001 7.16761C10.6415 6.67225 10.7235 6.11425 10.6349 5.57036C10.5462 5.02647 10.2912 4.5234 9.90501 4.13034C9.51878 3.73728 9.02026 3.47352 8.47801 3.37534Z"
        fill={props.fill || "var(--primary-500)"}
      />
      <path
        d="M8.66668 12C8.66668 11.6318 8.3682 11.3333 8.00001 11.3333C7.63182 11.3333 7.33334 11.6318 7.33334 12C7.33334 12.3682 7.63182 12.6667 8.00001 12.6667C8.3682 12.6667 8.66668 12.3682 8.66668 12Z"
        fill={props.fill || "var(--primary-500)"}
      />
    </g>
    <defs>
      <clipPath>
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { HelpIcon };

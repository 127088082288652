import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const CheckSolidIcon = (props: Props) => (
  <svg
    width={18}
    height={19}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 1C4.58867 1 1 4.58867 1 9C1 13.4113 4.58867 17 9 17C13.4113 17 17 13.4113 17 9C17 4.58867 13.4113 1 9 1ZM8.93933 11.2793C8.68133 11.5373 8.342 11.666 8.00133 11.666C7.66067 11.666 7.318 11.536 7.05733 11.276L5.20267 9.47867L6.13133 8.52067L7.99333 10.3253L11.866 6.52467L12.802 7.47467L8.93933 11.2793Z"
      fill="#28282B"
    />
  </svg>
);

export { CheckSolidIcon };

import "./AddCar.css";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { SaleCarOptions } from "../../../../../../data/FilterData";
import { useAddSaleCarMutation } from "../../../../../../app/api/saleCarsApiSlice";
import { useAddRentCarMutation } from "../../../../../../app/api/rentCarsApiSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useShowroom } from "../../../../../../hooks/useAuth";
import { CarType } from "../../../../../../components/cards/SaleCard";
import { ProgressBar } from "../../../../../../components/customs/progress bar/ProgressBar";
import { RentCarType } from "../../../../../../components/cards/RentalCard";

export type AddCarDataType = Omit<
  CarType,
  | "_id"
  | "createdAt"
  | "status"
  | "plate"
  | "showroom"
  | "images"
  | "phone"
  | "location"
  | "color"
  | "condition"
  | "views"
> & {
  images: FileList;
  showroom?: string;
  location?: string;
  status?: string;
  phone?: string;
};

export type AddRentCarDataType = Omit<
  RentCarType,
  | "_id"
  | "createdAt"
  | "status"
  | "showroom"
  | "location"
  | "images"
  | "phone"
  | "views"
> & {
  images: FileList;
  showroom?: string;
  location?: string;
  status?: string;
  phone?: string;
  rentType?: string;
  toDateRent?: Date;
};

export const AddCar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showroom } = useShowroom();
  const [step, setStep] = useState<number>(1);

  const errMessage = (field: string) => t(`errorMessage.${field}`);
  // Add sale car mutation
  const [addSaleCar, { isLoading: isAddSaleCarLoading, error }] =
    useAddSaleCarMutation();

  // Add rent car mutation
  const [addRentCar, { isLoading: isAddRentCarLoading, error: rentError }] =
    useAddRentCarMutation();

  // Add sale car schema
  const AddCarSchema = yup.object().shape({
    make: yup.string().required(errMessage("make")),
    model: yup.string().required(errMessage("model")),
    images: yup
      .mixed<FileList>()
      .required(errMessage("carImages"))
      .test("images", errMessage("carImages"), (value) => {
        if (value) {
          return value.length >= 4;
        }
        return false;
      }),
    exteriorColor: yup.string().required(errMessage("exteriorColor")),
    interiorColor: yup.string().required(errMessage("interiorColor")),
    seatMaterial: yup.string().required(errMessage("seatMaterial")),
    seating: yup.string().required(errMessage("seating")),
    transmission: yup.string().required(errMessage("transmission")),
    engine: yup.string().required(errMessage("engine")),
    cylinders: yup.string().required(errMessage("cylinders")),
    fuelType: yup.string().required(errMessage("fuelType")),
    trim: yup.string().required(errMessage("trim")),
    modelYear: yup.number().required(errMessage("modelYear")),
    price: yup.number().required().typeError(errMessage("price")),

    priceUnit: yup.string().required(errMessage("priceUnit")),
    isPriceShown: yup.boolean().required(errMessage("isPriceShown")),
    paint: yup.string().required(errMessage("paint")),

    drivetrain: yup.string().required(errMessage("drivetrain")),
    plateCategory: yup.string().required(errMessage("plateCategory")),
    plateCity: yup.string().required(errMessage("plateCity")),
    mileage: yup.string().required(errMessage("mileage")),
    mileageUnit: yup.string().required(errMessage("mileageUnit")),
    horsepower: yup.string().required(errMessage("horsepower")),
    bodyStyle: yup.string().required(errMessage("bodyStyle")),
  });

  // Add rent car schema
  const AddRentCarSchema = yup.object().shape({
    make: yup.string().required(errMessage("make")),
    model: yup.string().required(errMessage("model")),
    images: yup
      .mixed<FileList>()
      .required(errMessage("carImages"))
      .test("images", errMessage("carImages"), (value) => {
        if (value) {
          return value.length >= 4;
        }
        return false;
      }),
    exteriorColor: yup.string().required(errMessage("exteriorColor")),
    interiorColor: yup.string().required(errMessage("interiorColor")),
    seatMaterial: yup.string().required(errMessage("seatMaterial")),
    seating: yup.string().required(errMessage("seating")),
    transmission: yup.string().required(errMessage("transmission")),
    engine: yup.string().required(errMessage("engine")),
    cylinders: yup.string().required(errMessage("cylinders")),
    fuelType: yup.string().required(errMessage("fuelType")),
    trim: yup.string().required(errMessage("trim")),
    modelYear: yup.number().required(errMessage("modelYear")),
    price: yup.object().shape({
      daily: yup.number().required().typeError(errMessage("price")),
      weekly: yup.number().required().typeError(errMessage("price")),
      monthly: yup.number().required().typeError(errMessage("price")),
    }),
    priceUnit: yup.string().required(errMessage("priceUnit")),
    isPriceShown: yup.boolean().required(errMessage("isPriceShown")),
    bodyStyle: yup.string().required(errMessage("bodyStyle")),
  });

  // Form sale car
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger,
    unregister,
  } = useForm<AddCarDataType>({
    resolver: yupResolver(AddCarSchema),
    mode: "onChange",
  });

  // Form rent car
  const {
    register: registerRent,
    handleSubmit: handleSubmitRent,
    formState: { errors: rentErrors },
    watch: watchRent,
    setValue: setValueRent,
    trigger: triggerRent,
    unregister: unregisterRent,
  } = useForm<AddRentCarDataType>({
    resolver: yupResolver(AddRentCarSchema),
    mode: "onChange",
  });

  // sale car submit handler
  const onSubmit: SubmitHandler<AddCarDataType> = async (data) => {
    const carData = new FormData();

    showroom.phoneNumbers && carData.append("phone", showroom.phoneNumbers[0]);
    carData.append("make", data.make);
    carData.append("model", data.model);
    carData.append("condition", Number(data.mileage) > 100 ? "used" : "new");
    carData.append("exteriorColor", data.exteriorColor);
    carData.append("interiorColor", data.interiorColor);

    data.drivetrain && carData.append("drivetrain", data.drivetrain);

    carData.append("paint", data.paint);
    carData.append("seating", data.seating);
    carData.append("seatMaterial", data.seatMaterial);
    carData.append("transmission", data.transmission);
    carData.append("modelYear", data.modelYear.toString());
    carData.append("trim", data.trim);

    carData.append("cylinders", data.cylinders);
    carData.append("engine", data.engine);
    carData.append("horsepower", data.horsepower);
    carData.append("fuelType", data.fuelType);
    carData.append("price", data.price.toString());
    carData.append("priceUnit", data.priceUnit);

    carData.append("isPriceShown", data.isPriceShown.toString());

    carData.append("mileage", data.mileage);
    carData.append("mileageUnit", data.mileageUnit);
    carData.append("plateCategory", data.plateCategory);
    carData.append("plateCity", data.plateCity);
    showroom.city && carData.append("location", showroom.city.name);
    showroom.iqdValue &&
      carData.append("iqdValue", showroom.iqdValue.toString());
    if (data.features) {
      for (let i = 0; i < data.features.length; i++) {
        carData.append("features", data.features[i]);
      }
    }
    data.description && carData.append("description", data.description);
    showroom && carData.append("showroom", showroom._id);
    if (data.images) {
      for (let i = 0; i < data.images.length; i++) {
        carData.append("images", data.images[i]);
      }
    }
    data.primaryImageIndex &&
      carData.append("primaryImageIndex", data.primaryImageIndex.toString());

    data.bodyStyle && carData.append("bodyStyle", data.bodyStyle);
    data.importer && carData.append("importer", data.importer);

    try {
      await addSaleCar(carData).unwrap();
      navigate("../car-listings", { state: { carAdded: t("alert.carAdded") } });
    } catch (error) {
      console.log(error);
    }
  };

  // rent car submit handler
  const onSubmitRent: SubmitHandler<AddRentCarDataType> = async (data) => {
    const carData = new FormData();

    showroom.phoneNumbers && carData.append("phone", showroom.phoneNumbers[0]);
    data.make && carData.append("make", data.make);
    data.model && carData.append("model", data.model);
    data.exteriorColor && carData.append("exteriorColor", data.exteriorColor);
    data.interiorColor && carData.append("interiorColor", data.interiorColor);

    data.seating && carData.append("seating", data.seating);
    data.seatMaterial && carData.append("seatMaterial", data.seatMaterial);
    data.transmission && carData.append("transmission", data.transmission);
    data.modelYear && carData.append("modelYear", data.modelYear.toString());
    data.trim && carData.append("trim", data.trim);

    data.cylinders && carData.append("cylinders", data.cylinders);
    data.engine && carData.append("engine", data.engine);
    data.fuelType && carData.append("fuelType", data.fuelType);
    data.price && carData.append("price", JSON.stringify(data.price));
    data.priceUnit && carData.append("priceUnit", data.priceUnit);
    carData.append("isPriceShown", data.isPriceShown.toString());

    showroom.city && carData.append("location", showroom.city.name);
    showroom.iqdValue &&
      carData.append("iqdValue", showroom.iqdValue.toString());

    if (data.features) {
      for (let i = 0; i < data.features.length; i++) {
        carData.append("features", data.features[i]);
      }
    }
    data.description && carData.append("description", data.description);
    showroom && carData.append("showroom", showroom._id);
    if (data.images) {
      for (let i = 0; i < data.images.length; i++) {
        carData.append("images", data.images[i]);
      }
    }
    data.primaryImageIndex &&
      carData.append("primaryImageIndex", data.primaryImageIndex.toString());

    data.bodyStyle && carData.append("bodyStyle", data.bodyStyle);
    data.importer && carData.append("importer", data.importer);

    try {
      await addRentCar(carData).unwrap();
      navigate("../car-listings", {
        state: { rentCarAdded: t("alert.carAdded") },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="add-car">
      {showroom?.showroomType === "sale" ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <ProgressBar step={step} totalSteps={7} />
          <Outlet
            context={{
              register,
              unregister,
              setValue,
              watch,
              trigger,
              errors,
              error,
              setStep,
              isAddSaleCarLoading,
            }}
          />
        </form>
      ) : (
        <form onSubmit={handleSubmitRent(onSubmitRent)}>
          <ProgressBar step={step} totalSteps={7} />
          <Outlet
            context={{
              register: registerRent,
              unregister: unregisterRent,
              setValue: setValueRent,
              watch: watchRent,
              trigger: triggerRent,
              errors: rentErrors,
              error: rentError,
              setStep,
              isAddRentCarLoading,
            }}
          />
        </form>
      )}
    </div>
  );
};

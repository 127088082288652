import "./StepSevenAddCar.css";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OutletContextAddCarType, StepOneAddCar } from "./StepOneAddCar";
import { StepTwoAddCar } from "./StepTwoAddCar";
import { StepThreeAddCar } from "./StepThreeAddCar";
import { StepFourAddCar } from "./StepFourAddCar";
import { StepFiveAddCar } from "./StepFiveAddCar";
import { StepSixAddCar } from "./StepSixAddCar";

export const StepSevenAddCar = () => {
  const { t } = useTranslation();
  const { isAddSaleCarLoading, error } =
    useOutletContext<OutletContextAddCarType>();

  return (
    <div id="step-seven-add-car">
      <header>
        <p>{t("showroomManagement.inventory.addCar.stepSevenHeading")}</p>
      </header>
      <StepOneAddCar className="hidden" />
      <StepTwoAddCar className="hidden" />
      <StepThreeAddCar className="hidden" />
      <StepFourAddCar className="hidden" />
      <StepFiveAddCar className="hidden" />
      <StepSixAddCar className="hidden" />
      {error && <p className="error">{error?.data?.message}</p>}
      <div className="step-seven-btns">
        <button disabled={isAddSaleCarLoading} className="submit" type="submit">
          {isAddSaleCarLoading ? t("loading") : t("btns&links.add")}
        </button>
      </div>
    </div>
  );
};

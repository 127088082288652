import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const YoutubeIcon = (props: Props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.3301 6.09375C23.1082 4.85669 22.0511 3.95581 20.8244 3.67456C18.9886 3.28125 15.5911 3 11.9152 3C8.24144 3 4.78956 3.28125 2.9516 3.67456C1.72703 3.95581 0.667754 4.79956 0.445894 6.09375C0.22186 7.5 0 9.46875 0 12C0 14.5312 0.22186 16.5 0.500272 17.9062C0.724307 19.1433 1.7814 20.0442 3.00598 20.3254C4.95487 20.7188 8.29581 21 11.9717 21C15.6476 21 18.9886 20.7188 20.9375 20.3254C22.162 20.0442 23.2191 19.2004 23.4432 17.9062C23.665 16.5 23.9434 14.4741 24 12C23.8869 9.46875 23.6085 7.5 23.3301 6.09375ZM8.90919 15.9375V8.0625L15.702 12L8.90919 15.9375Z"
      fill={props.fill || "#fff"}
    />
  </svg>
);

export { YoutubeIcon };

import "./OpeningDay.css";
import { FC } from "react";
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RegisterDataType } from "../../pages/auth showroom/register/Register";

type Props = {
  day:
    | "saturday"
    | "sunday"
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday";
  register: UseFormRegister<RegisterDataType | any>;
  watch: UseFormWatch<RegisterDataType | any>;
  setValue: UseFormSetValue<RegisterDataType | any>;
  trigger: UseFormTrigger<RegisterDataType | any>;
};

export const OpeningDay: FC<Props> = ({
  day,
  register,
  watch,
  setValue,
  trigger,
}) => {
  const { t } = useTranslation();
  const opening = watch(`openingDays.${day}.opening`);

  const handleOpeningChange = (newValue: string) => {
    setValue(`openingDays.${day}.opening`, newValue);

    if (newValue === "closed") {
      setValue(`openingDays.${day}.openTime`, "");
      setValue(`openingDays.${day}.closeTime`, "");
      trigger(`openingDays.${day}.openTime`);
      trigger(`openingDays.${day}.closeTime`);
    }
  };

  return (
    <>
      <div id="day">
        <p>{t(`inputPlaceholder.openingTimes.days.${day}`)}</p>
        <select
          value={opening}
          {...register(`openingDays.${day}.opening`, { required: true })}
          onChange={(e) => handleOpeningChange(e.target.value)}
        >
          <option value="closed">{t("dropDownPlaceholder.closed")}</option>
          <option value="open">{t("dropDownPlaceholder.open")}</option>
        </select>
        <div className="times">
          <input
            disabled={opening === "closed" ? true : false}
            type="time"
            {...register(`openingDays.${day}.openTime`)}
          />
          <p>{t("inputPlaceholder.openingTimes.to")}</p>
          <input
            disabled={opening === "closed" ? true : false}
            type="time"
            {...register(`openingDays.${day}.closeTime`)}
          />
        </div>
      </div>
      <div className="times-solid"></div>
    </>
  );
};

import "./Footer.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LinkedinIcon } from "../../assets/svg/linkedinIcon";
import { FacebookIcon } from "../../assets/svg/facebookIcon";
import { InstagramIcon } from "../../assets/svg/instagramIcon";
import { TwitterXIcon } from "../../assets/svg/twitterXIcon";
import { AppStoreBadge } from "../../assets/svg/appStoreBadge";
import { GooglePlayBadge } from "../../assets/svg/googlePlayBadge";

export const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <section id="footer">
      <div className="container">
        {/* First col links */}
        <div className="f-col">
          <p className="header">{t("footer.buying&renting.heading")}</p>
          <div className="links">
            <Link
              className={"link"}
              to={`${i18n.language}/cars`}
              aria-label={t("footer.buying&renting.findACar").toString()}
            >
              {t("footer.buying&renting.findACar")}
            </Link>
            <Link
              className={"link"}
              to={`${i18n.language}/rental-cars`}
              aria-label={t("footer.buying&renting.findARentCar").toString()}
            >
              {t("footer.buying&renting.findARentCar")}
            </Link>
            <Link
              className={"link"}
              to={`${i18n.language}/showrooms`}
              aria-label={t("footer.buying&renting.findAShowroom").toString()}
            >
              {t("footer.buying&renting.findAShowroom")}
            </Link>
            <Link
              className={"link"}
              to={`${i18n.language}/number-plates`}
              aria-label={t(
                "footer.buying&renting.findANumberPlate"
              ).toString()}
            >
              {t("footer.buying&renting.findANumberPlate")}
            </Link>
          </div>
        </div>
        <div className="solid"></div>
        {/* Second col links */}
        <div className="f-col">
          <p className="header">{t("footer.forShowrooms.heading")}</p>
          <div className="links">
            <Link
              className={"link"}
              to={`${i18n.language}/register`}
              aria-label={t("footer.forShowrooms.register").toString()}
            >
              {t("footer.forShowrooms.register")}
            </Link>
            <Link
              className={"link"}
              to={`${i18n.language}/sign-in`}
              aria-label={t("footer.forShowrooms.signIn").toString()}
            >
              {t("footer.forShowrooms.signIn")}
            </Link>
          </div>
        </div>
        <div className="solid"></div>
        {/* Third col links */}
        <div className="f-col">
          <p className="header">{t("footer.ourCompany.heading")}</p>
          <div className="links">
            <Link
              className={"link"}
              to={`${i18n.language}/about`}
              aria-label={t("footer.ourCompany.aboutUs").toString()}
            >
              {t("footer.ourCompany.aboutUs")}
            </Link>
            <Link
              className={"link"}
              to={`${i18n.language}/contact`}
              aria-label={t("footer.ourCompany.contactUs").toString()}
            >
              {t("footer.ourCompany.contactUs")}
            </Link>
            <Link
              className={"link"}
              to={`${i18n.language}/terms`}
              aria-label={t("footer.ourCompany.terms&Conditions").toString()}
            >
              {t("footer.ourCompany.terms&Conditions")}
            </Link>
            <Link
              className={"link"}
              to={`${i18n.language}/privacy-policy`}
              aria-label={t("footer.ourCompany.privacyPolicy").toString()}
            >
              {t("footer.ourCompany.privacyPolicy")}
            </Link>
          </div>
        </div>
        <div className="solid"></div>
        {/* Fourth col */}
        <div className="social">
          <p className="header">{t("footer.socialMediaHeading")}</p>
          <div className="social-links">
            <a
              href={"https://www.instagram.com/noyya.iq/"}
              target="_blank"
              aria-label={"Instagram"}
            >
              <InstagramIcon />
            </a>
            <a
              href={"https://www.facebook.com/noyya.iq/"}
              target="_blank"
              aria-label="Facebook"
            >
              <FacebookIcon />
            </a>
            <a
              href={"https://twitter.com/noyya_iq"}
              target="_blank"
              aria-label="Twitter"
            >
              <TwitterXIcon />
            </a>
            <a
              href={"https://www.linkedin.com/company/noyya"}
              target="_blank"
              aria-label="Linkedin"
            >
              <LinkedinIcon />
            </a>
          </div>
        </div>
        <div className="solid"></div>
        <div className="store-badges">
          <Link
            to={"https://apps.apple.com/us/app/noyya/id6478633457"}
            target="_blank"
            aria-label="App Store"
          >
            <AppStoreBadge />
          </Link>
          <Link
            to={
              "https://play.google.com/store/apps/details?id=net.noyya.twa&pcampaignid=web_share"
            }
            target="_blank"
            aria-label="Google Play"
          >
            <GooglePlayBadge />
          </Link>
        </div>
        <div className="solid"></div>
      </div>
      <div className="bottom">
        <p>
          {t("footer.copyRight", {
            year: t("number", { count: new Date().getFullYear() }),
          })}
        </p>
        <div className="store-badges">
          <Link
            to={"https://apps.apple.com/us/app/noyya/id6478633457"}
            target="_blank"
            aria-label="App Store"
          >
            <AppStoreBadge />
          </Link>
          <Link
            to={
              "https://play.google.com/store/apps/details?id=net.noyya.twa&pcampaignid=web_share"
            }
            target="_blank"
            aria-label="Google Play"
          >
            <GooglePlayBadge />
          </Link>
        </div>
      </div>
    </section>
  );
};

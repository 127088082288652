import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const InstagramIcon = (props: Props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.09091 0C3.18 0 0 3.18 0 7.09091V16.9091C0 20.82 3.18 24 7.09091 24H16.9091C20.82 24 24 20.82 24 16.9091V7.09091C24 3.18 20.82 0 16.9091 0H7.09091ZM18.5455 4.36364C19.1455 4.36364 19.6364 4.85455 19.6364 5.45455C19.6364 6.05455 19.1455 6.54545 18.5455 6.54545C17.9455 6.54545 17.4545 6.05455 17.4545 5.45455C17.4545 4.85455 17.9455 4.36364 18.5455 4.36364ZM12 6C15.3109 6 18 8.68909 18 12C18 15.3109 15.3109 18 12 18C8.68909 18 6 15.3109 6 12C6 8.68909 8.68909 6 12 6ZM12 7.09091C9.29455 7.09091 7.09091 9.29455 7.09091 12C7.09091 14.7055 9.29455 16.9091 12 16.9091C14.7055 16.9091 16.9091 14.7055 16.9091 12C16.9091 9.29455 14.7055 7.09091 12 7.09091Z"
      fill={props.fill || "#fff"}
    />
  </svg>
);

export { InstagramIcon };

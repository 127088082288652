import { Outlet } from "react-router-dom";
import "./Inventory.css";
import { InventoryTabs } from "../tabs/inventory tabs/InventoryTabs";
import { useTranslation } from "react-i18next";

export const Inventory = () => {
  const { t } = useTranslation();
  return (
    <div id="inventory">
      <p className="inventory-heading">
        {t("showroomManagement.inventory.heading")}
      </p>
      <InventoryTabs />
      <Outlet />
    </div>
  );
};

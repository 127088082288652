import "./About.css";
import { useTranslation } from "react-i18next";
import { SEO } from "../../utilities/SEO";

export const About = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* SEO */}
      <SEO
        title={t("seo.aboutTitle")}
        description={t("seo.homeDescription").toString()}
      />

      {/* Hero Section */}
      <section id="a-hero">
        <header>
          <h1 aria-label={t("aboutUs.heading").toString()}>
            {t("aboutUs.heading")}
          </h1>
        </header>
      </section>
      {/* Feature Section */}
      <section id="a-feature">
        <div className="container">
          <div className="row">
            <div>
              <h2 className="heading">{t("aboutUs.firstSection.heading")}</h2>
              <p className="h2">{t("aboutUs.firstSection.h2")}</p>
              <p className="desc">{t("aboutUs.firstSection.desc")}</p>
            </div>
            <div>
              <h2 className="heading">{t("aboutUs.secondSection.heading")}</h2>
              <p className="h2">{t("aboutUs.secondSection.h2")}</p>
              <p className="desc">{t("aboutUs.secondSection.desc")}</p>
            </div>
            <div>
              <h2 className="heading">{t("aboutUs.thirdSection.heading")}</h2>
              <p className="h2">{t("aboutUs.thirdSection.h2")}</p>
              <p className="desc">{t("aboutUs.thirdSection.desc")}</p>
            </div>
            <div>
              <h2 className="heading">{t("aboutUs.fourthSection.heading")}</h2>
              <p className="h2">{t("aboutUs.fourthSection.h2")}</p>
              <p className="desc">{t("aboutUs.fourthSection.desc")}</p>
            </div>
          </div>
          <div className="last-row">
            <h2 className="heading">{t("aboutUs.fifthSection.heading")}</h2>
            <p className="h2">{t("aboutUs.fifthSection.h2")}</p>
            <p className="desc">{t("aboutUs.fifthSection.desc")}</p>
          </div>
        </div>
      </section>
    </>
  );
};

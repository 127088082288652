import "./VerifyOtp.css";
import { KeyboardEvent, useEffect } from "react";
import {
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useVerifyPhoneNumberMutation } from "../../../../app/api/showroomApiSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RegisterDataType } from "../Register";

type OutletContextType = {
  trigger: UseFormTrigger<RegisterDataType>;
  watch: UseFormWatch<RegisterDataType>;
  setValue: UseFormSetValue<RegisterDataType>;
};
const otpPattern = /^[0-9]{6}$/;

export const VerifyOtp = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { trigger, watch, setValue } = useOutletContext<OutletContextType>();

  // Verify phone mutation
  const [verifyPhone, { isLoading: isVerifyPhoneLoading, error }] =
    useVerifyPhoneNumberMutation();

  // Verify phone schema
  const verifyPhoneSchema = yup.object().shape({
    code: yup
      .string()
      .min(6, t("errorMessage.codeLength") as string)
      .max(6, t("errorMessage.codeLength") as string)
      .required(t("errorMessage.verificationCode") as string),
  });
  // Form hook
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue: setValueCode,
    trigger: triggerCode,
  } = useForm<{ code: string }>({
    resolver: yupResolver(verifyPhoneSchema),
    mode: "onChange",
  });

  // Submit handler
  const onSubmit = async (data: { code: string }) => {
    await triggerCode("code", { shouldFocus: true });
    try {
      await verifyPhone({
        phoneNumber: watch("phoneNumber"),
        code: data.code,
      }).unwrap();
      setValue("isVerified", true);
      navigate("../step-two", { replace: true });
    } catch (err) {
      console.log(err);
    }
  };

  // Only allow 6 numbers and convert arabic numbers to english in input
  const onChangeCodeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.slice(0, 6);

    // if it is arabic number convert it to english number
    let inputValue = e.target.value.replace(/[\u0660-\u0669]/g, (c) =>
      String.fromCharCode(c.charCodeAt(0) - 0x0660 + 48)
    );

    setValueCode(`code`, inputValue, {
      shouldValidate: true,
    });
  };

  // Only allow numbers in input
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // only allow numbers
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 46
      )
    )
      e.preventDefault();
  };

  // Redirect to first step
  useEffect(() => {
    const redirectToFirstStep = async () => {
      const isValid = await trigger([
        "name",
        "phoneNumber",
        "showroomType",
        "password",
        "confirmPassword",
      ]);
      if (!isValid) {
        navigate("..");
      }
    };
    redirectToFirstStep();
  }, [navigate]);

  return (
    <div id="verify-otp">
      <header>
        <p className="title">{t("register.verifyOtp.heading")}</p>
        <p>{t("register.verifyOtp.desc")}</p>
      </header>
      <div className="col">
        <div className="input-group">
          <input
            placeholder=" "
            type="number"
            onChange={onChangeCodeInput}
            onKeyDown={onKeyDown}
          />
          <label>{t("inputPlaceholder.code")}</label>
        </div>
        {errors.code && <p className="error">{errors.code.message}</p>}
        {error && <p className="error">{error?.data?.message}</p>}
      </div>
      <button
        disabled={isSubmitting || isVerifyPhoneLoading}
        onClick={handleSubmit(onSubmit)}
      >
        {isSubmitting || isVerifyPhoneLoading
          ? t("loading")
          : t("btns&links.verify")}
      </button>
    </div>
  );
};

import "./CarTable.css";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CarType } from "../cards/SaleCard";
import {
  formatCurrency,
  iqdFormatCurrency,
} from "../../utilities/formatCurrency";
import { camelCase } from "../../utilities/camelCase";

type Props = {
  thead: string[];
  cars: CarType[];
  carPath: string;
  editPath: string;
  Delete: (id: string) => any;
  isDeleteLoading: boolean;
};

export const CarTable: FC<Props> = ({
  thead,
  cars,
  carPath,
  editPath,
  Delete,
  isDeleteLoading,
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <div id="car-table">
      <table>
        <thead>
          <tr>
            {thead.map((title) => {
              return <th key={title}>{title}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {cars?.length > 0 &&
            cars.map((car: CarType, index: number) => (
              <tr key={car._id.toString()}>
                <td
                  onClick={() => navigate(carPath + car._id)}
                  className="td-1"
                >
                  <img src={car.images[car.primaryImageIndex || 0]} alt="" />
                  <div className="col-1">
                    <p className="make">{t(`make.${camelCase(car.make)}`)}</p>
                    <p className="model">
                      {t(`model.${camelCase(car.model)}`)}&nbsp;
                      {/* {t(`trim.${car.trim.toLowerCase()}`)}&nbsp; */}
                      {t(`number`, { count: car.modelYear })}
                    </p>
                  </div>
                </td>
                <td className="price">
                  {car.priceUnit === "usd"
                    ? formatCurrency(car.price)
                    : iqdFormatCurrency(car.price)}
                  {car?.price === 0 && "-"}
                </td>
                <td>
                  {car.status && (
                    <p
                      className={`status ${
                        car.status === "sold" ? "sold" : "available"
                      }`}
                    >
                      {t(`showroomManagement.carTable.status.${car.status}`)}
                    </p>
                  )}
                </td>
                <td>
                  {i18n.language !== "en"
                    ? new Date(car.createdAt).toLocaleDateString("ar-IQ", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })
                    : new Date(car.createdAt).toLocaleDateString("en-GB")}
                </td>
                <td>
                  <div className="action-btns">
                    <button
                      className="edit"
                      onClick={() => {
                        navigate(editPath + car._id);
                      }}
                    >
                      {t("btns&links.edit")}
                    </button>
                    <button
                      disabled={isDeleteLoading}
                      className="delete"
                      onClick={async () => await Delete(car._id).unwrap()}
                    >
                      {t("btns&links.delete")}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const SortIcon = (props: Props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2310_218)">
      <path
        d="M16 2.00001C16 2.36668 15.7 2.66668 15.3333 2.66668H0.666667C0.3 2.66668 0 2.36668 0 2.00001C0 1.63334 0.3 1.33334 0.666667 1.33334H15.3333C15.7 1.33334 16 1.63334 16 2.00001ZM4.66667 13.3333H0.666667C0.3 13.3333 0 13.6333 0 14C0 14.3667 0.3 14.6667 0.666667 14.6667H4.66667C5.03333 14.6667 5.33333 14.3667 5.33333 14C5.33333 13.6333 5.03333 13.3333 4.66667 13.3333ZM10 7.33334H0.666667C0.3 7.33334 0 7.63334 0 8.00001C0 8.36668 0.3 8.66668 0.666667 8.66668H10C10.3667 8.66668 10.6667 8.36668 10.6667 8.00001C10.6667 7.63334 10.3667 7.33334 10 7.33334Z"
        fill="#28282B"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { SortIcon };

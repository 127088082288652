import "./Cars.css";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import { FilterData } from "../../data/FilterData";
import { sortData } from "../../data/SortData";
import { useMakes, useModelYears, useModels } from "../../hooks/useCarSpecs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useSaleCars } from "../../hooks/useAuth";
import { resetFilter } from "../../features/filter/filterSlice";
import {
  selectCarCondition,
  selectCarLocation,
  selectCarMake,
  selectCarModel,
} from "../../features/filter/filterSlice";
import { CarType, SaleCard } from "../../components/cards/SaleCard";
import { SortDropDown } from "../../components/customs/sort drop down/SortDropDown";
import { MobileSort } from "../../components/customs/mobile sort/MobileSort";
import { FilterDropDown } from "../../components/customs/filter drop down/FilterDropDown";
import { camelCase } from "../../utilities/camelCase";
import { CloseIcon } from "../../assets/svg/closeIcon";
import { SEO } from "../../utilities/SEO";
import { CardSkeleton } from "../../components/skeleton/CardSkeleton";
import { SortIcon } from "../../assets/svg/sortIcon";
import { SearchIcon } from "../../assets/svg/searchIcon";
import { motion } from "framer-motion";

export const Cars = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState<number>(
    Number(searchParams.get("page")) || 1
  );
  const limit = 24;

  // Get search filters data of home page
  const carMake = useAppSelector(selectCarMake);
  const carModel = useAppSelector(selectCarModel);
  const carCondition = useAppSelector(selectCarCondition);
  const cLocation = useAppSelector(selectCarLocation);

  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [sortOpen, setSortOpen] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<string>(
    searchParams.get("sort") || ""
  );

  // Search filters
  const [make, setMake] = useState<string>(
    carMake || searchParams.get("make") || ""
  );
  const [model, setModel] = useState(
    carModel || searchParams.get("model") || ""
  );
  const [condition, setCondition] = useState<string>(
    carCondition || searchParams.get("condition") || ""
  );
  const [fromYear, setFromYear] = useState<string>(
    searchParams.get("fromYear") || ""
  );
  const [toYear, setToYear] = useState<string>(
    searchParams.get("toYear") || ""
  );
  const [minPrice, setMinPrice] = useState<string>(
    searchParams.get("minPrice") || ""
  );
  const [maxPrice, setMaxPrice] = useState<string>(
    searchParams.get("maxPrice") || ""
  );
  const [mileage, setMileage] = useState<string>(
    searchParams.get("mileage") || ""
  );
  const [carLocation, setCarLocation] = useState<string>(
    cLocation || searchParams.get("location") || ""
  );
  const [exteriorColor, setExteriorColor] = useState<string>(
    searchParams.get("exteriorColor") || ""
  );
  const [interiorColor, setInteriorColor] = useState<string>(
    searchParams.get("interiorColor") || ""
  );
  const [transmission, setTransmission] = useState<string>(
    searchParams.get("transmission") || ""
  );
  const [fuelType, setFuelType] = useState<string>(
    searchParams.get("fuelType") || ""
  );

  const { makes } = useMakes();
  const { models } = useModels(make);
  const { modelYears } = useModelYears(model, make);

  // Change page number
  const changePage = ({ selected }: { selected: number }) => {
    setPageNumber(selected + 1);
    window.scrollTo(0, 0);
  };

  // Get sale cars
  const { cars, isLoading, isSuccess, isFetching, isError, error } =
    useSaleCars(
      pageNumber,
      limit,
      make,
      model,
      condition,
      fromYear,
      toYear,
      minPrice,
      maxPrice,
      mileage,
      carLocation,
      exteriorColor,
      interiorColor,
      transmission,
      fuelType,
      selectedSort
    );

  // Set model to empty string when make changes
  const handleMakeChange = () => {
    setModel("");
    searchParams.delete("model");
  };

  // Clear search filters
  const handleResetSearch = () => {
    setPageNumber(1);
    setMake("");
    setModel("");
    setCondition("");
    setFromYear("");
    setToYear("");
    setMinPrice("");
    setMaxPrice("");
    setMileage("");
    setCarLocation("");
    setExteriorColor("");
    setInteriorColor("");
    setTransmission("");
    setFuelType("");

    setSearchParams((prev) => {
      prev.delete("make");
      prev.delete("model");
      prev.delete("condition");
      prev.delete("fromYear");
      prev.delete("toYear");
      prev.delete("minPrice");
      prev.delete("maxPrice");
      prev.delete("mileage");
      prev.delete("location");
      prev.delete("exteriorColor");
      prev.delete("interiorColor");
      prev.delete("transmission");
      prev.delete("fuelType");
      return prev;
    });

    window.scrollTo(0, 0);
  };

  // From year field options
  const fromYearOptions = () => {
    if (make && model) {
      return toYear ? modelYears.filter((year) => year <= toYear) : modelYears;
    } else if (toYear && toYear !== "newest") {
      return FilterData.year.from.filter((year) => year <= toYear);
    } else {
      return FilterData.year.from;
    }
  };
  // To year field options
  const toYearOptions = () => {
    if (make && model) {
      return fromYear
        ? modelYears.filter((year) => year >= fromYear)
        : modelYears;
    } else if (fromYear && fromYear !== "oldest") {
      return FilterData.year.to.filter((year) => year >= fromYear);
    } else {
      return FilterData.year.to;
    }
  };

  // Reset search filters of home page when i leave the page
  useEffect(() => {
    return () => {
      dispatch(resetFilter());
    };
  }, [dispatch]);

  // Set search filters in url
  useEffect(() => {
    setSearchParams((prev) => {
      pageNumber && prev.set("page", pageNumber.toString());
      make && prev.set("make", make);
      model && prev.set("model", model);
      condition && prev.set("condition", condition);
      fromYear && prev.set("fromYear", fromYear);
      toYear && prev.set("toYear", toYear);
      minPrice && prev.set("minPrice", minPrice);
      maxPrice && prev.set("maxPrice", maxPrice);
      mileage && prev.set("mileage", mileage);
      carLocation && prev.set("location", carLocation);
      exteriorColor && prev.set("exteriorColor", exteriorColor);
      interiorColor && prev.set("interiorColor", interiorColor);
      transmission && prev.set("transmission", transmission);
      fuelType && prev.set("fuelType", fuelType);
      selectedSort && prev.set("sort", selectedSort);
      return prev;
    });
  }, [
    pageNumber,
    make,
    model,
    condition,
    fromYear,
    toYear,
    minPrice,
    maxPrice,
    mileage,
    carLocation,
    exteriorColor,
    interiorColor,
    transmission,
    fuelType,
    selectedSort,
  ]);

  // Reset page number when search filters change
  useEffect(() => {
    setPageNumber(1);
  }, [
    make,
    model,
    condition,
    fromYear,
    toYear,
    minPrice,
    maxPrice,
    mileage,
    carLocation,
    exteriorColor,
    interiorColor,
    transmission,
    fuelType,
    selectedSort,
  ]);

  // Set page number to the last page when i leave the page
  useEffect(() => {
    const savedPageNumber = Number(searchParams.get("page")) || 1;
    setPageNumber(savedPageNumber > 1 ? savedPageNumber : 1);
  }, []);

  return (
    <>
      {/* SEO */}
      <SEO
        title={t("seo.carsTitle", {
          car:
            make && model
              ? t(`make.${camelCase(make)}`) +
                " " +
                t(`model.${camelCase(model)}`)
              : t("cars"),
          location: carLocation
            ? t(
                carLocation !== "all" ? `city.${carLocation}` : `city.iraq`
              ).toString()
            : t("city.iraq"),
        })}
        description={t("seo.homeDescription").toString()}
      />
      <section id="c-hero">
        {/* Mobile UI tabs for search and sort */}
        <div className="mobile">
          <div className="btns">
            <button
              onClick={() => setSearchOpen(!searchOpen)}
              aria-label={t("btns&links.search").toString()}
            >
              <SearchIcon />
              {t("btns&links.search")}
            </button>
            <button
              onClick={() => setSortOpen(!sortOpen)}
              aria-label={t("btns&links.sort").toString()}
            >
              <SortIcon />
              {t("btns&links.sort")}
            </button>
          </div>
        </div>
        {/* Header */}
        <header className="header">
          <h1>{t("saleCars.heading")}</h1>
          {/* Sort drop-down */}
          <div className="sort-div">
            <SortDropDown
              options={sortData.sortCars}
              placeholder="Sort By"
              setSelectedSort={setSelectedSort}
              selectedSort={selectedSort}
            />
          </div>
        </header>
        <div className="container">
          {/* Search Section */}
          <div className="left">
            <form>
              <h2 className="heading">{t("saleCars.searchHeading")}</h2>
              <div className="filters">
                <FilterDropDown
                  placeholder="Condition"
                  options={FilterData.condition}
                  setSelection={setCondition}
                  selectedOption={condition}
                />
                <FilterDropDown
                  placeholder="Make"
                  options={makes && ["all makes", ...makes]}
                  setSelection={setMake}
                  onChangeMake={handleMakeChange}
                  selectedOption={make}
                />
                <FilterDropDown
                  placeholder="Model"
                  options={models}
                  setSelection={setModel}
                  selectedOption={model}
                />
                <div className="year">
                  <FilterDropDown
                    placeholder="From Year"
                    options={fromYearOptions()}
                    setSelection={setFromYear}
                    selectedOption={fromYear}
                  />
                  <FilterDropDown
                    placeholder="To Year"
                    options={toYearOptions()}
                    setSelection={setToYear}
                    selectedOption={toYear}
                  />
                </div>
                <div className="solid"></div>
                <div className="price">
                  <FilterDropDown
                    placeholder="Min Price"
                    options={
                      maxPrice && maxPrice !== "highest"
                        ? FilterData.price.min.filter(
                            // including lowest price
                            (price) =>
                              Number(price) <= Number(maxPrice) ||
                              price === "lowest"
                            // (price) => Number(price) <= Number(maxPrice)
                          )
                        : FilterData.price.min
                    }
                    setSelection={setMinPrice}
                    selectedOption={minPrice}
                  />
                  <FilterDropDown
                    placeholder="Max Price"
                    options={
                      minPrice && minPrice !== "lowest"
                        ? FilterData.price.max.filter(
                            (price) =>
                              Number(price) >= Number(minPrice) ||
                              price === "highest"
                          )
                        : FilterData.price.max
                    }
                    setSelection={setMaxPrice}
                    selectedOption={maxPrice}
                  />
                </div>
                <FilterDropDown
                  placeholder="Mileage"
                  options={FilterData.mileage}
                  setSelection={setMileage}
                  selectedOption={mileage}
                />
                <FilterDropDown
                  placeholder="Location"
                  options={FilterData.location}
                  setSelection={setCarLocation}
                  selectedOption={carLocation}
                />
                <div className="solid"></div>
                <FilterDropDown
                  placeholder="Exterior Color"
                  options={FilterData.exteriorColor}
                  setSelection={setExteriorColor}
                  selectedOption={exteriorColor}
                />
                <FilterDropDown
                  placeholder="Interior Color"
                  options={FilterData.interiorColor}
                  setSelection={setInteriorColor}
                  selectedOption={interiorColor}
                />
                <FilterDropDown
                  placeholder="Transmission"
                  options={FilterData.transmission}
                  setSelection={setTransmission}
                  selectedOption={transmission}
                />
                <FilterDropDown
                  placeholder="Fuel Type"
                  options={FilterData.fuelType}
                  setSelection={setFuelType}
                  selectedOption={fuelType}
                />
              </div>
              <button
                type="button"
                className="clear-search"
                onClick={handleResetSearch}
                aria-label={t("btns&links.clearSearch").toString()}
              >
                {t("btns&links.clearSearch")}
              </button>
            </form>
          </div>
          {/* Sale cars section */}
          <div className="right">
            <div className="sale">
              {/* Listings range */}
              {isSuccess && cars.saleCars?.length > 0 ? (
                <p className="listings-range">
                  {t("number", { count: cars?.currentRangeStart })}&nbsp;-&nbsp;
                  {t("number", { count: cars?.currentRangeEnd })}&nbsp;
                  {t("saleCars.of")}&nbsp;
                  {t("number", { count: cars?.totalCars })}&nbsp;
                  {t("saleCars.listings")}
                </p>
              ) : cars?.saleCars && !isFetching ? (
                <div className="no-results">
                  <p>
                    {t("saleCars.noResults")}
                    <br />
                    <span>{t("saleCars.noResultsDesc")}</span>
                  </p>
                  <button
                    onClick={handleResetSearch}
                    aria-label={t("btns&links.clearSearch").toString()}
                  >
                    {t("btns&links.clearSearch")}
                  </button>
                </div>
              ) : (
                isSuccess &&
                !cars?.saleCars && (
                  <div className="no-results">
                    <p>{t("saleCars.noResults")}</p>
                  </div>
                )
              )}
              {/* Sale cars cards */}
              {isLoading || isFetching ? (
                <div className="cards">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <CardSkeleton key={index} />
                  ))}
                </div>
              ) : isSuccess && cars.saleCars?.length > 0 ? (
                <div className="cards">
                  {cars.saleCars?.map((car: CarType, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, transform: "translateY(40px)" }}
                      whileInView={{
                        opacity: 1,
                        transform: "none",
                        transition: { delay: index * 0.015 },
                      }}
                      viewport={{ once: true }}
                    >
                      <SaleCard
                        key={car._id}
                        data={car}
                        path={location.pathname + "/"}
                      />
                    </motion.div>
                  ))}
                </div>
              ) : (
                isError && (
                  <p className="fetch-error">
                    {error.status} <br />
                    {JSON.stringify(error.data)}
                  </p>
                )
              )}
              {/* Pagination */}
              {isSuccess && cars.pageCount > 0 && (
                <ReactPaginate
                  previousLabel={t("btns&links.previous")}
                  nextLabel={t("btns&links.next")}
                  pageCount={cars.pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBtns"}
                  previousLinkClassName={"previousBtn"}
                  nextLinkClassName={"nextBtn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                  forcePage={Number(pageNumber) - 1}
                  pageRangeDisplayed={3}
                  breakLabel={"..."}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* Mobile UI for search section */}
      {searchOpen && (
        <div className="cars-mobile-search">
          <div>
            <h2>{t("saleCars.searchHeading")}</h2>
            <button
              onClick={() => setSearchOpen(!searchOpen)}
              aria-label="close button"
            >
              <CloseIcon width={12} height={12} />
            </button>
          </div>
          <form>
            <div className="filters">
              <FilterDropDown
                placeholder="Condition"
                options={FilterData.condition}
                setSelection={setCondition}
                selectedOption={condition}
              />
              <FilterDropDown
                placeholder="Make"
                options={makes}
                setSelection={setMake}
                selectedOption={make}
              />
              <FilterDropDown
                placeholder="Model"
                options={models}
                setSelection={setModel}
                selectedOption={model}
              />
              <div className="year">
                <FilterDropDown
                  placeholder="From Year"
                  options={FilterData.year.from}
                  setSelection={setFromYear}
                  selectedOption={fromYear}
                />
                <FilterDropDown
                  placeholder="To Year"
                  options={FilterData.year.to}
                  setSelection={setToYear}
                  selectedOption={toYear}
                />
              </div>
              <div className="solid"></div>
              <div className="price">
                <FilterDropDown
                  placeholder="Min Price"
                  options={FilterData.price.min}
                  setSelection={setMinPrice}
                  selectedOption={minPrice}
                />
                <FilterDropDown
                  placeholder="Max Price"
                  options={FilterData.price.max}
                  setSelection={setMaxPrice}
                  selectedOption={maxPrice}
                />
              </div>
              <FilterDropDown
                placeholder="Mileage"
                options={FilterData.mileage}
                setSelection={setMileage}
                selectedOption={mileage}
              />
              <FilterDropDown
                placeholder="Location"
                options={FilterData.location}
                setSelection={setCarLocation}
                selectedOption={carLocation}
              />
              <div className="solid"></div>
              <FilterDropDown
                placeholder="Exterior Color"
                options={FilterData.exteriorColor}
                setSelection={setExteriorColor}
                selectedOption={exteriorColor}
              />
              <FilterDropDown
                placeholder="Interior Color"
                options={FilterData.interiorColor}
                setSelection={setInteriorColor}
                selectedOption={interiorColor}
              />
              <FilterDropDown
                placeholder="Transmission"
                options={FilterData.transmission}
                setSelection={setTransmission}
                selectedOption={transmission}
              />
              <FilterDropDown
                placeholder="Fuel Type"
                options={FilterData.fuelType}
                setSelection={setFuelType}
                selectedOption={fuelType}
              />
            </div>
          </form>
          <div>
            <button
              onClick={() => {
                setSearchOpen(!searchOpen);
                window.scrollTo(0, 0);
              }}
              type="button"
              aria-label={t("btns&links.clearSearch").toString()}
            >
              {t("btns&links.search")}
            </button>
          </div>
        </div>
      )}
      {/* Mobile UI for sort */}
      {sortOpen && (
        <MobileSort
          sort={sortData.sortCars}
          setSortOpen={setSortOpen}
          setSelectedSort={setSelectedSort}
          selectedSort={selectedSort}
        />
      )}
    </>
  );
};

import { apiSlice } from "./apiSlice";

export const platesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPlates: builder.query({
      query: ({ page, limit = 2, sort }) =>
        `plates?page=${page}&limit=${limit}&sort=${sort || ""}`,
      // keepUnusedDataFor: 0,
      providesTags: ["Plates"],
    }),
    getPlate: builder.query({
      query: (id) => `plates/${id}`,
      providesTags: ["Plates"],
    }),
    getPlateById: builder.query({
      query: (id) => `plates/${id}`,
      providesTags: ["Plates"],
    }),
    getPlatesByShowroomId: builder.query({
      query: ({ page, limit = 2, showroomId }) =>
        `plates/showroom/${showroomId}?page=${page}&limit=${limit}`,
      providesTags: ["Plates"],
    }),
    addPlate: builder.mutation({
      query: (credentials) => ({
        url: `plates`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["Plates"],
    }),
    updatePlateById: builder.mutation({
      query: ({ id, credentials }) => ({
        url: `plates/${id}`,
        method: "PATCH",
        body: credentials,
      }),
      invalidatesTags: ["Plates"],
    }),
    deletePlateById: builder.mutation({
      query: (id) => ({
        url: `plates/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Plates"],
    }),
  }),
});

export const {
  useGetAllPlatesQuery,
  useGetPlateQuery,
  useGetPlatesByShowroomIdQuery,
  useGetPlateByIdQuery,
  useAddPlateMutation,
  useUpdatePlateByIdMutation,
  useDeletePlateByIdMutation,
} = platesApiSlice;

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const BurgerMenuIcon = (props: Props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M2 22H26"
        stroke="#28282B"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M2 14H26"
        stroke="#28282B"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M2 6H26"
        stroke="#28282B"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export { BurgerMenuIcon };

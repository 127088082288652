import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const CopyIcon = (props: Props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2338_4)">
      <path
        d="M11.25 15H3.75C2.7558 14.9988 1.80267 14.6033 1.09966 13.9003C0.396661 13.1973 0.00119089 12.2442 0 11.25L0 3.75C0.00119089 2.7558 0.396661 1.80267 1.09966 1.09966C1.80267 0.396661 2.7558 0.00119089 3.75 0L11.25 0C12.2442 0.00119089 13.1973 0.396661 13.9003 1.09966C14.6033 1.80267 14.9988 2.7558 15 3.75V11.25C14.9988 12.2442 14.6033 13.1973 13.9003 13.9003C13.1973 14.6033 12.2442 14.9988 11.25 15ZM3.75 1.5C3.15326 1.5 2.58097 1.73705 2.15901 2.15901C1.73705 2.58097 1.5 3.15326 1.5 3.75V11.25C1.5 11.8467 1.73705 12.419 2.15901 12.841C2.58097 13.2629 3.15326 13.5 3.75 13.5H11.25C11.8467 13.5 12.419 13.2629 12.841 12.841C13.2629 12.419 13.5 11.8467 13.5 11.25V3.75C13.5 3.15326 13.2629 2.58097 12.841 2.15901C12.419 1.73705 11.8467 1.5 11.25 1.5H3.75ZM18 14.25V4.5C18 4.30109 17.921 4.11032 17.7803 3.96967C17.6397 3.82902 17.4489 3.75 17.25 3.75C17.0511 3.75 16.8603 3.82902 16.7197 3.96967C16.579 4.11032 16.5 4.30109 16.5 4.5V14.25C16.5 14.8467 16.2629 15.419 15.841 15.841C15.419 16.2629 14.8467 16.5 14.25 16.5H4.5C4.30109 16.5 4.11032 16.579 3.96967 16.7197C3.82902 16.8603 3.75 17.0511 3.75 17.25C3.75 17.4489 3.82902 17.6397 3.96967 17.7803C4.11032 17.921 4.30109 18 4.5 18H14.25C15.2442 17.9988 16.1973 17.6033 16.9003 16.9003C17.6033 16.1973 17.9988 15.2442 18 14.25Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={18} height={18} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { CopyIcon };

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const PhoneIcon = (props: Props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.7762 15.8225L16.436 15.0816C17.1219 14.4076 17.1653 13.2546 16.4251 12.5148L14.8027 11.2539C14.1088 10.5478 12.9689 10.5378 12.2694 11.2318L11.2408 12.2162C8.717 11.1141 6.73447 9.10339 5.75592 6.64143L6.75812 5.63013C7.10107 5.29311 7.29163 4.84809 7.29582 4.36811C7.30001 3.88813 7.11724 3.43985 6.8066 3.13046L5.51476 1.42579C4.8209 0.719713 3.68094 0.709764 2.99498 1.39047L2.20907 2.06364C1.46937 2.79054 1.05411 3.77362 1.04474 4.84691C0.999653 10.0134 7.79297 16.9196 12.9528 16.9646C14.0194 16.9739 15.0162 16.5759 15.7694 15.8358L15.7762 15.8225ZM1.71816 4.83946C1.72596 3.94616 2.06644 3.1291 2.66492 2.54097L3.45749 1.86786C3.90132 1.43171 4.61462 1.43794 5.02439 1.8482L6.31623 3.55287C6.52769 3.76805 6.64524 4.04909 6.64257 4.35575C6.63989 4.6624 6.51745 4.94134 6.30227 5.15281L5.13868 6.32269C5.04454 6.41521 5.01671 6.5483 5.06227 6.67537C6.09797 9.46452 8.33816 11.7442 11.1946 12.9225C11.3142 12.9702 11.4545 12.9447 11.5553 12.8523L12.7453 11.7093C13.1891 11.2731 13.9024 11.2794 14.3717 11.7501L15.9941 13.011C16.4302 13.4548 16.424 14.1681 15.9666 14.6242L15.3068 15.3651C14.6949 15.9664 13.872 16.2926 12.9787 16.2848C8.20556 16.2432 1.68984 9.61272 1.7315 4.83957L1.71816 4.83946Z"
      fill={props.color || "var(--primary-900)"}
    />
  </svg>
);

export { PhoneIcon };

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const PreviousIcon = (props: Props) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      style={{ transition: "fill 0.3s ease-in-out" }}
      d="M9.72997 14L3.97814 8.17108C3.66991 7.85872 3.5 7.44261 3.5 7C3.5 6.5574 3.66991 6.14238 3.97814 5.82892L9.72997 0L10.5 0.780353L4.74817 6.60927C4.64579 6.71413 4.58915 6.8521 4.58915 7C4.58915 7.1479 4.64579 7.28587 4.74817 7.39073L10.5 13.2196L9.72997 14Z"
      fill={props.fill || "var(--primary-900)"}
    />
  </svg>
);

export { PreviousIcon };

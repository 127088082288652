import "./DropDown.css";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from "react-hook-form";
import { camelCase } from "../../../utilities/camelCase";
import { DpdownIcon } from "../../../assets/svg/dpdownIcon";

export type Option = {
  name: string;
  value: { lat: number; lng: number };
};

type Props = {
  options: string[] | Option[];
  placeholder: string;
  onChangeMake?: () => void;
  onChangeModel?: () => void;
  onChangeModelYear?: () => void;
  setValue?: UseFormSetValue<any>;
  watch?: UseFormWatch<any>;
  unregister?: UseFormUnregister<any>;
};

export const DropDown: FC<Props> = ({
  options,
  placeholder,
  onChangeMake,
  onChangeModel,
  onChangeModelYear,
  setValue,
  watch,
  unregister,
}) => {
  const { t } = useTranslation();
  const isDisabled = !options?.some((option) => option);

  const listRef = useRef<HTMLUListElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const label = t(`dropDownPlaceholder.${camelCase(placeholder)}`);

  const toggleDropdown = () => setIsOpen(!isOpen);

  // Handle option click
  const handleOptionClick = (option: string | Option) => {
    onChangeMake && onChangeMake();
    onChangeModel && onChangeModel();
    onChangeModelYear && onChangeModelYear();

    if (setValue) {
      setValue(camelCase(placeholder), option, { shouldValidate: true });

      if (typeof option === "object") {
        setValue(`${camelCase(placeholder)}.name`, option.name, {
          shouldValidate: true,
        });
        setValue(
          `${camelCase(placeholder)}.value`,
          { lat: option.value.lat, lng: option.value.lng },
          { shouldValidate: true }
        );
        unregister && unregister("latLng", { keepError: true });
      }
    }
  };

  // Get the selected option data
  const optionData =
    watch &&
    (typeof watch(camelCase(placeholder)) === "object"
      ? t(
          `${camelCase(placeholder)}.${camelCase(
            watch(`${camelCase(placeholder)}.name`)
          )}`
        )
      : placeholder === "Plate City"
      ? t(`city.${camelCase(watch(camelCase(placeholder)))}`)
      : placeholder === "Model Year" || placeholder === "Phone Number"
      ? !isNaN(watch(camelCase(placeholder))) &&
        t(`number`, { count: watch(camelCase(placeholder)) })
      : t(
          `${camelCase(placeholder)}.${camelCase(
            watch(camelCase(placeholder))
          )}`
        ).includes("trim")
      ? watch(camelCase(placeholder))
      : t(
          `${camelCase(placeholder)}.${camelCase(
            watch(camelCase(placeholder))
          )}`
        ));

  const selectedOption =
    typeof optionData === "string" &&
    !optionData.includes("undefined") &&
    optionData;

  const selectedInEnglish = watch && watch(camelCase(placeholder));

  // Check if an option is selected
  const isOptionSelected = useMemo(
    () => (option: string | Option) =>
      !!selectedInEnglish && selectedInEnglish === option,
    [selectedInEnglish]
  );

  // Map options
  const mapOptions = (option: string | Option) => {
    const optionName =
      typeof option === "string" || typeof option === "number"
        ? option
        : option.name;
    const isCurrency = optionName === "usd" || optionName === "iqd";

    return (
      <li
        key={optionName.toString()}
        className={isOptionSelected(option) ? "selected" : ""}
        onClick={() => handleOptionClick(option)}
      >
        {placeholder === "Plate City"
          ? t(`city.${camelCase(optionName)}`)
          : placeholder === "Phone Number" || placeholder === "Model Year"
          ? !isNaN(Number(optionName)) &&
            t("number", { count: Number(optionName) })
          : t(`${camelCase(placeholder)}.${camelCase(optionName)}`).includes(
              "trim"
            )
          ? optionName
          : t(`${camelCase(placeholder)}.${camelCase(optionName)}`)}
        {isCurrency && " "}
        {optionName === "usd" ? "($)" : optionName === "iqd" && "(دينار)"}
      </li>
    );
  };

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        headerRef.current &&
        !headerRef.current.contains(event.target as HTMLElement)
      ) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Scroll to selected option
  useEffect(() => {
    if (isOpen && listRef.current) {
      // Find the selected option element
      const selectedElement = listRef.current.querySelector(".selected");

      // Scroll to the selected element if found
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest", // Scroll to the nearest edge of the element (top/bottom)
        });
      }
    }
  }, [isOpen, selectedOption]);

  return (
    <div id="drop-down" className={isDisabled ? "d-disabled" : ""}>
      <div className="dd-container">
        <div
          className="dd-header"
          ref={headerRef}
          onClick={toggleDropdown}
          style={isOpen ? { borderColor: "var(--primary-900)" } : {}}
        >
          <p>
            {selectedOption && selectedOption}
            {(selectedOption === "usd" || selectedOption === "iqd") && " "}
            {selectedOption === "usd"
              ? "($)"
              : selectedOption === "iqd" && "(دينار)"}
          </p>
          <DpdownIcon isDpOpen={isOpen} width={14} />
        </div>
        <label
          style={
            isOpen || selectedOption
              ? {
                  top: "0.875rem",
                  fontSize: "0.75rem",
                }
              : {}
          }
        >
          {label}
        </label>
        {isOpen && (
          <div className="ddl-container">
            <ul className="dd-list" ref={listRef}>
              {options.map((option) => mapOptions(option))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

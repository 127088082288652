import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const LeftArrowIcon = (props: Props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.65565 14.4503C6.52707 14.5888 6.35278 14.6667 6.17106 14.6667C5.98934 14.6667 5.81505 14.5888 5.68647 14.4503L0.200547 8.5323C0.0721301 8.39359 4.08467e-07 8.20558 4.08467e-07 8.00954C4.08467e-07 7.81351 0.0721301 7.62549 0.200547 7.48678L5.68647 1.56877C5.74925 1.49609 5.82495 1.43779 5.90907 1.39736C5.99319 1.35693 6.08399 1.33519 6.17607 1.33344C6.26814 1.33169 6.3596 1.34996 6.44499 1.38716C6.53037 1.42437 6.60794 1.47975 6.67305 1.54999C6.73817 1.62024 6.78951 1.70391 6.824 1.79602C6.85848 1.88814 6.87542 1.9868 6.8738 2.08612C6.87217 2.18545 6.85202 2.28341 6.81454 2.37415C6.77706 2.46489 6.72302 2.54656 6.65565 2.61429L2.34006 7.26979L15.3143 7.26979C15.4961 7.26979 15.6706 7.34773 15.7992 7.48646C15.9278 7.62519 16 7.81335 16 8.00954C16 8.20574 15.9278 8.39389 15.7992 8.53262C15.6706 8.67135 15.4961 8.74929 15.3143 8.74929L2.34006 8.74929L6.65565 13.4048C6.78406 13.5435 6.85619 13.7315 6.85619 13.9276C6.85619 14.1236 6.78406 14.3116 6.65565 14.4503Z"
      fill="#28282B"
    />
  </svg>
);

export { LeftArrowIcon };

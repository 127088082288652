import "./RentalCarsDetails.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetRentCarByModelQuery } from "../../app/api/rentCarsApiSlice";
import { useRentCar } from "../../hooks/useAuth";
import { useAd } from "../../hooks/useAd";
import { RentCarType, RentalCard } from "../../components/cards/RentalCard";
import { CarImagesSlider } from "../../components/car image slider/CarImagesSlider";
import { WhiteLoading } from "../../components/customs/loading/WhiteLoading";
import {
  formatCurrency,
  iqdFormatCurrency,
} from "../../utilities/formatCurrency";
import { camelCase } from "../../utilities/camelCase";
import { LocationIcon } from "../../assets/svg/locationIcon";
import { ClockIcon } from "../../assets/svg/clockIcon";
import { PhoneIcon } from "../../assets/svg/phoneIcon";
import { WhatsAppIcon } from "../../assets/svg/whatsAppIcon";
import { SEO } from "../../utilities/SEO";
import { ShareIcon } from "../../assets/svg/shareIcon";
import { ShareModal } from "../../components/share modal/ShareModal";
import { BackIcon } from "../../assets/svg/backIcon";

type similarCarsType = {
  data: RentCarType[];
  isLoading: boolean;
  isSuccess: boolean;
  isFetching: boolean;
  isError: boolean;
};
export const RentalCarsDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { carId } = useParams();
  const [showModal, setShowModal] = useState<boolean>(false);

  const [showNo, setShowNo] = useState<boolean>(false);

  // Get ad
  const { ad, isAdSuccess, isAdLoading, isAdFetching, isAdError, adError } =
    useAd("noyya");

  // Get rent car
  const { rentCar, isLoading, isSuccess, isFetching, isError, error } =
    useRentCar(carId as string);

  // Rent car showroom name in current language
  const showroomName = useMemo(() => {
    const showroomNames: { [key: string]: string } = {
      ar: rentCar?.showroom?.nameInAr,
      ku: rentCar?.showroom?.nameInKu,
      default: rentCar?.showroom?.name,
    };

    return showroomNames[i18n.language] || showroomNames.default;
  }, [
    i18n.language,
    rentCar?.showroom?.name,
    rentCar?.showroom?.nameInAr,
    rentCar?.showroom?.nameInKu,
  ]);

  // Get similar cars
  const { data: similarCars } = useGetRentCarByModelQuery(
    {
      model: rentCar?.model,
      make: rentCar?.make,
      id: carId,
    },
    {
      skip: !rentCar?.model,
    }
  ) as similarCarsType;

  // Set path of similar cars
  const path = location.pathname.split("/");
  const similarCarsPath = path.slice(0, -2).join("/") + "/";

  // Convert date to ago time
  const agoTime = (date: Date) => {
    const now = new Date();
    const diff = now.getTime() - new Date(date).getTime();

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
      if (seconds === 1)
        return t("agoTime.second", { number: t("number", { count: seconds }) });
      return t("agoTime.seconds", { number: t("number", { count: seconds }) });
    } else if (minutes < 60) {
      if (minutes === 1)
        return t("agoTime.minute", { number: t("number", { count: minutes }) });
      return t("agoTime.minutes", { number: t("number", { count: minutes }) });
    } else if (hours < 24) {
      if (hours === 1)
        return t("agoTime.hour", { number: t("number", { count: hours }) });
      return t("agoTime.hours", { number: t("number", { count: hours }) });
    } else if (days < 30) {
      if (days === 1)
        return t("agoTime.day", { number: t("number", { count: days }) });
      return t("agoTime.days", { number: t("number", { count: days }) });
    } else if (months < 12) {
      if (months === 1)
        return t("agoTime.month", { number: t("number", { count: months }) });
      return t("agoTime.months", { number: t("number", { count: months }) });
    } else {
      if (years === 1)
        return t("agoTime.year", { number: t("number", { count: years }) });
      return t("agoTime.years", { number: t("number", { count: years }) });
    }
  };

  // Share button handler
  const handleShareButton = () => {
    // Check if navigator.share is supported by the browser
    if (navigator?.share) {
      navigator.share({
        url: location.pathname,
        title: t("seo.carTitle", {
          make: t(`make.${camelCase(rentCar.make)}`),
          model: t(`model.${camelCase(rentCar.model)}`),
          modelYear: t("number", { count: rentCar.modelYear }),
          deal: t(`deal.rent`),
          city: t(`city.${camelCase(rentCar.location)}`),
        }).toString(),
      });
    } else {
      setShowModal(true);
    }
  };
  // Redirect to previous page if rentCar not found or error
  useEffect(() => {
    if (isError) {
      navigate("..");
    }
  }, [isError]);

  return (
    <>
      {/* SEO */}
      {rentCar && (
        <SEO
          title={t("seo.carTitle", {
            make: t(`make.${camelCase(rentCar.make)}`),
            model: t(`model.${camelCase(rentCar.model)}`),
            modelYear: t("number", { count: rentCar.modelYear }),
            deal: t(`deal.rent`),
            city: t(`city.${camelCase(rentCar.location)}`),
          })}
          titlePost={t("seo.carTitlePost", {
            deal: t(`deal.rent`),
            make: t(`make.${camelCase(rentCar.make)}`),
            model: t(`model.${camelCase(rentCar.model)}`),
            trim: t(`trim.${camelCase(rentCar.trim)}`).includes("trim")
              ? rentCar.trim
              : t(`trim.${camelCase(rentCar.trim)}`),
            modelYear: t("number", { count: rentCar.modelYear }),
            city: t(`city.${camelCase(rentCar.location)}`),
          }).toString()}
          description={t("seo.carDesc", {
            make: t(`make.${camelCase(rentCar.make)}`),
            model: t(`model.${camelCase(rentCar.model)}`),
            trim: t(`trim.${camelCase(rentCar.trim)}`).includes("trim")
              ? rentCar.trim
              : t(`trim.${camelCase(rentCar.trim)}`),
            modelYear: t("number", { count: rentCar.modelYear }),
            deal: t(`deal.rent`),
            city: t(`city.${camelCase(rentCar.location)}`),
            description: rentCar?.description || "",
          }).toString()}
          image={rentCar.images[rentCar.primaryImageIndex || 0]}
        />
      )}

      {/* Hero Section */}
      <section id="rcd-hero">
        {isLoading || isFetching ? (
          <WhiteLoading />
        ) : isSuccess && rentCar ? (
          <>
            {/* Header */}
            <header>
              <div>
                <h1>{t(`make.${camelCase(rentCar.make)}`)}</h1>
                <h2>
                  {t(`model.${camelCase(rentCar.model)}`)}
                  &nbsp;
                  {t(`trim.${camelCase(rentCar.trim)}`).includes("trim")
                    ? rentCar.trim
                    : t(`trim.${camelCase(rentCar.trim)}`)}
                  &nbsp;
                  {t("number", { count: rentCar.modelYear })}
                </h2>
              </div>

              {/* Share button */}
              <button className="share-btn" onClick={handleShareButton}>
                <ShareIcon />
              </button>
              {showModal && <ShareModal setShowModal={setShowModal} />}
            </header>
            {/* Car details */}
            <div className="container">
              <div className="left-container">
                {/* Images slider */}
                <CarImagesSlider
                  car={rentCar}
                  primaryImageIndex={rentCar?.primaryImageIndex || 0}
                />
                <div className="car-info">
                  {/* Basic information */}
                  <p>{t("rentCars.details.basicInfo")}</p>
                  <div className="carInfo-container">
                    <table>
                      <tbody>
                        <tr>
                          <td>{t("dropDownPlaceholder.make")}</td>
                          <td>{t(`make.${camelCase(rentCar.make)}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.model")}</td>
                          <td>{t(`model.${camelCase(rentCar.model)}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.exteriorColor")}</td>
                          <td>
                            {t(
                              `exteriorColor.${camelCase(
                                rentCar.exteriorColor
                              )}`
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("inputPlaceholder.bodyStyle")}</td>
                          <td>
                            {t(`bodyStyle.${rentCar.bodyStyle.toLowerCase()}`)}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.seating")}</td>
                          <td>{t(`seating.${camelCase(rentCar.seating)}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.interiorColor")}</td>
                          <td>
                            {t(
                              `interiorColor.${camelCase(
                                rentCar.interiorColor
                              )}`
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.seatMaterial")}</td>
                          <td>
                            {t(
                              `seatMaterial.${camelCase(rentCar.seatMaterial)}`
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <tbody>
                        <tr>
                          <td>{t("dropDownPlaceholder.modelYear")}</td>
                          <td> {t("number", { count: rentCar.modelYear })}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.trim")}</td>
                          <td>
                            {t(`trim.${camelCase(rentCar.trim)}`).includes(
                              "trim"
                            )
                              ? rentCar.trim
                              : t(`trim.${camelCase(rentCar.trim)}`)}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.engine")}</td>
                          <td>{t(`engine.${camelCase(rentCar.engine)}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.transmission")}</td>
                          <td>{t(`transmission.${rentCar.transmission}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.cylinders")}</td>
                          <td>
                            {t(`cylinders.${camelCase(rentCar.cylinders)}`)}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.fuelType")}</td>
                          <td>
                            {t(`fuelType.${camelCase(rentCar.fuelType)}`)}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.importer")}</td>
                          <td>
                            {rentCar.importer
                              ? t(`importer.${rentCar.importer}`)
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* Features */}
                {rentCar?.features && rentCar?.features?.length > 0 && (
                  <div className="features">
                    <p>{t("rentCars.details.features")}</p>
                    <div className="features-container">
                      {rentCar.features.map((feature, index) => (
                        <div key={index} className="feature">
                          <p>{t(`feature.${feature}`)}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {/* Description */}
                {rentCar?.description && rentCar?.description?.length > 0 && (
                  <div className="description">
                    <p>{t("rentCars.details.description")}</p>
                    <p> {rentCar.description}</p>
                  </div>
                )}
              </div>
              <div className="right-container">
                {/* Seller info */}
                <div className="seller-info">
                  <p>{t("inputPlaceholder.price")}</p>
                  {rentCar.status === "rented" ? (
                    <p id="rented-badge">
                      {t(`rentCars.badge.${rentCar.status}`)}
                    </p>
                  ) : rentCar?.price && rentCar?.isPriceShown ? (
                    <div className="price">
                      {rentCar.price?.daily !== 0 && (
                        <p>
                          {rentCar.priceUnit === "usd"
                            ? formatCurrency(rentCar.price.daily)
                            : iqdFormatCurrency(rentCar.price.daily)}
                          <span>
                            {i18n.language === "en" ? "/" : "\\"}
                            {t("rentCars.details.daily")}
                          </span>
                        </p>
                      )}
                      {rentCar.price?.weekly !== 0 && (
                        <p>
                          {rentCar.priceUnit === "usd"
                            ? formatCurrency(rentCar.price.weekly)
                            : iqdFormatCurrency(rentCar.price.weekly)}
                          <span>
                            {i18n.language === "en" ? "/" : "\\"}
                            {t("rentCars.details.weekly")}
                          </span>
                        </p>
                      )}
                      {rentCar.price?.monthly !== 0 && (
                        <p>
                          {rentCar.priceUnit === "usd"
                            ? formatCurrency(rentCar.price.monthly)
                            : iqdFormatCurrency(rentCar.price.monthly)}
                          <span>
                            {i18n.language === "en" ? "/" : "\\"}
                            {t("rentCars.details.monthly")}
                          </span>
                        </p>
                      )}
                    </div>
                  ) : (
                    <p className="no-price">{t("callUs")}</p>
                  )}
                  <div className="solid"></div>
                  <div className="row-2">
                    <LocationIcon width={22} height={22} color={"#fff"} />
                    <p>
                      {(rentCar?.showroom?.city?.name &&
                        t(`city.${camelCase(rentCar.showroom.city.name)}`)) ??
                        t(`city.${camelCase(rentCar.location)}`)}
                    </p>
                  </div>
                  <div className="row-2">
                    <ClockIcon width={22} height={22} />
                    <time>
                      {rentCar?.createdAt && agoTime(rentCar.createdAt)}
                    </time>
                  </div>
                  <div className="solid"></div>
                  <div className="showroom-info-container">
                    <p>{t("rentCars.details.ownerInfo")}</p>
                    {rentCar.showroom && (
                      <div
                        onClick={() =>
                          navigate(
                            `/${i18n.language}/showrooms/${rentCar.showroom._id}`
                          )
                        }
                        className="showroom-info"
                      >
                        <div className="showroom-logo">
                          <img
                            loading="lazy"
                            src={
                              rentCar.showroom.images[
                                rentCar.showroom.logoImageIndex || 0
                              ]
                            }
                            alt={showroomName + " logo"}
                          />
                        </div>
                        <p>{showroomName}</p>
                      </div>
                    )}

                    {/* Phone number */}
                    <a
                      onClick={() => setShowNo(!showNo)}
                      className="link-phone"
                      href={`tel:+964${rentCar.showroom?.phoneNumbers[0]}`}
                    >
                      <PhoneIcon />
                      {showNo
                        ? t("phone", {
                            count: Number(rentCar.showroom?.phoneNumbers[0]),
                          })
                        : t("btns&links.showPhoneNumber")}
                    </a>
                    {/* WhatsApp  */}
                    <a
                      className="link-whatsapp"
                      href={`https://api.whatsapp.com/send?phone=00964${
                        rentCar.showroom?.phoneNumbers[0]
                      }&text=${
                        t("chat.rentCar", {
                          car:
                            t(`make.${camelCase(rentCar.make)}`) +
                            " " +
                            t(`model.${camelCase(rentCar.model)}`) +
                            " " +
                            rentCar.modelYear,
                        }) +
                        " " +
                        window.location.href
                      }`}
                      target="_blank"
                    >
                      <WhatsAppIcon />
                      {t("btns&links.chat")}
                    </a>
                  </div>
                </div>
                {/* Ad */}
                {isAdLoading || isAdFetching ? (
                  <WhiteLoading />
                ) : isAdSuccess && ad ? (
                  <div className="ad-info">
                    {i18n.language === "en" ? (
                      <a
                        href={
                          `${ad?.website}` ||
                          `https://api.whatsapp.com/send?phone=${ad.phone}&text=${ad.title}`
                        }
                        target="_blank"
                      >
                        <img
                          loading="lazy"
                          src={ad.images[ad?.enImageIndex || 0]}
                          alt={ad.title}
                        />
                      </a>
                    ) : i18n.language === "ar" ? (
                      <a
                        href={
                          `${ad?.websiteAr}` ||
                          `https://api.whatsapp.com/send?phone=${ad.phone}&text=${ad.title}`
                        }
                        target="_blank"
                      >
                        <img
                          loading="lazy"
                          src={ad.images[ad?.arImageIndex || 0]}
                          alt={ad.title}
                        />
                      </a>
                    ) : (
                      <a
                        href={
                          `${ad?.websiteKu}` ||
                          `https://api.whatsapp.com/send?phone=${ad.phone}&text=${ad.title}`
                        }
                        target="_blank"
                      >
                        <img
                          loading="lazy"
                          src={ad.images[ad?.kuImageIndex || 0]}
                          alt={ad.title}
                        />
                      </a>
                    )}
                  </div>
                ) : (
                  isAdError && (
                    <p className="fetch-error">
                      {adError.status} <br />
                      {JSON.stringify(adError.data)}
                    </p>
                  )
                )}
              </div>
            </div>
          </>
        ) : (
          isError && (
            <p className="fetch-error">
              {error.status} <br />
              {JSON.stringify(error.data)}
            </p>
          )
        )}
      </section>
      {/* Feature Section */}
      {similarCars && similarCars.length > 0 && (
        <section id="rcd-feature">
          {/* Similar cars cards */}
          <div className="rental">
            <h3 className="heading">{t("rentCars.details.similarCars")}</h3>
            <div className="cards">
              {similarCars.map((rentCar) => (
                <RentalCard
                  key={rentCar._id}
                  data={rentCar}
                  path={similarCarsPath}
                />
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

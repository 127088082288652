import "./ShowroomAnalyticsTable.css";
import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EyeIcon } from "../../assets/svg/eyeIcon";
import { VisitorIcon } from "../../assets/svg/visitorIcon";
import { ShowroomType } from "../cards/ShowroomCard";
import { ConditionIcon } from "../../assets/svg/conditionIcon";

type Props = {
  thead: string[];
  showroom: ShowroomType;
  showroomPath: string;
  totalCars?: number;
  views: number;
  visitors: number;
};

export const ShowroomAnalyticsTable: FC<Props> = ({
  thead,
  showroom,
  showroomPath,
  totalCars,
  views,
  visitors,
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Showroom name
  const showroomName = useMemo(() => {
    const showroomNames: { [key: string]: string } = {
      ar: showroom?.nameInAr,
      ku: showroom?.nameInKu,
      default: showroom?.name,
    };

    return showroomNames[i18n.language] || showroomNames.default;
  }, [i18n.language, showroom?.name, showroom?.nameInAr, showroom?.nameInKu]);
  return (
    <div id="showroom-analytics-table">
      <table>
        <thead>
          <tr>
            {thead.map((title) => {
              return <th key={title}>{title}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {showroom && (
            <tr key={showroom._id.toString()}>
              <td
                onClick={() => navigate(showroomPath + showroom._id)}
                className="td-1"
              >
                <img
                  src={showroom.images[showroom.logoImageIndex || 0]}
                  alt=""
                />
                <div className="col-1">
                  <p className="name">{showroomName}</p>
                  <p className="type">
                    {t(`showroomType.${showroom.showroomType}`)}
                  </p>
                </div>
              </td>

              <td>
                {showroom.createdAt &&
                  (i18n.language !== "en"
                    ? new Date(showroom.createdAt).toLocaleDateString("ar-IQ", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })
                    : new Date(showroom.createdAt).toLocaleDateString("en-GB"))}
              </td>
              <td>
                <div className="view">
                  <EyeIcon />
                  {t(`number`, { count: views })}
                </div>
              </td>
              <td>
                <div className="view">
                  <VisitorIcon />
                  {t(`number`, { count: visitors })}
                </div>
              </td>
              <td>
                <div className="cars">
                  <ConditionIcon fill={"var(--primary-400)"} />
                  {t(`number`, { count: totalCars })}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

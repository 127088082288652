import { apiSlice } from "./apiSlice";

type makeType = string;
type modelType = string;
type modelYearType = string;
type modelYearArgsType = { modelName: string; makeName: string };
type trimType = string;
type trimArgsType = { modelName: string; modelYear: number };

export const carSpecsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMakes: builder.query<makeType[], void>({
      query: () => `carSpecs/makes`,
    }),
    getModels: builder.query<modelType[], string>({
      query: (makeName) => `carSpecs/models/${makeName}`,
    }),
    getModelYears: builder.query<modelYearType[], modelYearArgsType>({
      query: ({ modelName, makeName }) =>
        `carSpecs/modelYears/${modelName}/${makeName}`,
    }),
    getTrims: builder.query<trimType[], trimArgsType>({
      query: ({ modelName, modelYear }) =>
        `carSpecs/trims/${modelName}/${modelYear}`,
    }),
  }),
});

export const {
  useGetMakesQuery,
  useGetModelsQuery,
  useGetModelYearsQuery,
  useGetTrimsQuery,
} = carSpecsApiSlice;

import { t } from "i18next";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  currency: "USD",
  style: "currency",
  maximumFractionDigits: 0,
});

export const formatCurrency = (number: number | undefined) => {
  if (number) {
    return currencyFormatter.format(number);
  }
};

const iqdCurrencyFormatter = new Intl.NumberFormat("en-US", {
  // currency: "IQD",
  // style: "currency",
  maximumFractionDigits: 0,
});

export const iqdFormatCurrency = (number: number | undefined) => {
  if (number) {
    return iqdCurrencyFormatter.format(number) + ` ${t("iqd")}`;
  }
};

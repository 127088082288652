import { apiSlice } from "./apiSlice";

export const rentCarsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllRentCars: builder.query({
      query: ({
        page,
        limit = 2,
        make,
        model,
        fromYear,
        toYear,
        fuelType,
        location,
        transmission,
        sort,
      }) =>
        `rentCars?page=${page}&limit=${limit}&make=${make || ""}&model=${
          model || ""
        }&fromYear=${fromYear || ""}&toYear=${toYear || ""}&fuelType=${
          fuelType || ""
        }&location=${location || ""}&transmission=${
          transmission || ""
        }&sort=${sort}`,
      providesTags: ["RentCars"],
    }),
    getRentCarById: builder.query({
      query: (id) => `rentCars/${id}`,
      providesTags: ["RentCars"],
    }),
    getRentCarByModel: builder.query({
      query: ({ model, make, id }) => `rentCars/model/${model}/${make}/${id}`,
      providesTags: ["RentCars"],
    }),
    getRentCarsByShowroom: builder.query({
      query: ({ page, limit, showroomId, sort, status, isAnalytics }) =>
        `rentCars/r/${showroomId}?page=${page}&limit=${limit}&sort=${
          sort || ""
        }&status=${status || ""}&isAnalytics=${isAnalytics || ""}`,
      providesTags: ["RentCars"],
    }),

    addRentCar: builder.mutation({
      query: (credentials) => ({
        url: "rentCars",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["RentCars"],
    }),
    updateRentCarById: builder.mutation({
      query: ({ id, credentials }) => ({
        url: `rentCars/${id}`,
        method: "PATCH",
        body: credentials,
      }),
      invalidatesTags: ["RentCars"],
    }),
    deleteRentCarById: builder.mutation({
      query: (id) => ({
        url: `rentCars/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RentCars"],
    }),
  }),
});

export const {
  useGetAllRentCarsQuery,
  useGetRentCarByIdQuery,
  useGetRentCarByModelQuery,
  useGetRentCarsByShowroomQuery,
  useAddRentCarMutation,
  useUpdateRentCarByIdMutation,
  useDeleteRentCarByIdMutation,
} = rentCarsApiSlice;

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const TwitterXIcon = (props: Props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2085_2)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3333 24H2.66667C1.194 24 0 22.806 0 21.3333V2.66667C0 1.194 1.194 0 2.66667 0H21.3333C22.806 0 24 1.194 24 2.66667V21.3333C24 22.806 22.806 24 21.3333 24Z"
        fill={props.color || "#FFF"}
      />
      <path
        d="M18.72 18.72H14.485L5.28 5.28003H9.51503L18.72 18.72ZM14.9896 17.5803H16.6758L9.01041 6.41974H7.32416L14.9896 17.5803Z"
        fill={props.fill || "var(--primary-900)"}
      />
      <path
        d="M6.65879 18.72L11.52 12.8749L10.8843 12L5.28 18.72H6.65879Z"
        fill={props.fill || "var(--primary-900)"}
      />
      <path
        d="M12.48 10.6273L13.0801 11.52L18.24 5.28003H16.9137L12.48 10.6273Z"
        fill={props.fill || "var(--primary-900)"}
      />
    </g>
    <defs>
      <clipPath>
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { TwitterXIcon };

import "./BasicInfoRent.css";
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from "react-hook-form";
import { FC } from "react";
import { AddRentCarDataType } from "../../../pages/auth showroom/profile/Inventory management/outlet/add car/AddCar";
import { RentCarOptions, SaleCarOptions } from "../../../data/FilterData";
import {
  useMakes,
  useModels,
  useModelYears,
  useTrims,
} from "../../../hooks/useCarSpecs";
import { DropDown } from "../../customs/drop down/DropDown";
import { useTranslation } from "react-i18next";

type Props = {
  isAddCar?: boolean;
  watch: UseFormWatch<AddRentCarDataType>;
  setValue: UseFormSetValue<AddRentCarDataType>;
  unregister: UseFormUnregister<AddRentCarDataType>;
  errors: FieldErrors<AddRentCarDataType>;
  register: UseFormRegister<AddRentCarDataType>;
};

export const BasicInfoRent: FC<Props> = ({
  isAddCar,
  watch,
  setValue,
  unregister,
  register,
  errors,
}) => {
  const { t } = useTranslation();
  const make = watch("make");
  const model = watch("model");
  const modelYear = watch("modelYear");

  const { makes } = useMakes();
  const { models } = useModels(make);
  const { modelYears } = useModelYears(model, make);
  const { trims } = useTrims(model, modelYear);

  const handleMakeChange = () => {
    unregister(["model", "modelYear", "trim", "bodyStyle"], {
      keepError: true,
    });
  };
  const handleModelChange = () => {
    unregister(["modelYear", "trim", "bodyStyle"], { keepError: true });
  };
  const handleModelYearChange = () => {
    unregister("trim", { keepError: true });
  };

  return (
    <section id="basic-info-rent">
      <header>
        <p>{t(`showroomManagement.inventory.addCar.stepOneHeading`)}</p>
      </header>
      <div className="row">
        {!isAddCar && (
          <>
            <div className="col">
              <DropDown
                options={RentCarOptions.status}
                placeholder={"Status"}
                setValue={setValue}
                watch={watch}
              />
            </div>
            {watch("status") === "rented" && (
              <>
                <div className="col">
                  <DropDown
                    options={RentCarOptions.rentType}
                    placeholder={"Rent Type"}
                    setValue={setValue}
                    watch={watch}
                  />
                  {errors.rentType && (
                    <p className="error">{errors.rentType.message}</p>
                  )}
                </div>
                <div className="col">
                  <div className="date-input-group">
                    <input
                      id="toDateRent"
                      type="datetime-local"
                      {...register("toDateRent")}
                    />
                    <label htmlFor="toDateRent">
                      {t("inputPlaceholder.toDateRent")}
                    </label>
                  </div>
                  {errors.toDateRent && (
                    <p className="error">{errors.toDateRent.message}</p>
                  )}
                </div>
              </>
            )}
          </>
        )}

        <div className="col">
          <DropDown
            options={makes}
            placeholder={"Make"}
            onChangeMake={handleMakeChange}
            setValue={setValue}
            watch={watch}
          />
          {errors.make && <p className="error">{errors.make.message}</p>}
        </div>
        <div className="col">
          <DropDown
            placeholder="Model"
            options={models}
            onChangeModel={handleModelChange}
            setValue={setValue}
            watch={watch}
          />
          {errors.model && <p className="error">{errors.model.message}</p>}
        </div>
        <div className="col">
          <DropDown
            options={modelYears}
            onChangeModelYear={handleModelYearChange}
            placeholder={"Model Year"}
            setValue={setValue}
            watch={watch}
          />
          {errors.modelYear && (
            <p className="error">{errors.modelYear.message}</p>
          )}
        </div>
        <div className="col">
          <DropDown
            options={trims}
            placeholder={"Trim"}
            setValue={setValue}
            watch={watch}
          />
          {errors.trim && <p className="error">{errors.trim.message}</p>}
        </div>
        <div className="col">
          <DropDown
            options={SaleCarOptions.exteriorColor}
            placeholder={"Exterior Color"}
            setValue={setValue}
            watch={watch}
          />
          {errors.exteriorColor && (
            <p className="error">{errors.exteriorColor.message}</p>
          )}
        </div>
      </div>
      {make && (
        <div className="body-style-row">
          <p className="body-style-heading">
            {t("inputPlaceholder.bodyStyle")}
          </p>
          <div className="body-styles">
            {SaleCarOptions.bodyStyle[make].map((bodyStyle) => (
              <div key={bodyStyle} className="body-style">
                <input
                  id={bodyStyle}
                  type="radio"
                  value={bodyStyle}
                  {...register("bodyStyle", { required: true })}
                />
                <label htmlFor={bodyStyle}>
                  {t(`bodyStyle.${bodyStyle.toLowerCase()}`)}
                </label>
                <div className="body-img">
                  <img
                    src={SaleCarOptions.bodyStyleIcon[bodyStyle]}
                    alt={bodyStyle}
                  />
                </div>
              </div>
            ))}
          </div>
          {errors.bodyStyle && (
            <p className="error">{errors.bodyStyle.message}</p>
          )}
        </div>
      )}
    </section>
  );
};

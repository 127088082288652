import "./InventoryTabs.css";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const InventoryTabs = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const isActive = (path: string) => location.pathname.includes(path);
  return (
    <div id="inventory-tabs">
      <Link
        style={
          isActive("car-listings")
            ? {
                borderColor: "var(--primary-900)",
                color: "var(--primary-900)",
              }
            : {}
        }
        to={"car-listings"}
        className="tab"
      >
        {t("showroomManagement.inventory.carListings.heading")}
      </Link>
      <Link
        style={
          isActive("add-car")
            ? {
                borderColor: "var(--primary-900)",
                color: "var(--primary-900)",
              }
            : {}
        }
        to={"add-car"}
        className="tab"
      >
        {t("showroomManagement.inventory.addCar.heading")}
      </Link>
      <Link
        style={
          isActive("plate-listings")
            ? {
                borderColor: "var(--primary-900)",
                color: "var(--primary-900)",
              }
            : {}
        }
        to={"plate-listings"}
        className="tab"
      >
        {t("showroomManagement.inventory.plateListings.heading")}
      </Link>
      <Link
        style={
          isActive("add-plate")
            ? {
                borderColor: "var(--primary-900)",
                color: "var(--primary-900)",
              }
            : {}
        }
        to={"add-plate"}
        className="tab"
      >
        {t("showroomManagement.inventory.addPlate.heading")}
      </Link>
    </div>
  );
};

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const SpeedoIcon = (props: Props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1825_22)">
      <path
        d="M16 8.66665C16 10.608 15.2933 12.4806 14.0107 13.9393C13.23 14.8273 11.8627 14.9173 10.966 14.1393L10.57 13.798C10.4307 13.6773 10.416 13.4666 10.5353 13.3273C10.6573 13.1873 10.8673 13.174 11.006 13.2926L11.402 13.6346C12.024 14.1746 12.9713 14.114 13.51 13.4993C14.686 12.162 15.3333 10.446 15.3333 8.66665C15.3333 6.49532 14.3787 4.44865 12.7153 3.04998C11.0533 1.65265 8.85267 1.06932 6.67533 1.44932C3.52333 1.99932 1.13333 4.51398 0.728001 7.70665C0.456667 9.84398 1.08333 11.9026 2.49333 13.5033C3.022 14.1026 3.986 14.1633 4.59867 13.6353L4.994 13.2933C5.13267 13.1726 5.34333 13.188 5.46467 13.3273C5.58533 13.4666 5.56933 13.6766 5.43067 13.7973L5.03533 14.1393C4.62467 14.4946 4.116 14.6693 3.61067 14.6693C3.01133 14.6693 2.416 14.424 1.99333 13.9433C0.454667 12.1973 -0.229333 9.95265 0.0666672 7.62332C0.508667 4.13798 3.11867 1.39331 6.56133 0.792648C8.93333 0.374648 11.3313 1.01465 13.1447 2.53931C14.9593 4.06465 16 6.29798 16 8.66598V8.66665ZM9.148 7.98998C9.26533 8.18865 9.33333 8.41998 9.33333 8.66665C9.33333 9.40198 8.73533 9.99998 8 9.99998C7.26467 9.99998 6.66667 9.40198 6.66667 8.66665C6.66667 7.93132 7.26467 7.33332 8 7.33332C8.24667 7.33332 8.478 7.40132 8.67667 7.51865L11.0973 5.09798C11.2273 4.96798 11.4387 4.96798 11.5687 5.09798C11.6987 5.22798 11.6987 5.43932 11.5687 5.56932L9.148 7.98998ZM8.66667 8.66665C8.66667 8.29865 8.36733 7.99998 8 7.99998C7.63267 7.99998 7.33333 8.29865 7.33333 8.66665C7.33333 9.03465 7.63267 9.33332 8 9.33332C8.36733 9.33332 8.66667 9.03465 8.66667 8.66665Z"
        fill="#28282B"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { SpeedoIcon };

import "./MyAccount.css";
import { useEffect, useMemo, useState, KeyboardEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { useShowroom } from "../../../../../../hooks/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegisterData } from "../../../../../../data/RegisterData";
import { RegisterDataType } from "../../../../register/Register";
import * as yup from "yup";
import {
  useDeleteShowroomByIdMutation,
  useSendEmailOtpMutation,
  useUpdateShowroomByIdMutation,
} from "../../../../../../app/api/showroomApiSlice";
import { useLogoutMutation } from "../../../../../../app/api/authApiSlice";
import { Map } from "../../../../../../components/google map/Map";
import { OpeningDay } from "../../../../../../components/opening times/OpeningDay";
import { DropDown } from "../../../../../../components/customs/drop down/DropDown";
import { UploadShowroomImages } from "../../../../../../components/showroom upload image/UploadShowroomImages";
import { Loading } from "../../../../../../components/customs/loading/Loading";
import { DeleteModal } from "./modal/DeleteModal";
import { EmailModal } from "./modal/EmailModal";
import { CheckSolidIcon } from "../../../../../../assets/svg/checkSolidIcon";

export type UpdateDataType = Omit<
  RegisterDataType,
  "password" | "confirmPassword" | "phoneNumber" | "showroomType"
> & {
  deletedImages?: string[];
};

export const MyAccount = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { showroom, isLoading } = useShowroom();
  const [email, setEmail] = useState<string>("");
  const [images, setImages] = useState<FileList>([] as any);
  const [savedImages, setSavedImages] = useState<string[]>(
    () => showroom?.images
  );

  const [openModal, setOpenModal] = useState(false);
  const [emailOpenModal, setEmailOpenModal] = useState(false);

  const inputT = (name: string) => t(`inputPlaceholder.${name}`);

  // Update showroom mutation
  const [Update, { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess }] =
    useUpdateShowroomByIdMutation();

  // Delete showroom mutation
  const [
    Delete,
    {
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      error: deleteError,
    },
  ] = useDeleteShowroomByIdMutation();

  // Logout mutation
  const [logout] = useLogoutMutation();

  // Send email otp mutation
  const [
    sendEmailOtp,
    { isLoading: isSendEmailOtpLoading, error: isSendEmailOtpError },
  ] = useSendEmailOtpMutation();

  // Email validation
  const validateEmail = useMemo(
    () =>
      yup
        .string()
        .email(t("errorMessage.email") as string)
        .matches(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          t("errorMessage.email") as string
        ),
    []
  );

  // Send email handle
  const sendEmail = async () => {
    try {
      await sendEmailOtp({ email }).unwrap();
      setEmailOpenModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  // Update showroom schema
  const updateSchema = yup.object().shape({
    name: yup.string().required(t("errorMessage.showroomName") as string),
    nameInAr: yup
      .string()
      .required(t("errorMessage.showroomNameInKu") as string),
    nameInKu: yup
      .string()
      .required(t("errorMessage.showroomNameInAr") as string),
    city: yup.object().shape({
      name: yup.string().required(t("errorMessage.city") as string),
      value: yup.object().shape({
        lat: yup.number().required(t("errorMessage.city") as string),
        lng: yup.number().required(t("errorMessage.city") as string),
      }),
    }),
    latLng: yup.object().shape({
      lat: yup.number().required(t("errorMessage.address") as string),
      lng: yup.number().required(t("errorMessage.address") as string),
    }),
    logoImageIndex: yup.number().required(t("errorMessage.logo") as string),

    images: yup
      .mixed<FileList>()
      .required(t("errorMessage.showroomImages") as string),
    iqdValue: yup
      .number()
      .required()
      .typeError(t("errorMessage.iqdValue") as string),
  });

  // Form hook
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    trigger,
  } = useForm<UpdateDataType>({
    resolver: yupResolver(updateSchema),
    mode: "onChange",
  });

  // Delete showroom handle
  const onDeleteAccount = async () => {
    try {
      await Delete(showroom._id).unwrap();
      await logout();
      localStorage.removeItem("token");
      navigate(`/${i18n.language}/sign-in`, {
        state: { accountDeleted: t("alert.accountDeleted") },
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Submit handler
  const onSubmit: SubmitHandler<UpdateDataType> = async (data) => {
    const updatedData = new FormData();

    updatedData.append("name", data.name);
    updatedData.append("nameInAr", data.nameInAr);
    updatedData.append("nameInKu", data.nameInKu);
    updatedData.append("city", JSON.stringify(data.city));
    updatedData.append("latLng", JSON.stringify(data.latLng));

    data.website && updatedData.append("website", data.website);

    data.description && updatedData.append("description", data.description);

    data.openingDays &&
      updatedData.append("openingDays", JSON.stringify(data.openingDays));

    if (data.logoImageIndex || typeof data.logoImageIndex === "number")
      updatedData.append("logoImageIndex", data.logoImageIndex.toString());

    if (data.primaryImageIndex || typeof data.primaryImageIndex === "number")
      updatedData.append(
        "primaryImageIndex",
        data.primaryImageIndex.toString()
      );

    data.dealershipType &&
      updatedData.append("dealershipType", data.dealershipType);

    if (images) {
      for (let i = 0; i < images.length; i++) {
        updatedData.append("images", images[i]);
      }
    }
    data.deletedImages &&
      updatedData.append("deletedImages", JSON.stringify(data.deletedImages));

    data.iqdValue && updatedData.append("iqdValue", data.iqdValue.toString());

    try {
      await Update({
        id: showroom._id,
        credentials: updatedData,
      }).unwrap();
      window.scrollTo(0, 0);
      setImages([] as any);
    } catch (error) {
      console.log(error);
    }
  };

  // Only allow numbers in input
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // only allow numbers
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 46
      )
    )
      e.preventDefault();
  };

  // Set showroom data to the form
  useEffect(() => {
    if (showroom) {
      reset({
        name: showroom.name,
        nameInAr: showroom.nameInAr,
        nameInKu: showroom.nameInKu,
        email: showroom.email,
        city: showroom.city,
        latLng: showroom.latLng,
        website: showroom.website,
        description: showroom.description,
        // showroomType: showroom.showroomType,
        openingDays: showroom.openingDays,
        logoImageIndex: showroom.logoImageIndex,
        primaryImageIndex: showroom.primaryImageIndex,
        images: showroom.images as any,
        dealershipType: showroom.dealershipType,
        iqdValue: showroom.iqdValue,
      });
    }
  }, [showroom]);

  // Set email to the form
  useEffect(() => {
    if (showroom?.email) {
      setEmail(showroom?.email);
    }
  }, [showroom?.email]);

  useEffect(() => {
    setSavedImages(showroom?.images);
  }, [showroom?.images]);

  if (isLoading) return <Loading />;
  return (
    <div id="my-account">
      <ToastContainer />
      {isUpdateSuccess && (
        <div className="row">
          <div className="success">
            {<CheckSolidIcon fill={"var(--green-600)"} />}
            <p>{t("alert.changesSaved")}</p>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="input-group disabled-input">
              <label>{inputT("showroomType")}</label>
              <input
                disabled
                type="text"
                value={t(`showroomType.${showroom.showroomType}`) as string}
              />
            </div>
          </div>
          {showroom?.showroomType === "sale" ? (
            <div className="col">
              <DropDown
                options={RegisterData.dealershipTypeOptions}
                placeholder={"Dealership Type"}
                setValue={setValue}
                watch={watch}
              />
              {errors.dealershipType && (
                <p className="error">{errors.dealershipType.message}</p>
              )}
            </div>
          ) : (
            <div className="col">
              <div className="input-group disabled-input">
                <label>{inputT("phoneNumber")}</label>
                <input
                  disabled
                  type="text"
                  value={"0" + showroom.phoneNumbers[0]}
                />
              </div>
            </div>
          )}
          <div className="col">
            <div className={`input-group`}>
              <label>{inputT("email")}</label>
              <input
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {email && !validateEmail.isValidSync(email) && (
                <p className="error">{t("errorMessage.email") as string}</p>
              )}
              {isSendEmailOtpError && (
                <p className="error">{isSendEmailOtpError?.data?.message}</p>
              )}
              {email !== (showroom.email || "") && (
                <button
                  id="verify-email"
                  type="button"
                  onClick={() =>
                    validateEmail.isValidSync(email) && sendEmail()
                  }
                  disabled={isSendEmailOtpLoading}
                >
                  {t("btns&links.verify")}
                </button>
              )}
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <input
                placeholder=" "
                type="text"
                {...register("name", { required: true })}
              />
              <label>{inputT("showroomName")}</label>
            </div>
            {errors.name && <p className="error">{errors.name.message}</p>}
          </div>
          <div className="col">
            <div className="input-group">
              <input
                placeholder=" "
                type="text"
                {...register("nameInKu", { required: true })}
              />
              <label>{inputT("showroomNameInKu")}</label>
            </div>
            {errors.nameInKu && (
              <p className="error">{errors.nameInKu.message}</p>
            )}
          </div>
          <div className="col">
            <div className="input-group">
              <input
                placeholder=" "
                type="text"
                {...register("nameInAr", { required: true })}
              />
              <label>{inputT("showroomNameInAr")}</label>
            </div>
            {errors.nameInAr && (
              <p className="error">{errors.nameInAr.message}</p>
            )}
          </div>
          <div className="col">
            <div className="input-group">
              <input placeholder=" " type="text" {...register("website")} />
              <label>{inputT("website")}</label>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <input
                placeholder=" "
                type="text"
                maxLength={4}
                {...register("iqdValue", { required: true })}
                onKeyDown={onKeyDown}
              />
              <label>{inputT("iqdValue")}</label>
            </div>
            {errors.iqdValue && (
              <p className="error">{errors.iqdValue.message}</p>
            )}
          </div>
          <div className="col">
            <DropDown
              options={RegisterData.cityOptions}
              placeholder={"City"}
              setValue={setValue}
              watch={watch}
              unregister={unregister}
            />
            {errors.city?.name && (
              <p className="error">{errors.city.name.message}</p>
            )}
          </div>
        </div>
        {watch("city") && (
          <div className="col">
            <div className="address">
              <p>{inputT("address")}</p>
              <div className="map">
                <Map setValue={setValue} watch={watch} />
                {errors.latLng?.lat && (
                  <p className="error">{errors.latLng.lat.message}</p>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="col">
          <div className="opening-times">
            <p>{t("inputPlaceholder.openingTimes.heading")}</p>
            <div className="days">
              {RegisterData.daysOfTheWeekOptions.map(
                (day) =>
                  day && (
                    <OpeningDay
                      key={day}
                      day={day}
                      register={register}
                      watch={watch}
                      setValue={setValue}
                      trigger={trigger}
                    />
                  )
              )}
            </div>
          </div>
        </div>

        <div className="last-row">
          <UploadShowroomImages
            setValue={setValue}
            unregister={unregister}
            watch={watch}
            errors={errors}
            images={images}
            setImages={setImages}
            savedImages={savedImages}
            setSavedImages={setSavedImages}
          />
          <div className="input-group">
            <textarea placeholder=" " {...register("description")} />
            <label>{inputT("aboutYourShowroom")}</label>
          </div>
        </div>

        <div className="action-btns">
          <button type="button" onClick={() => setOpenModal(true)}>
            {t("btns&links.deleteAccount")}
          </button>
          <button disabled={isUpdateLoading} type="submit">
            {isUpdateLoading ? t("loading") : t("btns&links.saveChanges")}
          </button>
        </div>
      </form>
      {openModal && (
        <DeleteModal
          setOpenModal={setOpenModal}
          onDeleteAccount={onDeleteAccount}
          isLoading={isDeleteLoading}
          error={deleteError}
        />
      )}
      {emailOpenModal && (
        <EmailModal
          setOpenModal={setEmailOpenModal}
          email={email}
          id={showroom._id}
        />
      )}
    </div>
  );
};

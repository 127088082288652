import { useEffect, useState } from "react";
import {
  useGetMakesQuery,
  useGetModelYearsQuery,
  useGetModelsQuery,
  useGetTrimsQuery,
} from "../app/api/carSpecsApiSlice";

// Get makes
export const useMakes = () => {
  const { data, isSuccess } = useGetMakesQuery() as {
    data: string[];
    isSuccess: boolean;
  };

  const [makes, setMakes] = useState<string[]>([]);
  useEffect(() => {
    if (isSuccess && data) {
      setMakes(data);
    } else {
      setMakes([]);
    }
  }, [data, isSuccess]);
  return { makes, isSuccess };
};

// Get models
export const useModels = (make: string) => {
  const { data, isSuccess } = useGetModelsQuery(make, {
    skip: !make,
  }) as {
    data: string[];
    isSuccess: boolean;
  };
  const [models, setModels] = useState<string[]>([]);
  useEffect(() => {
    if (isSuccess && data) {
      setModels(data);
    } else {
      setModels([]);
    }
  }, [data, isSuccess]);

  return { models, isSuccess };
};

// Get model years
export const useModelYears = (modelName: string, makeName: string) => {
  const { data, isSuccess } = useGetModelYearsQuery(
    { modelName, makeName },
    {
      skip: !modelName || !makeName,
    }
  ) as {
    data: string[];
    isSuccess: boolean;
  };
  const [modelYears, setModelYears] = useState<string[]>([]);
  useEffect(() => {
    if (isSuccess && data) {
      setModelYears(data);
    } else {
      setModelYears([]);
    }
  }, [data, isSuccess]);
  return { modelYears, isSuccess };
};

// Get trims
export const useTrims = (modelName: string, modelYear: number) => {
  const { data, isSuccess } = useGetTrimsQuery(
    { modelName, modelYear },
    {
      skip: !modelName || !modelYear,
    }
  ) as {
    data: string[];
    isSuccess: boolean;
  };
  const [trims, setTrims] = useState<string[]>([]);
  useEffect(() => {
    if (isSuccess && data) {
      setTrims(data);
    } else {
      setTrims([]);
    }
  }, [data, isSuccess]);

  return { trims, isSuccess };
};

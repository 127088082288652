import "./UploadShowroomImages.css";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
  FieldErrors,
} from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { UpdateDataType } from "../../pages/auth showroom/profile/settings/outlet/my account info/MyAccount";
import { CheckIcon } from "../../assets/svg/checkIcon";
import { CheckSolidIcon } from "../../assets/svg/checkSolidIcon";
import { CloseIcon } from "../../assets/svg/closeIcon";
import { ImgIcon } from "../../assets/svg/imgIcon";
import Compressor from "compressorjs";

type Props = {
  setValue: UseFormSetValue<UpdateDataType>;
  unregister: UseFormUnregister<UpdateDataType>;
  watch: UseFormWatch<UpdateDataType>;
  errors: FieldErrors<UpdateDataType>;
  images: FileList;
  setImages: Dispatch<SetStateAction<FileList>>;
  savedImages: string[];
  setSavedImages: Dispatch<SetStateAction<string[]>>;
};

export const UploadShowroomImages: FC<Props> = ({
  setValue,
  unregister,
  watch,
  errors,
  images,
  setImages,
  savedImages,
  setSavedImages,
}) => {
  const { t } = useTranslation();
  const [deletedImages, setDeletedImages] = useState<string[]>([]);
  const primaryImageIndex = watch("primaryImageIndex");
  const logoImageIndex = watch("logoImageIndex");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const inputT = (key: string) => t(`inputPlaceholder.${key}`);

  // Add images
  const handleImagesChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    // max 10 images
    if (savedImages.length + images.length + e.target.files!.length > 10) {
      toast.error(t("alert.showroomImagesMax"));
      return;
    }

    const files = e.target.files;
    if (files) {
      const newImages: File[] = Array.from(files);

      const updatedImages = Array.from(images).filter(
        (existingImage) =>
          !newImages.some((newImage) => newImage.name === existingImage.name)
      );

      const isDuplicate = newImages.some((newImage) =>
        Array.from(images).some(
          (existingImage) => existingImage.name === newImage.name
        )
      );

      if (!isDuplicate) {
        // Compress each new image before adding it
        const compressedImages = await Promise.all(
          newImages.map(
            (newImage) =>
              new Promise<File>((resolve) => {
                const currentImg = new Image();
                currentImg.src = URL.createObjectURL(newImage);

                currentImg.onload = () => {
                  const originalWidth = currentImg.width;
                  const originalHeight = currentImg.height;

                  let targetWidth = originalWidth;
                  let targetHeight = originalHeight;

                  if (originalWidth > 1920 && originalHeight < originalWidth) {
                    targetWidth = 1920;
                    targetHeight = 1080;
                  } else if (
                    originalHeight > 1920 &&
                    originalWidth < originalHeight
                  ) {
                    targetWidth = 1080;
                    targetHeight = 1920;
                  }

                  new Compressor(newImage, {
                    quality: 0.8, // Adjust the quality as needed
                    width: targetWidth,
                    height: targetHeight,

                    success(result) {
                      // Convert the compressed result to a File
                      const compressedFile = new File([result], newImage.name, {
                        type: result.type,
                        lastModified: Date.now(),
                      });
                      resolve(compressedFile);
                    },
                    error(err) {
                      console.error("Image compression failed:", err.message);
                      resolve(newImage); // Fallback to the original image if compression fails
                    },
                  });
                };
              })
          )
        );

        const allImages: File[] = [...updatedImages, ...compressedImages];

        const fileList = new DataTransfer();
        allImages.forEach((file) => fileList.items.add(file));
        setImages(fileList.files);
        setIsLoading(false);
      } else {
        toast.error(t("alert.imageAlreadyExists"));
        setIsLoading(false);
      }
    }
  };

  // Handle delete images
  const handleDeleteImage = (index: number) => {
    // cannot delete if less than 2 images
    if (savedImages.length + images.length < 3) {
      toast.error(t("alert.showroomImagesDelete"));
      return;
    }
    const newImages = Array.from(images).filter(
      (image, i) => i + savedImages.length !== index
    );
    const isPrimary = primaryImageIndex === index;
    const isLogo = logoImageIndex === index;

    const fileList = new DataTransfer();
    newImages.forEach((file) => fileList.items.add(file));

    setImages(fileList.files);
    if (isPrimary) {
      unregister("primaryImageIndex", { keepError: true });
    } else if (index < Number(primaryImageIndex)) {
      setValue("primaryImageIndex", Number(primaryImageIndex) - 1, {
        shouldValidate: true,
      });
    }
    if (isLogo) {
      unregister("logoImageIndex", { keepError: true });
    } else if (index < Number(logoImageIndex)) {
      setValue("logoImageIndex", Number(logoImageIndex) - 1, {
        shouldValidate: true,
      });
    }

    const inputElement = document.querySelector("#showroom-images");
    if (inputElement instanceof HTMLInputElement) {
      inputElement.files = fileList.files;
    }
  };

  // Handle delete saved images
  const handleDeleteSavedImage = (index: number) => {
    // cannot delete if less than 2 images
    if (savedImages.length + images.length < 3) {
      toast.error(t("alert.showroomImagesDelete"));
      return;
    }
    const newImages = savedImages.filter((image, i) => i !== index);
    const isPrimary = primaryImageIndex === index;
    const isLogo = logoImageIndex === index;

    setSavedImages(newImages);
    if (isPrimary) {
      unregister("primaryImageIndex", { keepError: true });
    } else if (index < Number(primaryImageIndex)) {
      setValue("primaryImageIndex", Number(primaryImageIndex) - 1, {
        shouldValidate: true,
      });
    }
    if (isLogo) {
      unregister("logoImageIndex", { keepError: true });
    } else if (index < Number(logoImageIndex)) {
      setValue("logoImageIndex", Number(logoImageIndex) - 1, {
        shouldValidate: true,
      });
    }
    setDeletedImages([...deletedImages, savedImages[index]]);
    setValue("deletedImages", [...deletedImages, savedImages[index]], {
      shouldValidate: true,
    });
  };

  return (
    <div id="upload-showroom-images">
      <div className="images-input">
        <input
          id="showroom-images"
          type="file"
          onChange={handleImagesChange}
          multiple
          accept="image/*"
        />
        <label htmlFor="showroom-images">
          <ImgIcon />
          {isLoading ? t("loading") : inputT("uploadImages")}
        </label>
      </div>
      {errors.images && <p className="error">{errors.images.message}</p>}
      {errors.logoImageIndex && (
        <p className="error">{errors.logoImageIndex.message}</p>
      )}

      <div className="images-preview">
        {savedImages &&
          savedImages?.map((image, index) => (
            <div key={index}>
              <button
                className="primary-img-btn"
                type="button"
                onClick={() => {
                  setValue("primaryImageIndex", index, {
                    shouldValidate: true,
                  });
                }}
              >
                {primaryImageIndex === index ? (
                  <CheckSolidIcon width={16} height={16} />
                ) : (
                  <CheckIcon width={16} height={16} />
                )}
                {primaryImageIndex === index
                  ? inputT("primary")
                  : inputT("setAsPrimary")}
              </button>
              <div className="image">
                <img src={image} alt="preview" />
                <button
                  type="button"
                  onClick={() => handleDeleteSavedImage(index)}
                >
                  <CloseIcon width={12} height={12} />
                </button>
              </div>
              <button
                type="button"
                onClick={() => {
                  setValue("logoImageIndex", index, {
                    shouldValidate: true,
                  });
                }}
              >
                {logoImageIndex === index ? (
                  <CheckSolidIcon width={16} height={16} />
                ) : (
                  <CheckIcon width={16} height={16} />
                )}

                {logoImageIndex === index
                  ? inputT("logo")
                  : inputT("setAsLogo")}
              </button>
            </div>
          ))}

        {images &&
          Array.from(images).map((image, index) => (
            // change index number to after saved images index
            <div key={index + savedImages.length}>
              <button
                className="primary-img-btn"
                type="button"
                onClick={() => {
                  setValue("primaryImageIndex", index + savedImages.length, {
                    shouldValidate: true,
                  });
                }}
              >
                {primaryImageIndex === index + savedImages.length ? (
                  <CheckSolidIcon width={16} height={16} />
                ) : (
                  <CheckIcon width={16} height={16} />
                )}
                {primaryImageIndex === index + savedImages.length
                  ? inputT("primary")
                  : inputT("setAsPrimary")}
              </button>
              <div className="image">
                <img src={URL.createObjectURL(image)} alt="preview" />
                <button
                  type="button"
                  onClick={() => handleDeleteImage(index + savedImages.length)}
                >
                  <CloseIcon width={12} height={12} />
                </button>
              </div>
              <button
                type="button"
                onClick={() => {
                  setValue("logoImageIndex", index + savedImages.length, {
                    shouldValidate: true,
                  });
                }}
              >
                {logoImageIndex === index + savedImages.length ? (
                  <CheckSolidIcon width={16} height={16} />
                ) : (
                  <CheckIcon width={16} height={16} />
                )}

                {logoImageIndex === index + savedImages.length
                  ? inputT("logo")
                  : inputT("setAsLogo")}
              </button>
            </div>
          ))}
        <ToastContainer />
      </div>
    </div>
  );
};

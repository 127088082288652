import "./ChangePass.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useShowroom } from "../../../../../../hooks/useAuth";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useChangePasswordMutation } from "../../../../../../app/api/showroomApiSlice";
import { EyeIcon } from "../../../../../../assets/svg/eyeIcon";
import { CheckSolidIcon } from "../../../../../../assets/svg/checkSolidIcon";

type changePassDataType = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export const ChangePass = () => {
  const { t } = useTranslation();
  const inputT = (key: string) => t(`inputPlaceholder.${key}`);

  // State variables for password visibility
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  // Get showroom
  const { showroom } = useShowroom();

  // Change password mutation
  const [
    changePassword,
    { isLoading, isError, error, isSuccess: isChangePasswordSuccess },
  ] = useChangePasswordMutation();

  // Change password schema
  const changePassSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .required(t("errorMessage.currentPassword") as string),
    newPassword: yup
      .string()
      .required(t("errorMessage.newPassword") as string)
      .min(8, t("errorMessage.passwordLength") as string),
    confirmNewPassword: yup
      .string()
      .required(t("errorMessage.confirmNewPassword") as string)
      .oneOf(
        [yup.ref("newPassword")],
        t("errorMessage.passwordsMatch") as string
      ),
  });

  // Form hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<changePassDataType>({
    resolver: yupResolver(changePassSchema),
    mode: "onChange",
  });

  // Submit handler
  const onSubmit: SubmitHandler<changePassDataType> = async (data) => {
    const { oldPassword, newPassword } = data;
    try {
      await changePassword({
        id: showroom?._id,
        credentials: { oldPassword, newPassword },
      }).unwrap();
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="change-pass">
      {isChangePasswordSuccess && (
        <div className="row">
          <div className="success">
            {<CheckSolidIcon fill={"var(--green-600)"} />}
            <p>{t("alert.passwordChanged")}</p>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          {/* Col */}
          <div className="col">
            <div className="password">
              <div className="input-group">
                <input
                  placeholder=" "
                  type={showOldPassword ? "text" : "password"}
                  {...register("oldPassword", { required: true })}
                />
                <label>{inputT("currentPassword")}</label>
              </div>
              <button
                type="button"
                onClick={() => setShowOldPassword(!showOldPassword)}
              >
                <EyeIcon />
              </button>
            </div>
            {errors.oldPassword && (
              <p className="error">{errors.oldPassword.message}</p>
            )}
          </div>
          {/* Col */}
          <div className="col">
            <div className="password">
              <div className="input-group">
                <input
                  placeholder=" "
                  type={showPassword ? "text" : "password"}
                  {...register("newPassword", { required: true })}
                />
                <label>{inputT("newPassword")}</label>
              </div>
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                <EyeIcon />
              </button>
            </div>
            {errors.newPassword && (
              <p className="error">{errors.newPassword.message}</p>
            )}
          </div>
          {/* Col */}
          <div className="col">
            <div className="password">
              <div className="input-group">
                <input
                  placeholder=" "
                  type={showConfirmPassword ? "text" : "password"}
                  {...register("confirmNewPassword", { required: true })}
                />
                <label>{inputT("confirmNewPassword")}</label>
              </div>
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                <EyeIcon />
              </button>
            </div>
            {errors.confirmNewPassword && (
              <p className="error">{errors.confirmNewPassword.message}</p>
            )}
          </div>
        </div>
        {isError && <p className="error">{error?.data?.message}</p>}
        <button type="submit" disabled={isLoading}>
          {isLoading ? t("loading") : t("btns&links.changePassword")}
        </button>
      </form>
    </div>
  );
};

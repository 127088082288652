import "./Settings.css";
import { Outlet } from "react-router-dom";
import { SettingsTabs } from "../tabs/settings tabs/SettingsTabs";
import { useTranslation } from "react-i18next";

export const Settings = () => {
  const { t } = useTranslation();
  return (
    <div id="settings">
      <p className="settings-heading">
        {t("showroomManagement.settings.heading")}
      </p>
      <SettingsTabs />
      <Outlet />
    </div>
  );
};

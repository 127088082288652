import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const VisitorIcon = (props: Props) => (
  <svg
    width={18}
    height={23}
    viewBox="0 0 18 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 11C11.4818 11 13.5 8.98175 13.5 6.5C13.5 4.01825 11.4818 2 9 2C6.51825 2 4.5 4.01825 4.5 6.5C4.5 8.98175 6.51825 11 9 11ZM9 2.75C11.0678 2.75 12.75 4.43225 12.75 6.5C12.75 8.56775 11.0678 10.25 9 10.25C6.93225 10.25 5.25 8.56775 5.25 6.5C5.25 4.43225 6.93225 2.75 9 2.75ZM15 16.25V20H14.25V16.25C14.25 14.5955 12.9045 13.25 11.25 13.25H6.75C5.0955 13.25 3.75 14.5955 3.75 16.25V20H3V16.25C3 14.1823 4.68225 12.5 6.75 12.5H11.25C13.3178 12.5 15 14.1823 15 16.25Z"
      fill="#7C7C85"
    />
  </svg>
);

export { VisitorIcon };

import "./Map.css";
import { useMemo, FC } from "react";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import {
  GoogleMap,
  useLoadScript,
  MarkerF as Marker,
} from "@react-google-maps/api";
import { Loading } from "../customs/loading/Loading";

export type typeLocation = {
  lat: number;
  lng: number;
};
export type cityType = {
  name: string;
  value: { lat: number; lng: number };
};
type Props = {
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
};
export const Map: FC<Props> = ({ setValue, watch }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  });

  const city = watch("city");
  const address = watch("latLng");

  const handleMapClick = async (event: { latLng: any }) => {
    const { latLng } = event;

    if (latLng) {
      const lat = await latLng.lat();
      const lng = await latLng.lng();

      setValue("latLng", { lat, lng }, { shouldValidate: true });
    }
  };
  const center = useMemo(
    () => (address ? address : { lat: city.value.lat, lng: city.value.lng }),
    [city, address]
  );

  if (!isLoaded) return <Loading />;

  return (
    city && (
      <GoogleMap
        zoom={15}
        center={address || center}
        mapContainerClassName="map-container"
        onClick={handleMapClick}
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          keyboardShortcuts: false,
        }}
      >
        {address && <Marker position={address} />}
      </GoogleMap>
    )
  );
};

import { t } from "i18next";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import defaultImageSrc from "../assets/images/NoyyaBlack72.png";

type SEOProps = {
  title: string;
  titlePost?: string;
  description?: string;
  image?: string;
};
export const SEO: FC<SEOProps> = ({ title, titlePost, description, image }) => {
  const { pathname } = useLocation();
  const defaultTitle = t("seo.homeTitle").toString();
  const defaultDescription = t("seo.homeDescription").toString();
  const url = "https://www.noyya.net" + pathname;
  const twitterUsername = "@noyya_iq";

  return (
    <Helmet title={title || defaultTitle}>
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta name="image" content={image || defaultImageSrc} />
      <link rel="canonical" href={url} />

      {/* OG Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={titlePost || defaultTitle} />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image || defaultImageSrc} />
      {/* Twitter tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={titlePost || defaultTitle} />
      <meta
        name="twitter:description"
        content={description || defaultDescription}
      />
      <meta name="twitter:image" content={image || defaultImageSrc} />
    </Helmet>
  );
};

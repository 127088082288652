import "./MileageDetails.css";
import { FC, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  UseFormWatch,
  UseFormSetValue,
  UseFormRegister,
  FieldErrors,
} from "react-hook-form";
import { SaleCarOptions } from "../../../../../../../../data/FilterData";
import { DropDown } from "../../../../../../../../components/customs/drop down/DropDown";
import { AddCarDataType } from "../../../add car/AddCar";

type MileageDetailsProps = {
  watch: UseFormWatch<AddCarDataType>;
  setValue: UseFormSetValue<AddCarDataType>;
  register: UseFormRegister<AddCarDataType>;
  errors: FieldErrors<AddCarDataType>;
};
export const MileageDetails: FC<MileageDetailsProps> = ({
  watch,
  setValue,
  register,
  errors,
}) => {
  const { t } = useTranslation();

  // Only allow numbers in input
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // only allow numbers
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 46
      )
    )
      e.preventDefault();
  };

  return (
    <section id="mileage-details">
      <header>
        <p>{t("showroomManagement.inventory.addCar.stepTwoHeading")}</p>
      </header>
      <div className="row">
        <div className="col">
          <div className="mileage">
            <div className="mileage-unit">
              <DropDown
                options={SaleCarOptions.mileageUnit}
                placeholder={"Mileage Unit"}
                setValue={setValue}
                watch={watch}
              />
            </div>
            <div className="input-group">
              <input
                placeholder=" "
                type="number"
                {...register("mileage", { required: true })}
              />
              <label>{t("inputPlaceholder.mileage")}</label>
            </div>
          </div>
          {errors.mileageUnit && (
            <p className="error">{errors.mileageUnit.message}</p>
          )}
          {errors.mileage && <p className="error">{errors.mileage.message}</p>}
        </div>
        <div className="col">
          <DropDown
            options={SaleCarOptions.plateCategory}
            placeholder={"Plate Category"}
            setValue={setValue}
            watch={watch}
          />
          {errors.plateCategory && (
            <p className="error">{errors.plateCategory.message}</p>
          )}
        </div>
        <div className="col">
          <DropDown
            options={SaleCarOptions.plateCity}
            placeholder={"Plate City"}
            setValue={setValue}
            watch={watch}
          />
          {errors.plateCity && (
            <p className="error">{errors.plateCity.message}</p>
          )}
        </div>
        <div className="col">
          <DropDown
            options={SaleCarOptions.interiorColor}
            placeholder={"Interior Color"}
            setValue={setValue}
            watch={watch}
          />
          {errors.interiorColor && (
            <p className="error">{errors.interiorColor.message}</p>
          )}
        </div>
        <div className="col">
          <DropDown
            options={SaleCarOptions.seatMaterial}
            placeholder={"Seat Material"}
            setValue={setValue}
            watch={watch}
          />
          {errors.seatMaterial && (
            <p className="error">{errors.seatMaterial.message}</p>
          )}
        </div>
        <div className="col">
          <DropDown
            options={SaleCarOptions.seating}
            placeholder={"Seating"}
            setValue={setValue}
            watch={watch}
          />
          {errors.seating && <p className="error">{errors.seating.message}</p>}
        </div>
      </div>
    </section>
  );
};

import "./CarImagesSlider.css";
import "swiper/css";
import { FC, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Keyboard } from "swiper/modules";
import SwiperType from "swiper";
import { useTranslation } from "react-i18next";
import { CarType } from "../cards/SaleCard";
import { RentCarType } from "../cards/RentalCard";
import { camelCase } from "../../utilities/camelCase";
import { ExpandIcon } from "../../assets/svg/expandIcon";
import { CompressIcon } from "../../assets/svg/compressIcon";
import { BackIcon } from "../../assets/svg/backIcon";
import { useNavigate } from "react-router-dom";

type propsType = {
  car: CarType | RentCarType;
  primaryImageIndex: number;
};
export const CarImagesSlider: FC<propsType> = ({ car, primaryImageIndex }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType>();
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const onSlideChange = (swiper: SwiperType) => {
    // i want to next slide visible in thumbs slider
    if (car.images?.length && swiper.activeIndex < car.images?.length - 1)
      thumbsSwiper?.slideTo(swiper.activeIndex);
    // i want to prev slide visible in thumbs slider
    if (swiper.activeIndex > 0) thumbsSwiper?.slideTo(swiper.activeIndex - 1);
  };

  const handleFullScreen = () => {
    const carousel = document.querySelector(".swiper-carousel");
    const prevbtn = document.querySelector(".prev-btn");
    // Check if the document is already in full screen mode
    if (!isFullScreen && carousel) {
      // Add the full-screen class to the swiper container
      carousel.classList.add("full-screen");
      prevbtn?.classList.add("prev-btn-hidden");
      setIsFullScreen(true);
    } else {
      // Remove the full-screen class from the swiper container
      document
        .querySelector(".swiper-carousel")
        ?.classList.remove("full-screen");
      prevbtn?.classList.remove("prev-btn-hidden");
      setIsFullScreen(false);
    }
  };

  const swiperSlides = car.images?.map((image, index) => (
    <SwiperSlide key={index}>
      <img
        loading="lazy"
        src={image}
        alt={
          t(`make.${camelCase(car.make)}`) +
          " " +
          t(`model.${camelCase(car.model)}`) +
          " " +
          t("number", { count: car.modelYear })
        }
      />
    </SwiperSlide>
  ));

  // Move the item with primaryImageIndex to the front
  if (
    swiperSlides &&
    primaryImageIndex !== undefined &&
    primaryImageIndex >= 0
  ) {
    const primaryImageSlide = swiperSlides.splice(primaryImageIndex, 1)[0];
    swiperSlides.unshift(primaryImageSlide);
  }

  return (
    <section className="swiper-carousel">
      <button className="prev-btn" onClick={() => navigate(-1)}>
        <BackIcon width={14} height={14} fill={"#fff"} />
      </button>
      <Swiper
        dir={document.body.dir}
        navigation={true}
        slidesPerView={1}
        thumbs={thumbsSwiper && { swiper: thumbsSwiper }}
        modules={[Navigation, Thumbs, Keyboard]}
        className="car-images-slider"
        keyboard={{ enabled: true }}
        onSlideChange={onSlideChange}
      >
        {swiperSlides}
      </Swiper>
      <div onClick={handleFullScreen} className="full-screen-btn">
        {isFullScreen ? <CompressIcon /> : <ExpandIcon />}
      </div>
      <Swiper
        dir={document.body.dir}
        onSwiper={(swiper: SwiperType) => {
          setThumbsSwiper(swiper);
        }}
        slidesPerView={isFullScreen ? 8 : 4}
        modules={[Navigation, Thumbs]}
        className="car-images-slider-thumbs"
      >
        {swiperSlides}
      </Swiper>
    </section>
  );
};

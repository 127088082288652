import "./ShowroomImageSlider.css";
import "swiper/css";
import { FC, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperType from "swiper";
import { Navigation, Thumbs, Keyboard } from "swiper/modules";
import { ExpandIcon } from "../../assets/svg/expandIcon";
import { CompressIcon } from "../../assets/svg/compressIcon";

type propsType = {
  images: string[];
  showroomName: string;
};
export const ShowroomImageSlider: FC<propsType> = ({
  images,
  showroomName,
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType>();
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const onSlideChange = (swiper: SwiperType) => {
    // i want to next slide visible in thumbs slider
    if (images?.length && swiper.activeIndex < images?.length - 1)
      thumbsSwiper?.slideTo(swiper.activeIndex);
    // i want to prev slide visible in thumbs slider
    if (swiper.activeIndex > 0) thumbsSwiper?.slideTo(swiper.activeIndex - 1);
  };

  const handleFullScreen = () => {
    const carousel = document.querySelector(".showroom-carousel");
    // Check if the document is already in full screen mode
    if (!isFullScreen && carousel) {
      // Add the full-screen class to the swiper container
      carousel.classList.add("sh-full-screen");
      setIsFullScreen(true);
    } else {
      // Remove the full-screen class from the swiper container
      document
        .querySelector(".showroom-carousel")
        ?.classList.remove("sh-full-screen");
      setIsFullScreen(false);
    }
  };

  return (
    <section className="showroom-carousel">
      <Swiper
        dir={document.body.dir}
        navigation={true}
        slidesPerView={1}
        thumbs={thumbsSwiper && { swiper: thumbsSwiper }}
        modules={[Navigation, Thumbs, Keyboard]}
        className="showroom-images-slider"
        keyboard={{ enabled: true }}
        onSlideChange={onSlideChange}
      >
        {images?.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image} alt={showroomName} loading="lazy" />
          </SwiperSlide>
        ))}
      </Swiper>
      <div onClick={handleFullScreen} className="sh-full-screen-btn">
        {isFullScreen ? <CompressIcon /> : <ExpandIcon />}
      </div>
      <Swiper
        dir={document.body.dir}
        onSwiper={setThumbsSwiper}
        slidesPerView={isFullScreen ? 8 : 6}
        modules={[Navigation, Thumbs]}
        className="showroom-images-slider-thumbs"
      >
        {images?.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image} alt={showroomName} loading="lazy" />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

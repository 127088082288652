import "./StatCard.css";
import { FC } from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../../utilities/formatCurrency";

type Props = {
  title: string;
  value?: number;
  amount?: number;
  link: string;
  path?: string;
};
export const StatCard: FC<Props> = ({ title, value, amount, link, path }) => {
  return (
    <div id="stat-card">
      <p className="title">{title}</p>
      <p className="value">{value || formatCurrency(amount) || 0}</p>
      <div className="percentage">
        {/* <p
          style={
            isDown ? { color: "var(--red-600)" } : { color: "var(--green-600)" }
          }
        >
          {isDown ? <TrendDownIcon /> : <TrendUpIcon />}
          {percentage}%
        </p> */}
        <Link className="report-link" to={path || "#"}>
          {link}
        </Link>
      </div>
    </div>
  );
};

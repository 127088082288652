import { useGetAdQuery } from "../app/api/adApiSlice";

// ad data type
export type adDataType = {
  title: string;
  description?: string;
  images: string[];
  phone?: string;
  website?: string;
  websiteKu?: string;
  websiteAr?: string;
  status?: string;
  arImageIndex?: number;
  kuImageIndex?: number;
  enImageIndex?: number;
  expirationDate?: Date;
};

// Get ad data type
type getAdDataType = {
  data: adDataType;
  isSuccess: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  error: any;
};

export const useAd = (title: string) => {
  const {
    data: ad,
    isSuccess: isAdSuccess,
    isLoading: isAdLoading,
    isFetching: isAdFetching,
    isError: isAdError,
    error: adError,
  } = useGetAdQuery(title, { skip: !title }) as getAdDataType;
  return { ad, isAdSuccess, isAdLoading, isAdFetching, isAdError, adError };
};

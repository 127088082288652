import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const ClockIcon = (props: Props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0234 23.1343C6.14311 23.1343 1.35938 18.3506 1.35938 12.4702C1.35938 6.58992 6.14311 1.80618 12.0234 1.80618C17.9038 1.80618 22.6875 6.58992 22.6875 12.4702C22.6875 18.3506 17.9038 23.1343 12.0234 23.1343ZM12.0234 2.69485C6.63366 2.69485 2.24805 7.08046 2.24805 12.4702C2.24805 17.86 6.63366 22.2457 12.0234 22.2457C17.4132 22.2457 21.7988 17.86 21.7988 12.4702C21.7988 7.08046 17.4132 2.69485 12.0234 2.69485ZM14.5117 16.8247C14.7081 16.6772 14.7481 16.3991 14.6015 16.2027L12.0243 12.7662V6.69389C12.0243 6.44859 11.8252 6.24956 11.58 6.24956C11.3347 6.24956 11.1357 6.44859 11.1357 6.69389V12.9146C11.1357 13.0106 11.1667 13.1039 11.2245 13.1812L13.8905 16.7359C13.9785 16.8523 14.1118 16.9136 14.246 16.9136C14.3384 16.9136 14.4326 16.8843 14.5126 16.8247H14.5117Z"
      fill="white"
    />
  </svg>
);

export { ClockIcon };

import "./CarsDetails.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetSaleCarByModelQuery } from "../../app/api/saleCarsApiSlice";
import { useAd } from "../../hooks/useAd";
import { useSaleCar } from "../../hooks/useAuth";
import { CarType, SaleCard } from "../../components/cards/SaleCard";
import { CarImagesSlider } from "../../components/car image slider/CarImagesSlider";
import { WhiteLoading } from "../../components/customs/loading/WhiteLoading";
import {
  formatCurrency,
  iqdFormatCurrency,
} from "../../utilities/formatCurrency";
import { camelCase } from "../../utilities/camelCase";
import { LocationIcon } from "../../assets/svg/locationIcon";
import { ClockIcon } from "../../assets/svg/clockIcon";
import { PhoneIcon } from "../../assets/svg/phoneIcon";
import { WhatsAppIcon } from "../../assets/svg/whatsAppIcon";
import { SEO } from "../../utilities/SEO";
import { ShareIcon } from "../../assets/svg/shareIcon";
import { ShareModal } from "../../components/share modal/ShareModal";
import { BackIcon } from "../../assets/svg/backIcon";
import { PreviousIcon } from "../../assets/svg/previousIcon";
import { PrevVector } from "../../assets/svg/prevVector";

export const CarsDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { carId } = useParams();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showNo, setShowNo] = useState<boolean>(false);

  // Get ad
  const { ad, isAdSuccess, isAdLoading, isAdFetching, isAdError, adError } =
    useAd("noyya");

  // Get sale car
  const {
    saleCar: car,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useSaleCar(carId as string);

  // Rent car showroom name in current language
  const showroomName = useMemo(() => {
    const showroomNames: { [key: string]: string } = {
      ar: car?.showroom?.nameInAr,
      ku: car?.showroom?.nameInKu,
      default: car?.showroom?.name,
    };

    return showroomNames[i18n.language] || showroomNames.default;
  }, [
    i18n.language,
    car?.showroom?.name,
    car?.showroom?.nameInAr,
    car?.showroom?.nameInKu,
  ]);

  // Get similar cars
  const { data: similarCars } = useGetSaleCarByModelQuery(
    {
      model: car?.model,
      make: car?.make,
      id: carId as string,
    },
    {
      skip: !car?.model || !car?.make,
    }
  ) as {
    data: CarType[];
  };

  // Get path to similar cars
  const path = location.pathname.split("/");
  const similarCarsPath = path.slice(0, -2).join("/") + "/";

  // Convert date to ago time
  const agoTime = (date: Date) => {
    const now = new Date();
    const diff = now.getTime() - new Date(date).getTime();

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
      if (seconds === 1)
        return t("agoTime.second", { number: t("number", { count: seconds }) });
      return t("agoTime.seconds", { number: t("number", { count: seconds }) });
    } else if (minutes < 60) {
      if (minutes === 1)
        return t("agoTime.minute", { number: t("number", { count: minutes }) });
      return t("agoTime.minutes", { number: t("number", { count: minutes }) });
    } else if (hours < 24) {
      if (hours === 1)
        return t("agoTime.hour", { number: t("number", { count: hours }) });
      return t("agoTime.hours", { number: t("number", { count: hours }) });
    } else if (days < 30) {
      if (days === 1)
        return t("agoTime.day", { number: t("number", { count: days }) });
      return t("agoTime.days", { number: t("number", { count: days }) });
    } else if (months < 12) {
      if (months === 1)
        return t("agoTime.month", { number: t("number", { count: months }) });
      return t("agoTime.months", { number: t("number", { count: months }) });
    } else {
      if (years === 1)
        return t("agoTime.year", { number: t("number", { count: years }) });
      return t("agoTime.years", { number: t("number", { count: years }) });
    }
  };

  // Share button handler
  const handleShareButton = () => {
    // Check if navigator.share is supported by the browser
    if (navigator?.share) {
      navigator.share({
        url: location.pathname,
        title: t("seo.carTitle", {
          make: t(`make.${camelCase(car.make)}`),
          model: t(`model.${camelCase(car.model)}`),
          modelYear: t("number", { count: car.modelYear }),
          deal: t(`deal.sale`),
          city: t(`city.${camelCase(car.location)}`),
        }).toString(),
      });
    } else {
      setShowModal(true);
    }
  };

  // Redirect to previous page if there is no car or error
  useEffect(() => {
    if (isError) {
      navigate("..");
    }
  }, [isError]);

  return (
    <>
      {/* SEO */}
      {car && (
        <SEO
          title={t("seo.carTitle", {
            make: t(`make.${camelCase(car.make)}`),
            model: t(`model.${camelCase(car.model)}`),
            modelYear: t("number", { count: car.modelYear }),
            deal: t(`deal.sale`),
            city: t(`city.${camelCase(car.location)}`),
          })}
          titlePost={t("seo.carTitlePost", {
            deal: t(`deal.sale`),
            make: t(`make.${camelCase(car.make)}`),
            model: t(`model.${camelCase(car.model)}`),
            trim: t(`trim.${camelCase(car.trim)}`).includes("trim")
              ? car.trim
              : t(`trim.${camelCase(car.trim)}`),
            modelYear: t("number", { count: car.modelYear }),
            city: t(`city.${camelCase(car.location)}`),
          }).toString()}
          description={t("seo.carDesc", {
            make: t(`make.${camelCase(car.make)}`),
            model: t(`model.${camelCase(car.model)}`),
            trim: t(`trim.${camelCase(car.trim)}`).includes("trim")
              ? car.trim
              : t(`trim.${camelCase(car.trim)}`),
            modelYear: t("number", { count: car.modelYear }),
            deal: t(`deal.sale`),
            city: t(`city.${camelCase(car.location)}`),
            description: car?.description || "",
          }).toString()}
          image={car.images[car.primaryImageIndex || 0]}
        />
      )}

      {/* Hero Section */}
      <section id="cd-hero" style={showModal ? { overflow: "hidden" } : {}}>
        {isLoading || isFetching ? (
          <WhiteLoading />
        ) : isSuccess && car ? (
          <>
            {/* Header */}
            <header>
              <div>
                <h1>{t(`make.${camelCase(car.make)}`)}</h1>
                <h2>
                  {t(`model.${camelCase(car.model)}`)}
                  &nbsp;
                  {t(`trim.${camelCase(car.trim)}`).includes("trim")
                    ? car.trim
                    : t(`trim.${camelCase(car.trim)}`)}
                  &nbsp;
                  {t("number", { count: car.modelYear })}
                </h2>
              </div>
              {/* Share button */}
              <button className="share-btn" onClick={handleShareButton}>
                <ShareIcon />
              </button>
              {showModal && <ShareModal setShowModal={setShowModal} />}
            </header>
            <div className="container">
              <div className="left-container">
                {/* Images slider */}
                <CarImagesSlider
                  car={car}
                  primaryImageIndex={car.primaryImageIndex || 0}
                />
                <div className="car-info">
                  {/* Basic information */}
                  <p>{t("saleCars.details.basicInfo")}</p>
                  <div className="carInfo-container">
                    <table>
                      <tbody>
                        <tr>
                          <td>{t("dropDownPlaceholder.make")}</td>
                          <td>{t(`make.${camelCase(car.make)}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.model")}</td>
                          <td>{t(`model.${camelCase(car.model)}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.condition")}</td>
                          <td>{t(`condition.${car.condition}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.exteriorColor")}</td>
                          <td>
                            {t(`exteriorColor.${camelCase(car.exteriorColor)}`)}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.paint")}</td>
                          <td>{t(`paint.${camelCase(car.paint)}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.interiorColor")}</td>
                          <td>
                            {t(`interiorColor.${camelCase(car.interiorColor)}`)}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.seating")}</td>
                          <td>{t(`seating.${camelCase(car.seating)}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.seatMaterial")}</td>
                          <td>
                            {t(`seatMaterial.${camelCase(car.seatMaterial)}`)}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.transmission")}</td>
                          <td>{t(`transmission.${car.transmission}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("saleCars.details.plate")}</td>
                          <td>
                            {car.plateCategory === "no plate"
                              ? t(
                                  `plateCategory.${camelCase(
                                    car.plateCategory
                                  )}`
                                )
                              : t(
                                  `plateCategory.${camelCase(
                                    car.plateCategory
                                  )}`
                                ) +
                                (i18n.language === "en" ? ", " : "، ") +
                                t(`city.${camelCase(car.plateCity)}`)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <tbody>
                        <tr>
                          <td>{t("inputPlaceholder.mileage")}</td>
                          <td>
                            {t("mileageNumber", { count: Number(car.mileage) })}
                            &nbsp;
                            {car.mileageUnit &&
                              t(`saleCars.details.${car.mileageUnit}`)}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.modelYear")}</td>
                          <td> {t("number", { count: car.modelYear })}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.trim")}</td>
                          <td>
                            {t(`trim.${camelCase(car.trim)}`).includes("trim")
                              ? car.trim
                              : t(`trim.${camelCase(car.trim)}`)}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.engine")}</td>
                          <td>{t(`engine.${camelCase(car.engine)}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.cylinders")}</td>
                          <td>{t(`cylinders.${camelCase(car.cylinders)}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.horsepower")}</td>
                          <td>{t(`horsepower.${car.horsepower}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.fuelType")}</td>
                          <td>{t(`fuelType.${camelCase(car.fuelType)}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("inputPlaceholder.bodyStyle")}</td>
                          <td>
                            {t(`bodyStyle.${car.bodyStyle.toLowerCase()}`)}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.drivetrain")}</td>
                          <td>{t(`drivetrain.${car.drivetrain}`)}</td>
                        </tr>
                        <tr>
                          <td>{t("dropDownPlaceholder.importer")}</td>
                          <td>
                            {car.importer ? t(`importer.${car.importer}`) : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* Features */}
                {car.features && car.features?.length > 0 && (
                  <div className="features">
                    <p>{t("saleCars.details.features")}</p>
                    <div className="features-container">
                      {car.features.map((feature, index) => (
                        <div key={index} className="feature">
                          <p>{t(`feature.${feature}`)}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {/* Description */}
                {car.description && car.description?.length > 0 && (
                  <div className="description">
                    <p>{t("saleCars.details.description")}</p>
                    <p>{car.description}</p>
                  </div>
                )}
              </div>

              <div className="right-container">
                {/* Seller info */}
                <div className="seller-info">
                  {car.status === "sold" ? (
                    <p id="sold-badge">{t("saleCars.badge.sold")}</p>
                  ) : (
                    <>
                      <p>{t("saleCars.details.price")}</p>
                      {car.isPriceShown === true ? (
                        <p>
                          {car.priceUnit === "usd"
                            ? formatCurrency(car.price)
                            : iqdFormatCurrency(car.price)}
                        </p>
                      ) : (
                        <p className="no-price">{t("callUs")}</p>
                      )}
                    </>
                  )}
                  <div className="solid"></div>
                  <div className="row-2">
                    <LocationIcon width={22} height={22} color={"#fff"} />
                    <p>
                      {(car?.showroom?.city?.name &&
                        t(`city.${camelCase(car.showroom.city.name)}`)) ??
                        t(`city.${camelCase(car.location)}`)}
                    </p>
                  </div>
                  <div className="row-2">
                    <ClockIcon width={22} height={22} color={"#fff"} />
                    <time>{car?.createdAt && agoTime(car.createdAt)}</time>
                  </div>
                  <div className="solid"></div>
                  <div className="showroom-info-container">
                    <p>{t("saleCars.details.sellerInfo")}</p>
                    {car.showroom && (
                      <div
                        onClick={() =>
                          navigate(
                            `/${i18n.language}/showrooms/${car.showroom._id}`
                          )
                        }
                        className="showroom-info"
                      >
                        <div className="img">
                          <img
                            loading="lazy"
                            src={
                              car.showroom.images[
                                car.showroom.logoImageIndex || 0
                              ]
                            }
                            alt={showroomName + " logo"}
                          />
                        </div>
                        <p>{showroomName}</p>
                      </div>
                    )}

                    {/* Phone number */}
                    <a
                      onClick={() => setShowNo(!showNo)}
                      className="link-phone"
                      href={`tel:+964${car.showroom?.phoneNumbers[0]}`}
                    >
                      <PhoneIcon />
                      {showNo
                        ? t("phone", {
                            count: Number(car.showroom?.phoneNumbers[0]),
                          })
                        : t("btns&links.showPhoneNumber")}
                    </a>
                    {/* WhatsApp */}
                    <a
                      className="link-whatsapp"
                      href={`https://api.whatsapp.com/send?phone=00964${
                        car.showroom?.phoneNumbers[0]
                      }&text=${
                        t("chat.saleCar", {
                          car:
                            t(`make.${camelCase(car.make)}`) +
                            " " +
                            t(`model.${camelCase(car.model)}`) +
                            " " +
                            car.modelYear,
                        }) +
                        " " +
                        window.location.href
                      }`}
                      target="_blank"
                    >
                      <WhatsAppIcon />
                      {t("btns&links.chat")}
                    </a>
                  </div>
                </div>
                {/* Ad */}
                {isAdLoading || isAdFetching ? (
                  <WhiteLoading />
                ) : isAdSuccess && ad ? (
                  <div className="ad-info">
                    {i18n.language === "en" ? (
                      <a
                        href={
                          `${ad?.website}` ||
                          `https://api.whatsapp.com/send?phone=${ad.phone}&text=${ad.title}`
                        }
                        target="_blank"
                      >
                        <img
                          loading="lazy"
                          src={ad.images[ad?.enImageIndex || 0]}
                          alt={ad.title}
                        />
                      </a>
                    ) : i18n.language === "ar" ? (
                      <a
                        href={
                          `${ad?.websiteAr}` ||
                          `https://api.whatsapp.com/send?phone=${ad.phone}&text=${ad.title}`
                        }
                        target="_blank"
                      >
                        <img
                          loading="lazy"
                          src={ad.images[ad.arImageIndex || 0]}
                          alt={ad.title}
                        />
                      </a>
                    ) : (
                      <a
                        href={
                          `${ad?.websiteKu}` ||
                          `https://api.whatsapp.com/send?phone=${ad.phone}&text=${ad.title}`
                        }
                        target="_blank"
                      >
                        <img
                          loading="lazy"
                          src={ad.images[ad.kuImageIndex || 0]}
                          alt={ad.title}
                        />
                      </a>
                    )}
                  </div>
                ) : (
                  isAdError && (
                    <p className="fetch-error">
                      {adError.status} <br />
                      {JSON.stringify(adError.data)}
                    </p>
                  )
                )}
              </div>
            </div>
          </>
        ) : (
          isError && (
            <p className="fetch-error">
              {error.status} <br />
              {JSON.stringify(error.data)}
            </p>
          )
        )}
      </section>
      {/* Similar cars cards */}
      {similarCars && similarCars.length > 0 && (
        <section id="cd-feature">
          <div className="sale">
            <h3 className="heading">{t("saleCars.details.similarCars")}</h3>
            <div className="cards">
              {similarCars.map((car) => (
                <SaleCard key={car._id} data={car} path={similarCarsPath} />
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

import "./App.css";
import { useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth, useShowroom } from "./hooks/useAuth";
import { Navbar } from "./components/navbar/Navbar";
import { Home } from "./pages/home/Home";
import { Cars } from "./pages/cars/Cars";
import { RentalCars } from "./pages/rental cars/RentalCars";
import { Showrooms } from "./pages/showrooms/Showrooms";
import { Plates } from "./pages/plates/Plates";
import { Contact } from "./pages/contact/Contact";
import { About } from "./pages/about/About";
import { ShowroomDetails } from "./pages/showroom details/ShowroomDetails";
import { CarsDetails } from "./pages/cars details/CarsDetails";
import { RentalCarsDetails } from "./pages/rental cars details/RentalCarsDetails";
import { NotFound } from "./pages/not found/NotFound";
import { Login } from "./pages/auth showroom/login/Login";
import { Register } from "./pages/auth showroom/register/Register";
import { StepOne } from "./pages/auth showroom/register/steps/StepOne";
import { StepTwo } from "./pages/auth showroom/register/steps/StepTwo";
import { StepThree } from "./pages/auth showroom/register/steps/StepThree";
import { Footer } from "./components/footer/Footer";
import { Dashboard } from "./pages/auth showroom/profile/dashboard/Dashboard";
import { Inventory } from "./pages/auth showroom/profile/Inventory management/Inventory";
import { Settings } from "./pages/auth showroom/profile/settings/Settings";
import { Profile } from "./pages/auth showroom/profile/Profile";
import { Support } from "./pages/auth showroom/profile/support & help/Support";
import { MyAccount } from "./pages/auth showroom/profile/settings/outlet/my account info/MyAccount";
import { PhoneNumbers } from "./pages/auth showroom/profile/settings/outlet/phone number/PhoneNumbers";
import { ChangePass } from "./pages/auth showroom/profile/settings/outlet/change password/ChangePass";
import { CarListings } from "./pages/auth showroom/profile/Inventory management/outlet/car lisitngs/sale/CarListings";
import { AddCar } from "./pages/auth showroom/profile/Inventory management/outlet/add car/AddCar";
import { EditCar } from "./pages/auth showroom/profile/Inventory management/outlet/edit car/sale car/EditCar";
import { StepOneAddCar } from "./pages/auth showroom/profile/Inventory management/outlet/add car/sale steps/StepOneAddCar";
import { StepTwoAddCar } from "./pages/auth showroom/profile/Inventory management/outlet/add car/sale steps/StepTwoAddCar";
import { StepThreeAddCar } from "./pages/auth showroom/profile/Inventory management/outlet/add car/sale steps/StepThreeAddCar";
import { StepFourAddCar } from "./pages/auth showroom/profile/Inventory management/outlet/add car/sale steps/StepFourAddCar";
import { StepFiveAddCar } from "./pages/auth showroom/profile/Inventory management/outlet/add car/sale steps/StepFiveAddCar";
import { StepSixAddCar } from "./pages/auth showroom/profile/Inventory management/outlet/add car/sale steps/StepSixAddCar";
import { StepSevenAddCar } from "./pages/auth showroom/profile/Inventory management/outlet/add car/sale steps/StepSevenAddCar";
import { VerifyOtp } from "./pages/auth showroom/register/steps/VerifyOtp";
import { ForgotPass } from "./pages/auth showroom/forgot password/ForgotPass";
import { ForgotStepOne } from "./pages/auth showroom/forgot password/steps/ForgotStepOne";
import { ForgotStepTwo } from "./pages/auth showroom/forgot password/steps/ForgotStepTwo";
import { ForgotStepThree } from "./pages/auth showroom/forgot password/steps/ForgotStepThree";
import { AddPlate } from "./pages/auth showroom/profile/Inventory management/outlet/add plate/AddPlate";
import { SocialMedia } from "./pages/auth showroom/profile/settings/outlet/social media/SocialMedia";
import { StepOneAddRentCar } from "./pages/auth showroom/profile/Inventory management/outlet/add car/rent steps/StepOneAddRentCar";
import { StepTwoAddRentCar } from "./pages/auth showroom/profile/Inventory management/outlet/add car/rent steps/StepTwoAddRentCar";
import { StepThreeAddRentCar } from "./pages/auth showroom/profile/Inventory management/outlet/add car/rent steps/StepThreeAddRentCar";
import { StepFourAddRentCar } from "./pages/auth showroom/profile/Inventory management/outlet/add car/rent steps/StepFourAddRentCar";
import { StepFiveAddRentCar } from "./pages/auth showroom/profile/Inventory management/outlet/add car/rent steps/StepFiveAddRentCar";
import { StepSevenAddRentCar } from "./pages/auth showroom/profile/Inventory management/outlet/add car/rent steps/StepSevenAddRentCar";
import { StepSixAddRentCar } from "./pages/auth showroom/profile/Inventory management/outlet/add car/rent steps/StepSixAddRentCar";
import { EditRentCar } from "./pages/auth showroom/profile/Inventory management/outlet/edit car/rent car/EditRentCar";
import { RentCarListings } from "./pages/auth showroom/profile/Inventory management/outlet/car lisitngs/rent/RentCarListings";
import { PlateListings } from "./pages/auth showroom/profile/Inventory management/outlet/plate listings/PlateListings";
import { EditPlate } from "./pages/auth showroom/profile/Inventory management/outlet/edit plate/EditPlate";
import { PrivacyPolicy } from "./pages/privacy policy/PrivacyPolicy";
import { TermsConditions } from "./pages/terms/TermsConditions";
import { RentAnalytics } from "./pages/auth showroom/profile/analytics/outlet/car/rent/RentAnalytics";
import { SaleAnalytics } from "./pages/auth showroom/profile/analytics/outlet/car/sale/SaleAnalytics";
import { Analytics } from "./pages/auth showroom/profile/analytics/Analytics";
import { ShowroomAnalytics } from "./pages/auth showroom/profile/analytics/outlet/showroom/ShowroomAnalytics";

function App() {
  const location = useLocation();
  const { i18n } = useTranslation();
  const { isSaleShowroom } = useShowroom();
  const { isAuth } = useAuth();
  const currentLanguage = useMemo(() => i18n.language, [i18n.language]);
  const isRouteRegister = location.pathname.includes("register");
  const isRouteShowroomProfile = location.pathname.includes("showroom-profile");

  useEffect(() => {
    if (currentLanguage === "en") {
      document.body.dir = "ltr";
      document.documentElement.lang = "en";
    } else if (currentLanguage === "ar") {
      document.body.dir = "rtl";
      document.documentElement.lang = "ar";
    } else {
      document.body.dir = "rtl";
      document.documentElement.lang = "ku";
    }
  }, [currentLanguage]);

  // scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      {!isRouteRegister && !isRouteShowroomProfile && <Navbar />}
      <Routes>
        <Route path={currentLanguage}>
          <Route index element={<Home />} />
          <Route path={`cars`}>
            <Route index element={<Cars />} />
            <Route path="details/:carId" element={<CarsDetails />} />
          </Route>
          <Route path={`rental-cars`}>
            <Route index element={<RentalCars />} />
            <Route path="details/:carId" element={<RentalCarsDetails />} />
          </Route>
          <Route path={`showrooms`}>
            <Route index element={<Showrooms />} />
            <Route path=":showroomId" element={<ShowroomDetails />} />
          </Route>
          <Route path={`number-plates`} element={<Plates />} />
          <Route path={`contact`} element={<Contact />} />
          <Route path={`about`} element={<About />} />
          <Route path={`privacy-policy`} element={<PrivacyPolicy />} />
          <Route path={`terms`} element={<TermsConditions />} />
          {!isAuth && (
            <>
              <Route path={`register`} element={<Register />}>
                <Route index element={<StepOne />} />
                <Route path="step-two" element={<StepTwo />} />
                <Route path="step-three" element={<StepThree />} />
                <Route path="otp-verification" element={<VerifyOtp />} />
              </Route>
              <Route path={`sign-in`} element={<Login />} />
              <Route path={`forgot-password`} element={<ForgotPass />}>
                <Route index element={<ForgotStepOne />} />
                <Route path="otp" element={<ForgotStepTwo />} />
                <Route path="new-password" element={<ForgotStepThree />} />
              </Route>
            </>
          )}
        </Route>
        {isAuth && (
          <Route
            path={`${currentLanguage}/showroom-profile`}
            element={<Profile />}
          >
            <Route index element={<Navigate to={"dashboard"} />} />
            <Route path={`dashboard`} element={<Dashboard />} />
            <Route path={`inventory-management`} element={<Inventory />}>
              <Route index element={<Navigate to={"car-listings"} />} />
              <Route path={`plate-listings`} element={<PlateListings />} />
              {isSaleShowroom ? (
                <Route path={`car-listings`} element={<CarListings />} />
              ) : (
                <Route path={`car-listings`} element={<RentCarListings />} />
              )}
              <Route path={`add-car`} element={<AddCar />}>
                {isSaleShowroom ? (
                  <>
                    <Route index element={<StepOneAddCar />} />
                    <Route path={`step-two`} element={<StepTwoAddCar />} />
                    <Route path={`step-three`} element={<StepThreeAddCar />} />
                    <Route path={`step-four`} element={<StepFourAddCar />} />
                    <Route path={`step-five`} element={<StepFiveAddCar />} />
                    <Route path={`step-six`} element={<StepSixAddCar />} />
                    <Route path={`step-seven`} element={<StepSevenAddCar />} />
                  </>
                ) : (
                  <>
                    <Route index element={<StepOneAddRentCar />} />
                    <Route path={`step-two`} element={<StepTwoAddRentCar />} />
                    <Route
                      path={`step-three`}
                      element={<StepThreeAddRentCar />}
                    />
                    <Route
                      path={`step-four`}
                      element={<StepFourAddRentCar />}
                    />
                    <Route
                      path={`step-five`}
                      element={<StepFiveAddRentCar />}
                    />
                    <Route path={`step-six`} element={<StepSixAddRentCar />} />
                    <Route
                      path={`step-seven`}
                      element={<StepSevenAddRentCar />}
                    />
                  </>
                )}
              </Route>
              <Route path="add-plate" element={<AddPlate />} />
            </Route>
            <Route path={`edit-plate/:plateId`} element={<EditPlate />} />
            <Route path={`edit-car/:carId`} element={<EditCar />} />
            <Route
              path={`edit-rent-car/:rentCarId`}
              element={<EditRentCar />}
            />
            <Route path={`analytics`} element={<Analytics />}>
              {isSaleShowroom ? (
                <>
                  <Route index element={<Navigate to={"car"} />} />
                  <Route path={`car`} element={<SaleAnalytics />} />
                </>
              ) : (
                <>
                  <Route index element={<Navigate to={"car"} />} />
                  <Route path={`car`} element={<RentAnalytics />} />
                </>
              )}
              <Route
                path={`showroom-analytics`}
                element={<ShowroomAnalytics />}
              />
            </Route>

            <Route path={`settings`} element={<Settings />}>
              <Route index element={<Navigate to={"account"} />} />
              <Route path={`account`} element={<MyAccount />} />
              <Route path={`phone-number`} element={<PhoneNumbers />} />
              <Route path={`social-media`} element={<SocialMedia />} />
              <Route path={`change-password`} element={<ChangePass />} />
            </Route>
            <Route path={`support`} element={<Support />} />
          </Route>
        )}
        <Route path="*" element={<NotFound />} />
        <Route path="" element={<Navigate to={`${currentLanguage}`} />} />
      </Routes>
      {!isRouteRegister && !isRouteShowroomProfile && <Footer />}
    </>
  );
}

export default App;

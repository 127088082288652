import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import {
  OutletContextAddRentCarType,
  StepOneAddRentCar,
} from "./StepOneAddRentCar";
import { StepTwoAddRentCar } from "./StepTwoAddRentCar";
import { StepThreeAddRentCar } from "./StepThreeAddRentCar";
import { StepFourAddRentCar } from "./StepFourAddRentCar";
import { StepFiveAddRentCar } from "./StepFiveAddRentCar";
import { StepSixAddRentCar } from "./StepSixAddRentCar";

export const StepSevenAddRentCar = () => {
  const { t } = useTranslation();
  const { isAddRentCarLoading, error } =
    useOutletContext<OutletContextAddRentCarType>();

  return (
    <div id="step-seven-add-car">
      <header>
        <p>{t(`showroomManagement.inventory.addCar.stepSevenHeading`)}</p>
      </header>
      <StepOneAddRentCar className="hidden" />
      <StepTwoAddRentCar className="hidden" />
      <StepThreeAddRentCar className="hidden" />
      <StepFourAddRentCar className="hidden" />
      <StepFiveAddRentCar className="hidden" />
      <StepSixAddRentCar className="hidden" />
      {error && <p className="error">{error?.data?.message}</p>}
      <div className="step-seven-btns">
        <button disabled={isAddRentCarLoading} className="submit" type="submit">
          {isAddRentCarLoading ? t("loading") : t("btns&links.add")}
        </button>
      </div>
    </div>
  );
};

import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const LocationIcon = (props: Props) => (
  <svg
    width={18}
    height={19}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1830_53)">
      <path
        d="M8.82196 18.0623C8.87265 18.1088 8.93671 18.1317 9.00001 18.1317C9.06771 18.1317 9.13604 18.1052 9.18692 18.0541C9.31389 17.9267 10.8564 16.3656 12.3675 14.2743C13.1231 13.2286 13.8719 12.0491 14.432 10.8488C14.9917 9.64921 15.365 8.42464 15.365 7.29011C15.365 3.76764 12.51 0.901675 9.00001 0.901675C5.49001 0.901675 2.63501 3.76764 2.63501 7.29011C2.63501 9.83252 4.12485 12.4708 5.63641 14.4953C7.14958 16.5219 8.69407 17.9458 8.82196 18.0623ZM3.16431 7.29011C3.16431 4.05997 5.78243 1.43246 9.00001 1.43246C12.2176 1.43246 14.8357 4.05997 14.8357 7.29011C14.8357 8.26575 14.5262 9.33556 14.0442 10.4089C13.5625 11.4817 12.9103 12.5539 12.2292 13.5334C10.9097 15.4308 9.48531 16.9752 8.99196 17.4931C8.49165 17.0102 7.07219 15.5833 5.75911 13.7396C4.40059 11.8321 3.16431 9.48984 3.16431 7.29011ZM6.51705 7.29011C6.51705 8.66395 7.63068 9.78207 9.00001 9.78207C10.3693 9.78207 11.483 8.66395 11.483 7.29011C11.483 5.91628 10.3693 4.79816 9.00001 4.79816C7.63068 4.79816 6.51705 5.91628 6.51705 7.29011ZM7.04635 7.29011C7.04635 6.20861 7.9231 5.32894 9.00001 5.32894C10.0769 5.32894 10.9537 6.20861 10.9537 7.29011C10.9537 8.37162 10.0769 9.25128 9.00001 9.25128C7.9231 9.25128 7.04635 8.37162 7.04635 7.29011Z"
        fill={props.color || "var(--primary-900)"}
        stroke={props.color || "var(--primary-900)"}
        strokeWidth={0.13}
      />
    </g>
    <defs>
      <clipPath>
        <rect
          width={18}
          height={18}
          fill="white"
          transform="translate(0 0.666656)"
        />
      </clipPath>
    </defs>
  </svg>
);

export { LocationIcon };

import "./Contact.css";
import { Trans, useTranslation } from "react-i18next";
import { EmailIcon } from "../../assets/svg/emailIcon";
import { FacebookIcon } from "../../assets/svg/facebookIcon";
import { InstagramIcon } from "../../assets/svg/instagramIcon";
import { LinkedinIcon } from "../../assets/svg/linkedinIcon";
import { LocationIcon } from "../../assets/svg/locationIcon";
import { PhoneIcon } from "../../assets/svg/phoneIcon";
import { TwitterXIcon } from "../../assets/svg/twitterXIcon";
import { SEO } from "../../utilities/SEO";

export const Contact = () => {
  const { t } = useTranslation();
  const address = "";
  const phoneNumber = "7733532323";
  const email = "info@noyya.net";

  return (
    <>
      {/* SEO */}
      <SEO title={t("seo.contactTitle")} />

      <section id="co-hero">
        <div className="container">
          <h1>{t("contactUs.heading")}</h1>
          <h2>
            <Trans>{t("contactUs.desc")}</Trans>
          </h2>
          <div className="co-info">
            {address && (
              <div>
                <LocationIcon color="#fff" width={22} height={23} />
                <p>{address}</p>
              </div>
            )}
            <div>
              <PhoneIcon color="#fff" width={22} height={22} />
              <a href={`tel:+964${phoneNumber}`}>
                <p>{t("phone", { count: Number(phoneNumber) })}</p>
              </a>
            </div>
            <div>
              <EmailIcon width={22} height={22} />
              <a href={`mailto:${email}`}>
                <p>{email}</p>
              </a>
            </div>
          </div>
          <div className="account">
            <p>{t("contactUs.socialMediaHeading")}</p>
            <div>
              <a
                href={"https://www.instagram.com/noyya.iq/"}
                target="_blank"
                aria-label="Instagram"
              >
                <InstagramIcon />
              </a>
              <a
                href={"https://www.facebook.com/noyya.iq/"}
                target="_blank"
                aria-label="Facebook"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://twitter.com/noyya_iq"
                target="_blank"
                aria-label="Twitter"
              >
                <TwitterXIcon />
              </a>
              <a
                href={"https://www.linkedin.com/company/noyya"}
                target="_blank"
                aria-label="Linkedin"
              >
                <LinkedinIcon />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

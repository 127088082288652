import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const CheckIcon = (props: Props) => (
  <svg
    width={18}
    height={19}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.8653 6.52533L12.8013 7.47533L8.93933 11.28C8.68133 11.538 8.342 11.6667 8.00133 11.6667C7.66067 11.6667 7.318 11.5367 7.05733 11.2767L5.20267 9.47933L6.13133 8.52133L7.99333 10.326L11.8653 6.52533ZM17 9C17 13.4113 13.4113 17 9 17C4.58867 17 1 13.4113 1 9C1 4.58867 4.58867 1 9 1C13.4113 1 17 4.58867 17 9ZM15.6667 9C15.6667 5.324 12.676 2.33333 9 2.33333C5.324 2.33333 2.33333 5.324 2.33333 9C2.33333 12.676 5.324 15.6667 9 15.6667C12.676 15.6667 15.6667 12.676 15.6667 9Z"
      fill="#28282B"
    />
  </svg>
);

export { CheckIcon };

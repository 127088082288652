import "./Pricing.css";
import { FC, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { SaleCarOptions } from "../../../../../../../../data/FilterData";
import { DropDown } from "../../../../../../../../components/customs/drop down/DropDown";
import { AddCarDataType } from "../../../add car/AddCar";

type PricingProps = {
  register: UseFormRegister<AddCarDataType>;
  errors: FieldErrors<AddCarDataType>;
  setValue: UseFormSetValue<AddCarDataType>;
  watch: UseFormWatch<AddCarDataType>;
};
export const Pricing: FC<PricingProps> = ({
  register,
  errors,
  setValue,
  watch,
}) => {
  const { t } = useTranslation();

  // Only allow numbers in input
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // only allow numbers
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 46
      )
    )
      e.preventDefault();
  };
  return (
    <section id="pricing">
      <header>
        <p>{t("showroomManagement.inventory.addCar.stepFiveHeading")}</p>
      </header>
      <div className="row">
        <div className="col">
          <div className="price">
            <div className="price-unit">
              <DropDown
                options={SaleCarOptions.priceUnit}
                placeholder={"Price Unit"}
                setValue={setValue}
                watch={watch}
              />
            </div>
            <div className="input-group">
              <input
                placeholder=" "
                type="text"
                {...register("price", { required: true })}
                onKeyDown={onKeyDown}
              />
              <label>{t("inputPlaceholder.price")}</label>
            </div>
          </div>
          {errors.priceUnit && (
            <p className="error">{errors.priceUnit.message}</p>
          )}
          {errors.price && <p className="error">{errors.price.message}</p>}
        </div>
      </div>
      <div className="row-2">
        <div className="price-shown">
          <p>{t("inputPlaceholder.priceShown")}</p>
          <div className="btns">
            <div className="radio-group">
              <input
                type="radio"
                id="true"
                value={"true"}
                {...register("isPriceShown", { required: true })}
              />
              <label htmlFor="true">{t("priceShown.show")}</label>
            </div>
            <div className="radio-group">
              <input
                type="radio"
                id="false"
                value={"false"}
                {...register("isPriceShown", { required: true })}
              />
              <label htmlFor="false">{t("priceShown.hide")}</label>
            </div>
          </div>
        </div>
        {errors.isPriceShown && (
          <p className="error">{errors.isPriceShown.message}</p>
        )}
      </div>
    </section>
  );
};

import "./InteriorDetails.css";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  UseFormWatch,
  UseFormSetValue,
  UseFormRegister,
  FieldErrors,
} from "react-hook-form";
import { SaleCarOptions } from "../../../data/FilterData";
import { DropDown } from "../../customs/drop down/DropDown";
import { AddRentCarDataType } from "../../../pages/auth showroom/profile/Inventory management/outlet/add car/AddCar";

type Props = {
  watch: UseFormWatch<AddRentCarDataType>;
  setValue: UseFormSetValue<AddRentCarDataType>;
  register: UseFormRegister<AddRentCarDataType>;
  errors: FieldErrors<AddRentCarDataType>;
};
export const InteriorDetails: FC<Props> = ({ watch, setValue, errors }) => {
  const { t } = useTranslation();
  return (
    <section id="interior-details">
      <header>
        <p>{t("showroomManagement.inventory.addCar.stepInteriorDetails")}</p>
      </header>
      <div className="row">
        <div className="col">
          <DropDown
            options={SaleCarOptions.interiorColor}
            placeholder={"Interior Color"}
            setValue={setValue}
            watch={watch}
          />
          {errors.interiorColor && (
            <p className="error">{errors.interiorColor.message}</p>
          )}
        </div>
        <div className="col">
          <DropDown
            options={SaleCarOptions.seatMaterial}
            placeholder={"Seat Material"}
            setValue={setValue}
            watch={watch}
          />
          {errors.seatMaterial && (
            <p className="error">{errors.seatMaterial.message}</p>
          )}
        </div>
        <div className="col">
          <DropDown
            options={SaleCarOptions.seating}
            placeholder={"Seating"}
            setValue={setValue}
            watch={watch}
          />
          {errors.seating && <p className="error">{errors.seating.message}</p>}
        </div>
      </div>
    </section>
  );
};

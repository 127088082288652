import "./StepOne.css";
import { ChangeEvent, useState, KeyboardEvent } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import {
  UseFormRegister,
  FieldErrors,
  UseFormTrigger,
  UseFormWatch,
  UseFormSetValue,
} from "react-hook-form";
import { EyeIcon } from "../../../../assets/svg/eyeIcon";
import flagSrc from "../../../../assets/svg/iraqFlag.svg";
import { RegisterDataType } from "../Register";
import { useSendSmsMutation } from "../../../../app/api/showroomApiSlice";
import { useTranslation } from "react-i18next";

type OutletContextType = {
  register: UseFormRegister<RegisterDataType>;
  setValue: UseFormSetValue<RegisterDataType>;
  watch: UseFormWatch<RegisterDataType>;
  errors: FieldErrors<RegisterDataType>;
  trigger: UseFormTrigger<RegisterDataType>;
};
export const StepOne = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const { register, setValue, watch, errors, trigger } =
    useOutletContext<OutletContextType>();

  const [sendSms, { isLoading: isSendSmsLoading, error: sendSmsError }] =
    useSendSmsMutation();

  const sendOtp = async (phoneNumber: string) => {
    try {
      await sendSms({ phoneNumbers: phoneNumber }).unwrap();
      navigate(`otp-verification`);
    } catch (error) {
      console.log(error);
    }
  };

  // Handle change phone number input
  const onChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    const firstChar = e.target.value[0];
    if (firstChar !== "0" && e.target.value.length > 10)
      e.target.value = e.target.value.slice(0, 10);
    if (firstChar === "0" && e.target.value.length > 11)
      e.target.value = e.target.value.slice(0, 11);
    let formattedNumber = "";
    if (firstChar === "0") {
      formattedNumber = e.target.value.slice(1);
    } else {
      formattedNumber = e.target.value;
    }
    // if number is arabic convert it to english
    formattedNumber = formattedNumber.replace(/[\u0660-\u0669]/g, (c) =>
      String.fromCharCode(c.charCodeAt(0) - 0x0660 + 48)
    );
    setValue(`phoneNumber`, formattedNumber, {
      shouldValidate: true,
    });
  };

  // Only allow numbers in input
  const onKeyDownPhone = (e: KeyboardEvent<HTMLInputElement>) => {
    // only allow numbers
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 46
      )
    )
      e.preventDefault();
  };

  return (
    <section id="step-one">
      <p>{t("register.stepOne.heading")}</p>
      <div className="first-row">
        <p> {t("inputPlaceholder.showroomType")}</p>
        <div className="radio-group">
          <div>
            <input
              type="radio"
              id="sale"
              value={"sale"}
              {...register("showroomType", { required: true })}
            />
            <label className="sale-label" htmlFor="sale">
              {t("register.stepOne.sale")}
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="rental"
              value={"rental"}
              {...register("showroomType", { required: true })}
            />
            <label className="rental-label" htmlFor="rental">
              {t("register.stepOne.rental")}
            </label>
          </div>
        </div>
        {errors.showroomType && (
          <p className="error">{errors.showroomType.message}</p>
        )}
      </div>
      <div className="row">
        <div className="col">
          <div className="input-group">
            <input
              placeholder=" "
              type="text"
              {...register("name", { required: true })}
            />
            <label>{t("inputPlaceholder.showroomName")}</label>
          </div>
          {errors.name && <p className="error">{errors.name.message}</p>}
        </div>
        <div className="col">
          <div className="phone-input-container">
            <div className="country">
              <img src={flagSrc} alt="iraq flag" />
              <p>{t("countryCode")}</p>
            </div>
            <div className="phone-input-group">
              <input
                placeholder=" "
                type="tel"
                onChange={onChangePhone}
                onKeyDown={onKeyDownPhone}
              />
              <label>{t("inputPlaceholder.phoneNumber")}</label>
            </div>
          </div>
          {errors.phoneNumber && (
            <p className="error">{errors.phoneNumber.message}</p>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="password">
            <div className="input-group">
              <input
                placeholder=" "
                type={showPassword ? "text" : "password"}
                {...register("password", { required: true })}
              />
              <label>{t("inputPlaceholder.password")}</label>
            </div>
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              <EyeIcon />
            </button>
          </div>
          {errors.password && (
            <p className="error">{errors.password.message}</p>
          )}
        </div>
        <div className="col">
          <div className="password">
            <div className="input-group">
              <input
                placeholder=" "
                type={showConfirmPassword ? "text" : "password"}
                {...register("confirmPassword", { required: true })}
              />
              <label>{t("inputPlaceholder.confirmPassword")}</label>
            </div>

            <button
              type="button"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              <EyeIcon />
            </button>
          </div>
          {errors.confirmPassword && (
            <p className="error">{errors.confirmPassword.message}</p>
          )}
        </div>
      </div>
      {sendSmsError && <p className="error">{sendSmsError?.data?.message}</p>}
      <p className="agree">
        {t("register.stepOne.agree.message")}
        &nbsp;
        <Link className="link" to="../terms">
          {t("register.stepOne.agree.terms")}
        </Link>
        &nbsp;
        {t("register.stepOne.agree.and")}
        &nbsp;
        <Link className="link" to="../privacy-policy">
          {t("register.stepOne.agree.privacyPolicy")}
        </Link>
      </p>
      <button
        disabled={isSendSmsLoading}
        type="button"
        id="register-btn"
        onClick={async () => {
          const isValid = await trigger(
            [
              "name",
              "phoneNumber",
              "password",
              "confirmPassword",
              "showroomType",
            ],
            { shouldFocus: true }
          );
          if (isValid) {
            sendOtp(watch("phoneNumber"));
          }
        }}
        aria-label={t("btns&links.register").toString()}
      >
        {isSendSmsLoading ? t("loading") : t("btns&links.register")}
      </button>
      <p className="signin">
        {t("register.stepOne.alreadyHaveAccount")}&nbsp;
        <Link className="link" to={`/${i18n.language}/sign-in`}>
          {t("btns&links.signIn")}
        </Link>
      </p>
    </section>
  );
};

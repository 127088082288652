import { FC, useEffect, useRef } from "react";
import { PlateType } from "../cards/PlateCard";

type Props = {
  plate: PlateType;
  width: number;
  height: number;
};
export const Canvas: FC<Props> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const plateFont = "30px Fe-Normal";
  const plateFontSmall = "8px Fe-Normal";

  const cities = {
    sulaymaniyah: "21",
    erbil: "22",
    halabja: "23",
    duhok: "24",
  } as {
    [key: string]: string;
  };

  const category = {
    private: "#fff",
    commercial: "orange",
    taxi: "#ff3535",
  } as {
    [key: string]: string;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    const cityInNumber = cities[props.plate.plateCity];
    const plateColor = category[props.plate.plateCategory];

    document.fonts.load(plateFont, plateFontSmall).then(() => {
      if (context && canvas) {
        const scale = window.devicePixelRatio;
        canvas.width = props.width * scale;
        canvas.height = props.height * scale;
        context.scale(scale, scale);

        // background
        context.fillStyle = "#f6f6f7";
        context.rect(0, 0, props.width, props.height);
        context.fillRect(0, 0, props.width, props.height);

        // plate background
        context.fillStyle = "#fff";
        context.fillRect(45, 60, 214, 50);
        // border
        context.lineWidth = 1;
        context.lineJoin = "round";
        context.strokeStyle = "#28282b";
        context.strokeRect(45, 60, 214, 50);
        context.strokeRect(62.5, 60, 0, 50);

        //  text
        context.font = plateFont;
        context.textAlign = document.body.dir === "rtl" ? "end" : "start";
        context.fillStyle = "#28282b";
        const plateNumberWidth =
          props.plate.plateNumber.toString().length === 5 ? 158 : 170;

        context.fillText(cityInNumber, 70, 94);
        context.fillText(props.plate.plateLetter, 124, 94);
        context.fillText(
          props.plate.plateNumber.toString(),
          plateNumberWidth,
          94
        );

        context.fillStyle = plateColor;
        context.fillRect(45.75, 60.5, 16.5, 49);
        context.fillStyle = "#28282b";
        context.font = plateFontSmall;
        context.textAlign = "center";
        context.fillText("I", 54, 71);
        context.fillText("R", 54, 82);
        context.fillText("Q", 54, 93);
        context.fillText("KR", 54, 104);
      }
    });
  }, [props, document.body.dir]);

  return props && <canvas ref={canvasRef} />;
};

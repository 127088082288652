import convertibleIcon from "../../src/assets/svg/convertible - icon.svg";
import suvIcon from "../../src/assets/svg/suv - icon.svg";
import wagonIcon from "../../src/assets/svg/wagon- icon.svg";
import sedanIcon from "../../src/assets/svg/sedan - icon.svg";
import pickupIcon from "../../src/assets/svg/pickup - icon.svg";
import minivanIcon from "../../src/assets/svg/minivan- icon.svg";
import coupeIcon from "../../src/assets/svg/coupe - icon.svg";
import hatchbackIcon from "../../src/assets/svg/hatchback - icon.svg";
import atvIcon from "../../src/assets/svg/atv - icon.svg";
import utvIcon from "../../src/assets/svg/utv - icon.svg";

export type Option = {
  label: string;
  value: string;
};

type CarOption = {
  [key: string]: string[];
};

export const PlateOptions = {
  category: ["private", "taxi", "commercial"],
  city: ["erbil", "sulaymaniyah", "duhok", "halabja"],
  letters: [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ],
};

export const RentCarOptions = {
  status: ["available", "rented"],
  rentType: ["daily", "weekly", "monthly"],
};

export const SaleCarOptions = {
  // Body style
  bodyStyle: {
    // Add body styles here for each  make

    acura: ["sedan", "suv", "hatchback"],
    "alfa romeo": ["sedan", "suv", "coupe", "convertible"],
    "aston martin": ["sedan", "suv", "coupe", "convertible"],
    audi: ["sedan", "suv", "coupe", "convertible", "wagon", "hatchback"],
    bentley: ["sedan", "suv", "coupe", "convertible"],
    bestune: ["sedan", "suv"],
    bmw: ["sedan", "suv", "coupe", "convertible", "wagon", "hatchback"],
    bugatti: ["coupe", "convertible"],
    buick: ["sedan", "suv"],
    cadillac: ["sedan", "suv", "coupe", "convertible"],
    changan: ["sedan", "suv"],
    chery: ["sedan", "suv"],
    chevrolet: ["sedan", "suv", "hatchback", "coupe", "convertible", "truck"],
    chrysler: ["sedan", "coupe", "van"],
    dodge: ["sedan", "suv", "coupe", "convertible", "truck"],
    dongfeng: ["sedan", "suv"],
    "faw besturn": ["sedan", "suv"],
    ferrari: ["coupe", "convertible"],
    fiat: ["hatchback", "convertible"],
    fisker: ["suv", "coupe"],
    ford: [
      "sedan",
      "suv",
      "hatchback",
      "coupe",
      "convertible",
      "wagon",
      "van",
      "truck",
    ],
    gac: ["sedan", "suv", "van"],
    geely: ["sedan", "suv", "van"],
    genesis: ["sedan", "suv"],
    gmc: ["suv", "van", "truck"],
    gwm: ["suv", "hatchback", "truck"],
    haima: ["sedan", "suv"],
    haval: ["suv", "compact suv"],
    honda: ["sedan", "suv", "hatchback", "coupe", "convertible", "van"],
    hongqi: ["sedan", "suv"],
    hummer: ["suv", "truck"],
    hyundai: ["sedan", "suv", "hatchback", "coupe", "convertible", "van"],
    infiniti: ["sedan", "suv", "coupe", "convertible"],
    isuzu: ["suv", "truck"],
    jaguar: ["sedan", "suv", "coupe", "convertible"],
    jeep: ["suv", "truck"],
    jetour: ["suv", "compact suv"],
    kaiyi: ["sedan", "suv"],
    karma: ["sedan", "suv"],
    kia: ["sedan", "suv", "hatchback", "coupe", "van"],
    lamborghini: ["coupe", "convertible", "suv"],
    "land rover": ["suv", "truck"],
    lexus: ["sedan", "suv", "coupe", "convertible"],
    lincoln: ["sedan", "suv", "coupe"],
    lotus: ["coupe", "convertible"],
    maserati: ["sedan", "suv", "coupe", "convertible"],
    maybach: ["sedan", "coupe"],
    mazda: ["sedan", "suv", "hatchback", "coupe", "convertible"],
    mclaren: ["coupe", "convertible"],
    "mercedes benz": [
      "sedan",
      "suv",
      "hatchback",
      "coupe",
      "convertible",
      "wagon",
      "van",
      "truck",
    ],
    mercury: ["sedan", "suv", "coupe", "convertible"],
    mg: ["sedan", "suv", "hatchback", "truck"],
    mini: ["compact suv", "hatchback", "coupe", "convertible"],
    mitsubishi: ["sedan", "suv", "hatchback", "truck"],
    nissan: [
      "sedan",
      "suv",
      "hatchback",
      "coupe",
      "convertible",
      "van",
      "truck",
    ],
    oldsmobile: ["sedan", "coupe"],
    opel: ["sedan", "suv", "hatchback"],
    polaris: ["atv", "utv"],
    porsche: ["sedan", "suv", "wagon", "coupe", "convertible"],
    proton: ["sedan", "hatchback"],
    ram: ["truck", "van"],
    renault: ["sedan", "suv", "hatchback", "van"],
    rivian: ["suv", "truck"],
    "rolls royce": ["sedan", "suv", "coupe", "convertible"],
    skoda: ["sedan", "suv", "hatchback"],
    smart: ["coupe", "convertible", "hatchback"],
    ssangyong: ["suv", "truck"],
    subaru: ["sedan", "suv", "hatchback"],
    suzuki: ["sedan", "suv", "hatchback"],
    tesla: ["sedan", "suv", "truck"],
    toyota: [
      "sedan",
      "suv",
      "hatchback",
      "coupe",
      "convertible",
      "van",
      "truck",
    ],
    uaz: ["suv", "truck"],
    volkswagen: ["sedan", "suv", "hatchback", "coupe", "convertible", "van"],
    volvo: ["sedan", "suv", "hatchback", "wagon"],
  } as CarOption,

  // Body style icons
  bodyStyleIcon: {
    convertible: convertibleIcon,
    coupe: coupeIcon,
    hatchback: hatchbackIcon,
    sedan: sedanIcon,
    wagon: wagonIcon,
    suv: suvIcon,
    "compact suv": suvIcon,
    truck: pickupIcon,
    van: minivanIcon,
    atv: atvIcon,
    utv: utvIcon,
  } as { [key: string]: string },

  // Status
  status: ["available", "sold"],
  // Condition
  condition: ["new", "used"],
  // Exterior color
  exteriorColor: [
    "black",
    "white",
    "silver",
    "cement",
    "gray",
    "red",
    "maroon",
    "blue",
    "green",
    "yellow",
    "orange",
    "purple",
    "brown",
    "beige",
    "gold",
    "pink",
    "bronze",
    "dark blue",
    "dark green",
    "dark red",
    "dark gray",
    "dark brown",
    "dark purple",
    "dark orange",
    "dark yellow",
    "two tone",
  ],

  // Interior color
  interiorColor: [
    "black",
    "gray",
    "silver",
    "white",
    "beige",
    "brown",
    "red",
    "maroon",
    "blue",
    "green",
    "cream",
    "orange",
    "yellow",
    "purple",
    "pink",
    "gold",
    "bronze",
  ],
  // Drivetrain
  drivetrain: ["awd", "4wd", "fwd", "rwd"],
  // Paint
  paint: [
    "no paint",
    "clean title",
    "1 part",
    "2 parts",
    "3 parts",
    "4 parts",
    "5 parts",
    "6 parts",
    "7 parts",
    "8 parts",
    "9 parts",
    "10 parts",
    "all paint",
  ],
  // Seating
  seating: [
    "2 seats",
    "3 seats",
    "4 seats",
    "5 seats",
    "6 seats",
    "7 seats",
    "8 seats",
    "9 seats",
    "10 seats",
    "10+ seats",
  ],
  // Plate category
  plateCategory: ["private", "taxi", "commercial", "temporary", "no plate"],
  // Plate city
  plateCity: [
    "erbil",
    "sulaymaniyah",
    "duhok",
    "kirkuk",
    "halabja",
    "baghdad",
    "basrah",
    "mosul",
    "anbar",
  ],
  // Seat material
  seatMaterial: ["leather", "cloth", "suede", "synthetic", "vinyl"],
  // Fuel type
  fuelType: ["gasoline", "diesel", "electric", "hybrid", "plug in hybrid"],
  // Transmission
  transmission: ["automatic", "manual"],
  // Cylinders
  cylinders: [
    "electric",
    "1 cylinder",
    "2 cylinder",
    "3 cylinder",
    "4 cylinder",
    "5 cylinder",
    "6 cylinder",
    "8 cylinder",
    "10 cylinder",
    "12 cylinder",
    "16 cylinder",
  ],
  // Engine
  engine: [
    "electric",
    "0.5",
    "1.0",
    "1.2",
    "1.3",
    "1.4",
    "1.5",
    "1.6",
    "1.7",
    "1.8",
    "2.0",
    "2.2",
    "2.3",
    "2.4",
    "2.5",
    "2.6",
    "2.7",
    "2.8",
    "3.0",
    "3.2",
    "3.3",
    "3.4",
    "3.5",
    "3.6",
    "3.7",
    "3.8",
    "4.0",
    "4.2",
    "4.3",
    "4.4",
    "4.5",
    "4.6",
    "4.7",
    "4.8",
    "5.0",
    "5.2",
    "5.3",
    "5.4",
    "5.5",
    "5.6",
    "5.7",
    "5.8",
    "6.0",
    "6.2",
    "6.3",
    "6.4",
    "6.6",
    "6.7",
    "6.8",
    "7.0",
  ],
  // Horsepower
  horsepower: [
    "50+",
    "100+",
    "200+",
    "300+",
    "400+",
    "500+",
    "600+",
    "700+",
    "800+",
    "900+",
    "1000+",
  ],
  // Mileage unit
  mileageUnit: ["km", "miles"],
  // Price unit
  priceUnit: ["iqd", "usd"],
  // Importer
  importer: [
    "canada",
    "europe",
    "france",
    "gcc",
    "germany",
    "iraq",
    "italy",
    "japan",
    "korea",
    "uk",
    "usa",
    "other",
  ],
  // Features
  features: [
    "Air Conditioning",
    "Airbags",
    "Alarm/Anti-Theft System",
    "AM/FM Radio",
    "Aux Audio In",
    "Bluetooth System",
    "Body Kit",
    "Brush Guard",
    "Cassette Player",
    "CD Player",
    "Climate Control",
    "Cooled Seats",
    "Cruise Control",
  ],
};

export const FilterData = {
  condition: ["both", "new", "used"],
  exteriorColor: [
    "all colors",
    "black",
    "white",
    "silver",
    "cement",
    "gray",
    "red",
    "maroon",
    "blue",
    "green",
    "yellow",
    "orange",
    "purple",
    "brown",
    "beige",
    "gold",
    "pink",
    "bronze",
    "dark blue",
    "dark green",
    "dark red",
    "dark gray",
    "dark brown",
    "dark purple",
    "dark orange",
    "dark yellow",
    "two tone",
  ],
  interiorColor: [
    "all colors",
    "black",
    "gray",
    "silver",
    "white",
    "beige",
    "brown",
    "red",
    "maroon",
    "blue",
    "green",
    "cream",
    "orange",
    "yellow",
    "purple",
    "pink",
    "gold",
    "bronze",
  ],
  location: [
    "all",
    "erbil",
    "sulaymaniyah",
    "baghdad",
    "duhok",
    "kirkuk",
    "halabja",
    "zakho",
    "basrah",
    "mosul",
    "anbar",
  ],

  year: {
    from: [
      "Oldest",
      "2023",
      "2022",
      "2021",
      "2020",
      "2019",
      "2018",
      "2017",
      "2016",
      "2015",
      "2014",
      "2013",
      "2012",
      "2011",
      "2010",
      "2009",
      "2008",
      "2007",
      "2006",
      "2005",
      "2004",
      "2003",
      "2002",
      "2001",
      "2000",
      "1999",
      "1998",
      "1997",
      "1996",
      "1995",
      "1994",
      "1993",
      "1992",
      "1991",
      "1990",
      "1989",
      "1988",
      "1987",
      "1986",
      "1985",
      "1984",
      "1983",
      "1982",
      "1981",
      "1980",
      "1979",
      "1978",
      "1977",
      "1976",
      "1975",
      "1974",
      "1973",
      "1972",
      "1971",
      "1970",
      "1969",
      "1968",
      "1967",
      "1966",
      "1965",
      "1964",
      "1963",
      "1962",
      "1961",
      "1960",
      "1959",
      "1958",
      "1957",
      "1956",
      "1955",
      "1954",
      "1953",
      "1952",
      "1951",
      "1950",
    ],
    to: [
      "Newest",
      "2023",
      "2022",
      "2021",
      "2020",
      "2019",
      "2018",
      "2017",
      "2016",
      "2015",
      "2014",
      "2013",
      "2012",
      "2011",
      "2010",
      "2009",
      "2008",
      "2007",
      "2006",
      "2005",
      "2004",
      "2003",
      "2002",
      "2001",
      "2000",
      "1999",
      "1998",
      "1997",
      "1996",
      "1995",
      "1994",
      "1993",
      "1992",
      "1991",
      "1990",
      "1989",
      "1988",
      "1987",
      "1986",
      "1985",
      "1984",
      "1983",
      "1982",
      "1981",
      "1980",
      "1979",
      "1978",
      "1977",
      "1976",
      "1975",
      "1974",
      "1973",
      "1972",
      "1971",
      "1970",
      "1969",
      "1968",
      "1967",
      "1966",
      "1965",
      "1964",
      "1963",
      "1962",
      "1961",
      "1960",
      "1959",
      "1958",
      "1957",
      "1956",
      "1955",
      "1954",
      "1953",
      "1952",
      "1951",
      "1950",
    ],
  },

  price: {
    min: [
      "lowest",
      "2500",
      "5000",
      "7500",
      "10000",
      "15000",
      "20000",
      "25000",
      "30000",
      "35000",
      "40000",
      "45000",
      "50000",
      "60000",
      "70000",
      "80000",
      "90000",
      "100000",
      "200000",
    ],
    max: [
      "highest",
      "2500",
      "5000",
      "7500",
      "10000",
      "15000",
      "20000",
      "25000",
      "30000",
      "35000",
      "40000",
      "45000",
      "50000",
      "60000",
      "70000",
      "80000",
      "90000",
      "100000",
      "200000",
    ],
  },

  mileage: [
    "any",
    "under 10000",
    "under 20000",
    "under 30000",
    "under 40000",
    "under 50000",
    "under 60000",
    "under 70000",
    "under 80000",
    "under 90000",
    "under 100000",
    "under 125000",
    "under 150000",
    "under 175000",
    "under 200000",
    "over 200000",
  ],
  transmission: ["all transmissions", "automatic", "manual"],
  fuelType: [
    "all fuel types",
    "diesel",
    "electric",
    "gasoline",
    "hybrid",
    "plug in hybrid",
  ],
  filterShowrooms: ["all showrooms", "sale", "rental"],
  times: ["today", "this week", "this month", "this year"],
};
